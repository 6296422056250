/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import doneIcon from "resources/icon/done-pr.svg";

import styles from "./Welcome.module.scss";

export const Welcome = () => {
    return (
        <>
            <div className={`${styles.welcome}` + " global-width"}>
                <div className={styles.welcome__logo}>
                    <img src={doneIcon} alt="" />
                </div>
                <div className={`${styles.welcome__mainTxt}` + " heading-2"}>가입을 완료했습니다!</div>
                <p className={`${styles.welcome__subTxt}` + " pl"}>회원가입 승인이 진행되면 서비스를 이용하실 수 있습니다.</p>

                <div className={`${styles.welcome__buttons}` + " button-form"}>
                    <Link to={`/login`} className="btn btn-xl btn--primary primaryFill btnLarge">
                        다음
                    </Link>
                </div>
            </div>
        </>
    );
};
