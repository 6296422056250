/* eslint-disable */
import React, { useEffect } from "react";
import { convertToKoreanWon } from "util";

export const SpecialAgreement = ({ initData, depositRentalFee, deductible }) => {
    useEffect(() => {
        console.log(initData);
        console.log(deductible);
    }, [initData, deductible]);

    return (
        <section id="section-07" className="section width-100">
            <div className="section-tit">
                <h2>특약</h2>
            </div>

            <div className="section-content">
                <div className="desc-wrapper">
                    {depositRentalFee?.depositTwiceYn === "Y" && (
                        <p className="desc">
                            <em>• 보증금 2회 분납 가능</em>
                        </p>
                    )}
                    {deductible?.contractAgeTypes?.includes("AGE_21") && (
                        <p className="desc">
                            <em>• 만 21세 이상 가능</em>
                            {"\n"}
                            {deductible?.age21RentPrice && `* 월 렌트료 ${convertToKoreanWon(deductible?.age21RentPrice)} 추가`}
                            {"\n"}
                            {deductible?.age21IndemPrice && `* 면책금 기본 + ${convertToKoreanWon(deductible?.age21IndemPrice)} 추가`}
                        </p>
                    )}
                    {deductible?.contractAgeTypes?.includes("AGE_23") && (
                        <p className="desc">
                            <em>• 만 23세 이상 가능 </em>
                            {"\n"}
                            {deductible?.age23RentPrice && `* 월 렌트료 ${convertToKoreanWon(deductible?.age23RentPrice)} 추가`}
                            {"\n"}
                            {deductible?.age23IndemPrice && `* 면책금 기본 + ${convertToKoreanWon(deductible?.age23IndemPrice)} 추가`}
                        </p>
                    )}
                    {deductible?.expLt1Yn === "Y" && (
                        <p className="desc">
                            <em>• 운전경력 1년미만 추가금</em>
                            {"\n"}
                            {deductible?.expLt1RentPrice && `* 월 렌트료 ${convertToKoreanWon(deductible?.expLt1RentPrice)} 추가`}
                            {"\n"}
                            {deductible?.expLt1IndemPrice && `* 면책금 기본 + ${convertToKoreanWon(deductible?.expLt1IndemPrice)} 추가`}
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
};
