/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { comma, handleNumericInput, getTakeOverTypeList } from "util";

export const ContractConditions = ({ initData, onConditionsChange }) => {
    const [formData, setFormData] = useState({
        supportDeliveryFee: undefined, // 탁송비지원
        deliverySupportPrice: "", // 지원 금액
        maintenanceProduct: undefined, // 정비상품
        inspectionProducts: [], // 정비상품 내용
        contractTypes: ["ONLINE"], // 계약방식
        takeOverType: "", // 차량 만기 인수 옵션
        yearContractMileage: "", // 연간약정 주행거리
    });

    const {
        supportDeliveryFee,
        maintenanceProduct,
        contractTypes,
        deliverySupportPrice,
        inspectionProducts,
        takeOverType,
        yearContractMileage,
    } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const name = target.name ? target.name : e.name;

        if (target.type === "checkbox") {
            if (target.checked) {
                setFormData((prevState) => ({
                    ...prevState,
                    inspectionProducts: [...inspectionProducts, value],
                }));
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    inspectionProducts: inspectionProducts.filter((item) => item !== value),
                }));
            }
        } else {
            if (name === "contractTypes") {
                // 계약방식 멀티플 선택
                if (contractTypes.includes(value)) {
                    // 이미 선택된 경우 선택 해제
                    setFormData((prevState) => ({
                        ...prevState,
                        contractTypes: contractTypes.filter((type) => type !== value),
                    }));
                } else {
                    // 선택되지 않은 경우 선택
                    setFormData((prevState) => ({
                        ...prevState,
                        contractTypes: [...contractTypes, value],
                    }));
                }
            } else {
                // 다른 입력 항목은 그대로 처리
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
    };

    // 스테이트 리프팅
    useEffect(() => {
        onConditionsChange(formData);
    }, [formData, onConditionsChange]);

    // 셀렉트박스
    const [options, setOptions] = useState({
        takeOverTypeOptions: [],
    });

    const { takeOverTypeOptions } = options;

    const fetchSelectOptions = async () => {
        const takeOverTypeOptionsPromise = getTakeOverTypeList();

        const [takeOverType] = await Promise.all([takeOverTypeOptionsPromise]);

        setOptions({
            takeOverTypeOptions: takeOverType,
        });
    };

    useEffect(() => {
        fetchSelectOptions();
    }, []);

    useEffect(() => {
        if (initData !== undefined) {
            const selectedInspectionProducts = initData?.rentInfo?.inspectionProducts.map((item) => item.name);

            setFormData({
                supportDeliveryFee: initData?.rentInfo?.deliverySupportPrice ? "Y" : "N",
                deliverySupportPrice: comma(initData?.rentInfo?.deliverySupportPrice),
                maintenanceProduct: initData?.rentInfo?.inspectionProducts.length !== 0 ? "Y" : "N",
                inspectionProducts: selectedInspectionProducts,
                contractTypes: initData?.rentInfo?.contractTypes,
                takeOverType: initData?.rentInfo?.takeOverType,
                yearContractMileage: comma(initData?.rentInfo?.yearContractMileage),
            });
        }
    }, [initData]);

    return (
        <section id="section-03" className="section width-100">
            <div className="section-tit">
                <h2>계약조건</h2>
            </div>

            <div className="section-content">
                <div className="form">
                    <div className="input-form">
                        <label className="form-title">만기 인수 옵션</label>
                        <div className="form-field">
                            <div className="select-element">
                                <Select
                                    options={takeOverTypeOptions}
                                    className="custom-select"
                                    classNamePrefix="custom-select"
                                    isSearchable={false}
                                    placeholder="만기 인수옵션"
                                    value={takeOverTypeOptions.filter((option) => option.value === takeOverType)}
                                    onChange={(option) => handleChange({ option, name: "takeOverType" })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">연간약정 주행거리</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder="연간약정 주행거리 입력"
                                    name="yearContractMileage"
                                    value={yearContractMileage || ""}
                                    onChange={handleChange}
                                    onInput={handleNumericInput}
                                />
                            </div>
                            <span className="input-esset">km</span>
                        </div>
                    </div>
                    <div className="input-form"></div>
                    <div className="input-form">
                        <label className="form-title">탁송비지원</label>
                        <div className="form-field direction-col">
                            <div className="button-field gap-6">
                                <button
                                    type="button"
                                    className={`btn ${supportDeliveryFee === "Y" ? "btn-primary" : "btn-default"}`}
                                    onClick={() => handleChange({ target: { name: "supportDeliveryFee", value: "Y" } })}
                                >
                                    지원
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${supportDeliveryFee === "N" ? "btn-primary" : "btn-default"}`}
                                    onClick={() => handleChange({ target: { name: "supportDeliveryFee", value: "N" } })}
                                >
                                    미지원
                                </button>
                            </div>
                            {supportDeliveryFee === "Y" && (
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="지원 금액 입력"
                                        name="deliverySupportPrice"
                                        value={deliverySupportPrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">정비상품</label>
                        <div className="form-field direction-col">
                            <div className="button-field gap-6">
                                <button
                                    type="button"
                                    className={`btn ${maintenanceProduct === "Y" ? "btn-primary" : "btn-default"}`}
                                    onClick={() => handleChange({ target: { name: "maintenanceProduct", value: "Y" } })}
                                >
                                    포함
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${maintenanceProduct === "N" ? "btn-primary" : "btn-default"}`}
                                    onClick={() => handleChange({ target: { name: "maintenanceProduct", value: "N" } })}
                                >
                                    미포함
                                </button>
                            </div>
                            {maintenanceProduct === "Y" && (
                                // <div className="input-element">
                                //     <input
                                //         type="text"
                                //         placeholder="정비상품 입력"
                                //         name="inspectionProducts"
                                //         value={inspectionProducts || ""}
                                //         onChange={handleChange}
                                //     />
                                // </div>
                                <>
                                    <div className="input-check">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="shareCarInfoTypes"
                                                    value="엔진오일 연1회"
                                                    onChange={handleChange}
                                                    checked={formData?.inspectionProducts?.includes("엔진오일 연1회")}
                                                />
                                                <div></div>
                                                <span>엔진오일 연1회</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-check">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="shareCarInfoTypes"
                                                    value="순회정비 연2회"
                                                    onChange={handleChange}
                                                    checked={formData?.inspectionProducts?.includes("순회정비 연2회")}
                                                />
                                                <div></div>
                                                <span>순회정비 연2회</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-check">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="shareCarInfoTypes"
                                                    value="순회정비 연4회"
                                                    onChange={handleChange}
                                                    checked={formData?.inspectionProducts?.includes("순회정비 연4회")}
                                                />
                                                <div></div>
                                                <span>순회정비 연4회</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-check">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="shareCarInfoTypes"
                                                    value="순회정비 연6회"
                                                    onChange={handleChange}
                                                    checked={formData?.inspectionProducts?.includes("순회정비 연6회")}
                                                />
                                                <div></div>
                                                <span>순회정비 연6회</span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">계약방식</label>
                        <div className="form-field direction-col">
                            <div className="button-field gap-6">
                                <button type="button" className={`btn ${contractTypes.includes("ONLINE") ? "btn-primary" : "btn-default"}`}>
                                    전자계약
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${contractTypes.includes("OFFLINE") ? "btn-primary" : "btn-default"}`}
                                    style={{ visibility: "hidden", pointerEvents: "none" }}
                                >
                                    방문계약
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
