/* eslint-disable */
import React, { useState, useRef } from "react";
import { FormStep1, FormStep2, FormStep3, FormStep4, FormStep5, FormStep6 } from ".";

export const ContractStep2 = ({ tabIndex, termCheckedStatus, setTermCheckedStatus, takeoverInfo, setTakeoverInfo, data }) => {
    return (
        <>
            <section id="section-02">
                <div className="section-tab_menu">
                    <div className={tabIndex === 1 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">1</span>
                        <span className="tab_tit-txt">서면등록 및 약관동의</span>
                    </div>
                    <div className={tabIndex === 2 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">2</span>
                        <span className="tab_tit-txt">렌터카정보 및 차량정보</span>
                    </div>
                    <div className={tabIndex === 3 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">3</span>
                        <span className="tab_tit-txt">자동차보험</span>
                    </div>
                    <div className={tabIndex === 4 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">4</span>
                        <span className="tab_tit-txt">정비서비스 및 기타</span>
                    </div>
                    <div className={tabIndex === 5 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">5</span>
                        <span className="tab_tit-txt">인수정보</span>
                    </div>
                    <div className={tabIndex === 6 ? "tab-tit active" : "tab-tit"}>
                        <span className="tab_tit-num">6</span>
                        <span className="tab_tit-txt">결제금액</span>
                    </div>
                </div>
                <div className="section-tab_body-container">
                    {tabIndex === 1 && <FormStep1 data={data}termCheckedStatus={termCheckedStatus} setTermCheckedStatus={setTermCheckedStatus} />}
                    {tabIndex === 2 && <FormStep2 data={data} />}
                    {tabIndex === 3 && <FormStep3 data={data} />}
                    {tabIndex === 4 && <FormStep4 />}
                    {tabIndex === 5 && <FormStep5 data={data} takeoverInfo={takeoverInfo} setTakeoverInfo={setTakeoverInfo} />}
                    {tabIndex === 6 && <FormStep6 data={data} takeoverInfo={takeoverInfo} />}
                </div>
            </section>
        </>
    );
};
