/* eslint-disable */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { handleNumericInput } from "util";
import { Toast } from "components";

import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const SetGrade = ({ setGradeModalStatus }) => {
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태
    const [formData, setFormData] = useState({
        commissionRateGold: "",
        commissionRateSilver: "",
        commissionRateBronze: "",
        commissionRateNormal: "",
    });

    const { commissionRateGold, commissionRateSilver, commissionRateBronze, commissionRateNormal } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const name = target.name ? target.name : e.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const fetchCommissionData = async () => {
        setLoading(true);

        try {
            const response = await fetchData("/api/share/ag_corp/v1/grade_commission_rate");

            // 등급에 따라 commissionRate 세팅
            const commissionRates = {
                commissionRateGold: "",
                commissionRateSilver: "",
                commissionRateBronze: "",
                commissionRateNormal: "",
            };

            response.data.data.forEach(({ shareGrade, commissionRate }) => {
                switch (shareGrade) {
                    case "GOLD":
                        commissionRates.commissionRateGold = commissionRate;
                        break;
                    case "SILVER":
                        commissionRates.commissionRateSilver = commissionRate;
                        break;
                    case "BRONZE":
                        commissionRates.commissionRateBronze = commissionRate;
                        break;
                    case "NORMAL":
                        commissionRates.commissionRateNormal = commissionRate;
                        break;
                    default:
                        break;
                }
            });
            console.log(commissionRates);
            setFormData(commissionRates);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const updateCommissionData = async () => {
        setLoading(true);

        try {
            const requestData = {
                gradeCommissionRates: [
                    {
                        shareGrade: "GOLD",
                        commissionRate: Number(commissionRateGold),
                    },
                    {
                        shareGrade: "SILVER",
                        commissionRate: Number(commissionRateSilver),
                    },
                    {
                        shareGrade: "BRONZE",
                        commissionRate: Number(commissionRateBronze),
                    },
                    {
                        shareGrade: "NORMAL",
                        commissionRate: Number(commissionRateNormal),
                    },
                ],
            };
            console.log(requestData);

            const response = await updateData("/api/share/ag_corp/v1/grade_commission_rate", requestData);
            handleToast(response.data.message);
            console.log(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const handleUpdate = () => {
        updateCommissionData();
    };

    useEffect(() => {
        fetchCommissionData();
    }, []);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal modal-sub">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>등급 설정</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setGradeModalStatus(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="grade_set-table">
                        <table>
                            <colgroup>
                                <col width={"40%"} />
                                <col width={"60%"} />
                            </colgroup>

                            <thead>
                                <tr>
                                    <th>등급</th>
                                    <th>등급별 지급률 (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="grade-icon grade-gold">
                                            <img src={GoldIcon} alt="" />
                                            <span className="grade-txt">gold</span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="지급률% 입력"
                                            name="commissionRateGold"
                                            value={commissionRateGold || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="grade-icon grade-silver">
                                            <img src={SilverIcon} alt="" />
                                            <span className="grade-txt">silver</span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="지급률% 입력"
                                            name="commissionRateSilver"
                                            value={commissionRateSilver || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="grade-icon grade-bronze">
                                            <img src={BronzeIcon} alt="" />
                                            <span className="grade-txt">bronze</span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="지급률% 입력"
                                            name="commissionRateBronze"
                                            value={commissionRateBronze || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="grade-icon grade-normal">
                                            <img src={NormalIcon} alt="" />
                                            <span className="grade-txt">normal</span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="지급률% 입력"
                                            name="commissionRateNormal"
                                            value={commissionRateNormal || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="button-row">
                    <button type="button" className="btn btn-grade" onClick={handleUpdate}>
                        변경
                    </button>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
