/* eslint-disable */
import React from "react";
import styles from "./Footer.module.scss";
import LogoIcon from "resources/icon/share-logo.png";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__content}>
                <div className={styles.footer__content__logo}>
                    <img src={LogoIcon} alt="" />
                </div>
                <div className={styles.footer__content__detail}>
                    <ul>
                        <li>
                            <span>상호명 : </span>
                            <span>모비먼트(주)</span>
                        </li>
                        <li>
                            <span>대표자 : </span>
                            <span>한규민</span>
                        </li>
                        <li>
                            <span>사업자등록번호 : </span>
                            <span>172-87-01718</span>
                        </li>
                        <li>
                            <span>통신판매업신고 : </span>
                            <span>2024-서울금천-0693</span>
                        </li>
                        {/* <li>
              <span>통신판매업번호 : </span>
              <span>123-56-78900</span>
            </li> */}
                    </ul>
                    <ul>
                        <li>
                            <span>주소 : </span>
                            {/* <span>서울특별시 금천구 가산디지털1로 30, 10동 19층 1901호</span> */}
                            <span>경기 성남시 수정구 위례서일로 10 슈퍼스타타워 902호</span>
                        </li>
                        <li>
                            <span>TEL : </span>
                            <span>1644-4145</span>
                        </li>
                        <li>
                            <span>E-MAIL : </span>
                            <span>mobi@mobiment.co.kr</span>
                        </li>
                    </ul>
                </div>
                <div className={styles.footer__content__terms}>
                    <ul>
                        <li>
                            <a href="http://www.mobiment.co.kr" target="_blank">
                                회사소개
                            </a>
                        </li>
                        <li>
                            <a href="http://www.chada.kr" target="_blank">
                                차다소개
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
