/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableCell } from "components";
import { comma } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faHeart } from "@fortawesome/free-solid-svg-icons";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const TableRow = ({ loading, active, row, setActiveRow, onClick, compareItems, onCompareClick, filters }) => {
    const navigate = useNavigate();

    const handleDetailClick = (id) => {
        navigate(`/product/detail/${row.id}`);
    };

    // 비교하기 리스트에 포함되어 있는지 여부를 확인
    const isCompared = compareItems.some((item) => item.id === row.id);

    const handleView = (e) => {
        if (active === true) {
            // 클릭된 요소의 부모 요소들 중에 btn-detail, btn-compare, btn-favorite 중 하나라도 포함하는지 확인
            const isButtonArea = e.target.closest(".btn-detail") || e.target.closest(".btn-compare") || e.target.closest(".btn-favorite");

            // 버튼 영역 외의 부분을 클릭한 경우에만 active 제거
            if (!isButtonArea) {
                setActiveRow(null);
            }
        } else {
            onClick();
        }
    };

    const handleClickOutside = (e) => {
        if (active && !e.target.closest("tr")) {
            setActiveRow(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [active]);

    // useEffect(() => {
    //     console.log(filters.contract_range_month_list);
    // }, [filters]);

    return loading ? (
        <tr>
            <TableCell className="td_model">
                <div className="tdd">
                    <span className="model_name">
                        <Skeleton width={150} height={20} />
                    </span>
                    <span className="model_detail">
                        <Skeleton width={350} height={20} />
                    </span>
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    <Skeleton width={150} height={20} />
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    <Skeleton width={100} height={20} />
                </div>
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
            <TableCell className="td_deposit">
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
            <TableCell className="td_rent">
                <div className="tdd">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                </div>
            </TableCell>
        </tr>
    ) : (
        <tr onClick={handleView} className={active ? "active" : ""}>
            <TableCell className="td_model">
                <div className="tdd">
                    <span className="model_name">{row?.modelName}</span>
                    <span className="model_status">
                        {row?.shareReleaseStatus && (
                            <span className="status_yellow">
                                {row.shareReleaseStatus === "OK"
                                    ? "출고가능"
                                    : row.shareReleaseStatus === "IN_10_DAY"
                                    ? "10일이내 출고가능"
                                    : row.shareReleaseStatus === "IN_5_DAY"
                                    ? "5일이내 출고가능"
                                    : row.shareReleaseStatus === "IN_CONTRACT"
                                    ? "계약중"
                                    : row.shareReleaseStatus === "NO"
                                    ? "출고불가"
                                    : "알 수 없음"}
                            </span>
                        )}

                        {row?.rentInfo?.rentType && (
                            <span className="status_orange">
                                {row.rentInfo?.rentType === "LONG_RENT"
                                    ? "장기렌트"
                                    : row.rentInfo?.rentType === "SUBSCRIPTION"
                                    ? "구독"
                                    : row.rentInfo?.rentType === "FIRST_RELEASE"
                                    ? "선출고"
                                    : "알 수 없음"}
                            </span>
                        )}
                    </span>
                    <span className="model_detail">
                        {row?.modelDetailName} {row?.className}
                    </span>
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    {row?.trimName}
                    {/* {row?.carType === "COMPACT" ? "경차" :
                row?.carType === "SMALL" ? "소형차" :
                  row?.carType === "SMALL_MID" ? "준중형차" :
                    row?.carType === "MID" ? "중형차" :
                      row?.carType === "BIG" ? "대형차" :
                        row?.carType === "RV" ? "RV차" :
                          row?.carType === "SUV" ? "SUV차" :
                            row?.carType === "SPORT" ? "스포츠카" :
                              row?.carType === "VAN" ? "승합차" :
                                row?.carType === "ETC" ? "기타" :
                                  "알 수 없음"} */}
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">
                    {row?.fuelType === "GAS"
                        ? "가솔린"
                        : row?.fuelType === "DIESEL"
                        ? "디젤"
                        : row?.fuelType === "LPG"
                        ? "LPG"
                        : row?.fuelType === "GAS_LPG"
                        ? "가솔린(LPG겸용)"
                        : row?.fuelType === "GAS_CNG"
                        ? "가솔린(CNG겸용)"
                        : row?.fuelType === "GAS_HYBRID"
                        ? "가솔린 하이브리드"
                        : row?.fuelType === "ELECTRIC"
                        ? "전기"
                        : row?.fuelType === "HYDROGEN"
                        ? "수소"
                        : "알 수 없음"}
                </div>
            </TableCell>
            <TableCell>
                <div className="tdd">{row?.color}</div>
            </TableCell>
            <TableCell>
                <div className="tdd">{comma(row?.mileage)}km</div>
                <div className="tdd">
                    {row?.carMadedYear}.{row?.carMadedMonth}
                </div>
            </TableCell>
            <TableCell>
                {row?.rentInfo?.contractPrices
                    ?.filter((priceInfo) => filters.contract_range_month_list.includes(priceInfo.contractRangeMonth.toString()))
                    .map((priceInfo) => (
                        <div key={priceInfo.id} className="tdd">
                            {priceInfo.contractRangeMonth}개월
                        </div>
                    ))}
            </TableCell>
            <TableCell className="td_deposit">
                {row?.rentInfo?.contractPrices
                    ?.filter((priceInfo) => filters.contract_range_month_list.includes(priceInfo.contractRangeMonth.toString()))
                    .map((priceInfo) => (
                        <div key={priceInfo.id} className="tdd">
                            {comma(priceInfo.depositPrice)}원
                        </div>
                    ))}
            </TableCell>
            <TableCell className="td_rent">
                {row?.rentInfo?.contractPrices
                    ?.filter((priceInfo) => filters.contract_range_month_list.includes(priceInfo.contractRangeMonth.toString()))
                    .map((priceInfo) => (
                        <div key={priceInfo.id} className="tdd">
                            {comma(priceInfo.rentPrice)}원
                        </div>
                    ))}
            </TableCell>

            <td className="col_hover" colSpan={8}>
                <button type="button" className="btn-detail" onClick={() => handleDetailClick(row.id)}>
                    상세보기
                </button>
                <button type="button" className={`btn-compare ${isCompared ? "active" : ""}`} onClick={() => onCompareClick(row)}>
                    <FontAwesomeIcon icon={faExchangeAlt} />
                    비교하기 <b>{compareItems.length}/3</b>
                </button>
                {/* <button type="button" className="btn-favorite">
                    <FontAwesomeIcon icon={faHeart} />
                </button> */}
            </td>
        </tr>
    );
};
