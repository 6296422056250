/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { AdditionalDrivers } from ".";
import { handleNumericInputWithoutComma, comma, handleNumericInput, getDepositCountList, calculateKoreanAge, getAgeCategory } from "util";
import { fetchData, createData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";
import searchIcon from "resources/icon/search-pr.svg";

export const ContractStep3 = ({
    carData,
    driverInfo,
    customerInfo,
    setCustomerInfo,
    additionalDrivers,
    setAdditionalDrivers,
    handleCheckDriverLincese,
    verifyDriverLinceseSub,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        rentType: customerInfo.rentType || "LONG_RENT",
        customerType: customerInfo.customerType || "PERSONAL",
        depositType: customerInfo.depositType || "",
        phone: customerInfo.phone || "",
        address: customerInfo.address || "",
        addressDetail: "",
        contractRangeMonth: customerInfo.contractRangeMonth || undefined,
        corpName: customerInfo.corpName || "",
        corpAddress: customerInfo.corpAddress || "",
        corpAddressDetail: "",
        bizLicenseNumber: customerInfo.bizLicenseNumber || "",
        depositPrice: "",
        rentalPrice: "",
        // depositPrice: comma(customerInfo.depositPrice) || "",
        // rentalPrice: comma(customerInfo.rentalPrice) || "",
        additionalDriverStatus: customerInfo.additionalDriverStatus || false,
    });
    // const [additionalDriverStatus, setAdditionalDriverStatus] = useState(false);

    const {
        customerType,
        depositType,
        phone,
        address,
        addressDetail,
        contractRangeMonth,
        corpName,
        corpAddress,
        corpAddressDetail,
        bizLicenseNumber,
        depositPrice,
        rentalPrice,
        additionalDriverStatus,
    } = formData;

    const [conditionalPrice, setConditionalPrice] = useState({
        age21RentPrice: null,
        age23RentPrice: null,
        expLt1RentPrice: null,
    });
    const { age21RentPrice, age23RentPrice, expLt1RentPrice, expLt1Yn } = conditionalPrice;

    // idNumber를 사용하여 최연소 운전자 찾기
    const findYoungestDriver = (drivers) => {
        let youngestAge = Infinity;
        let youngestDriver = null;

        drivers.forEach((driver) => {
            if (!driver.idNumber || driver.idNumber.length < 6) {
                // idNumber가 없거나 길이가 6 미만인 경우, 이 항목을 무시
                return;
            }

            // 연도 추출 로직 수정
            const yearPrefix = parseInt(driver.idNumber.substring(0, 2), 10);
            const birthYear = yearPrefix < 22 ? 2000 + yearPrefix : 1900 + yearPrefix; // 2022년을 기준으로 작성, 22년 이하면 2000년대, 그렇지 않으면 1900년대
            const currentYear = new Date().getFullYear();
            const age = currentYear - birthYear;

            if (age < youngestAge) {
                youngestAge = age;
                youngestDriver = driver;
            }
        });

        return youngestDriver;
    };

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        if (target.type === "checkbox") {
            const dataTarget = target?.getAttribute("data-target");

            // Checkbox인 경우 처리
            if (target.checked) {
                // Checkbox가 체크된 경우 해당 값을 배열에 추가
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: inputName,
                }));
            } else {
                // Checkbox가 체크 해제된 경우 해당 값을 배열에서 제거
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: prevState[dataTarget].filter((item) => item !== inputName),
                }));
            }
        } else {
            // 다른 input 요소인 경우 처리 (예: input type="text")
            if (inputName === "contractRangeMonth") {
                // 개월수 선택시 보증금/렌탈료 기본 세팅
                const selectedMonthData = carData?.rentInfo?.contractPrices?.find((item) => item.contractRangeMonth.toString() === value);

                // 렌탈료 조건 추가사항
                // A. 면허취득 1년미만 계산
                let baseRentPrice = selectedMonthData?.rentPrice;
                if (carData?.rentInfo.expLt1Yn === "Y") {
                    if (driverInfo.expLt1Yn === "Y" || additionalDrivers.expLt1Yn === "Y") {
                        baseRentPrice += conditionalPrice.expLt1RentPrice;
                    }
                }

                // B. 연령에 맞는 렌탈료 계산
                const driversArray = [
                    {
                        ...driverInfo,
                        // driverInfo에 대해 idNumber 생성
                        idNumber: driverInfo.idNumber1 && driverInfo.idNumber2 ? `${driverInfo.idNumber1}${driverInfo.idNumber2}` : "",
                    },
                    // additionalDrivers에서 각 운전자에 대해 idNumber 생성
                    ...(Array.isArray(additionalDrivers) ? additionalDrivers : [additionalDrivers]).map((driver) => ({
                        ...driver,
                        idNumber: driver.idNumber1 && driver.idNumber2 ? `${driver.idNumber1}${driver.idNumber2}` : "",
                    })),
                ];

                const youngestDriver = findYoungestDriver(driversArray);
                const ageCondition = getAgeCategory(youngestDriver.idNumber1 + youngestDriver.idNumber2);

                if (ageCondition === "만 26세 이상") {
                    baseRentPrice += 0;
                } else if (ageCondition === "만 23세 이상") {
                    baseRentPrice += conditionalPrice.age23RentPrice;
                } else if (ageCondition === "만 21세 이상") {
                    baseRentPrice += conditionalPrice.age21RentPrice;
                } else if (ageCondition === "만 21세 미만") {
                }

                setFormData((prevState) => ({
                    ...prevState,
                    contractRangeMonth: value,
                    depositPrice: comma(selectedMonthData?.depositPrice?.toString()) || "",
                    rentalPrice: comma(baseRentPrice?.toString()) || "",
                }));
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    [inputName]: value,
                }));
            }
        }
    };

    useEffect(() => {
        setConditionalPrice({
            age21RentPrice: carData?.rentInfo?.age21RentPrice,
            age23RentPrice: carData?.rentInfo?.age23RentPrice,
            expLt1RentPrice: carData?.rentInfo?.expLt1RentPrice,
            expLt1Yn: carData?.rentInfo?.expLt1Yn,
        });
    }, [carData]);

    // 셀렉트박스
    const [options, setOptions] = useState({
        contractRangeMonthOptions: [],
    });

    const { contractRangeMonthOptions } = options;

    // 개월수에 따른 셀렉트 박스 옵션 생성 함수
    function createContractRangeMonthOptions(carData) {
        const uniqueMonths = [...new Set(carData?.rentInfo?.contractPrices?.map((item) => item.contractRangeMonth))];
        return uniqueMonths.map((month) => ({
            value: month.toString(),
            label: `${month}개월`,
        }));
    }

    const fetchSelectOptions = async (carData) => {
        const contractRangeMonthOptions = createContractRangeMonthOptions(carData);

        setOptions({
            contractRangeMonthOptions: contractRangeMonthOptions,
        });
    };

    useEffect(() => {
        if (!carData) return;

        fetchSelectOptions(carData);
    }, [carData]);

    // 이미지 파일 처리
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);

        // 현재 업로드된 파일의 개수와 새로운 파일의 개수를 합산
        const totalFilesCount = formData.vehiclePhotos.length + newFiles.length;

        if (totalFilesCount > 10) {
            // 파일 개수가 10개를 초과할 경우 경고 메시지를 표시하고 추가 작업 중단
            showModal("파일 제한 초과", <p>최대 10개의 파일만 업로드할 수 있습니다.</p>, [{ text: "확인", handler: handleModalClose }]);
            return;
        }

        // 파일 개수가 10개 이하인 경우, 기존 파일 목록에 새 파일 추가
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: [...prevState.vehiclePhotos, ...newFiles],
        }));
    };

    const handleImageRemove = (index) => {
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: prevState.vehiclePhotos.filter((_, i) => i !== index),
        }));
    };

    // 스테이트 리프팅
    useEffect(() => {
        setCustomerInfo(formData);
    }, [formData, setCustomerInfo]);

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState({
        isOpen: false,
        addressType: "",
    });

    const openPostCode = (addressType) => {
        setAddressPopup({
            isOpen: true,
            addressType: addressType,
        });
    };

    const closePostCode = () => {
        setAddressPopup({
            isOpen: false,
            addressType: "",
        });
    };

    const handleAddress = (data) => {
        if (addressPopup.addressType === "PER") {
            setFormData((prevState) => ({
                ...prevState,
                address: data.daumData.address,
                // srcAddressDetail: "",
                // srcBcode: data.daumData.bcode,
            }));
        } else if (addressPopup.addressType === "CORP") {
            setFormData((prevState) => ({
                ...prevState,
                corpAddress: data.daumData.address,
                // destAddressDetail: "",
                // destBcode: data.daumData.bcode,
            }));
        }
        closePostCode();
    };

    // 모달
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: "",
        content: null,
        buttons: [],
    });

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const showModal = (title, content, buttons) => {
        setModalContent({ title, content, buttons });
        setModalOpen(true);
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="step">
                <div className="step-customer">
                    <div className="customer-type">
                        <div className="input-check">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={"PERSONAL"}
                                        checked={customerType === "PERSONAL"}
                                        onChange={(e) => handleChange(e)}
                                        data-target="customerType"
                                    />
                                    <div></div>
                                    <span>개인</span>
                                </label>
                            </div>
                        </div>
                        <div className="input-check">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={"BIZ_PERSONAL"}
                                        checked={customerType === "BIZ_PERSONAL"}
                                        onChange={(e) => handleChange(e)}
                                        data-target="customerType"
                                    />
                                    <div></div>
                                    <span>개인 사업자</span>
                                </label>
                            </div>
                        </div>
                        <div className="input-check">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={"BIZ_CORP"}
                                        checked={customerType === "BIZ_CORP"}
                                        onChange={(e) => handleChange(e)}
                                        data-target="customerType"
                                    />
                                    <div></div>
                                    <span>법인 사업자</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="customer-contents">
                        <div className="input-form grid-row">
                            <label className="form-title">렌트유형</label>
                            <div className="form-field">
                                <div className="rent-type">
                                    {customerInfo.rentType === "LONG_RENT" && (
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="LONG_RENT"
                                                    name="rentType"
                                                    value="LONG_RENT"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={customerInfo.rentType === "LONG_RENT"}
                                                />
                                                <label htmlFor="LONG_RENT">장기렌트</label>
                                            </div>
                                        </div>
                                    )}
                                    {customerInfo.rentType === "SUBSCRIPTION" && (
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="SUBSCRIPTION"
                                                    name="rentType"
                                                    value="SUBSCRIPTION"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={customerInfo.rentType === "SUBSCRIPTION"}
                                                />
                                                <label htmlFor="SUBSCRIPTION">구독</label>
                                            </div>
                                        </div>
                                    )}
                                    {customerInfo.rentType === "FIRST_RELEASE" && (
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="FIRST_RELEASE"
                                                    name="rentType"
                                                    value="FIRST_RELEASE"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={customerInfo.rentType === "FIRST_RELEASE"}
                                                />
                                                <label htmlFor="FIRST_RELEASE">선출고</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">보증급 분납</label>
                            <div className="form-field">
                                <div className="button-element">
                                    <button
                                        type="button"
                                        className={`btn ${depositType === "FULL" ? "btn-primary" : "btn-secondary"}`}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                depositType: "FULL",
                                            }));
                                        }}
                                    >
                                        일시납
                                    </button>
                                    {carData?.rentInfo.depositTwiceYn === "Y" && (
                                        <button
                                            type="button"
                                            className={`btn ${depositType === "DIVIDE_2" ? "btn-primary" : "btn-secondary"}`}
                                            onClick={() => {
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    depositType: "DIVIDE_2",
                                                }));
                                            }}
                                        >
                                            2회분납
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">성명</label>
                            <div className="form-field">
                                <div className="input-element disabled">
                                    <span className="elem-desc">{driverInfo.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">면허번호</label>
                            <div className="form-field">
                                <div className="input-element disabled">
                                    <span className="elem-desc">{driverInfo.driverLicenseNumber}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">운전연령</label>
                            <div className="form-field">
                                <div className="input-element disabled">
                                    <span className="elem-desc">{getAgeCategory(driverInfo.idNumber1 + driverInfo.idNumber2)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">주민등록번호</label>
                            <div className="form-field">
                                <div className="input-element disabled">
                                    <span className="elem-desc">{driverInfo.idNumber1}</span>
                                    {/* <input type="text" placeholder="" name="name" value={driverInfo.idNumber1 || ""} onChange={handleChange} readOnly /> */}
                                </div>
                                -
                                <div className="input-element disabled">
                                    <span className="elem-desc">{driverInfo.idNumber2}</span>
                                    {/* <input type="text" placeholder="" name="name" value={driverInfo.idNumber2 || ""} onChange={handleChange} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">추가운전자</label>
                            <div className="form-field">
                                <div className="button-element">
                                    <button
                                        type="button"
                                        className={`btn ${additionalDriverStatus === true ? "btn-primary" : "btn-secondary"}`}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                additionalDriverStatus: true,
                                                contractRangeMonth: undefined,
                                                depositPrice: "",
                                                rentalPrice: "",
                                            }));
                                        }}
                                    >
                                        유
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn ${additionalDriverStatus === false ? "btn-primary" : "btn-secondary"}`}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                additionalDriverStatus: false,
                                                contractRangeMonth: undefined,
                                                depositPrice: "",
                                                rentalPrice: "",
                                            }));
                                        }}
                                    >
                                        무
                                    </button>
                                </div>
                            </div>
                        </div>

                        {additionalDriverStatus === true && (
                            <AdditionalDrivers
                                additionalDrivers={additionalDrivers}
                                setAdditionalDrivers={setAdditionalDrivers}
                                handleCheckDriverLincese={handleCheckDriverLincese}
                                verifyDriverLinceseSub={verifyDriverLinceseSub}
                            />
                        )}
                        <div className="input-form grid-row">
                            <label className="form-title">전화번호</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="-없이 입력"
                                        name="phone"
                                        value={phone || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInputWithoutComma}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">주소</label>
                            <div className="form-field grid-col">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="면허증에 기재된 주소 검색"
                                        name="address"
                                        value={address || ""}
                                        onChange={handleChange}
                                        onClick={() => {
                                            openPostCode("PER");
                                        }}
                                        readOnly
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            openPostCode("PER");
                                        }}
                                    >
                                        <img src={searchIcon} alt="" />
                                    </button>
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="상세주소"
                                        name="addressDetail"
                                        value={addressDetail || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {(customerType === "BIZ_PERSONAL" || customerType === "BIZ_CORP") && (
                            <>
                                <div className="input-form grid-row">
                                    <label className="form-title">상호명</label>
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                placeholder="사업자등록증에 기재된 상호명 입력"
                                                name="corpName"
                                                value={corpName || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-form grid-row">
                                    <label className="form-title">사업장 소재지</label>
                                    <div className="form-field grid-col">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                placeholder="사업자등록증에 기재된 소재지 입력"
                                                name="corpAddress"
                                                value={corpAddress || ""}
                                                onChange={handleChange}
                                                onClick={() => {
                                                    openPostCode("CORP");
                                                }}
                                                readOnly
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    openPostCode("CORP");
                                                }}
                                            >
                                                <img src={searchIcon} alt="" />
                                            </button>
                                        </div>
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                placeholder="상세주소"
                                                name="corpAddressDetail"
                                                value={corpAddressDetail || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-form grid-row">
                                    <label className="form-title">사업자 등록번호</label>
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                placeholder="사업자등록증에 기재된 등록번호 입력"
                                                name="bizLicenseNumber"
                                                value={bizLicenseNumber || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="input-form grid-row">
                            <label className="form-title">개월수</label>
                            <div className="form-field">
                                <div className="select-element" id="menuPortal">
                                    <Select
                                        options={contractRangeMonthOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="개월수"
                                        value={contractRangeMonthOptions.filter((option) => option.value === contractRangeMonth)}
                                        onChange={(option) => handleChange({ option, name: "contractRangeMonth" })}
                                        // menuPlacement="top"
                                        // menuPortalTarget={document.querySelector("#menuPortal")}
                                        // menuPosition={"fixed"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form grid-row">
                            <label className="form-title">보증금/렌탈료</label>
                            <div className="form-field">
                                <div className="input-element disabled">
                                    <input
                                        type="text"
                                        placeholder="보증금"
                                        name="depositPrice"
                                        value={depositPrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                        readOnly
                                    />
                                </div>
                                <div className="input-element disabled">
                                    <input
                                        type="text"
                                        placeholder="렌탈료"
                                        name="rentalPrice"
                                        value={rentalPrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 주소 검색 창 (DaumPost 컴포넌트) */}
            {addressPopup.isOpen && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}

            {modalOpen && (
                <Modal
                    title={modalContent.title}
                    content={modalContent.content}
                    buttons={modalContent.buttons}
                    onClose={handleModalClose}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
