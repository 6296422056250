/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "redux/store";
import { ToastProvider } from "context";

import "resources/styles/custom.scss?version=2024051801";
import Main from "layout/Main";
import {
    ProductCompare,
    ProductList,
    ProductDetail,
    ProductInterest,
    ProductUpload,
    MypageMaster,
    MypageDealer,
    MypageSupplier,
    Settlement,
    Alert,
    Contract,
    Share,
    Login,
    Logout,
    Join,
    Welcome,
    FindId,
    YourId,
    FindPw,
    YourPw,
    ResetPw,
    NotFound,
    NotAuthorized,
} from "pages";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    {/* 공통 레이아웃이 포함되지 않은 화면 */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/findId" element={<FindId />} />
                    <Route path="/yourId" element={<YourId />} />
                    <Route path="/findPw" element={<FindPw />} />
                    <Route path="/yourPw" element={<YourPw />} />
                    <Route path="/ag/join/:corpId" element={<Join />} />
                    <Route path="/product/compare/:carIds" element={<ProductCompare />} />
                    <Route path="/contract/:contractId" element={<Contract />} />
                    <Route path="/share/:shareHistoryId" element={<Share />} />

                    {/* 404 */}
                    <Route path="*" element={<NotFound />} />
                    <Route path="/401" element={<NotAuthorized />} />

                    {/* 공통 레이아웃이 포함된 화면 */}
                    <Route element={<Main />}>
                        <Route path="/" exact element={<ProductList />} />
                        <Route path="/product/list" element={<ProductList />} />
                        <Route path="/product/detail/:carId" element={<ProductDetail />} />
                        <Route path="/product/interest" element={<ProductInterest />} />
                        <Route path="/product/upload" element={<ProductUpload />} />
                        <Route path="/product/upload/:carId" element={<ProductUpload />} />
                        <Route path="/mypage/master" element={<MypageMaster />} />
                        <Route path="/mypage/supplier" element={<MypageSupplier />} />
                        <Route path="/mypage/dealer" element={<MypageDealer />} />
                        <Route path="/password/reset" element={<ResetPw />} />
                        <Route path="/settlement" element={<Settlement />} />
                        <Route path="/alert" element={<Alert />} />
                    </Route>
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
