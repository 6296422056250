/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData } from "api";
import { handleNumericInput, comma } from "util";
import { Toast } from "components";

export const LiabilityLimit = ({ initData, onLiabilityLimitChange }) => {
    const [formData, setFormData] = useState({
        infinityDaein: true,
        dutyDaein: "", // 대인
        indemDaein: "", // 대인 면책금
        dutyDaemul: "", // 대물
        indemDaemul: "", // 대물 면책금
        dutyJacha: "", // 자차
        indemJachaMin: "", // 자차 최소 면책금
        indemJachaMax: "", // 자차 최대 면책금
        uninsuredInjuryCoverage: undefined, // 무보험 상해가입
        dutyNoInsuranceInjury: "", // 무보험 상해가입 금액
        dutyJason: "", // 자손
        indemJason: "", // 자손 면책금
        insuranceCorpName: "", // 보험사명
        emergencyCallNumber: "", // 긴급출동 번호
        emergencyCallCount: "", // 긴급출동 연 횟수
    });

    const {
        infinityDaein,
        dutyDaein,
        indemDaein,
        dutyDaemul,
        indemDaemul,
        dutyJacha,
        indemJachaMin,
        indemJachaMax,
        uninsuredInjuryCoverage,
        dutyNoInsuranceInjury,
        dutyJason,
        indemJason,
        insuranceCorpName,
        emergencyCallNumber,
        emergencyCallCount,
    } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const name = target.name ? target.name : e.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // 책임한도/면책금 기본값 API
    const fetchDefaultValues = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);
            console.log(response);

            setFormData((prevState) => ({
                ...prevState,
                dutyDaein: String(comma(response.data.data.dutyDaein)),
                indemDaein: String(comma(response.data.data.indemDaein)),
                dutyDaemul: String(comma(response.data.data.dutyDaemul)),
                indemDaemul: String(comma(response.data.data.indemDaemul)),
                dutyJacha: String(comma(response.data.data.dutyJacha)),
                indemJachaMin: String(comma(response.data.data.indemJachaMin)),
                indemJachaMax: String(comma(response.data.data.indemJachaMax)),
                dutyNoInsuranceInjury: String(comma(response.data.data.dutyNoInsuranceInjury)),
                dutyJason: String(comma(response.data.data.dutyJason)),
                indemJason: String(comma(response.data.data.indemJason)),
                emergencyCallCount: String(comma(response.data.data.emergencyCallCount)),
            }));
        } catch (error) {
            console.error("데이터 불러오는 중 에러 발생:", error);
        }
    };

    // 스테이트 리프팅
    useEffect(() => {
        onLiabilityLimitChange(formData);
    }, [formData, onLiabilityLimitChange]);

    useEffect(() => {
        if (initData !== undefined) {
            console.log(initData);
            setFormData({
                infinityDaein: initData?.rentInfo?.dutyDaein === -1 ? true : false,
                dutyDaein: comma(initData?.rentInfo?.dutyDaein),
                indemDaein: comma(initData?.rentInfo?.indemDaein),
                dutyDaemul: comma(initData?.rentInfo?.dutyDaemul),
                indemDaemul: comma(initData?.rentInfo?.indemDaemul),
                dutyJacha: comma(initData?.rentInfo?.dutyJacha),
                indemJachaMin: comma(initData?.rentInfo?.indemJachaMin),
                indemJachaMax: comma(initData?.rentInfo?.indemJachaMax),
                uninsuredInjuryCoverage: initData?.rentInfo?.dutyNoInsuranceInjury ? "Y" : "N",
                dutyNoInsuranceInjury: comma(initData?.rentInfo?.dutyNoInsuranceInjury),
                dutyJason: comma(initData?.rentInfo?.dutyJason),
                indemJason: comma(initData?.rentInfo?.indemJason),
                insuranceCorpName: initData?.rentInfo?.insuranceCorpName,
                emergencyCallNumber: initData?.rentInfo?.emergencyCallNumber,
                emergencyCallCount: initData?.rentInfo?.emergencyCallCount,
            });
        }
    }, [initData]);

    const handlePercentageNumericInput = (e) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9]/g, "");

        const parsedValue = parseInt(inputValue, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
            const numberWithCommas = parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            e.target.value = numberWithCommas;
        } else {
            e.preventDefault();
            e.target.value = "";
            handleToast("0에서 100 사이의 정수만 입력 가능합니다.");
        }
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <section id="section-04" className="section width-100">
                <div className="section-tit">
                    <h2>책임한도/면책금</h2>

                    <button type="button" className="btn btn-primary btn-large" onClick={fetchDefaultValues}>
                        기본값 불러오기
                    </button>
                </div>

                <div className="section-content">
                    <div className="form">
                        <div className="input-form">
                            <label className="form-title">보험사명</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="보험사명 입력"
                                        name="insuranceCorpName"
                                        value={insuranceCorpName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">긴급출동 번호</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="긴급출동 번호 입력"
                                        name="emergencyCallNumber"
                                        value={emergencyCallNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">긴급출동 연 횟수</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="긴급출동 연 횟수 입력"
                                        name="emergencyCallCount"
                                        value={emergencyCallCount}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">
                                대인
                                <div className="input-check">
                                    <div className="checkbox" style={{ justifyContent: "flex-start" }}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="infinityDaein"
                                                value="한도 입력"
                                                onChange={() => handleChange({ target: { name: "infinityDaein", value: !infinityDaein } })}
                                                checked={!infinityDaein}
                                            />
                                            <div></div>
                                            <span style={{ fontSize: "12px", marginLeft: "4px" }}>한도 입력</span>
                                        </label>
                                    </div>
                                </div>
                            </label>
                            <div className="form-field">
                                <div className={infinityDaein ? "input-element disabled" : "input-element"}>
                                    <input
                                        type="text"
                                        placeholder="한도 입력"
                                        name="dutyDaein"
                                        value={infinityDaein ? "무제한" : dutyDaein || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="면책금 입력"
                                        name="indemDaein"
                                        value={indemDaein || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">대물</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="한도 입력"
                                        name="dutyDaemul"
                                        value={dutyDaemul || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="면책금 입력"
                                        name="indemDaemul"
                                        value={indemDaemul || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">자차 (%)</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="한도 입력"
                                        name="dutyJacha"
                                        value={dutyJacha || ""}
                                        onChange={handleChange}
                                        onInput={handlePercentageNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="최소 면책금 입력"
                                        name="indemJachaMin"
                                        value={indemJachaMin || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="최대 면책금 입력"
                                        name="indemJachaMax"
                                        value={indemJachaMax || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">무보험 상해가입</label>
                            <div className="form-field direction-col">
                                <div className="button-field gap-6">
                                    <button
                                        type="button"
                                        className={`btn ${uninsuredInjuryCoverage === "Y" ? "btn-primary" : "btn-default"}`}
                                        onClick={() => handleChange({ target: { name: "uninsuredInjuryCoverage", value: "Y" } })}
                                    >
                                        가입
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn ${uninsuredInjuryCoverage === "N" ? "btn-primary" : "btn-default"}`}
                                        onClick={() => handleChange({ target: { name: "uninsuredInjuryCoverage", value: "N" } })}
                                    >
                                        미가입
                                    </button>
                                </div>

                                {uninsuredInjuryCoverage === "Y" && (
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="금액 입력"
                                            name="dutyNoInsuranceInjury"
                                            value={dutyNoInsuranceInjury || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">자손</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="한도 입력"
                                        name="dutyJason"
                                        value={dutyJason || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="면책금 입력"
                                        name="indemJason"
                                        value={indemJason || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="input-form">
                        <div className="form-field">
                            <button type="button" className="btn btn-primary btn-large" onClick={fetchDefaultValues}>
                                기본값 불러오기
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
