/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";

import { Modal, Toast } from "components";
import styles from "./ResetPw.module.scss";
import "./button.scss";

// API URL
const AUTH_VERIFY_URL = "/api/share/auth/v1/nice/phone/verify";
const AUTH_REQUEST_URL = "/api/share/auth/v1/nice/phone/request";

export const ResetPw = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [btnState, setBtnState] = useState(false);
    const [authStatus, setAuthStatus] = useState(0);
    const [authId, setAuthId] = useState(null);
    const [checkAuthStatus, setCheckAuthStatus] = useState(null);
    const [confirmAuthStatus, setConfirmAuthStatus] = useState(false);

    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const userData = useSelector((state) => state.login.userData);
    const userRole = useSelector((state) => state.login.userData?.roles);

    // 입력 데이터 세팅
    const [inputs, setInputs] = useState({
        oldPw: "",
        pw: "",
        pwChk: "",
        authNum: "",
    });
    const { oldPw, pw, pwChk, authNum } = inputs;

    // 통신사
    const mobileTypeOptions = [
        { value: "KT", label: "KT" },
        { value: "SKT", label: "SKT" },
        { value: "LG", label: "LG" },
    ];

    const updateInputData = (e) => {
        const { name, value } = e.target;

        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const updatePassword = async () => {
        setLoading(true);

        try {
            const requestData = {
                loginId: userData.loginId,
                phone: userData.phone,
                password: pw,
            };

            const response = await updateData(`/api/share/auth/v1/password/reset`, requestData);

            handleToast(response.data.message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data?.message, Infinity);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 비밀번호 변경 API 전송
    const handleSubmit = async () => {
        if (pw !== pwChk) {
            handleToast("비밀번호가 일치하지 않습니다.");
            return;
        }
        if (pw.length < 8) {
            handleToast("최소 8글자 이상 입력해주세요.");
            return;
        }

        //* API 전송
        updatePassword();
    };

    // 인증번호 발송
    const requestAuthNum = async () => {
        try {
            const response = await createData("/api/share/auth/v1/phone/request", {
                phone: userData?.phone,
            });

            handleToast(response.data.message);
            setAuthId(response.data.data.id);
            setCheckAuthStatus(true);
        } catch (error) {
            console.log(error);
            handleToast(error.response.data.message);
            setCheckAuthStatus(false);
            return false;
        }
    };

    // 인증번호 검사
    const checkAuthNum = async () => {
        try {
            const response = await createData("/api/share/auth/v1/nice/phone/verify", {
                id: authId,
                authNum: authNum,
            });

            handleToast(response.data.message);
            setConfirmAuthStatus(true);
        } catch (error) {
            handleToast(error.response.data.message);
            setConfirmAuthStatus(false);
            return false;
        }
    };

    // 폼 유효성 검사
    useEffect(() => {
        if (pw !== "" && pwChk !== "") {
            setBtnState(true);
        } else {
            setBtnState(false);
        }
    }, [pw, pwChk]);

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {/* 대시보드 */}
            {/* <div className={`${styles.dashboard}` + " global-width"}>
                <Dashboard />
            </div> */}
            {/* 비밀번호 변경하기 */}
            <div className={`${styles.resetPw}` + " form-width"}>
                <div className={styles.content}>
                    <div className={`${styles.mainTxt}` + " heading-1"}>비밀번호 변경하기</div>
                    {authStatus === 0 && (
                        <div className={styles.formContainer}>
                            <div className={`${styles.findPw__content__mainTxt}` + " heading-3"}>연락처 인증</div>
                            <p className={`${styles.findPw__content__subTxt}` + " pm"}>비밀번호 변경을 위한 본인인증을 해주세요.</p>
                            <div className={`${styles.findPw__content__formContainer}` + " mt-35"}>
                                {/* 등록된 연락처 */}
                                <div className="input-form">
                                    <p className="form-title">
                                        <span>등록된 연락처</span>
                                    </p>
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                name="phone"
                                                className=""
                                                placeholder="등록된 연락처"
                                                onChange={updateInputData}
                                                value={userData?.phone}
                                                readOnly
                                            />
                                        </div>
                                        <button type="button" className="btn-auth" onClick={requestAuthNum}>
                                            인증번호 발송
                                        </button>
                                    </div>
                                </div>
                                {/* 인증번호 입력 */}
                                {checkAuthStatus && (
                                    <div className="input-form">
                                        <div className="form-field">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="authNum"
                                                    className=""
                                                    placeholder="인증번호 입력"
                                                    onChange={updateInputData}
                                                    value={authNum}
                                                />
                                            </div>
                                            <button type="button" className="btn-auth" onClick={checkAuthNum}>
                                                인증번호 확인
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {authStatus === 1 && (
                        <div className={styles.formContainer}>
                            <div className={`${styles.findPw__content__mainTxt}` + " heading-3"}>비밀번호 재설정</div>
                            <p className={`${styles.findPw__content__subTxt}` + " pm"}>새롭게 사용할 비밀번호를 입력해주세요.</p>
                            <div className={`${styles.findPw__content__formContainer}` + " mt-35"}>
                                {/* 기존 비밀번호 */}
                                {/* <div className="input-form">
                                    <p className="form-title">
                                        <span>기존 비밀번호</span>
                                    </p>
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="password"
                                                name="oldPw"
                                                className=""
                                                placeholder="기존 비밀번호"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        // spacebar 막기
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={updateInputData}
                                                value={oldPw}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* 새로운 비밀번호 */}
                                <div className="input-form">
                                    <p className="form-title">
                                        <span>새로운 비밀번호</span>
                                    </p>
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="password"
                                                name="pw"
                                                className=""
                                                placeholder="비밀번호"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        // spacebar 막기
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={updateInputData}
                                                value={pw}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* 비밀번호 재입력 */}
                                <div className="input-form">
                                    <div className="form-field">
                                        <div className="input-element">
                                            <input
                                                type="password"
                                                name="pwChk"
                                                className=""
                                                placeholder="비밀번호 재입력"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        // spacebar 막기
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={updateInputData}
                                                value={pwChk}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${styles.buttons}` + " button-form"}>
                    {authStatus === 0 && (
                        <button
                            type="submit"
                            className={confirmAuthStatus ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"}
                            disabled={!confirmAuthStatus}
                            onClick={() => {
                                setAuthStatus(1);
                            }}
                        >
                            다음
                        </button>
                    )}
                    {authStatus === 1 && (
                        <button
                            type="submit"
                            className={btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"}
                            disabled={!btnState}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            변경하기
                        </button>
                    )}
                    <button
                        type="submit"
                        className={"btn btn-xl btn--outline_gray"}
                        onClick={() => {
                            {
                                userRole?.includes("AG_MASTER")
                                    ? navigate("/mypage/master")
                                    : userRole?.includes("AG_DEALER")
                                    ? navigate("/mypage/dealer")
                                    : userRole?.includes("RENT_MASTER")
                                    ? navigate("/mypage/supplier")
                                    : navigate("/mypage/dealer");
                            }
                        }}
                    >
                        돌아가기
                    </button>
                </div>
            </div>

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
