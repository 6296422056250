// /* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchData } from "api";
import { comma, convertArrayToCommaSeparatedString, parseCommaSeparatedStringToArray, parseQueryString } from "util";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faRotateRight, faCalendarDays, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ChangeGrade, ContractManagerInfo, ReleaseStatus } from "../modal";
import { Modal, Toast } from "components";

export const ReleaseTable = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [paramLoading, setParamLoading] = useState(true); // 파라미터 식별 유무 상태

    // 기존 상태 정의
    const [data, setData] = useState([]); // 상품 목록 상태
    const [pagination, setPagination] = useState([]);
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태

    const [searchKeyword, setSearchKeyword] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [calculateStatus, setCalculateStatus] = useState(null);

    // 출고요청 내역 조회
    const fetchReleaseData = async (data) => {
        setLoading(true);

        try {
            const queryParams = new URLSearchParams(filters);
            queryParams.set("share_product_status", "RELEASE_REQUEST");

            let requestDatas;
            if (data !== null) requestDatas = data;

            const response = await fetchData(`/api/share/rent_corp/product/v1?${queryParams.toString()}`, requestDatas);
            setData(response.data.data);
            setPagination(response.data.pagination);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("필터링된 상품 목록을 불러오는 중 에러 발생:", error);
        }
    };

    // 필터 상태 추가
    const initialFilters = {
        share_product_status: parseCommaSeparatedStringToArray(searchParams.get("share_product_status") || ""),
        page: parseInt(searchParams.get("page")) || 0,
        size: parseInt(searchParams.get("size")) || 20,
    };

    useEffect(() => {
        // URL 변경 감지 및 데이터 로딩 로직
        const urlFilters = {
            share_product_status: parseCommaSeparatedStringToArray(searchParams.get("share_product_status") || ""),
            page: parseInt(searchParams.get("page")) || initialFilters.page,
            size: parseInt(searchParams.get("size")) || initialFilters.size,
        };

        setFilters(urlFilters);
        setParamLoading(false);
    }, [location.search]);

    const [filters, setFilters] = useState(initialFilters);

    const handleResetFilters = () => {
        setFilters(initialFilters);
    };

    // 필터 상태 업데이트 함수
    const handleFilterChange = (e, option = {}) => {
        const { name, value } = e.target;

        if (name === "rent_price") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                min_rent_price: option.min_value,
                max_rent_price: option.max_value,
            }));
        } else if (name === "contract_age_types") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                contract_age_types: option.contract_age_types,
            }));
        } else if (name === "car_types" || name === "car_manufacturer_name_list" || name === "share_product_status") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: option,
            }));
        } else if (name === "contract_range_month") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        // 페이지 최초 진입 URL 파라미터 setFilters 적용 확인
        if (paramLoading) return;

        // 모든 배열 필드를 콤마로 구분된 문자열로 변환
        const filtersForURL = { ...filters };
        filtersForURL.share_product_status = convertArrayToCommaSeparatedString(filters.share_product_status);

        // 현재 URL의 쿼리 매개변수와 비교
        if (JSON.stringify(parseQueryString(location.search)) !== JSON.stringify(filters)) {
            setSearchParams(filtersForURL);
            fetchReleaseData("");
        }
    }, [filters]);

    const onSearch = (keyword) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            search_keyword: keyword,
        }));
    };

    const searchData = () => {
        const data = {
            contract_status_list: contractStatus,
            calculate_status: calculateStatus,
            started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            search_keyword: searchKeyword,
        };

        fetchReleaseData(data);
    };

    useEffect(() => {
        searchData();
    }, [startDate, endDate, contractStatus, calculateStatus]);

    const contractStatusOptions = [
        { value: "REQUEST", label: "접수중" },
        { value: "ON_PROCESS", label: "진행중" },
        { value: "FINISH", label: "계약완료" },
        { value: "DELIVERY_FINISH", label: "탁송신청완료" },
        { value: "RETURN", label: "반납" },
        { value: "RELEASE_READY", label: "출고준비중" },
        { value: "RELEASE_FINISH", label: "출고완료" },
        { value: "REJECT", label: "진행 불가" },
        { value: "CANCEL", label: "중도 해지" },
    ];

    const calculateStatusOptions = [
        { value: "PENDING", label: "정산전" },
        { value: "CANCEL", label: "환수" },
        { value: "FINISH", label: "정산완료" },
    ];

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const initParams = () => {
        setSearchKeyword("");
        setStartDate(null);
        setEndDate(null);
        setContractStatus(null);
        setCalculateStatus(null);

        const data = {
            contract_status_list: "",
            calculate_status: "",
            started_at: null,
            ended_at: null,
            search_keyword: "",
        };

        fetchReleaseData(data);
    };

    const [releaseStatusModalStatus, setReleaseStatusModalStatus] = useState(false);

    const confirmModal = () => {
        setReleaseStatusModalStatus(false);
        handleToast("완료되었습니다.");
    };

    //* 토스트 팝업 세팅
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    const [contractManagerInfoModalStatus, setContractManagerInfoModalStatus] = useState(false);
    const [changeGradeModalStatus, setChangeGradeModalStatus] = useState(false);

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="mypage_table-head" style={{ marginTop: "20px" }}>
                <div className="mypage_table-filter">
                    <div className="filter-option filter-search" style={{ marginRight: "auto" }}>
                        <input
                            type="text"
                            placeholder="고객명을 검색해주세요."
                            value={searchKeyword}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                        />
                        <button type="button" onClick={searchData}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                    </div>
                    <div className="filter-option filter-date" style={{ marginLeft: "auto" }}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <div className="input-datepicker">
                            <DatePicker
                                locale={ko}
                                dateFormat="yyyy-MM-dd"
                                placeholderText=""
                                selected={startDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(date) => {
                                    if (endDate && date > endDate) {
                                        handleToast("시작 날짜는 종료 날짜보다 이후일 수 없습니다.");
                                        return;
                                    }
                                    setStartDate(date);
                                }}
                            />
                        </div>
                        -
                        <div className="input-datepicker">
                            <DatePicker
                                locale={ko}
                                dateFormat="yyyy-MM-dd"
                                placeholderText=""
                                selected={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(date) => {
                                    if (startDate && date < startDate) {
                                        handleToast("종료 날짜는 시작 날짜보다 이전일 수 없습니다.");
                                        return;
                                    }
                                    setEndDate(date);
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-select">
                        <div className="select-element">
                            <Select
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="계약상태"
                                options={contractStatusOptions}
                                onChange={(value) => handleSelectChange(value, setContractStatus)}
                                value={contractStatusOptions.filter((option) => option.value === contractStatus)}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-select">
                        <div className="select-element">
                            <Select
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="정산현황"
                                options={calculateStatusOptions}
                                onChange={(value) => handleSelectChange(value, setCalculateStatus)}
                                value={calculateStatusOptions.filter((option) => option.value === calculateStatus)}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-refresh">
                        <button type="button" className="btn-refresh" onClick={initParams}>
                            초기화
                            <FontAwesomeIcon icon={faRotateRight} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="mypage_table-wrapper">
                <table>
                    <colgroup>
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "24%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "12%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <div className="tht">차량번호</div>
                            </th>
                            <th>
                                <div className="tht">모델명/상세모델</div>
                            </th>
                            <th>
                                <div className="tht">유종</div>
                            </th>
                            <th>
                                <div className="tht">외장색</div>
                            </th>
                            <th>
                                <div className="tht">주행거리/연식</div>
                            </th>
                            <th>
                                <div className="tht">계약기간</div>
                            </th>
                            <th>
                                <div className="tht">보증금</div>
                            </th>
                            <th>
                                <div className="tht">월렌트료</div>
                            </th>
                            <th>
                                <div className="tht"></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length === 0 && (
                            <tr>
                                <td colSpan={9}>
                                    <div className="tdd" style={{ textAlign: "center" }}>
                                        <span className="td-desc">조회된 데이터가 없습니다.</span>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {data?.map((row, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row.carNumber}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="model_name">{row.modelDetailName}</span>
                                            <span className="model_detail">
                                                {row.className} {row.trimName}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">
                                                {row.fuelType === "GAS"
                                                    ? "가솔린"
                                                    : row.fuelType === "DIESEL"
                                                    ? "디젤"
                                                    : row.fuelType === "LPG"
                                                    ? "LPG"
                                                    : row.fuelType === "GAS_LPG"
                                                    ? "가솔린(LPG겸용)"
                                                    : row.fuelType === "GAS_CNG"
                                                    ? "가솔린(CNG겸용)"
                                                    : row.fuelType === "GAS_HYBRID"
                                                    ? "가솔린 하이브리드"
                                                    : row.fuelType === "ELECTRIC"
                                                    ? "전기"
                                                    : row.fuelType === "HYDROGEN"
                                                    ? "수소"
                                                    : "알 수 없음"}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row.color}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">
                                                {comma(row.mileage)}km
                                                <br />
                                                {row.carMadedYear}.{row.carMadedMonth}
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                <span className="td-desc">
                                                    <b>{priceInfo.contractRangeMonth}개월</b>
                                                </span>
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                <span className="td-desc">
                                                    <b>{comma(priceInfo.depositPrice)}</b>
                                                </span>
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                <span className="td-desc td-color-01">
                                                    <b>{comma(priceInfo.rentPrice)}</b>
                                                </span>
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-button">
                                                <button
                                                    type="button"
                                                    className="btn btn-status btn-type-01"
                                                    onClick={() => {
                                                        setReleaseStatusModalStatus(row.id);
                                                    }}
                                                >
                                                    출고상태 변경
                                                </button>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="tableFoot">
                    <div className="pagination">
                        {data?.length !== 0 && (
                            <div className="numList">
                                <div
                                    className="btnPrev"
                                    onClick={() => {
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            page: pagination?.prevPage,
                                        }));
                                    }}
                                    aria-current={pagination?.isFirst ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                                <div
                                    className="btnNext"
                                    onClick={() => {
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            page: pagination?.nextPage,
                                        }));
                                    }}
                                    aria-current={pagination?.isLast ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                                <ul className="currentPage">
                                    {Array(pagination?.endPage)
                                        .fill()
                                        .map(
                                            (_, i) =>
                                                i >= pagination?.startPage - 1 && (
                                                    <li
                                                        key={i}
                                                        onClick={() => {
                                                            setFilters((prevFilters) => ({
                                                                ...prevFilters,
                                                                page: i,
                                                            }));
                                                        }}
                                                        className={pagination?.page === i ? "active" : null}
                                                    >
                                                        {i + 1}
                                                    </li>
                                                )
                                        )}
                                </ul>
                                {pagination?.endPage !== pagination?.totalPages && (
                                    <div className="lastPage">
                                        <li
                                            onClick={() => {
                                                setFilters((prevFilters) => ({
                                                    ...prevFilters,
                                                    page: pagination?.totalPages - 1,
                                                }));
                                            }}
                                        >
                                            {pagination?.totalPages}
                                        </li>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />

            {/* 계약담당자 정보 모달 */}
            {contractManagerInfoModalStatus && (
                <ContractManagerInfo setModal={setContractManagerInfoModalStatus} setChangeGradeModalStatus={setChangeGradeModalStatus} />
            )}

            {/* 등급 변경 모달 */}
            {changeGradeModalStatus && <ChangeGrade setModal={setChangeGradeModalStatus} />}

            {/* 출고확인 모달 */}
            {releaseStatusModalStatus && (
                <ReleaseStatus
                    setModal={setReleaseStatusModalStatus}
                    setConfirm={confirmModal}
                    rowId={releaseStatusModalStatus}
                    fetchReleaseData={fetchReleaseData}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
