/* eslint-disable */
// 과겨 연도부터 현재 연도까지 조회
export const getDecades = async () => {
  const currentYear = new Date().getFullYear();
  const pastYear = 2000;

  const years = [];
  for (let i = currentYear; i >= pastYear; i--) {
    years.push({
      value: i,
      label: i + "년",
    });
  }

  return years;
};

// datepicker + timepicker 옵션 반환
export const getTimeOptions = async () => {
  const meridiemOptions = [
    { value: "AM", label: "오전" },
    { value: "PM", label: "오후" },
  ];
  const hourOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];
  const minuteOptions = [
    { value: "00", label: "00" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];

  return [meridiemOptions, hourOptions, minuteOptions];
};

// 연료 타입(유종) 목록 반환
export const getFuelTypeList = async (str) => {
  const fuelTypes = [
    { value: "GAS", label: "가솔린" },
    { value: "DIESEL", label: "디젤" },
    { value: "LPG", label: "LPG" },
    { value: "GAS_LPG", label: "가솔린(LPG겸용)" },
    { value: "GAS_CNG", label: "가솔린(CNG겸용)" },
    { value: "GAS_HYBRID", label: "가솔린 하이브리드" },
    { value: "ELECTRIC", label: "전기" },
    { value: "HYDROGEN", label: "수소" },
  ];

  return fuelTypes;
};
export const getFuelTypeLabel = async (str) => {
  const fuelTypes = [
    { value: "GAS", label: "가솔린" },
    { value: "DIESEL", label: "디젤" },
    { value: "LPG", label: "LPG" },
    { value: "GAS_LPG", label: "가솔린(LPG겸용)" },
    { value: "GAS_CNG", label: "가솔린(CNG겸용)" },
    { value: "GAS_HYBRID", label: "가솔린 하이브리드" },
    { value: "ELECTRIC", label: "전기" },
    { value: "HYDROGEN", label: "수소" },
  ];

  if (!str) {
    return fuelTypes;
  }

  const matchingFuelType = fuelTypes.find((fuelType) => fuelType.value === str);
  if (matchingFuelType) {
    return matchingFuelType.label;
  } else {
    // 일치하는 값을 찾지 못한 경우에 대한 처리 (예: 기본값 반환)
    return "알 수 없음";
  }
};

// 배차 연료량 목록 반환
export const getFuelAmountList = async () => {
  const fuelAmounts = [
    { value: "1/8", label: "1/8" },
    { value: "2/8", label: "2/8" },
    { value: "3/8", label: "3/8" },
    { value: "4/8", label: "4/8" },
    { value: "5/8", label: "5/8" },
    { value: "6/8", label: "6/8" },
    { value: "7/8", label: "7/8" },
    { value: "8/8", label: "8/8" },
  ];

  return fuelAmounts;
};

// 차종 목록 반환
export const getCarTypeList = async () => {
  const carTypes = [
    { value: "COMPACT", label: "경차" },
    { value: "SMALL", label: "소형차" },
    { value: "SMALL_MID", label: "준중형차" },
    { value: "MID", label: "중형차" },
    { value: "BIG", label: "대형차" },
    { value: "RV", label: "RV차" },
    { value: "SUV", label: "SUV차" },
    { value: "SPORT", label: "스포츠카" },
    { value: "VAN", label: "승합차" },
    { value: "ETC", label: "기타" },
  ];

  return carTypes;
};

// 청구 종류 목록 반환
export const getPriceTypeList = async () => {
  const priceTypes = [
    { value: "RENT_PRICE", label: "렌트대여료" },
    { value: "EXTRA_PRICE", label: "할증료" },
    { value: "DELIVERY_PRICE", label: "배달료" },
    { value: "FUEL_PRICE", label: "주유비" },
    { value: "GUARANTEE_PRICE", label: "보증금" },
    { value: "SELF_INSURANCE_PRICE", label: "자차보험" },
    { value: "INDEM_PRICE", label: "면책금" },
    { value: "FIX_PRICE", label: "수리비" },
    { value: "COMPEN_PRICE", label: "휴차료" },
    { value: "UN_REPAIR_PRICE", label: "미수선" },
    { value: "INIT_DEPOSIT_PRICE", label: "최초납입금" },
    { value: "ADVANCE_PRICE", label: "선수금" },
    { value: "MONTHLY_PRICE", label: "월 납입금" },
  ];

  return priceTypes;
};

// 계약기간 목록 반환
export const getDepositCountList = async () => {
  const depositCount = [
    { value: "6", label: "6개월" },
    { value: "12", label: "12개월" },
    { value: "24", label: "24개월" },
    { value: "36", label: "36개월" },
    { value: "48", label: "48개월" },
    { value: "60", label: "60개월" },
    { value: "72", label: "72개월" },
  ];

  return depositCount;
};

// 월 납입일 목록 반환
export const getMonthlyDepositDayList = async () => {
  const monthlyDepositDay = [
    { value: "1", label: "1일" },
    { value: "10", label: "10일" },
    { value: "16", label: "16일" },
    { value: "28", label: "28일" },
  ];

  return monthlyDepositDay;
};

// 연/월 목록 반환
export const getDateTypeList = async () => {
  const dateType = [
    { value: "YEAR", label: "연" },
    { value: "MONTH", label: "월" },
  ];

  return dateType;
};

// 상품상태 반환
export const getProductStatusList = async () => {
  const data = [
    { value: "PENDING", label: "대기중" },
    { value: "RELEASE_REQUEST", label: "출고요청" },
    { value: "SELL", label: "판매가능" },
    { value: "FINISH", label: "계약완료" },
  ];

  return data;
};

// 차량 만기 인수 옵션 반환
export const getTakeOverTypeList = async () => {
  const data = [
    { value: "FULL_CHOICE", label: "만기선택형" },
    { value: "TAKE_OVER", label: "인수형" },
    { value: "RETURN", label: "반납형" },
  ];

  return data;
};

// 색상 반환
export const getColorList = async () => {
  const data = [
    { value: "1", label: "검정색" },
    { value: "2", label: "쥐색" },
    { value: "3", label: "은회색" },
    { value: "4", label: "은색" },
    { value: "5", label: "회색" },
    { value: "6", label: "진주색" },
    { value: "7", label: "갈색" },
    { value: "8", label: "금색" },
    { value: "9", label: "청색" },
    { value: "10", label: "파란색" },
    { value: "11", label: "하늘색" },
    { value: "12", label: "녹색" },
    { value: "13", label: "카키색" },
    { value: "14", label: "민트색" },
    { value: "15", label: "빨간색" },
    { value: "16", label: "주황색" },
    { value: "17", label: "보라색" },
    { value: "18", label: "자주색" },
    { value: "19", label: "분홍색" },
    { value: "20", label: "노란색" },
    { value: "21", label: "흰색" },
  ];

  return data;
};

// 면허 종류 반환
export const getDriverLicenseType = async () => {
  const data = [
    { value: "CLASS_1_NORMAL", label: "1종 보통" },
    { value: "CLASS_2_NORMAL", label: "2종 보통" },
    { value: "CLASS_1_BIG", label: "1종 대형" },
  ];

  return data;
};

// 변속기 종류 반환
export const getTransmissionList = async () => {
  const data = [
    { value: "자동", label: "자동" },
    { value: "수동", label: "수동" },
  ];

  return data;
};

// 셀렉트 박스 연/월/일 목록 반환
export const getYearList = async () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const yearList = [];

  for (let year = currentYear; year >= startYear; year--) {
    yearList.push({ value: `${year}`, label: `${year}년` });
  }

  return yearList;
};

export const getMonthList = async () => {
  const monthList = [];

  for (let month = 1; month <= 12; month++) {
    // const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    // monthList.push({ value: formattedMonth, label: `${month}월` });
    monthList.push({ value: `${month}`, label: `${month}월` });
  }

  return monthList;
};

export const getDayList = async () => {
  const dayList = [];

  for (let day = 1; day <= 31; day++) {
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    dayList.push({ value: formattedDay, label: `${day}일` });
  }

  return dayList;
};
