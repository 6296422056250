/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { DetailShareStep1, DetailShareStep2, DetailShareStep3 } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Toast } from "components";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";

export const DetailShare = ({ carData, setModalShareStatus, setLoading, showModal, handleModalClose }) => {
    const initialState = {
        isLoading: false,
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
    };
    const [state, setState] = useState(initialState);

    const [currentStep, setCurrentStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const sliderRef = useRef();

    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const previousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const requestData = {
                carId: carData.id,
                ...step1Data,
                ...step2Data,
            };

            const response = await createData("/api/share/car_share/v1", requestData);

            getShortUrl(response.data.data.shareUrl);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data.message);
        } finally {
        }
    };

    const getShortUrl = async (url) => {
        try {
            setLoading(true);
            const params = {
                url: `${url}`,
            };
            const urlResponse = await fetchData("/api/share/short_url/v1", params);
            setLoading(false);
            // setModalShareStatus(false);
            showModal("공유하기 URL", <p>{urlResponse.data.data}</p>, [
                {
                    text: "복사하기",
                    handler: () => {
                        const textarea = document.createElement("textarea");
                        textarea.value = urlResponse.data.data;
                        document.body.appendChild(textarea);
                        textarea.select();
                        document.execCommand("copy");
                        document.body.removeChild(textarea);

                        handleToast("클립보드에 복사되었습니다.");
                    },
                },
                {
                    text: "확인",
                    handler: () => {
                        handleModalClose();
                        setModalShareStatus(false);
                    },
                },
            ]);
        } catch (error) {
            setLoading(false);
            console.log(error);
            handleToast(error.response.data.message);
        } finally {
        }
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);
    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-type-3">
                <button type="button" className="btn-close" onClick={() => setModalShareStatus(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className="modal-title">
                    <span className="modal-tit-main">공유하기</span>
                    <span className="modal-tit-sub">
                        {currentStep === 1 || currentStep === 2 ? "공유하시는분의 정보를 입력해주세요." : null}
                        {currentStep === 3 && (
                            <>
                                <div className="product-name" style={{ fontSize: "16px", fontWeight: "700" }}>
                                    {carData?.modelName}
                                </div>
                                <div className="product-model">
                                    {carData?.modelDetailName} {carData?.className}&nbsp;{carData?.trimName}
                                </div>
                            </>
                        )}
                    </span>
                </div>

                <div className="modal-content">
                    <div className="step-container">
                        {currentStep === 1 && <DetailShareStep1 initData={step1Data} onStep1Change={setStep1Data} />}
                        {currentStep === 2 && <DetailShareStep2 initData={step2Data} data={carData} onStep2Change={setStep2Data} />}
                        {currentStep === 3 && (
                            <DetailShareStep3 data={carData} sliderRef={sliderRef} step1Data={step1Data} step2Data={step2Data} />
                        )}
                    </div>
                </div>

                {currentStep === 1 && (
                    <div className="modal-bottom">
                        <button type="button" className="btn-modal" onClick={() => setModalShareStatus(false)}>
                            취소
                        </button>
                        <button
                            type="button"
                            className="btn-modal"
                            onClick={() => {
                                if (!step1Data) {
                                    handleToast("서비스 이용을 위한 정보를 입력해주세요.");
                                    return;
                                } else if (!step1Data.customerName) {
                                    handleToast("공유 받으시는 분의 성함을 입력해주세요.");
                                    return;
                                } else if (!step1Data.customerPhone) {
                                    handleToast("공유 받으시는 분의 연락처를 입력해주세요.");
                                    return;
                                }

                                nextStep();
                            }}
                        >
                            공유목록 선택
                        </button>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="modal-bottom">
                        <button type="button" className="btn-modal" onClick={() => previousStep()}>
                            이전
                        </button>
                        <button
                            type="button"
                            className="btn-modal"
                            onClick={() => {
                                if (step2Data?.contractRangeMonthTypes?.length === 0) {
                                    handleToast("계약 기간을 선택해주세요.");
                                    return;
                                } else if (step2Data?.shareCarInfoTypes?.length === 0) {
                                    handleToast("공유할 차량 정보를 선택해주세요.");
                                    return;
                                } else {
                                    nextStep();
                                }
                            }}
                        >
                            미리보기
                        </button>
                    </div>
                )}

                {currentStep === 3 && (
                    <div className="modal-bottom">
                        <button type="button" className="btn-modal" onClick={() => previousStep()}>
                            이전
                        </button>
                        <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                            공유하기
                        </button>
                    </div>
                )}
            </div>

            {state.modalOpen && (
                <Modal
                    title={state.modalContent.title}
                    content={state.modalContent.content}
                    buttons={state.modalContent.buttons}
                    onClose={handleModalClose}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
