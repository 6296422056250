/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import searchWhiteIcon from "resources/icon/search-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";
import CameraIcon from "resources/icon/camera.svg";

export const NewCarEstimate = ({ setModal }) => {
    const [loading, setLoading] = useState(false);
    const [submittedFiles, setSubmittedFiles] = useState([]);
    const [formData, setFormData] = useState({
        vehiclePhotos: [],
        contractRangeMonths: [],
        requestMemo: "",
    });
    const { vehiclePhotos, contractRangeMonths, requestMemo } = formData;

    useEffect(() => {
        console.log(vehiclePhotos);
    }, [vehiclePhotos]);

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        // 다른 input 요소인 경우 처리 (예: input type="text")
        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleContractButtonClick = (month) => {
        let updatedMonths;
        if (contractRangeMonths.includes(month)) {
            updatedMonths = contractRangeMonths.filter((m) => m !== month);
        } else {
            updatedMonths = [...contractRangeMonths, month];
        }
        setFormData((prevState) => ({
            ...prevState,
            contractRangeMonths: updatedMonths,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            let uploadsIds = [];
            for (const file of vehiclePhotos) {
                const formData = new FormData();
                formData.append("file", file);

                const response = await createFormData("/api/share/upload/v1/image", formData);
                const uploadId = response.data.data.id;

                uploadsIds.push(uploadId);
            }

            const payload = {
                productRequestType: "ESTIMATE_RELEASE",
                contractRangeMonths: contractRangeMonths,
                requestMemo: requestMemo,
                uploadIds: uploadsIds,
            };

            console.log(payload);

            const response = await createData(`/api/share/product_request/v1`, payload);

            showModal("", <p>요청하신 내용이 접수되었습니다.</p>, [{ text: "확인", handler: () => setModal(false) }]);
            setLoading(false);
        } catch (error) {
            handleToast(error.response.data.message, Infinity);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 이미지 파일 처리
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);

        // 현재 업로드된 파일의 개수와 새로운 파일의 개수를 합산
        const totalFilesCount = formData?.vehiclePhotos.length + newFiles?.length;

        if (totalFilesCount > 10) {
            // 파일 개수가 10개를 초과할 경우 경고 메시지를 표시하고 추가 작업 중단
            showModal("파일 제한 초과", <p>최대 10개의 파일만 업로드할 수 있습니다.</p>, [{ text: "확인", handler: handleModalClose }]);
            return;
        }

        // 파일 개수가 10개 이하인 경우, 기존 파일 목록에 새 파일 추가
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: [...prevState.vehiclePhotos, ...newFiles],
        }));
    };

    const handleImageRemove = (index) => {
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: prevState.vehiclePhotos.filter((_, i) => i !== index),
        }));
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            <div className="modal modal-type-2" style={{ minHeight: "unset" }}>
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>신차 견적 요청</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        <div className="request_form">
                            <div className="item-wrapper" style={{ borderBottom: "none", paddingBottom: "24px" }}>
                                <div className="item">
                                    <div className="item-head">
                                        <span>차량 사진</span>
                                    </div>
                                    <div className="item-content" style={{ gap: "4px" }}>
                                        <div className="input-form">
                                            <div className="form-field">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-large"
                                                    onClick={handleUploadClick}
                                                    style={{ width: "auto", padding: "0 12px", gap: "6px", fontSize: "14px" }}
                                                >
                                                    차량사진 업로드
                                                </button>
                                                <span className="img-count">{vehiclePhotos?.length}/10</span>
                                                <input
                                                    type="file"
                                                    multiple
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="item-txt">
                                            <p>제조사 내차 만들기 또는 다나와자동차에서 옵션(내장색상포함)을 선택한 캡처화면을 등록해주세요</p>
                                        </div>
                                    </div>
                                </div>
                                {formData?.vehiclePhotos?.length !== 0 && (
                                    <div className="image-list">
                                        {formData?.vehiclePhotos?.map((file, index) => {
                                            return (
                                                <div className="img" key={index}>
                                                    <button
                                                        type="button"
                                                        className="btn-remove_img"
                                                        onClick={() => handleImageRemove(index)}
                                                    ></button>
                                                    <img src={URL.createObjectURL(file)} alt={`Vehicle ${index}`} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <div className="item">
                                    <div className="item-head">
                                        <span>계약기간</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="button-element">
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(12) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(12)}
                                                >
                                                    12개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(24) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(24)}
                                                >
                                                    24개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(36) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(36)}
                                                >
                                                    36개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(48) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(48)}
                                                >
                                                    48개월
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>추가 요청 사항</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="textarea-element">
                                                <textarea
                                                    name="requestMemo"
                                                    value={requestMemo || ""}
                                                    onChange={handleChange}
                                                    placeholder="추가 요청 사항 내용을 입력해주세요."
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-bottom2">
                            <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                                접수
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}
        </>
    );
};
