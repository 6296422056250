/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData } from "api";
import { handleNumericInput, comma } from "util";

export const Deductible = ({ initData, onDeductibleChange }) => {
    const [formData, setFormData] = useState({
        contractAgeTypes: [], // 계약연령
        expLt1Yn: "N", // 운전경력
        age21RentPrice: "", // 21세 이상 대여로 추가 금액
        age21IndemPrice: "", // 21세 이상 면책금 추가 금액
        expLt1RentPrice: "", // 운전경력 대여로 추가 금액
        expLt1IndemPrice: "", // 운전경력 면책금 추가 금액
        age23RentPrice: "", // 23세 이상 대여로 추가 금액
        age23IndemPrice: "", // 23세 이상 면책금 추가 금액
    });
    const {
        contractAgeTypes,
        expLt1Yn,
        age21RentPrice,
        age21IndemPrice,
        expLt1RentPrice,
        expLt1IndemPrice,
        age23RentPrice,
        age23IndemPrice,
    } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const name = target.name ? target.name : e.name;

        if (name === "contractAgeTypes") {
            // 계약방식 멀티플 선택
            if (contractAgeTypes.includes(value)) {
                // 이미 선택된 경우 선택 해제
                setFormData((prevState) => ({
                    ...prevState,
                    contractAgeTypes: contractAgeTypes.filter((type) => type !== value),
                }));
            } else {
                // 선택되지 않은 경우 선택
                setFormData((prevState) => ({
                    ...prevState,
                    contractAgeTypes: [...contractAgeTypes, value],
                }));
            }
        } else {
            // 다른 입력 항목은 그대로 처리
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    // 특약설정 기본값 API
    const fetchDefaultValues = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);
            console.log(response.data.data.expLt1RentPrice);
            setFormData((prevState) => ({
                ...prevState,
                // contractAgeTypes: ["AGE_21", "AGE_23"],
                age21RentPrice: String(comma(response.data.data.age21RentPrice)),
                age21IndemPrice: String(comma(response.data.data.age21IndemPrice)),
                expLt1RentPrice: String(comma(response.data.data.expLt1RentPrice)),
                expLt1IndemPrice: String(comma(response.data.data.expLt1IndemPrice)),
                age23RentPrice: String(comma(response.data.data.age23RentPrice)),
                age23IndemPrice: String(comma(response.data.data.age23IndemPrice)),
            }));
        } catch (error) {
            console.error("데이터 불러오는 중 에러 발생:", error);
        }
    };

    // 스테이트 리프팅
    useEffect(() => {
        onDeductibleChange(formData);
    }, [formData, onDeductibleChange]);

    useEffect(() => {
        if (initData !== undefined) {
            console.log(initData);
            setFormData({
                contractAgeTypes: initData?.rentInfo?.contractAgeTypes,
                expLt1Yn: initData?.rentInfo?.expLt1Yn,
                age21RentPrice: comma(initData?.rentInfo?.age21RentPrice),
                age21IndemPrice: comma(initData?.rentInfo?.age21IndemPrice),
                expLt1RentPrice: comma(initData?.rentInfo?.expLt1RentPrice),
                expLt1IndemPrice: comma(initData?.rentInfo?.expLt1IndemPrice),
                age23RentPrice: comma(initData?.rentInfo?.age23RentPrice),
                age23IndemPrice: comma(initData?.rentInfo?.age23IndemPrice),
            });
        }
    }, [initData]);

    return (
        <section id="section-05" className="section width-100">
            <div className="section-tit">
                <h2>특약설정</h2>

                <button type="button" className="btn btn-primary btn-large" onClick={fetchDefaultValues}>
                    기본값 불러오기
                </button>
            </div>

            <div className="section-content">
                <div className="form">
                    <div className="input-form">
                        <label className="form-title">계약연령</label>
                        <div className="button-field gap-6">
                            <button
                                type="button"
                                className={`btn ${contractAgeTypes.includes("AGE_21") ? "btn-primary" : "btn-default"}`}
                                onClick={() => handleChange({ target: { name: "contractAgeTypes", value: "AGE_21" } })}
                            >
                                만 21세 이상
                            </button>
                            <button
                                type="button"
                                className={`btn ${contractAgeTypes.includes("AGE_23") ? "btn-primary" : "btn-default"}`}
                                onClick={() => handleChange({ target: { name: "contractAgeTypes", value: "AGE_23" } })}
                            >
                                만 23세 이상
                            </button>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">운전경력</label>
                        <div className="button-field gap-6">
                            <button
                                type="button"
                                className={`${expLt1Yn === "Y" ? "btn-primary" : "btn-default"}`}
                                onClick={() => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        expLt1Yn: expLt1Yn === "Y" ? "N" : "Y",
                                    }));
                                }}
                            >
                                1년 미만
                            </button>
                        </div>
                    </div>
                    {/* <div className="input-form">
                        <div className="form-field">
                            <button type="button" className="btn btn-primary btn-large" onClick={fetchDefaultValues}>
                                기본값 불러오기
                            </button>
                        </div>
                    </div> */}
                    {contractAgeTypes.includes("AGE_21") && (
                        <div className="input-form">
                            <>
                                <label className="form-title">21세 이상 추가금</label>
                                <div className="form-field">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="대여로 추가 금액 입력"
                                            name="age21RentPrice"
                                            value={age21RentPrice || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="면책금 추가 금액 입력"
                                            name="age21IndemPrice"
                                            value={age21IndemPrice || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    )}
                    {contractAgeTypes.includes("AGE_23") && (
                        <div className="input-form">
                            <>
                                <label className="form-title">23세 이상 추가금</label>
                                <div className="form-field">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="대여로 추가 금액 입력"
                                            name="age23RentPrice"
                                            value={age23RentPrice || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="면책금 추가 금액 입력"
                                            name="age23IndemPrice"
                                            value={age23IndemPrice || ""}
                                            onChange={handleChange}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    )}
                    {expLt1Yn === "Y" && (
                        <div className="input-form">
                            <label className="form-title">운전경력 추가금</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="대여로 추가 금액 입력"
                                        name="expLt1RentPrice"
                                        value={expLt1RentPrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="면책금 추가 금액 입력"
                                        name="expLt1IndemPrice"
                                        value={expLt1IndemPrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
