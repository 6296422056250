// /* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma, convertArrayToCommaSeparatedString, parseCommaSeparatedStringToArray, parseQueryString } from "util";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faRotateRight, faCalendarDays, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Toast, Modal } from "components";
import {
    CancelElecAgreement,
    CustomerInfo,
    CarInfo,
    FeeInfo,
    ContractManagerInfo,
    ChangeGrade,
    AffiliatedDealerInfo,
    DealerDetail,
    ReleaseStatus,
    ReceiptHistory,
    CancelContract,
    RequestRedemtion,
    ContractConfirm,
    ApplicationConsignment,
    DetailConsignment,
    ApplicationReturn,
    ManagementContract,
} from "../modal";

export const ContractTable = () => {
    // 기존 상태 정의
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]); // 상품 목록 상태
    const [pagination, setPagination] = useState([]);
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태

    // 계약요청 내역
    const fetchContractData = async (data) => {
        setLoading(true);

        try {
            let requestDatas;
            if (data !== null) requestDatas = data;

            const response = await fetchData(`/api/share/rent_corp/contract/v1/request`, requestDatas);
            setData(response.data.data);
            setPagination(response.data.pagination);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("필터링된 상품 목록을 불러오는 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        setSearchParams();
        fetchContractData();
    }, []);

    const cancelContract = async (contractId) => {
        setLoading(true);

        try {
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/cancel`);
            handleModalClose();
            handleToast(response.data.message);
            fetchContractData();
            setLoading(false);
        } catch (error) {
            handleModalClose();
            handleToast(error.response.data.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const submitContract = async (contractId) => {
        setLoading(true);

        try {
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/send_customer`);
            handleModalClose();
            handleToast(response.data.message);
            fetchContractData();
            setLoading(false);
        } catch (error) {
            handleModalClose();
            handleToast(error.response.data.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const finishRelease = async (contractId) => {
        setLoading(true);

        try {
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/release_finish`);
            handleModalClose();
            handleToast(response.data.message);
            fetchContractData();
            setLoading(false);
        } catch (error) {
            handleModalClose();
            handleToast(error.response.data.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const cancelDelivery = async (contractId) => {
        setLoading(true);

        try {
            const response = await deleteData(`/api/share/rent_corp/contract/v1/${contractId}/delivery_cancel`);
            handleModalClose();
            handleToast(response.data.message);
            fetchContractData();
            setDetailConsignmentModalStatus(false);
            setLoading(false);
        } catch (error) {
            handleModalClose();
            handleToast(error.response.data.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const cancelRefund = async (contractId) => {
        setLoading(true);

        try {
            const response = await deleteData(`/api/share/rent_corp/contract/v1/${contractId}/refund_cancel`);
            handleModalClose();
            handleToast(response.data.message);
            fetchContractData();
            setLoading(false);
        } catch (error) {
            handleModalClose();
            handleToast(error.response.data.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const [searchKeyword, setSearchKeyword] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [calculateStatus, setCalculateStatus] = useState(null);
    const [page, setPage] = useState(0);

    const searchData = () => {
        const params = {
            contract_status_list: contractStatus,
            calculate_status: calculateStatus,
            started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            search_keyword: searchKeyword,
        };

        fetchContractData(params);
    };

    useEffect(() => {
        searchData();
    }, [startDate, endDate, contractStatus, calculateStatus]);

    const contractStatusOptions = [
        { value: "REQUEST", label: "접수중" },
        { value: "ON_PROCESS", label: "진행중" },
        { value: "FINISH", label: "계약완료" },
        { value: "DELIVERY_FINISH", label: "탁송신청완료" },
        { value: "RETURN", label: "반납" },
        { value: "RELEASE_READY", label: "출고준비중" },
        { value: "RELEASE_FINISH", label: "출고완료" },
        { value: "REJECT", label: "진행 불가" },
        { value: "CANCEL", label: "중도 해지" },
    ];

    const calculateStatusOptions = [
        { value: "PENDING", label: "정산전" },
        { value: "CANCEL", label: "환수" },
        { value: "FINISH", label: "정산완료" },
    ];

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const initParams = () => {
        setSearchKeyword("");
        setStartDate(null);
        setEndDate(null);
        setContractStatus(null);
        setCalculateStatus(null);

        const params = {
            contract_status_list: "",
            calculate_status: "",
            started_at: null,
            ended_at: null,
            search_keyword: "",
        };

        fetchContractData(params);
    };

    // 모달 State
    const [cancelElecAgreementModalStatus, setCancelElecAgreementModalStatus] = useState(false);
    const [carInfoModalStatus, setCarInfoModalStatus] = useState(false);
    const [feeInfoModalStatus, setFeeInfoModalStatus] = useState(false);
    const [contractManagerInfoModalStatus, setContractManagerInfoModalStatus] = useState(false);
    const [changeGradeModalStatus, setChangeGradeModalStatus] = useState(false);
    const [affiliatedDealerInfoModalStatus, setAffiliatedDealerInfoModalStatus] = useState(false);
    const [dealerDetailModalStatus, setDealerDetailModalStatus] = useState(false);
    const [requestRedemtionModalStatus, setRequestRedemtionModalStatus] = useState(false);
    const [contractConfirmModalStatus, setContractConfirmModalStatus] = useState(false);
    const [applicationReturnModalStatus, setApplicationReturnModalStatus] = useState(false);

    const [customerInfoModalStatus, setCustomerInfoModalStatus] = useState(false);
    const [receiptHistoryModalStatus, setReceiptHistoryModalStatus] = useState(false);
    const [cancelContractModalStatus, setCancelContractModalStatus] = useState(false);
    const [applicationConsignmentModalStatus, setApplicationConsignmentModalStatus] = useState(false);
    const [detailConsignmentModalStatus, setDetailConsignmentModalStatus] = useState(false);

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    //* 토스트 팝업 세팅
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="mypage_table-head" style={{ marginTop: "20px" }}>
                <div className="mypage_table-filter">
                    <div className="filter-option filter-search" style={{ marginRight: "auto" }}>
                        <input
                            type="text"
                            placeholder="고객명을 검색해주세요."
                            value={searchKeyword}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                        />
                        <button type="button" onClick={searchData}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                    </div>
                    <div className="filter-option filter-date" style={{ marginLeft: "auto" }}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <div className="input-datepicker">
                            <DatePicker
                                locale={ko}
                                dateFormat="yyyy-MM-dd"
                                placeholderText=""
                                selected={startDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(date) => {
                                    if (endDate && date > endDate) {
                                        handleToast("시작 날짜는 종료 날짜보다 이후일 수 없습니다.");
                                        return;
                                    }
                                    setStartDate(date);
                                }}
                            />
                        </div>
                        -
                        <div className="input-datepicker">
                            <DatePicker
                                locale={ko}
                                dateFormat="yyyy-MM-dd"
                                placeholderText=""
                                selected={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(date) => {
                                    if (startDate && date < startDate) {
                                        handleToast("종료 날짜는 시작 날짜보다 이전일 수 없습니다.");
                                        return;
                                    }
                                    setEndDate(date);
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-select">
                        <div className="select-element">
                            <Select
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="계약상태"
                                options={contractStatusOptions}
                                onChange={(value) => handleSelectChange(value, setContractStatus)}
                                value={contractStatusOptions.filter((option) => option.value === contractStatus)}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-select">
                        <div className="select-element">
                            <Select
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="정산현황"
                                options={calculateStatusOptions}
                                onChange={(value) => handleSelectChange(value, setCalculateStatus)}
                                value={calculateStatusOptions.filter((option) => option.value === calculateStatus)}
                            />
                        </div>
                    </div>
                    <div className="filter-option filter-refresh">
                        <button type="button" className="btn-refresh" onClick={initParams}>
                            초기화
                            <FontAwesomeIcon icon={faRotateRight} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="mypage_table-wrapper">
                <table>
                    <colgroup>
                        <col width={"6%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"16%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"7%"} />
                        <col width={"20%"} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <div className="tht">계약NO</div>
                            </th>
                            <th>
                                <div className="tht">접수일</div>
                            </th>
                            <th>
                                <div className="tht">상품구분</div>
                            </th>
                            <th>
                                <div className="tht">차량번호</div>
                            </th>
                            <th>
                                <div className="tht">차량모델</div>
                            </th>
                            <th>
                                <div className="tht">고객명</div>
                            </th>
                            <th>
                                <div className="tht">계약기간</div>
                            </th>
                            <th>
                                <div className="tht">보증금</div>
                            </th>
                            <th>
                                <div className="tht">분납여부</div>
                            </th>
                            <th>
                                <div className="tht">대여료</div>
                            </th>
                            <th>
                                <div className="tht">계약상태</div>
                            </th>
                            <th>
                                <div className="tht">입금현황</div>
                            </th>
                            <th>
                                <div className="tht"></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length === 0 && (
                            <tr>
                                <td colSpan={12}>
                                    <div className="tdd" style={{ textAlign: "center" }}>
                                        <span className="td-desc">조회된 데이터가 없습니다.</span>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {data?.map((row, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row.id}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row?.requestedAt?.split("T")[0].slice(2).replaceAll("-", "/")}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">
                                                {row?.rentType &&
                                                    (row.rentType === "LONG_RENT"
                                                        ? "장기렌트"
                                                        : row.rentType === "SUBSCRIPTION"
                                                        ? "구독"
                                                        : row.rentType === "FIRST_RELEASE"
                                                        ? "선출고"
                                                        : "알 수 없음")}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row.car.carNumber}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{row.car.modelDetailName}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd clickable" onClick={() => setCustomerInfoModalStatus(row)}>
                                            <span className="td-desc">
                                                <b>{row.customerName}</b>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">
                                                {row?.contractRangeMonth}개월
                                                {/* {row?.contractedAt?.split("T")[0].slice(2).replaceAll("-", "/")} */}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{comma(row.depositPrice)}원</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc td-color-01">
                                                {row?.depositType === "FULL" && "일시불"}
                                                {row?.depositType === "DIVIDE_2" && "2회분납"}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc">{comma(row.rentPrice)}원</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc td-color-01">{row.contractStatusStr}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <span className="td-desc td-color-01">
                                                <b>
                                                    {row?.depositStatus === "PENDING" && "입금전"}
                                                    {row?.depositStatus === "FINISH" && "입금완료"}
                                                </b>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            {row.contractStatus === "REQUEST" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setReceiptHistoryModalStatus(row.id)}
                                                    >
                                                        접수내역 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-02"
                                                        onClick={() => {
                                                            showModal("", <p>계약서를 발송하시겠습니까?</p>, [
                                                                { text: "취소", handler: handleModalClose },
                                                                {
                                                                    text: "확인",
                                                                    handler: () => {
                                                                        submitContract(row.id);
                                                                    },
                                                                },
                                                            ]);
                                                        }}
                                                    >
                                                        계약서 발송
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "ON_PROCESS" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setReceiptHistoryModalStatus(row.id)}
                                                    >
                                                        접수내역 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-02"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "FINISH" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-03"
                                                        onClick={() => setApplicationConsignmentModalStatus(row.id)}
                                                    >
                                                        탁송 신청
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-04"
                                                        onClick={() => setCancelContractModalStatus(row.id)}
                                                    >
                                                        계약 취소
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "DELIVERY_FINISH" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-02"
                                                        onClick={() => setDetailConsignmentModalStatus(row.id)}
                                                    >
                                                        탁송 정보
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-03"
                                                        onClick={() => {
                                                            showModal("", <p>해당 차량을 출고 완료 처리하시겠습니까?</p>, [
                                                                { text: "닫기", handler: handleModalClose },
                                                                {
                                                                    text: "출고 완료",
                                                                    handler: () => {
                                                                        finishRelease(row.id);
                                                                    },
                                                                },
                                                            ]);
                                                        }}
                                                    >
                                                        출고 완료처리
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "RELEASE_FINISH" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-02"
                                                        onClick={() => setRequestRedemtionModalStatus(row.id)}
                                                    >
                                                        환수 신청
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-03"
                                                        onClick={() => setApplicationReturnModalStatus(row.id)}
                                                    >
                                                        반납 신청
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "REJECT" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setCancelElecAgreementModalStatus(["REJECT", row.rejectReason])}
                                                    >
                                                        진행불가 사유확인
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "CANCEL" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setCancelElecAgreementModalStatus(["CANCEL", row.cancelReason])}
                                                    >
                                                        취소 사유확인
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "IN_CANCEL" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-04"
                                                        onClick={() => {
                                                            showModal("", <p>환수 신청을 취소하시겠습니까?</p>, [
                                                                { text: "닫기", handler: handleModalClose },
                                                                {
                                                                    text: "환수 취소",
                                                                    handler: () => {
                                                                        cancelRefund(row.id);
                                                                    },
                                                                },
                                                            ]);
                                                        }}
                                                    >
                                                        환수신청 취소
                                                    </button>
                                                </span>
                                            ) : row.contractStatus === "RETURN" ? (
                                                <span className="td-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setContractConfirmModalStatus(row.id)}
                                                    >
                                                        계약서 확인
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-status btn-type-01"
                                                        onClick={() => setCancelElecAgreementModalStatus(["RETURN", row.rejectReason])}
                                                    >
                                                        반납사유 확인
                                                    </button>
                                                </span>
                                            ) : null}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="tableFoot">
                    <div className="pagination">
                        {data?.length !== 0 && (
                            <div className="numList">
                                <div
                                    className="btnPrev"
                                    onClick={() => setPage(pagination?.prevPage)}
                                    aria-current={pagination?.isFirst ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                                <div
                                    className="btnNext"
                                    onClick={() => setPage(pagination?.nextPage)}
                                    aria-current={pagination?.isLast ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                                <ul className="currentPage">
                                    {Array(pagination?.endPage)
                                        .fill()
                                        .map(
                                            (_, i) =>
                                                i >= pagination?.startPage - 1 && (
                                                    <li
                                                        key={i}
                                                        onClick={() => setPage(i)}
                                                        className={pagination?.page === i ? "active" : null}
                                                    >
                                                        {i + 1}
                                                    </li>
                                                )
                                        )}
                                </ul>
                                {pagination?.endPage !== pagination?.totalPages && (
                                    <div className="lastPage">
                                        <li onClick={() => setPage(pagination?.totalPages - 1)}>{pagination?.totalPages}</li>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* 차량 정보 모달 */}
            {carInfoModalStatus && <CarInfo setModal={setCarInfoModalStatus} />}

            {/* 수수료 정보 모달 */}
            {feeInfoModalStatus && <FeeInfo setModal={setFeeInfoModalStatus} />}

            {/* 계약담당자 정보 모달 */}
            {contractManagerInfoModalStatus && (
                <ContractManagerInfo setModal={setContractManagerInfoModalStatus} setChangeGradeModalStatus={setChangeGradeModalStatus} />
            )}

            {/* 딜러 정보 상세 모달 */}
            {dealerDetailModalStatus && <DealerDetail setModal={setDealerDetailModalStatus} />}

            {/* 전자약정 발송 취소사유 모달 */}
            {cancelElecAgreementModalStatus && (
                <CancelElecAgreement
                    setModal={setCancelElecAgreementModalStatus}
                    contractId={cancelElecAgreementModalStatus}
                    type={cancelElecAgreementModalStatus[0]}
                    data={cancelElecAgreementModalStatus[1]}
                />
            )}

            {/* 고객 정보 모달 */}
            {customerInfoModalStatus && <CustomerInfo data={customerInfoModalStatus} setModal={setCustomerInfoModalStatus} />}

            {/* 접수내역 모달 */}
            {receiptHistoryModalStatus && (
                <ReceiptHistory
                    setModal={setReceiptHistoryModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    contractId={receiptHistoryModalStatus}
                    fetchTable={fetchContractData}
                />
            )}

            {/* 계약취소 모달 */}
            {cancelContractModalStatus && (
                <CancelContract
                    setModal={setCancelContractModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    contractId={cancelContractModalStatus}
                    fetchTable={fetchContractData}
                />
            )}

            {/* 탁송 신청 모달 */}
            {applicationConsignmentModalStatus && (
                <ApplicationConsignment
                    setModal={setApplicationConsignmentModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    dim={true}
                    contractId={applicationConsignmentModalStatus}
                    deliveryType={"DELIVERY"}
                    fetchTable={fetchContractData}
                />
            )}

            {/* 탁송 정보 확인 / 취소 모달 */}
            {detailConsignmentModalStatus && (
                <DetailConsignment
                    setModal={setDetailConsignmentModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    dim={true}
                    contractId={detailConsignmentModalStatus}
                    fetchTable={fetchContractData}
                    cancelDelivery={cancelDelivery}
                />
            )}

            {/* 환수신청 모달 */}
            {requestRedemtionModalStatus && (
                <RequestRedemtion
                    setModal={setRequestRedemtionModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    contractId={requestRedemtionModalStatus}
                />
            )}

            {/* 계약서 확인 모달 */}
            {contractConfirmModalStatus && (
                <ContractConfirm setModal={setContractConfirmModalStatus} contractId={contractConfirmModalStatus} />
            )}

            {/* 반납 신청 모달 */}
            {applicationReturnModalStatus && (
                <ApplicationReturn
                    setModal={setApplicationReturnModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    contractId={applicationReturnModalStatus}
                    fetchTable={fetchContractData}
                />
            )}

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
