/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { comma } from "util";

export const FeeInfo = ({ data, setModal }) => {
    const userData = useSelector((state) => state.login.userData);
    const userRole = useSelector((state) => state.login.userData?.roles);
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        if (userRole?.includes("AG_MASTER")) {
            setRoleType("AG_MASTER");
        } else if (userRole?.includes("AG_MEMBER") || userRole?.includes("AG_DEALER")) {
            setRoleType("AG_DEALER");
        } else if (userRole?.includes("RENT_MASTER")) {
            setRoleType("RENT_MASTER");
        }
    }, [userData]);

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>수수료 정보</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="content-list">
                        <div className="item">
                            <div className="item-head">
                                <span>보증금 분납</span>
                            </div>
                            <div className="item-content">
                                <span>
                                    {data?.depositType === "FULL" && "일시납"}
                                    {data?.depositType === "DIVIDE_2" && "2회분납"}
                                </span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>출고 수수료</span>
                            </div>
                            <div className="item-content">
                                {/* {roleType === "AG_MASTER" ? (
                                            <b>{comma(row.agCorperationCommission + row.agCorperationCommission * 0.1)}원</b>
                                        ) : roleType === "AG_DEALER" ? (
                                            <b>{comma(row.managerCommission + row.managerCommission * 0.1)}원</b>
                                        ) : roleType === "RENT_MASTER" ? (
                                            <b>{comma(row.rentCorpCommissio + row.rentCorpCommissio * 0.1)}원</b>
                                        ) : null} */}

                                {userData.shareGrade === "MASTER" ? (
                                    <span>{comma(data?.agCorperationCommission)} 원</span>
                                ) : (
                                    <span>{comma(data?.managerCommission)} 원</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * 출고 수수료:
 * shareGrade가 MASTER일 때 agCorperationCommission를 출고 수수료를 보여주면 됨
 * 그게 아니라면 managerCommission을 출고 수수료로 보여주면 됨
 */
