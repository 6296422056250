/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CompareShare } from "./modal";
import { Toast } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { convertToKoreanWon, comma } from "util";

export const ProductCompare = () => {
    const { carIds } = useParams();
    const navigate = useNavigate();

    // 기존 상태 정의
    const [datas, setDatas] = useState([]); // 상품 목록을 저장할 상태
    const [loading, setLoading] = useState(true); // 데이터 로딩 상태를 저장할 상태
    const [modalShareStatus, setModalShareStatus] = useState(false); // 공유하기 팝업

    // 필터링된 데이터 가져오기
    const fetchProductData = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/share/product/v1/compare?car_ids=${carIds}`);
            console.log(response.data.data);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            handleToast(error.response.data.message);
        }
    };

    useEffect(() => {
        if (carIds === undefined) return;

        fetchProductData();
    }, [carIds]);

    const onShare = () => {
        setModalShareStatus(true);
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    // 이미지 슬라이더 설정
    const settings = {
        arrows: false,
        dots: true, // 슬라이더 하단의 도트 표시 여부
        infinite: true, // 무한 슬라이딩 여부
        speed: 500, // 슬라이드 전환 속도
        slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
        slidesToScroll: 1, // 슬라이드 스크롤 수
    };

    return (
        <>
            {loading ? (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            ) : (
                <div className="productCompare container">
                    {/* <div className='compare-head'>
                <div className='info-box info-customer'>
                    <div className='info-box_tit'>
                        <span>고객정보</span>
                    </div>
                    <div className='info-box_list'>
                        <div className="ib-item">
                            <span className='ib-item_tit'>고객명</span>
                            <span className='ib-item_desc'>김지영</span>
                        </div>
                        <div className="ib-item">
                            <span className='ib-item_tit'>공유일</span>
                            <span className='ib-item_desc'>2022-11-31</span>
                        </div>
                    </div>
                </div>

                <div className='info-car'>
                    <span className='ic-car_number'>123가5678</span>
                    <span className='ic-car_model'>더 올뉴G80 가솔린 3.5 AWD 기본형</span>
                </div>

                <div className='info-box info-agency'>
                    <div className='info-box_tit'>
                        <span>AG 정보</span>
                    </div>
                    <div className='info-box_list'>
                        <div className="ib-item">
                            <span className='ib-item_tit'>담당AG</span>
                            <span className='ib-item_desc'>김민지</span>
                        </div>
                        <div className="ib-item">
                            <span className='ib-item_tit'>연락처</span>
                            <span className='ib-item_desc'>010-1235-5678</span>
                        </div>
                        <div className="ib-item">
                            <span className='ib-item_tit'>E-mail</span>
                            <span className='ib-item_desc'>Chada@gmail.com</span>
                        </div>
                    </div>
                </div>
            </div> */}

                    <div className="compare-contents">
                        <div className="compare-contents_tit">
                            <span>비교하기</span>
                        </div>
                        <div className="compare-list">
                            {datas?.map((data, idx) => {
                                return (
                                    <div key={idx} className="item">
                                        <div className="item-img">
                                            <Slider {...settings} className="custom-slider">
                                                {data?.carImages.map((img, idx) => {
                                                    return (
                                                        <div key={idx} className="slide-item">
                                                            <img src={img.url} />
                                                        </div>
                                                    );
                                                })}
                                            </Slider>
                                        </div>

                                        <div className="item-info">
                                            <div className="info-head">
                                                <div className="item-name">{data?.modelName}</div>
                                                <div className="item-model">
                                                    {data?.modelDetailName} {data?.className} {data?.trimName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_contents grid-4">
                                                <div className="info-box">
                                                    <div className="item-name">차량번호</div>
                                                    <div className="item-desc">{data?.carNumber}</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">주행거리</div>
                                                    <div className="item-desc">{comma(data?.mileage)}km</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">연료</div>
                                                    <div className="item-desc">
                                                        {data?.fuelType === "GAS"
                                                            ? "가솔린"
                                                            : data?.fuelType === "DIESEL"
                                                            ? "디젤"
                                                            : data?.fuelType === "LPG"
                                                            ? "LPG"
                                                            : data?.fuelType === "GAS_LPG"
                                                            ? "가솔린(LPG겸용)"
                                                            : data?.fuelType === "GAS_CNG"
                                                            ? "가솔린(CNG겸용)"
                                                            : data?.fuelType === "GAS_HYBRID"
                                                            ? "가솔린 하이브리드"
                                                            : data?.fuelType === "ELECTRIC"
                                                            ? "전기"
                                                            : data?.fuelType === "HYDROGEN"
                                                            ? "수소"
                                                            : "알 수 없음"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">색상</div>
                                                    <div className="item-desc">{data?.color}</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">신차가격</div>
                                                    <div className="item-desc">{comma(data?.carPrice?.newSalePrice || "-")}원</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">연식</div>
                                                    <div className="item-desc">
                                                        {data?.carMadedYear}.{data?.carMadedMonth}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">배기량</div>
                                                    <div className="item-desc">{comma(data?.displacement)}cc</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">변속기</div>
                                                    <div className="item-desc">
                                                        {data?.motorType === "AUTOMATIC"
                                                            ? "자동"
                                                            : data?.motorType === "MANUAL"
                                                            ? "수동"
                                                            : data?.motorType === "CVT"
                                                            ? "CVT"
                                                            : data?.motorType === "DUAL"
                                                            ? "듀얼클러치"
                                                            : "알 수 없음"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="item-info">
                                            <div className="item-info_contents">
                                                <div className="info-box">
                                                    <div className="item-name">등급</div>
                                                    <div className="item-desc">
                                                        {data.carType === "COMPACT"
                                                            ? "경차"
                                                            : data.carType === "SMALL"
                                                            ? "소형차"
                                                            : data.carType === "SMALL_MID"
                                                            ? "준중형차"
                                                            : data.carType === "MID"
                                                            ? "중형차"
                                                            : data.carType === "BIG"
                                                            ? "대형차"
                                                            : data.carType === "RV"
                                                            ? "RV차"
                                                            : data.carType === "SUV"
                                                            ? "SUV차"
                                                            : data.carType === "SPORT"
                                                            ? "스포츠카"
                                                            : data.carType === "VAN"
                                                            ? "승합차"
                                                            : data.carType === "ETC"
                                                            ? "기타"
                                                            : "알 수 없음"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">주행거리</div>
                                                    <div className="item-desc">{comma(data?.mileage)}km</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">연료</div>
                                                    <div className="item-desc">
                                                        {data?.fuelType === "GAS"
                                                            ? "가솔린"
                                                            : data?.fuelType === "DIESEL"
                                                            ? "디젤"
                                                            : data?.fuelType === "LPG"
                                                            ? "LPG"
                                                            : data?.fuelType === "GAS_LPG"
                                                            ? "가솔린(LPG겸용)"
                                                            : data?.fuelType === "GAS_CNG"
                                                            ? "가솔린(CNG겸용)"
                                                            : data?.fuelType === "GAS_HYBRID"
                                                            ? "가솔린 하이브리드"
                                                            : data?.fuelType === "ELECTRIC"
                                                            ? "전기"
                                                            : data?.fuelType === "HYDROGEN"
                                                            ? "수소"
                                                            : "알 수 없음"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">연식</div>
                                                    <div className="item-desc">
                                                        {data?.carMadedYear}.{data?.carMadedMonth}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="item-info">
                                            <div className="item-info_contents" style={{ minHeight: "213px" }}>
                                                <div className="info-box">
                                                    <div className="item-name">특약</div>
                                                    <div className="item-tags">
                                                        {data?.rentInfo?.depositTwiceYn === "N" &&
                                                        data?.rentInfo?.expLt1Yn === "N" &&
                                                        !data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                                        !data?.rentInfo?.contractAgeTypes?.includes("AGE_23")
                                                            ? "내용 없음"
                                                            : ""}

                                                        {data?.rentInfo?.depositTwiceYn === "Y" && (
                                                            <p>
                                                                <em>• 보증금 2회 분납 가능</em>
                                                            </p>
                                                        )}
                                                        {data?.rentInfo?.expLt1Yn === "Y" && (
                                                            <p>
                                                                <em>• 운전면허 1년 미만 계약 가능</em>
                                                            </p>
                                                        )}

                                                        {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                            <p>
                                                                <em>• 만 21세 이상 가능</em> <br />
                                                                &nbsp;&nbsp;&nbsp;* 월 렌트료{" "}
                                                                {convertToKoreanWon(data?.rentInfo?.age21RentPrice)} 추가 <br />
                                                                &nbsp;&nbsp;&nbsp;* 면책금 기본 +{" "}
                                                                {convertToKoreanWon(data?.rentInfo?.age21IndemPrice)} 추가
                                                            </p>
                                                        )}
                                                        {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                            <p>
                                                                <em>• 만 23세 이상 가능</em> <br />
                                                                &nbsp;&nbsp;&nbsp;* 월 렌트료{" "}
                                                                {convertToKoreanWon(data?.rentInfo?.age23RentPrice)} 추가 <br />
                                                                &nbsp;&nbsp;&nbsp;* 면책금 기본 +{" "}
                                                                {convertToKoreanWon(data?.rentInfo?.age23IndemPrice)} 추가
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_head">
                                                <span>보증금/렌트료</span>
                                            </div>
                                            <div className="item-info_contents">
                                                <div className="info-box">
                                                    <div className="item-name">약정개월</div>
                                                    <div className="item-col">
                                                        {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                            <span key={index} className="col">
                                                                {price.contractRangeMonth}개월
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">보증금</div>
                                                    <div className="item-col">
                                                        {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                            <span key={index} className="col">
                                                                {price.depositPrice.toLocaleString()}원
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">렌트료</div>
                                                    <div className="item-col">
                                                        {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                            <span key={index} className="col">
                                                                {price.rentPrice.toLocaleString()}원
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_head">
                                                <span>보험조건</span>
                                            </div>
                                            <div className="item-info_contents grid-3">
                                                <div className="info-box">
                                                    <div className="item-name">무보험 상해가입</div>
                                                    <div className="item-desc">
                                                        {data?.rentInfo?.dutyNoInsuranceInjury
                                                            ? `가입(${convertToKoreanWon(data?.rentInfo?.dutyNoInsuranceInjury)})`
                                                            : "미가입"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">대인</div>
                                                    <div className="item-desc">
                                                        {data?.rentInfo?.dutyDaein === -1
                                                            ? "무제한"
                                                            : `${convertToKoreanWon(data?.rentInfo?.dutyDaein)}`}
                                                        {/* {data?.rentInfo?.dutyDaein
                                                            ? `${convertToKoreanWon(data?.rentInfo?.dutyDaein)}`
                                                            : "미가입"} */}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">대물</div>
                                                    <div className="item-desc">
                                                        {data?.rentInfo?.dutyDaemul
                                                            ? `${convertToKoreanWon(data?.rentInfo?.dutyDaemul)}`
                                                            : "미가입"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">자손(사망)</div>
                                                    <div className="item-desc">
                                                        {data?.rentInfo?.dutyJason
                                                            ? `${convertToKoreanWon(data?.rentInfo?.dutyJason)}`
                                                            : "미가입"}
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">자차</div>
                                                    <div className="item-desc">
                                                        {`정비비용의 ${data?.rentInfo?.dutyJacha}%`}
                                                        {/* {data?.rentInfo?.dutyJacha
                                                            ? `${convertToKoreanWon(data?.rentInfo?.dutyJacha)}`
                                                            : "미가입"} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_head">
                                                <span>면책금</span>
                                            </div>
                                            <div className="item-info_contents">
                                                <div className="item-list grid-4 grid-table">
                                                    <div className="indem-item" style={{ minHeight: "248px" }}>
                                                        <div className="item-tit"></div>
                                                        <div className="item-col">
                                                            <span className="col color-grey">만 26세 이상</span>
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                                <span className="col color-grey">만 23세 이상</span>
                                                            )}
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                                <span className="col color-grey">만 21세 이상</span>
                                                            )}
                                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                                <span className="col color-grey">
                                                                    면허 1년 미만
                                                                    <br />
                                                                    (만 26세 기준)
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="indem-item" style={{ minHeight: "248px" }}>
                                                        <div className="item-tit">대인</div>
                                                        <div className="item-col">
                                                            <span className="col">{comma(data?.rentInfo?.indemDaein)}원</span>
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age23IndemPrice)}원
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age21IndemPrice)}원
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.expLt1IndemPrice)}원
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="indem-item" style={{ minHeight: "248px" }}>
                                                        <div className="item-tit">대물</div>
                                                        <div className="item-col">
                                                            <span className="col">{comma(data?.rentInfo?.indemDaemul)}원</span>
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age23IndemPrice)}원
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age21IndemPrice)}원
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                                <span className="col">
                                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.expLt1IndemPrice)}
                                                                    원
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="indem-item">
                                                        <div className="item-tit">자차</div>
                                                        <div className="item-col">
                                                            <span className="col">
                                                                최소 {convertToKoreanWon(data?.rentInfo?.indemJachaMin)} ~ <br /> 최대{" "}
                                                                {convertToKoreanWon(data?.rentInfo?.indemJachaMax)}
                                                            </span>
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                                <span className="col">
                                                                    최소{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.age23IndemPrice
                                                                    )}{" "}
                                                                    ~ <br /> 최대{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.age23IndemPrice
                                                                    )}
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                                <span className="col">
                                                                    최소{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.age21IndemPrice
                                                                    )}{" "}
                                                                    ~ <br /> 최대{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.age21IndemPrice
                                                                    )}
                                                                </span>
                                                            )}
                                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                                <span className="col">
                                                                    최소{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.expLt1IndemPrice
                                                                    )}{" "}
                                                                    ~ <br /> 최대{" "}
                                                                    {convertToKoreanWon(
                                                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.expLt1IndemPrice
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="info-box">
                                                    <div className="item-name">대인</div>
                                                    <div className="item-desc">30만원</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">대물</div>
                                                    <div className="item-desc">30만원</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="item-name">자차</div>
                                                    <div className="item-desc">50만원</div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_contents">
                                                <div className="info-box">
                                                    <div className="item-name">선택옵션</div>
                                                    <div className="item-head">
                                                        {data?.carAddOptionContents.length === 0 ? (
                                                            <span>옵션이 추가되지 않은 차량입니다.</span>
                                                        ) : (
                                                            <span>
                                                                {(() => {
                                                                    const total = data?.carAddOptionContents.reduce(
                                                                        (acc, option) => acc + option.price,
                                                                        0
                                                                    );
                                                                    const formattedTotal = Math.floor(total / 10000); // 소수점 제거

                                                                    return <em>{formattedTotal}만원</em>;
                                                                })()}
                                                                &nbsp;상당의 옵션이 추가된 차량입니다.
                                                            </span>
                                                        )}
                                                        {/* <button type='button' className='btn-catalog'>카탈로그 보기</button> */}
                                                    </div>
                                                    <div className="item-list option-list grid-2">
                                                        {data?.carAddOptionContents?.map((option, index) => {
                                                            if (option.carAddOptionContentItems.length === 0) {
                                                                return (
                                                                    <div key={index} className="item option-item">
                                                                        <div className="item-tit">{option.name}</div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                                                    return (
                                                                        <div key={sub_index} className="item option-item">
                                                                            <div className="item-tit">
                                                                                <span>{sub_option.title}</span>
                                                                                {sub_option.content !== "" && (
                                                                                    <button type="button">
                                                                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                                                                        <div className="guide_popup">
                                                                                            {/* <span className="guide_popup-tit">계약 가능 연령</span> */}
                                                                                            <p className="guide_popup-desc">
                                                                                                {sub_option.content}
                                                                                            </p>
                                                                                        </div>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                });
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="item-info_contents">
                                                <div className="info-box">
                                                    <div className="item-name">유의사항</div>
                                                    <div className="item-tags">
                                                        {data?.rentInfo?.contractNote && (
                                                            <p>
                                                                <em>{data?.rentInfo?.contractNote}</em>
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-buttons">
                                            <button
                                                type="button"
                                                className="btn-detail"
                                                onClick={() => navigate(`/product/detail/${data.id}`)}
                                            >
                                                상세보기
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="compare-contents_buttons">
                            {/* <button type="button" className="btn-share" onClick={onShare}>
                                공유하기
                            </button> */}
                        </div>
                    </div>
                </div>
            )}

            {/* 공유하기 모달 */}
            {modalShareStatus && <CompareShare setModal={setModalShareStatus} data={datas} />}

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
