import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from 'api';

// 초기 상태 정의
const initialState = {
  colors: [], // 색상 데이터 배열
  status: 'idle', // 데이터 로딩 상태를 관리하는 필드 추가
  error: null, // 에러를 저장하는 필드 추가
};

// createAsyncThunk를 사용하여 비동기 액션 생성
export const fetchColors = createAsyncThunk('colors/fetchColors', async () => {
  try {
    // API 호출하여 색상 데이터 가져오기
    const response = await fetchData('/api/share/car_color/v1');
    return response.data.data; // API 응답에서 데이터를 추출하여 반환
  } catch (error) {
    throw error;
  }
});

// createSlice 함수를 사용하여 액션 및 리듀서 생성
const colorSlice = createSlice({
  name: 'colors', // 슬라이스 이름
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.pending, (state) => {
        state.status = 'loading'; // 로딩 중 상태 설정
        state.error = null; // 에러 초기화
      })
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.status = 'succeeded'; // 로딩 성공 상태 설정
        state.colors = action.payload; // API에서 가져온 데이터 설정
      })
      .addCase(fetchColors.rejected, (state, action) => {
        state.status = 'failed'; // 로딩 실패 상태 설정
        state.error = action.error.message; // 에러 메시지 설정
      });
  },
});

export default colorSlice.reducer;