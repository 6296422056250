import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { FilterItem, RadioButton, CheckBox } from "components";
import { fetchData } from "api";

export const Filter = ({ filters, onFilterChange, onResetFilters }) => {
    const [firstLoading, setFirstLoading] = useState(true);
    const [manufacturerOptions, setManufacturerOptions] = useState([]); // 메타데이터 (차량제조사 등)

    // 차량 제조사 메타 데이터 가져오기
    const fetchCarManufacturer = async () => {
        try {
            const response = await fetchData(`/api/share/data/v1/car_manufacturer`);
            setManufacturerOptions(response.data.data);
            setFirstLoading(false);
        } catch (error) {
            console.error("필터링된 상품 목록을 불러오는 중 에러 발생:", error);
        }
    };

    const handleRadioButtonChange = (e, option) => {
        onFilterChange(e, option);
    };

    const handleCheckBoxChange = (e) => {
        const { name, value, checked } = e.target;

        if (value === "") {
            // 전체 선택시
            if (checked) {
                // const option = name === "car_types" ? ['COMPACT', 'SMALL', 'SMALL_MID', 'MID', 'BIG', 'SUV', 'RV'] : ['현대', '기아', '쉐보레', '제네시스'];
                // onFilterChange(e, option);
                onFilterChange(e, [""]);
            } else {
                onFilterChange(e, []);
            }
        } else {
            // 개별 선택시
            let newSelection = [...filters[name]];
            newSelection = newSelection.filter((type) => type !== "");
            if (checked) {
                newSelection = [...newSelection, value];
            } else {
                newSelection = newSelection.filter((type) => type !== value);
            }

            onFilterChange(e, newSelection);
        }
    };

    useEffect(() => {
        // if (firstLoading) return;
        fetchCarManufacturer();
    }, [filters]);

    return (
        <>
            <div className="filterHead">
                <span className="filterTit">필터</span>
                <button type="button" className="btnFilterReset" onClick={onResetFilters}>
                    <FontAwesomeIcon icon={faRedo} />
                    초기화
                </button>
            </div>

            <div className="filterList">
                {/* <FilterItem title="" dropdown={true}>
                    <CheckBox
                        name="share_product_status"
                        value="SELL"
                        label="출고 가능 상품만 보기"
                        checked={filters?.share_product_status?.includes("SELL")}
                        onChange={handleCheckBoxChange}
                    />
                </FilterItem> */}
                <FilterItem title="" dropdown={true}>
                    <CheckBox
                        name="release_avail_yn"
                        value="Y"
                        label="출고 가능 상품만 보기"
                        checked={filters?.release_avail_yn?.includes("Y")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="exp_lt_1_yn"
                        value="Y"
                        label="운전면허 1년 미만"
                        checked={filters?.exp_lt_1_yn?.includes("Y")}
                        onChange={handleCheckBoxChange}
                    />
                </FilterItem>
                <FilterItem title="월 렌트료" dropdown={true}>
                    <RadioButton
                        id="radio2"
                        name="rent_price"
                        value=""
                        label="전체"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "", max_value: "" }}
                        checked={filters?.min_rent_price === ""}
                    />
                    <RadioButton
                        id="radio3"
                        name="rent_price"
                        value=""
                        label="50만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "0", max_value: "499999" }}
                        checked={filters?.min_rent_price === "0"}
                    />
                    <RadioButton
                        id="radio4"
                        name="rent_price"
                        value=""
                        label="50만원~60만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "500000", max_value: "599999" }}
                        checked={filters?.min_rent_price === "500000"}
                    />
                    <RadioButton
                        id="radio5"
                        name="rent_price"
                        value=""
                        label="60만원~70만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "600000", max_value: "699999" }}
                        checked={filters?.min_rent_price === "600000"}
                    />
                    <RadioButton
                        id="radio6"
                        name="rent_price"
                        value=""
                        label="70만원~80만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "700000", max_value: "799999" }}
                        checked={filters?.min_rent_price === "700000"}
                    />
                    <RadioButton
                        id="radio7"
                        name="rent_price"
                        value=""
                        label="80만원~90만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "800000", max_value: "899999" }}
                        checked={filters?.min_rent_price === "800000"}
                    />
                    <RadioButton
                        id="radio8"
                        name="rent_price"
                        value=""
                        label="90만원~100만원 미만"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "900000", max_value: "999999" }}
                        checked={filters?.min_rent_price === "900000"}
                    />
                    <RadioButton
                        id="radio9"
                        name="rent_price"
                        value=""
                        label="100만원 이상"
                        onChange={handleRadioButtonChange}
                        option={{ min_value: "1000000", max_value: "999999999" }}
                        checked={filters?.min_rent_price === "1000000"}
                    />
                </FilterItem>
                <FilterItem title="계약 연령" icon={faQuestionCircle} dropdown={false}>
                    <RadioButton
                        id="radio10"
                        name="contract_age_types"
                        value=""
                        label="전체"
                        onChange={handleRadioButtonChange}
                        option={{ contract_age_types: "" }}
                        checked={filters?.contract_age_types === ""}
                    />
                    <RadioButton
                        id="radio11"
                        name="contract_age_types"
                        value=""
                        label="21세 이상"
                        onChange={handleRadioButtonChange}
                        option={{ contract_age_types: "AGE_21" }}
                        checked={filters?.contract_age_types === "AGE_21"}
                    />
                    <RadioButton
                        id="radio12"
                        name="contract_age_types"
                        value=""
                        label="23세 이상"
                        onChange={handleRadioButtonChange}
                        option={{ contract_age_types: "AGE_23" }}
                        checked={filters?.contract_age_types === "AGE_23"}
                    />
                </FilterItem>
                <FilterItem title="차량 종류" dropdown={false}>
                    <CheckBox
                        name="car_types"
                        value=""
                        label="전체"
                        checked={filters?.car_types?.length === 0}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="COMPACT"
                        label="경차"
                        checked={filters?.car_types?.includes("COMPACT")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="SMALL"
                        label="소형"
                        checked={filters?.car_types?.includes("SMALL")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="SMALL_MID"
                        label="준중형"
                        checked={filters?.car_types?.includes("SMALL_MID")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="MID"
                        label="중형"
                        checked={filters?.car_types?.includes("MID")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="BIG"
                        label="대형"
                        checked={filters?.car_types?.includes("BIG")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="SUV"
                        label="SUV"
                        checked={filters?.car_types?.includes("SUV")}
                        onChange={handleCheckBoxChange}
                    />
                    <CheckBox
                        name="car_types"
                        value="RV"
                        label="RV"
                        checked={filters?.car_types?.includes("RV")}
                        onChange={handleCheckBoxChange}
                    />
                </FilterItem>
                <FilterItem title="제조사" dropdown={false}>
                    <CheckBox
                        name="car_manufacturer_name_list"
                        value=""
                        label="전체"
                        checked={filters?.car_manufacturer_name_list.length === 0}
                        onChange={handleCheckBoxChange}
                    />
                    {manufacturerOptions?.map((option) => (
                        <CheckBox
                            key={option.id}
                            name="car_manufacturer_name_list"
                            value={option.nameKr}
                            label={option.nameKr}
                            checked={filters?.car_manufacturer_name_list?.includes(option.nameKr)}
                            onChange={handleCheckBoxChange}
                        />
                    ))}
                </FilterItem>
            </div>
        </>
    );
};
