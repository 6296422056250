/* eslint-disable */
import React from "react";

export const ContractStep1 = () => {
    return (
        <div className="step">
            <span className="guide-tit">
                <em>계약접수시</em> 필요서류
            </span>
            <p className="guide-txt">
                <b>
                    • 운전면허증 / 운전경력증명서(최근 3년) 사진 <em style={{ color: "red" }}>*필수</em>
                </b>
                {"\n"}* 분실 면허증이 아닌 재발급 신분증을 준비해 주세요.
                {"\n"}* 면허번호 및 일련번호가 잘 보이도록 촬영하여 첨부 해주세요.
                {"\n"}* 운전경력증명서는 정부24에서 발급 가능합니다.
                {"\n"}
                {"\n"}
                <b>• 등본/초본/가족관계증명서 사본 (향후 추가 가능)</b>
                {"\n"}* 주소 확인을 위해 등기서류중 이미지로 먼저 첨부해주세요.
                {"\n"}* 초본의 경우 이전주소가 모두 포함 되어야 합니다.
                {"\n"}* 원본서류는 등기로 보내주셔야 합니다.
                {"\n"}
                {"\n"}
                <b>• 자동이체 통장사본 (향후 추가 가능)</b>
                {"\n"}* CMS 자동이체를 위한 계약자 본인 명의의 통장 사본을 준비해주세요. (계약자 본인명의의 통장사본이 아닐경우 추가 서류가
                필요 합니다.)
                {"\n"}
                {"\n"}
                <b>• 본인서명사실 확인서 (향후 추가 가능)</b>
                {"\n"}
                <strong>* 전자계약 시 동일한 서명체 사용 필수입니다</strong>
            </p>

            <span className="guide-tit">
                <em>계약금 안내</em>
            </span>
            <p className="guide-txt">
                <b>
                    <strong>• 계약금 30만원 입금 확인 후 전자 계약서가 발송됩니다.</strong>
                </b>
                {"\n"}
                국민은행 081101-04-203926 모비먼트 주식회사
            </p>
        </div>
    );
};
