/* eslint-disable */
import React from "react";

export const CheckBox = ({ name, label, value, checked, onChange }) => {
  return (
    <div className="input-check">
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          <div></div>
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
};
