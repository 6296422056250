/* eslint-disable */
import * as XLSX from "xlsx";
// import moment from "moment";
// import "moment/locale/ko";

// 한글만 허용하는 함수
export function allowOnlyKoreanCharacters(event) {
    // const regExp = /[^0-9a-zA-Z]/g; // 숫자와 영문자만 허용
    const regExp = /[^ㄱ-ㅎ|가-힣]/g;
    event.target.value = event.target.value.replace(regExp, "");
}

// 입력 길이 제한 함수
export function limitInputMaxLength(event) {
    const maxLength = event.target.maxLength;
    if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
    }
}

// 배열 필드를 콤마로 구분된 문자열로 변환하는 함수
export const convertArrayToCommaSeparatedString = (array) => {
    if (Array.isArray(array)) {
        return array.join(",");
    } else {
        return "";
    }
};

// URL에서 콤마로 구분된 문자열을 배열로 파싱하는 함수
export const parseCommaSeparatedStringToArray = (str) => {
    if (str === "") return [];

    return str.split(",");
};

// URL 쿼리 문자열을 필터 객체로 변환
export const parseQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    let filters = {};
    for (const [key, value] of params.entries()) {
        filters[key] = value;
    }
    return filters;
};

// 엑셀 다운로드 함수
export const downloadExcel = (headers, data, fileName) => {
    if (!data || data.length === 0) {
        alert("내보낼 데이터가 없습니다.");
        return;
    }

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(dataBlob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// formData setState
export const handleChange = (e, name, formData, setFormData) => {
    const target = e.target ? e.target : e;
    const value = target.value ? target.value : e.option?.value;
    const inputName = target.name ? target.name : name;

    if (target.type === "checkbox") {
        const dataTarget = target?.getAttribute("data-target");

        if (target.checked) {
            setFormData((prevState) => ({
                ...prevState,
                [dataTarget]: [...prevState[dataTarget], inputName],
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [dataTarget]: prevState[dataTarget].filter((item) => item !== inputName),
            }));
        }
    } else {
        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    }
};

// 숫자만 입력 [input type="text"]

export const handleNumericInput = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const numberWithCommas = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    e.target.value = numberWithCommas;
};

export const handleNumericInputWithoutComma = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    e.target.value = inputValue;
};

// 퍼센트 계산 함수
export const getPercentValue = (value, percent) => {
    if (!percent) return 0;
    if (!value) return 0;
    return Math.round(value * (percent / 100));
};

// 주민번호 형식 변환 함수
export const convertResidentRegistrationNumber = (value) => {
    const cleanValue = value?.replace(/[^0-9]/g, "");

    return cleanValue?.replace(/^(\d{6})(\d{7})$/, `$1-$2`);
};

// 전화번호 형식 변환 함수
export const convertPhone = (value) => {
    return value?.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

// 하이픈 제거 함수
export const removeHyphen = (value) => {
    return value?.replace("-", "");
};

// 가격 형식 변환 함수
export const covertPrice = (value) => {
    return String(value).slice(0, -4);
};

export const removeTag = (str) => {
    str = str.replace(/(<([^>]+)>)/gi, "");
    str = str.replace(/\s\s+/g, " ");
    str = str.replace(/&nbsp;/gi, "");
    return str;
};

// 라이센스 번호 형식 변환 함수
export const covertLicNum = (value) => {
    const type = "ID_NUMBER";

    if (!value) return;
    if (type === "LICENSE_NUMBER") {
        // 사업자 등록번호 반환
        return value.replace(/-/g, "").replace(/(\d{3})(\d{2})(\d{5})/g, "$1-$2-$3");
    } else if (type === "ID_NUMBER") {
        // 주민등록번호 반환 (뒷자리 마스킹)
        value = value.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");

        let pattern = /.{6}$/;
        return value.replace(pattern, "******");
    }
};

// Blob을 File로 변환하는 함수
export const blobToFile = (blob) => {
    return new File([blob], "sign.pdf", {
        lastModified: new Date().getTime(),
        type: blob.type,
    });
};

// UTC 시간 초기화 함수
export const getDateTime = (date) => {
    //? (input) => Tue Jul 05 2022 00:00:00 GMT+0900 (한국 표준시)
    //? (output) => 2022-07-05T00:00:00.000
    if (date) {
        // 단위가 ms 이기 때문에 60000을 곱해준다.
        let offset = date.getTimezoneOffset() * 60000;
        let dateOffset = new Date(date.getTime() - offset);

        return dateOffset.toISOString().slice(0, -5);
    }
};

// 만 나이 계산
export function calculateKoreanAge(idNumber) {
    const today = new Date();
    const birthYearPrefix = idNumber.charAt(6) === "1" || idNumber.charAt(6) === "2" ? 1900 : 2000;
    const year = birthYearPrefix + parseInt(idNumber.substring(0, 2), 10);
    const month = idNumber.substring(2, 4);
    const day = idNumber.substring(4, 6);

    const birthDate = new Date(`${year}-${month}-${day}`);

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function calculateKoreanAge_front(idNumber) {
    const birthYear = parseInt(idNumber.substring(0, 2));
    const currentYear = new Date().getFullYear() % 100; // 현재 연도의 뒤 2자리
    let age = currentYear - birthYear + 1;
    if (birthYear > currentYear) {
        age += 100;
    }
    return age;
}

export function checkAgeEligibility(idNumber, ageLimit) {
    let age;
    if (idNumber.length === 13) {
        age = calculateKoreanAge(idNumber);
    } else if (idNumber.length === 6) {
        age = calculateKoreanAge_front(idNumber);
    } else {
        throw new Error("Invalid idNumber length. It should be either 13 or 6 characters long.");
    }

    return age >= ageLimit;
}

export function getAgeCategory(idNumber) {
    console.log(idNumber);
    if (checkAgeEligibility(idNumber, 26)) {
        return "만 26세 이상";
    } else if (checkAgeEligibility(idNumber, 23)) {
        return "만 23세 이상";
    } else if (checkAgeEligibility(idNumber, 21)) {
        return "만 21세 이상";
    } else {
        return "만 21세 미만";
    }
}

// 가장 적은 나이를 가진 데이터 찾기
export const findYoungestDriver = (drivers) => {
    let youngestAge = Infinity;
    let youngestDriver = null;

    drivers?.forEach((driver) => {
        const birthYear = parseInt(driver.idNumber.substring(0, 2));

        const currentYear = new Date().getFullYear();
        const age = currentYear - (1900 + birthYear);

        if (age < youngestAge) {
            youngestAge = age;
            youngestDriver = driver;
        }
    });

    return youngestDriver;
};

// 면허번호 형식 반환 함수
export const formatDriverLicenseNumber = (licenseNumber) => {
    const parts = [
        licenseNumber?.slice(0, 2), // 첫 번째 그룹
        licenseNumber?.slice(2, 4), // 두 번째 그룹
        licenseNumber?.slice(4, 10), // 세 번째 그룹
        licenseNumber?.slice(10), // 네 번째 그룹
    ];

    return parts.join("-");
};

// 현재 연도 반환 함수
export const getYear = () => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const newArr = [];

    for (let i = currentYear - 45; i <= currentYear; i++) {
        newArr.push(i);
    }

    return newArr;
};

// 한글 원화 단위 변환
// export function convertToKoreanWon(number) {
//     const units = ["", "만", "억", "조", "경"];
//     let result = "";
//     let unitIndex = 0;

//     while (number > 0) {
//         const part = number % 10000;
//         if (part !== 0) {
//             result = part + units[unitIndex] + result;
//         }
//         number = Math.floor(number / 10000);
//         unitIndex++;
//     }

//     return result + "원";
// }

export function convertToKoreanWon(numberString) {
    // 숫자 문자열로 변환 후 콤마 제거
    let number = parseFloat(numberString.toString().replace(/,/g, ""));

    const units = ["", "만", "억", "조", "경"];
    let result = "";
    let unitIndex = 0;

    while (number > 0) {
        const part = number % 10000;
        if (part !== 0) {
            result = part + units[unitIndex] + result;
        }
        number = Math.floor(number / 10000);
        unitIndex++;
    }

    return result + "원";
}

// 천단위 콤마 삽입 함수
export const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
};

// 천단위 콤마 제거 함수
export const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
};

// 특정일로부터 경과 개월 수 반환 함수
export const getCompareDays = (_inDate, _outDate) => {
    const outDate = new Date(_outDate);
    const inDate = new Date(_inDate);

    const betweenTime = Math.floor((outDate.getTime() - inDate.getTime()) / 1000 / 60);

    const betweenTimeHour = Math.floor(betweenTime / 60);
    return betweenTimeHour / 24;
};

// 등록일로부터 경과 개월 수 반환 함수
export const getMonths = (value) => {
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);

    const betweenTimeHour = Math.floor(betweenTime / 60);
    // console.log(betweenTimeHour / 24);
};

// 경과 개월수 반환
export const getElapsedMonths = (value) => {
    // value가 ex)20200401 형태로 들어와야 함.
    const nowTime = getCurrentTime();
    const startDate = nowTime.split("T")[0];
    const endDate = value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"); // value에 하이픈 기호 삽입 ex)2020-04-01

    const start_date_arr = startDate.split("-");
    const end_date_arr = endDate.split("-");

    // 개월수 비교
    const numberOfMonths = (end_date_arr[0] - start_date_arr[0]) * 12 + (end_date_arr[1] - start_date_arr[1]);
    if (numberOfMonths > 12) {
        console.log("달별 range 최대 12개월" + numberOfMonths);
        return;
    }

    return Math.abs(numberOfMonths); // 음수를 정수로 변환 후 반환
};

// 현재 일자/시각 구하기 함수
export const getCurrentTime = () => {
    let today = new Date();

    let year = today.getFullYear();
    let month = ("0" + (today.getMonth() + 1)).slice(-2);
    let day = ("0" + today.getDate()).slice(-2);
    let hours = ("0" + today.getHours()).slice(-2);
    let minutes = ("0" + today.getMinutes()).slice(-2);
    let seconds = ("0" + today.getSeconds()).slice(-2);
    let milliseconds = today.getMilliseconds();

    let dateString = year + "-" + month + "-" + day;
    let timeString = hours + ":" + minutes + ":" + seconds + "." + milliseconds;

    return dateString + "T" + timeString;
};

// 상품화 뱃지 함수 (등록된 시간으로부터 48시간 이내)
export const isNewData = (value) => {
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour <= 48) {
        return true;
    }
    return false;
};

// 쿠키 관련 함수들
export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
};

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
};

export const delCookie = (cname) => {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const getCookieJson = (cname) => {
    let res = getCookie(cname);

    if (res === "") {
        return null;
    } else {
        return JSON.parse(res);
    }
};

// 파일 추가 함수
export const onFileAdd = (setFile) => {
    let input = document.createElement("input");

    input.type = "file";
    input.onchange = (e) => {
        let files = Array.from(input.files);

        setFile({
            file: "",
            imagePreviewUrl: "",
        });
        e.preventDefault();
        let reader = new FileReader();
        let file = files[0];

        reader.onloadend = () => {
            setFile({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };
        !(file === undefined) && reader.readAsDataURL(file);
    };
    input.click();
};

// 파일 다운로드 함수
export const downloadFile = ({ response, fileName }) => {
    const contentType = response.headers["content-type"];

    if (response.data instanceof ArrayBuffer) {
        const uint8View = new Uint8Array(response.data);
    }

    const blob = new Blob([response.data], { type: contentType });
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    let fileExtension = "";
    switch (contentType) {
        case "image/jpeg":
            fileExtension = "jpg";
            break;
        case "image/png":
            fileExtension = "png";
            break;
        case "application/pdf":
            fileExtension = "pdf";
            break;
        case "application/octet-stream":
            fileExtension = "pdf";
            break;
        case "image/svg+xml":
            fileExtension = "svg";
            break;
        default:
            if (contentType.startsWith("image/")) {
                fileExtension = contentType.split("image/")[1];
            } else {
                fileExtension = "bin";
            }
            break;
    }

    link.download = `${fileName ?? "download"}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);
};
