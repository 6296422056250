/* eslint-disable */
import React, { useState } from "react";
import { FilterItem, RadioButton, CheckBox, TableRow } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faDownload, faTimesCircle, faAngleDown, faPlus, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export const ProductInterest = () => {
    const [pagination, setPagination] = useState({
        totalElements: 127,
        totalPages: 13,
        startPage: 1,
        endPage: 10,
        rangeSize: 10,
        curRange: 0,
        page: 0,
        size: 10,
        prevPage: -1,
        nextPage: 1,
        isFirst: true,
        isLast: false,
        sort: "receptionStatus",
        direction: "DESC",
    });
    const [page, setPage] = useState(0);

    return (
        <div className="productList">
            <div className="head">
                <div className="pageTitle">
                    <h1>관심차량</h1>
                </div>
            </div>

            <div className="contents">
                <div className="tableWrap">
                    <div className="tableHead">
                        <div className="itemContract">
                            <span>총 123대</span>
                        </div>
                        <div className="itemSort">
                            <button type="button">주행 거리순</button>
                            <button type="button">최근 등록순</button>
                            <button type="button">
                                엑셀 다운로드
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        </div>
                    </div>

                    <div className="tableCont">
                        <table>
                            <colgroup>
                                <col style={{ width: "28%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "8%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "13%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>모델명/트림</th>
                                    <th>등급</th>
                                    <th>유종</th>
                                    <th>외장색</th>
                                    <th>주행거리/연식</th>
                                    <th>계약기간</th>
                                    <th>보증금</th>
                                    <th>월렌트료</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 10 }, (_, index) => (
                                    <TableRow key={index} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="tableFoot">
                        <div className="pagination">
                            <div className="numList">
                                <div
                                    className="btnPrev"
                                    onClick={() => setPage(pagination.prevPage)}
                                    aria-current={pagination.isFirst ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                                <div
                                    className="btnNext"
                                    onClick={() => setPage(pagination.nextPage)}
                                    aria-current={pagination.isLast ? false : true}
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                                <ul className="currentPage">
                                    {Array(pagination.endPage)
                                        .fill()
                                        .map(
                                            (_, i) =>
                                                i >= pagination.startPage - 1 && (
                                                    <li
                                                        key={i}
                                                        onClick={() => setPage(i)}
                                                        className={pagination.page === i ? "active" : null}
                                                    >
                                                        {i + 1}
                                                    </li>
                                                )
                                        )}
                                </ul>
                                {pagination.endPage !== pagination.totalPages && (
                                    <div className="lastPage">
                                        <li onClick={() => setPage(pagination.totalPages - 1)}>{pagination.totalPages}</li>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
