import axios from "axios";

const API = axios.create({
    // baseURL: "https://api-share.chada-dev.kr", // 개발
    baseURL: "https://api-share.chada.kr/", // 운영
    timeout: 8000,
});

// 요청 인터셉터 추가
API.interceptors.request.use((request) => {
    // Local Storage에서 access_token 가져오기
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
});

// 응답 인터셉터 추가
API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // 응답 에러 처리
        if (error.code === "ECONNABORTED") {
            console.log("Request timed-out");
        }
        return Promise.reject(error);
    }
);

// GET 요청
export const fetchData = (url, params = {}) => API.get(url, { params });

// POST 요청 - 일반 JSON 데이터
export const createData = (url, data) => API.post(url, data);

// POST 요청 - FormData (multipart/form-data)
export const createFormData = (url, formData) =>
    API.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

// PUT 요청
export const updateData = (url, data) => API.put(url, data);

// DELETE 요청
export const deleteData = (url) => API.delete(url);

// 예외 처리를 위한 추가 함수
export const handleError = (error) => {
    // 에러 처리 로직
};
