/* eslint-disable */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const CancelElecAgreement = ({ setModal, type, data }) => {
    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        {type === "REJECT" && <span>계약 거절 사유</span>}
                        {type === "REFUND" && <span>환수 사유</span>}
                        {type === "RETURN" && <span>반납 사유</span>}
                        {type === "CANCEL" && <span>취소 사유</span>}
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <p className="modal-desc">{data ? data : "내용 없음"}</p>
                </div>
            </div>
        </>
    );
};
