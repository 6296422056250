/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import searchWhiteIcon from "resources/icon/search-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";
import CameraIcon from "resources/icon/camera.svg";

export const SubscribeEstimate = ({ setModal }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        url: "",
        contractRangeMonths: [],
        requestMemo: "",
    });
    const { url, contractRangeMonths, requestMemo } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        // 다른 input 요소인 경우 처리 (예: input type="text")
        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleContractButtonClick = (month) => {
        let updatedMonths;
        if (contractRangeMonths.includes(month)) {
            updatedMonths = contractRangeMonths.filter((m) => m !== month);
        } else {
            updatedMonths = [...contractRangeMonths, month];
        }
        setFormData((prevState) => ({
            ...prevState,
            contractRangeMonths: updatedMonths,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const payload = {
                productRequestType: "INSURANCE_REQUEST",
                contractRangeMonths: contractRangeMonths,
                requestMemo: requestMemo,
                url: url,
            };

            const response = await createData(`/api/share/product_request/v1`, payload);

            showModal("", <p>요청하신 내용이 접수되었습니다.</p>, [{ text: "확인", handler: () => setModal(false) }]);
            setLoading(false);
        } catch (error) {
            handleToast(error.response.data.message, Infinity);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            <div className="modal modal-type-2" style={{ minHeight: "unset" }}>
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>구독 견적 요청</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        <div className="request_form">
                            <div className="item-wrapper" style={{ borderBottom: "none", paddingBottom: "24px" }}>
                                <div className="item">
                                    <div className="item-head">
                                        <span>차량 URL</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="url"
                                                    value={url}
                                                    onChange={handleChange}
                                                    placeholder="엔카, K-CAR, KB차차차 등 매물 링크를 첨부해주세요."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>계약기간</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="button-element">
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(12) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(12)}
                                                >
                                                    12개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(24) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(24)}
                                                >
                                                    24개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(36) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(36)}
                                                >
                                                    36개월
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn-form_contract ${contractRangeMonths.includes(48) ? "active" : ""}`}
                                                    onClick={() => handleContractButtonClick(48)}
                                                >
                                                    48개월
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>추가 요청 사항</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="textarea-element">
                                                <textarea
                                                    name="requestMemo"
                                                    value={requestMemo || ""}
                                                    onChange={handleChange}
                                                    placeholder="추가 요청 사항 내용을 입력해주세요."
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-bottom2">
                            <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                                접수
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}
        </>
    );
};
