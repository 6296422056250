/* eslint-disable */
import React, { useEffect } from "react";

export const RadioButton = ({ id, name, label, value, onChange, option = null, checked = false }) => {
  return (
    <div className="input-radio">
      <div className="radiobox">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          onChange={(e) => onChange(e, option)}
          checked={checked} // checked 속성 추가
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};
