/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { getAgeCategory, comma, convertResidentRegistrationNumber, formatDriverLicenseNumber } from "util";
import { Toast } from "components";

export const CancelContract = ({ setModal, showModal, handleModalClose, contractId, fetchTable }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        denyReason: "",
    });

    const { denyReason } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const cancelContract = async () => {
        setIsLoading(true);

        try {
            const requestData = {
                rejectReason: denyReason,
            };
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/deny`, requestData);
            handleToast(response.data.message);
            handleModalClose();
            fetchTable();
            setIsLoading(false);
            setModal(false);
        } catch (error) {
            handleToast(error.response.data.message);
            handleModalClose();
            setIsLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const onCancel = () => {
        cancelContract();
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {isLoading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            {!isLoading && (
                <div className="modal modal-type-2">
                    <div className="modal-title">
                        <span className="modal-tit-main">계약 취소</span>
                        <button type="button" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                    <div className="modal-content">
                        {/* 계약 취소 폼 */}
                        <div className="modal-form">
                            <div className="content-tit">
                                <span>계약 취소 사유를 입력해주세요.</span>
                            </div>
                            <div className="content-form">
                                <div className="textarea-element">
                                    <textarea
                                        name="denyReason"
                                        value={denyReason || ""}
                                        onChange={handleChange}
                                        placeholder="계약 취소 사유를 입력해주세요."
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-bottom no-rounded">
                        <button type="button" className="btn-modal" onClick={() => setModal(false)}>
                            닫기
                        </button>
                        <button
                            type="button"
                            className="btn-modal"
                            style={{ color: "#e24f40" }}
                            onClick={() => {
                                showModal("", <p>계약을 취소하시겠습니까?</p>, [
                                    { text: "취소", handler: handleModalClose },
                                    {
                                        text: "확인",
                                        handler: () => {
                                            cancelContract();
                                        },
                                    },
                                ]);
                            }}
                        >
                            계약 취소
                        </button>
                    </div>
                </div>
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
