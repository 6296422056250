/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import {
    convertPhone,
    comma,
    convertToKoreanWon,
    findYoungestDriver,
    getAgeCategory,
    formatDriverLicenseNumber,
    convertResidentRegistrationNumber,
} from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import pdfIcon from "resources/icon/img-pdf.svg";

export const ContractConfirm = ({ setModal, contractId }) => {
    const [formStatus, setFormStatus] = useState(0);

    const [data, setData] = useState(null);
    const [youngestDriver, setYoungestDriver] = useState(null);
    const [priceInfo, setPriceInfo] = useState(0);
    const [deliverInfo, setDeliverInfo] = useState();
    const [deliverPrice, setDeliverPrice] = useState(0);
    const [loading, setLoading] = useState(true);
    const [termsData, setTermsData] = useState();
    const [carInspectionStatus, setCarInspectionStatus] = useState(false);

    const documentTitles = {
        DRIVER_LICENSE: "운전면허증 / 운전경력증명서(최근 3년)",
        DOCUMENT: "계약자 관련 서류",
        BANK: "자동이체 통장사본",
        BIZ_DOCUMENT: "사업자 관련 서류",
        POST_DOCUMENT: "등기영수증",
    };

    const documentDescriptions = {
        DRIVER_LICENSE: [
            "• 분실 면허증이 아닌 재발급 신분증을 첨부해주세요.",
            "• 일련번호가 잘 보이도록 촬영하여 첨부해주세요.",
            "• 운전경력증명서는 정부24에서 발급 가능합니다.",
        ],
        DOCUMENT: ["• 등본, 초본, 가족관계증명서, 본인서명사실확인서를 첨부해주세요.", "• 서류를 사진촬영 또는 스캔하여 첨부해주세요."],
        BANK: ["• 자동이체 신청을 위한 통장사본을 첨부해주세요.", "• (본인명의의 통장사본이 아닐 경우 추가서류가 필요합니다.)"],
        BIZ_DOCUMENT: ["• 사업자 등록증, 법인 인감증명서, 법인 등기부등본을 첨부해주세요."],
        POST_DOCUMENT: ["• 계약에 필요한 서류를 2부씩 등기 발송 후 영수증을 첨부해주세요."],
    };

    useEffect(() => {
        if (!data) return;

        // 서버에서 받은 약관 데이터
        const termsData = data?.rentCarCorperation.rentCorpTerms;
        setTermsData(termsData);

        if (data?.car.rentInfo?.inspectionProducts?.length !== 0) {
            setCarInspectionStatus(true);
        }

        const { depositPrice, depositType, rentPrice, car } = data;
        const age21RentPrice = getAgeCategory(youngestDriver.idNumber) === "만 21세 이상" ? car.rentInfo.age21RentPrice : null;
        const age23RentPrice = getAgeCategory(youngestDriver.idNumber) === "만 23세 이상" ? car.rentInfo.age23RentPrice : null;
        const deliverySupportPrice = car.rentInfo.deliverySupportPrice ?? 0;
        const contractPrice = 300000;

        console.log(depositPrice, rentPrice, age21RentPrice, age23RentPrice, contractPrice);

        const deliveryPrice = deliverPrice;
        const deliveryTotalPrice = Math.max(0, deliveryPrice - deliverySupportPrice);

        let depositTotalPrice;
        if (depositType === "DIVIDE_2") {
            depositTotalPrice = depositPrice / 2 + rentPrice + age21RentPrice + age23RentPrice - contractPrice;
        } else {
            depositTotalPrice = depositPrice + rentPrice + age21RentPrice + age23RentPrice - contractPrice;
        }

        const depositTotalPayment = depositTotalPrice + deliveryTotalPrice;

        setPriceInfo({
            depositPrice,
            devidePrice: depositType === "DIVIDE_2" ? depositPrice / 2 : null,
            rentPrice,
            age21RentPrice,
            age23RentPrice,
            contractPrice: contractPrice,
            depositTotalPrice,
            deliveryPrice,
            deliverySupportPrice,
            deliveryTotalPrice,
            depositTotalPayment,
        });
    }, [data, deliverPrice]);

    const fetchDeliveryPriceInfo = async (data) => {
        try {
            console.log(data);
            let requestData = {
                car_id: data?.carId,
                src_bcode: data?.car.carLocationBcode,
                dest_bcode: data?.customerAddressBcode,
            };

            const response = await fetchData(`/api/share/delivery/v1/price`, requestData);

            setDeliverInfo(response.data.data);
            setDeliverPrice(response.data.data.price);

            const maxDeliverySupportPrice = Math.min(response.data.data.price, data?.car.rentInfo.deliverySupportPrice ?? 0);

            setPriceInfo((prev) => ({
                ...prev,
                deliveryPrice: response.data.data.price,
                deliverySupportPrice: maxDeliverySupportPrice, // 조정된 deliverySupportPrice 사용
                deliveryTotalPrice: response.data.data.price - maxDeliverySupportPrice, // deliveryTotalPrice 업데이트
            }));
        } catch (error) {}
    };

    const fetchContract = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/${contractId}`);
            setData(response.data?.data);

            // 등록된 운전자중 가장 적은 나이를 가진 데이터 추출
            const youngestDriver = findYoungestDriver(response.data?.data.drivers);
            setYoungestDriver(youngestDriver);

            // 탁송 비용 계산
            fetchDeliveryPriceInfo(response.data?.data);

            setLoading(false);
        } catch (error) {
            setData(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (contractId === undefined) return;

        fetchContract();
    }, [contractId]);

    const handleDownloadAll = () => {
        console.log("clicked");
        const zip = new JSZip();

        data?.contractUploads.forEach((file) => {
            const filename = file.originFileName.split(".").slice(0, -1).join(".");
            const fileExtension = file.originFileName.split(".").pop();
            const fileType = documentTitles[file.contractUploadType];
            const url = file.uploadUrl;
            console.log(filename);

            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    zip.folder(fileType).file(`${filename}.${fileExtension}`, blob);
                })
                .catch((error) => console.error("Error fetching file:", error));
        });

        zip.generateAsync({ type: "blob" })
            .then((blob) => {
                saveAs(blob, "all_files.zip");
            })
            .catch((error) => console.error("Error generating ZIP file:", error));
    };

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-type-2">
                <div className="modal-title">
                    <span className="modal-tit-main">계약서 확인</span>
                    <button type="button" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="app-content contract-detail">
                        <section id="section-02">
                            <div className="section-tab_body-container" style={{ marginTop: "0" }}>
                                {formStatus === 0 && (
                                    <div className="tab" id="tab-01">
                                        <div className="item-wrapper border-separation">
                                            <div className="item-head">
                                                <span>약관확인</span>
                                            </div>
                                            {termsData?.length === 0 || !termsData ? (
                                                <div style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", margin: "20px 0" }}>
                                                    <b>약관 내용이 없습니다.</b>
                                                </div>
                                            ) : (
                                                <div className="form">
                                                    <div className="form-field">
                                                        {termsData?.map((term, idx) => {
                                                            if (term.rentCorpTermType !== "CAR_REPAIR") {
                                                                return (
                                                                    <div className="input-check" key={idx}>
                                                                        <div className="checkbox" style={{ width: "100%" }}>
                                                                            <label
                                                                                style={{ justifyContent: "space-between", width: "100%" }}
                                                                            >
                                                                                <span>
                                                                                    <em>(필수)</em>
                                                                                    {term.rentCorpTermType === "PERSONAL"
                                                                                        ? "개인정보 수집 이용에 관한 사항"
                                                                                        : term.rentCorpTermType === "PERSONAL_OFFER"
                                                                                        ? "개인정보의 제 3자 제공에 관한 사항"
                                                                                        : term.rentCorpTermType === "RENT_CONTRACT"
                                                                                        ? "자동차 임대차 계약 약관"
                                                                                        : term.rentCorpTermType === "DRIVER_LICENSE"
                                                                                        ? "운전자격 검증 확인서"
                                                                                        : term.rentCorpTermType === "RENT_RETURN"
                                                                                        ? "렌트카 차량회수 동의서"
                                                                                        : term.rentCorpTermType === "BANK_AUTO_TRANSFER"
                                                                                        ? "자동이체 동의서"
                                                                                        : "알 수 없음"}
                                                                                </span>
                                                                                <a
                                                                                    href={term.url}
                                                                                    download
                                                                                    target="_blank"
                                                                                    style={{ marginLeft: "auto" }}
                                                                                >
                                                                                    상세보기
                                                                                </a>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                if (carInspectionStatus === true) {
                                                                    return (
                                                                        <div className="input-check" key={idx}>
                                                                            <div className="checkbox" style={{ width: "100%" }}>
                                                                                <label
                                                                                    style={{
                                                                                        justifyContent: "space-between",
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        <em>(필수)</em>
                                                                                        {term.rentCorpTermType === "CAR_REPAIR"
                                                                                            ? "차량 정비 안내 동의서"
                                                                                            : "알 수 없음"}
                                                                                    </span>
                                                                                    <a
                                                                                        href={term.url}
                                                                                        download
                                                                                        target="_blank"
                                                                                        style={{ marginLeft: "auto" }}
                                                                                    >
                                                                                        상세보기
                                                                                    </a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="sign-wrapper">
                                                <div className="sign-form disabled">
                                                    <img src={data?.customerSignUrl} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {formStatus === 1 && (
                                    <div className="tab" id="tab-02">
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>렌터카 정보</span>
                                            </div>
                                            <div className="item-list grid-row">
                                                <div className="item width-100">
                                                    <span className="item-tit">상호명</span>
                                                    <span className="item-desc">{data?.rentCarCorperation.name}</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">대표번호</span>
                                                    <span className="item-desc">{data?.rentCarCorperation.repPhone}</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">주소</span>
                                                    <span className="item-desc">
                                                        {data?.rentCarCorperation.address} {data?.rentCarCorperation.addressDetail}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>계약조건</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item">
                                                    <span className="item-tit">대여기간</span>
                                                    <span className="item-desc">{data?.contractRangeMonth}개월</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">보증급 분납</span>
                                                    <span className="item-desc">
                                                        {data?.depositType === "FULL" && "일시납"}
                                                        {data?.depositType === "DIVIDE_2" && "2회분납"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">대여일시</span>
                                                    <span className="item-desc">
                                                        {data?.contractedAt ? data?.contractedAt.split("T")[0] : "-"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">반납일시</span>
                                                    <span className="item-desc">
                                                        {data?.returnedAt ? data?.returnedAt.split("T")[0] : "-"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">보증금</span>
                                                    <span className="item-desc">{comma(data?.depositPrice)}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">렌탈료</span>
                                                    <span className="item-desc">{comma(data?.rentPrice)}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">정비상품</span>
                                                    <span className="item-desc">
                                                        {data?.rentInfo?.inspectionProducts
                                                            ? data?.rentInfo?.inspectionProducts?.map((row, idx) => {
                                                                  return (
                                                                      <>
                                                                          <p>{row.name}</p>
                                                                      </>
                                                                  );
                                                              })
                                                            : "정비불포함"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">현재 주행거리</span>
                                                    <span className="item-desc">{comma(data?.car.mileage)}km</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>차량정보</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item">
                                                    <span className="item-tit">모델명</span>
                                                    <span className="item-desc">{data?.car.modelDetailName}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">차량번호</span>
                                                    <span className="item-desc">{data?.car.carNumber}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">연료</span>
                                                    <span className="item-desc">
                                                        {data?.car.fuelType === "GAS"
                                                            ? "가솔린"
                                                            : data?.car.fuelType === "DIESEL"
                                                            ? "디젤"
                                                            : data?.car.fuelType === "LPG"
                                                            ? "LPG"
                                                            : data?.car.fuelType === "GAS_LPG"
                                                            ? "가솔린(LPG겸용)"
                                                            : data?.car.fuelType === "GAS_CNG"
                                                            ? "가솔린(CNG겸용)"
                                                            : data?.car.fuelType === "GAS_HYBRID"
                                                            ? "가솔린 하이브리드"
                                                            : data?.car.fuelType === "ELECTRIC"
                                                            ? "전기"
                                                            : data?.car.fuelType === "HYDROGEN"
                                                            ? "수소"
                                                            : "알 수 없음"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">색상</span>
                                                    <span className="item-desc">{data?.car.color}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">연식/년도</span>
                                                    <span className="item-desc">
                                                        {data?.car.carMadedYear}.{data?.car.carMadedMonth}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">약정 주행거리</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.yearContractMileage
                                                            ? `${comma(data?.car.rentInfo.yearContractMileage)}km`
                                                            : "-"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">변속기</span>
                                                    <span className="item-desc">
                                                        {data?.car.motorType === "AUTOMATIC"
                                                            ? "자동"
                                                            : data?.car.motorType === "MANUAL"
                                                            ? "수동"
                                                            : data?.car.motorType === "CVT"
                                                            ? "CVT"
                                                            : data?.car.motorType === "DUAL"
                                                            ? "듀얼클러치"
                                                            : "알 수 없음"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">배기량</span>
                                                    <span className="item-desc">{comma(data?.car.displacement)}cc</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">신차가격</span>
                                                    <span className="item-desc">
                                                        {data?.car.newPrice ? comma(data?.car.newPrice) : "-"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">차량 인수 옵션</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.takeOverType === "FULL_CHOICE"
                                                            ? "만기선택형"
                                                            : data?.car.rentInfo.takeOverType === "TAKE_OVER"
                                                            ? "인수형"
                                                            : data?.car.rentInfo.takeOverType === "RETURN"
                                                            ? "반납형"
                                                            : "알 수 없음"}
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">선택옵션</span>
                                                    <span className="item-desc">
                                                        {/* {data?.car.majorOptions
                                                            ? data?.car.majorOptions?.map((option, index) => <span>{option.nameKr}</span>)
                                                            : "-"} */}

                                                        {data?.car.carAddOptionContents.length === 0 ? (
                                                            <span>옵션이 추가되지 않은 차량입니다.</span>
                                                        ) : (
                                                            <></>
                                                            // <span>
                                                            //     {(() => {
                                                            //         const total = data?.car.carAddOptionContents.reduce(
                                                            //             (acc, option) => acc + option.price,
                                                            //             0
                                                            //         );
                                                            //         const formattedTotal = Math.floor(total / 10000); // 소수점 제거

                                                            //         return <em>{formattedTotal}만원</em>;
                                                            //     })()}
                                                            //     &nbsp;상당의 옵션이 추가된 차량입니다.
                                                            // </span>
                                                        )}
                                                    </span>
                                                    <div className="item-list option-list grid-2">
                                                        {data?.car.carAddOptionContents?.map((option, index) => {
                                                            if (option.carAddOptionContentItems.length === 0) {
                                                                return (
                                                                    <div key={index} className="item option-item">
                                                                        <div className="option-tit">{option.name}</div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                                                    return (
                                                                        <div key={sub_index} className="item option-item">
                                                                            <div className="item-tit">
                                                                                <span>{sub_option.title}</span>
                                                                                {sub_option.content !== "" && (
                                                                                    <button type="button">
                                                                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                                                                        <div className="guide_popup">
                                                                                            {/* <span className="guide_popup-tit">계약 가능 연령</span> */}
                                                                                            <p className="guide_popup-desc">
                                                                                                {sub_option.content}
                                                                                            </p>
                                                                                        </div>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                });
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>계약 유의사항</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item width-100">
                                                    <span className="item-pre">
                                                        {data?.car.rentInfo.contractNote ? data?.car.rentInfo.contractNote : "내용 없음"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper border-none">
                                            <div className="item-head">
                                                <span>구비서류</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item width-100">
                                                    <span className="item-pre">
                                                        {`- 기본서류(개인)
                                                        운전면허증
                                                        주민등록등본
                                                        주민등록초본
                                                        가족관계증명서
                                                        본인서명사실확인서
                                                        운전경력증명서
                                                        통장사본

                                                        - 개인사업자
                                                        기본서류(대표자)
                                                        사업자등록증
                                                        사업자통장사본

                                                        - 법인사업자
                                                        기본서류(대표자)
                                                        사업자등록증
                                                        법인등기부등본
                                                        법인인감증명서
                                                        법인통장사본`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sign-wrapper">
                                            <div className="sign-form disabled">
                                                <img src={data?.customerSignUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {formStatus === 2 && (
                                    <div className="tab" id="tab-03">
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>자동차 보험</span>
                                            </div>
                                            <div className="item-list grid-row">
                                                <div className="item width-100">
                                                    <span className="item-tit">운전자 연령</span>
                                                    <span className="item-desc">
                                                        <em>
                                                            {getAgeCategory(youngestDriver.idNumber)}{" "}
                                                            {youngestDriver.expLt1Yn === "Y" && "/ 면허취득 1년 이상"}
                                                        </em>
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">운전자 범위</span>
                                                    <span className="item-desc">
                                                        <em>계약자와 배우자 및 계약자와 배우자의 직계가족 </em>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>책임한도</span>
                                            </div>
                                            <div className="item-list grid-col group-3">
                                                <div className="item">
                                                    <span className="item-tit">대인</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.dutyDaein === -1
                                                            ? "무제한"
                                                            : `${convertToKoreanWon(data?.car.rentInfo.dutyDaein)}원`}
                                                        {/* {data?.car.rentInfo.dutyDaein
                                                            ? `${convertToKoreanWon(data?.car.rentInfo.dutyDaein)}`
                                                            : "미가입"} */}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">대물</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.dutyDaemul
                                                            ? `${convertToKoreanWon(data?.car.rentInfo.dutyDaemul)}`
                                                            : "미가입"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">자손(사망)</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.dutyJason
                                                            ? `${convertToKoreanWon(data?.car.rentInfo.dutyJason)}`
                                                            : "미가입"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">무보험 상해</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo?.dutyNoInsuranceInjury
                                                            ? `가입(${convertToKoreanWon(data?.car.rentInfo?.dutyNoInsuranceInjury)})`
                                                            : "미가입"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">자차</span>
                                                    <span className="item-desc">
                                                        {data?.car.rentInfo.dutyJacha
                                                            ? `정비 비용의 ${data?.car.rentInfo?.dutyJacha}%`
                                                            : "미가입"}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">긴급출동</span>
                                                    <span className="item-desc">연 {data?.car.rentInfo.emergencyCallCount}회</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-head">
                                                <span>면책금</span>
                                            </div>
                                            <div className="item-list grid-col group-3">
                                                <div className="item">
                                                    <span className="item-tit">대인</span>
                                                    <span className="item-desc">
                                                        {comma(data?.indemDaein)}원
                                                        {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                                            ? `${comma(data?.car.rentInfo.indemDaein)}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemDaein + data?.car.rentInfo.age23IndemPrice
                                                              )}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemDaein + data?.car.rentInfo.age21IndemPrice
                                                              )}원`
                                                            : "알 수 없음"} */}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">대물</span>
                                                    <span className="item-desc">
                                                        {comma(data?.indemDaemul)}원
                                                        {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                                            ? `${comma(data?.car.rentInfo.indemDaemul)}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age23IndemPrice
                                                              )}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age21IndemPrice
                                                              )}원`
                                                            : "알 수 없음"} */}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">자차</span>
                                                    <span className="item-desc">
                                                        {comma(data?.indemJachaMin)}원 ~{comma(data?.indemJachaMax)}원
                                                        {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                                            ? `최소 ${convertToKoreanWon(
                                                                  data?.car.rentInfo?.indemJachaMin
                                                              )} ~ 최대 ${convertToKoreanWon(data?.car.rentInfo?.indemJachaMax)}`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                                            ? `최소 ${convertToKoreanWon(
                                                                  data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age23IndemPrice
                                                              )} ~ 최대 ${convertToKoreanWon(
                                                                  data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age23IndemPrice
                                                              )}`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                                            ? `최소 ${convertToKoreanWon(
                                                                  data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age21IndemPrice
                                                              )} ~ 최대 ${convertToKoreanWon(
                                                                  data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age21IndemPrice
                                                              )}`
                                                            : "알 수 없음"} */}
                                                    </span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">자손</span>
                                                    <span className="item-desc">
                                                        {comma(data?.indemJason)}원
                                                        {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                                            ? `${comma(data?.car.rentInfo.indemJason)}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemJason + data?.car.rentInfo.age23IndemPrice
                                                              )}원`
                                                            : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                                            ? `${comma(
                                                                  data?.car.rentInfo.indemJason + data?.car.rentInfo.age21IndemPrice
                                                              )}원`
                                                            : "알 수 없음"} */}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-wrapper border-none">
                                            <div className="item-head">
                                                <span>사고차량</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>주의사항</b>
                                                    </span>
                                                    <span className="item-pre">
                                                        <em>교통사고 접수시 경찰 신고 또는 보험사 현장 출동이 없는 사고는 사고처리 불가</em>
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>사고차량 입고수리절차</b>
                                                    </span>
                                                    <span className="item-pre color-grey">지정 정비공장 외 임의 수리시 자차 처리 불가</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>12대 중과실 사고</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        1. 신호위반 2.중앙선침범 3.속도위반(20km이상 과속) 4. 앞지르기 방법위반 5.
                                                        철길건널목 통과방법위반 6. 횡단보도사고 7. 무면허운전 8. 음주운전 9. 보도를 침범 10.
                                                        승객추락방지의무위반 11. 스쿨존사고 12. 화물고정고치 위반
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>보험사</b>
                                                    </span>
                                                    <span className="item-pre">
                                                        <em>
                                                            {data?.car.rentInfo.insuranceCorpName}) 사고접수/긴급출동 :{" "}
                                                            {data?.car.rentInfo.emergencyCallNumber}
                                                        </em>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sign-wrapper">
                                            <div className="sign-form disabled">
                                                <img src={data?.customerSignUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {formStatus === 3 && (
                                    <div className="tab" id="tab-04">
                                        <div className="item-wrapper border-none">
                                            <div className="item-head">
                                                <span>정비 서비스 및 기타내용</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>정비 서비스</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        정비 및 소모품 교체는 고객이 부담합니다. 단, 차량인도후 3개월 이내 기능고장(엔진결함
                                                        등) 의 경우 수리비지원 및 동종차량으로 대차, 교차가 가능합니다.
                                                        {"\n"}
                                                        {"\n"}
                                                        정비상품- 사전 합의된 지정 정비점에서의 내방한 정비만 인정합니다(예약필수)단, 사전에
                                                        폅의된 지정 정비점 제휴가 안되어 있을경우 제조자 공식공업사 (블루헨즈, 오토큐)로
                                                        내방한 정비만 인정합니다.{" "}
                                                    </span>
                                                </div>
                                                {/* 2024-01-11 삭제 */}
                                                {/* <div className="item width-100">
                                    <span className='item-tit'><b>연1회 엔진오일</b></span>
                                    <span className='item-pre color-grey'>차량점검 서비스 필수 가입 <em>(엔진오일 연 1회 무료교환)</em>
                                    {"\n"}최초 계약후 <em>12개월 뒤</em> 첫 엔진오일 서비스 진행합니다. 그 후 <em>매년 1년</em>마다 서비스 합니다.</span>
                                </div> */}
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>계약연장 및 해지</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        장기계약의 경우 계약종료 한달(30일)전 까지는 당사의 승인을 받야야 합니다. 계약 연장
                                                        희망시 고객님께서 먼저 렌터카로 연락하셔야 하며, 고객님의 연락이 없다면 연장하지
                                                        않는 것으로 간주합니다. 사전 연락이 없고 이체일이 경과될 경우 일할 계산되어 대여료가
                                                        청구됩니다.
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>탁송료(반납)</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        계약 만기 반납이 아닌 경우 탁송료는 고객님이 부담합니다.
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>약정운행거리</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        계약서에 나온 약정 km가 초과될 경우 1km당 110원의 추가요금이 부과됩니다.(유예거리
                                                        1,000km)
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>연락처,주소변경</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        정보 변경시 렌트사에 먼저 연락해야합니다.
                                                        {"\n"}연락두절시 시동제어 및 계약해지될 수 있습니다.
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>과태료, 차량검사</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        과태료 미납, 통행료 미납 및 차량 검사 불이행시 시동제어 될 수 있습니다.
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>검사대행 서비스 (대행업체진행)</b>
                                                    </span>
                                                    <div className="item-list grid-col">
                                                        <div className="item">
                                                            <span className="item-tit">정기검사</span>
                                                            <span className="item-desc">2년 1회</span>
                                                        </div>
                                                        <div className="item">
                                                            <span className="item-tit">종합검사</span>
                                                            <span className="item-desc">1년 1회</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>서비스 품목</b>
                                                    </span>
                                                    <span className="item-pre color-grey">썬팅, 블랙박스 내비게이션(자가수리진행)</span>
                                                </div> */}
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>특이사항</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        <em>
                                                            자동차키는 1개만 지급
                                                            {"\n"}GPS 장착(도단 및 연체, 연락두절시 시동제어)
                                                        </em>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sign-wrapper">
                                            <div className="sign-form disabled">
                                                <img src={data?.customerSignUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {formStatus === 4 && (
                                    <div className="tab" id="tab-05">
                                        <div className="item-wrapper border-separation">
                                            <div className="item-head">
                                                <span>고객 정보</span>
                                            </div>
                                            <div className="item-list grid-row">
                                                <div className="item width-100">
                                                    <span className="item-tit">성명</span>
                                                    <span className="item-desc">{data?.mainDriver.name}</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">면허번호</span>
                                                    <span className="item-desc">
                                                        {formatDriverLicenseNumber(data?.mainDriver.driverLicenseNumber)}
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">전화번호</span>
                                                    <span className="item-desc">{data?.customerPhone}</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">주민번호</span>
                                                    <span className="item-desc">
                                                        {convertResidentRegistrationNumber(data?.mainDriver.idNumber)}
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">주소</span>
                                                    <span className="item-desc">
                                                        {data?.customerAddress} {data?.customerAddressDetail}
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">긴급연락처</span>
                                                    <span className="item-desc">{data?.emergencyCallNumber}</span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">관계</span>
                                                    <span className="item-desc">{data?.emergencyRelation}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {data?.drivers.length > 1 && (
                                            <div className="item-wrapper border-separation">
                                                <div className="item-head">
                                                    <span>추가 운전자 정보</span>
                                                </div>
                                                {data?.drivers.map((driver, idx) => {
                                                    if (driver.mainDriverYn === "Y") return;

                                                    return (
                                                        <div className="item-list grid-row" key="idx">
                                                            <div className="item width-100">
                                                                <span className="item-tit">성명</span>
                                                                <span className="item-desc">{driver.name}</span>
                                                            </div>
                                                            <div className="item width-100">
                                                                <span className="item-tit">면허번호</span>
                                                                <span className="item-desc">
                                                                    {formatDriverLicenseNumber(driver.driverLicenseNumber)}
                                                                </span>
                                                            </div>
                                                            <div className="item width-100">
                                                                <span className="item-tit">주민번호</span>
                                                                <span className="item-desc">
                                                                    {convertResidentRegistrationNumber(driver.idNumber)}
                                                                </span>
                                                            </div>
                                                            <div className="item width-100">
                                                                <span className="item-tit">운전연령</span>
                                                                <span className="item-desc">{getAgeCategory(driver.idNumber)}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <div className="item-wrapper border-none">
                                            <div className="item-head">
                                                <span>탁송 정보</span>
                                            </div>
                                            <div className="item-list grid-row">
                                                <div className="item width-100">
                                                    <span className="item-tit">탁송 지원금</span>
                                                    <span className="item-desc">
                                                        {/* 렌트카사 지원(50,000원)} */}
                                                        {/* {comma(deliverPrice)}원 */}
                                                        {data?.car.rentInfo.deliverySupportPrice === 0
                                                            ? "지원 없음"
                                                            : `렌트카사 지원(${comma(data?.car.rentInfo.deliverySupportPrice)}원)`}
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">인수 주소</span>
                                                    <span className="item-desc">
                                                        {data?.customerAddress} {data?.customerAddressDetail}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sign-wrapper">
                                            <div className="sign-form disabled">
                                                <img src={data?.customerSignUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {formStatus === 5 && (
                                    <div className="tab" id="tab-06">
                                        <div className="item-wrapper border-separation">
                                            <div className="item-head">
                                                <span>연체 및 해지</span>
                                            </div>
                                            <div className="item-list grid-col">
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>대여료 연체</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        임차인은 대여약관에 동의하며, 계각기간 중 이라도 대여료를 3일 연체시{" "}
                                                        <em>시동제어</em>, 10일 연체시 계약은 <em>자동 해지</em>되며, 임차인은 차량을 회수
                                                        할 수 있으며, 임차인은 이에 대해 민 형사상의 책임을 묻지 아니한다
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>중도해지 수수료(위약금)</b>
                                                    </span>
                                                    <span className="item-pre color-grey">
                                                        잔여기간 대여료의 총 합 x 해지 수수료율
                                                        {"\n"}해지수수료율
                                                        {"\n"}차량인도일로부터 1년 미만 30%
                                                        {"\n"}차량인도일로부터 1년 이상 20%
                                                        {"\n"}
                                                        {"\n"}
                                                        지급명령 신청서 신청일 부터 지급명령정본이 송달된 날까지 연5% 그다음날로부터 다 갚는
                                                        날까지 연 12%
                                                    </span>
                                                </div>
                                                <div className="item width-100">
                                                    <span className="item-tit">
                                                        <b>지연손해금</b>
                                                    </span>
                                                    <span className="item-pre color-grey">5% / 12%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="receipt-wrapper">
                                            <div className="receipt-list">
                                                <div className="item">
                                                    <span className="item-tit">보증금</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">{comma(priceInfo?.depositPrice)}</span>
                                                </div>

                                                {priceInfo?.devidePrice !== null && (
                                                    <div className="item">
                                                        <span className="item-tit">- 2회분납</span>
                                                        <span className="item-sub">1회차</span>
                                                        <span className="item-desc">{comma(priceInfo?.devidePrice)}</span>
                                                    </div>
                                                )}
                                                <div className="item">
                                                    <span className="item-tit">월 대여료</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">{comma(priceInfo?.rentPrice)}</span>
                                                </div>

                                                {priceInfo?.age21RentPrice !== null ? (
                                                    <div className="item">
                                                        <span className="item-tit">- 만 21세이상 추가금</span>
                                                        <span className="item-sub"></span>
                                                        <span className="item-desc">+{comma(priceInfo?.age21RentPrice)}</span>
                                                    </div>
                                                ) : null}

                                                {priceInfo?.age23RentPrice !== null ? (
                                                    <div className="item">
                                                        <span className="item-tit">- 만 23세이상 추가금</span>
                                                        <span className="item-sub"></span>
                                                        <span className="item-desc">+{comma(priceInfo?.age23RentPrice)}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="receipt-list">
                                                <div className="item">
                                                    <span className="item-tit">- 계약금</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">-300,000</span>
                                                </div>
                                                <div className="item">
                                                    <span className="item-tit">합계금액</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">
                                                        <b>{comma(priceInfo?.depositTotalPrice)}</b>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="receipt-list">
                                                <div className="item">
                                                    <span className="item-tit">
                                                        [{deliverInfo?.srcAddress1} {deliverInfo?.srcAddress2}-{deliverInfo?.destAddress1}{" "}
                                                        {deliverInfo?.destAddress2}] 탁송비
                                                    </span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">{comma(priceInfo?.deliveryPrice)}</span>
                                                </div>
                                                {priceInfo?.deliverySupportPrice !== 0 && (
                                                    <div className="item">
                                                        <span className="item-tit">- 탁송비 지원금</span>
                                                        <span className="item-sub"></span>
                                                        <span className="item-desc">-{comma(priceInfo?.deliverySupportPrice)}</span>
                                                    </div>
                                                )}
                                                <div className="item">
                                                    <span className="item-tit">탁송비 총금액</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">{comma(priceInfo?.deliveryTotalPrice)}</span>
                                                </div>
                                            </div>
                                            <div className="receipt-summary">
                                                <div className="item">
                                                    <span className="item-tit">총 결제금액</span>
                                                    <span className="item-sub"></span>
                                                    <span className="item-desc">
                                                        <b>{comma(priceInfo?.depositTotalPayment)}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sign-wrapper">
                                            <div className="sign-form disabled">
                                                <img src={data?.customerSignUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>

                        {formStatus === 6 && (
                            <section id="section-03">
                                <div className="content-tit" style={{ marginTop: "0" }}>
                                    <h6>필요서류</h6>
                                </div>

                                <div className="doc-list">
                                    {Object.keys(documentTitles).map((type) => (
                                        <div key={type} className="item">
                                            <div className="item-head">
                                                <span>{documentTitles[type]}</span>
                                            </div>
                                            <div className="item-content">
                                                {documentDescriptions[type].map((description, index) => (
                                                    <p key={index}>{description}</p>
                                                ))}
                                            </div>
                                            <div className="file-thumbnails">
                                                {data?.contractUploads.map((file) => {
                                                    if (file.contractUploadType === type) {
                                                        return (
                                                            <div key={file.id} className="thumbnail">
                                                                {file.uploadUrl.endsWith(".pdf") ? (
                                                                    <a href={file.uploadUrl} target="_blank" rel="noopener noreferrer">
                                                                        <img src={pdfIcon} alt="PDF Icon" />
                                                                    </a>
                                                                ) : (
                                                                    <a href={file.uploadUrl} target="_blank" rel="noopener noreferrer">
                                                                        <img src={file.uploadUrl} alt="File" />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="button-file-row">
                                    <button type="button" className="btn-download_file-all" onClick={handleDownloadAll}>
                                        <FontAwesomeIcon icon={faDownload} />
                                        전체 다운로드
                                    </button>
                                </div>

                                <div className="sign-wrapper">
                                    <div className="sign-form disabled">
                                        <img src={data?.customerSignUrl} alt="" />
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>

                    <div className="step-pagination">
                        {[...Array(7).keys()].map((index) => (
                            <span key={index} className={formStatus === index ? "active" : ""}></span>
                        ))}
                    </div>
                </div>

                <div className="modal-bottom no-rounded">
                    {/* <button type='button' className='btn-modal'>계약요청 하기</button> */}
                    {formStatus !== 0 && (
                        <button
                            type="button"
                            className="btn-modal"
                            onClick={() => {
                                if (formStatus !== 0) {
                                    setFormStatus(formStatus - 1);
                                    document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
                                }
                            }}
                        >
                            이전
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn-modal"
                        onClick={() => {
                            if (formStatus !== 6) {
                                setFormStatus(formStatus + 1);
                                document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
                            } else if (formStatus === 6) {
                                setModal(false);
                            }
                        }}
                    >
                        {formStatus === 6 ? "완료" : "다음"}
                    </button>
                </div>
            </div>
        </>
    );
};
