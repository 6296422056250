/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchColors } from "redux/colorSlice";
import CameraIcon from "resources/icon/camera.svg";
import {
    comma,
    handleNumericInput,
    getProductStatusList,
    getYearList,
    getMonthList,
    getDayList,
    getColorList,
    getTransmissionList,
} from "util";
import { fetchData, createData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";

export const VehicleInformation = ({ uploadStatus, initData, onVehicleInfoChange, newPriceDisabled }) => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.colors);
    const [isLoading, setIsLoading] = useState(false);

    const [locations, setLocations] = useState({});
    const [formData, setFormData] = useState({
        rentType: "",
        vehiclePhotos: [],
        mainThumbnail: null,
        mainThumbnailId: null,
        carInquiryStatus: false,
        productStatus: "",
        mileage: "",
        year: "",
        month: "",
        vehicleNumber: "",
        autoUpCarId: undefined,
        fuelType: "",
        displacement: "",
        vehicleType: "",
        outColorId: "",
        innerColorId: "",
        mission: "",
        transmission: "",
        trim: "",
        releasePrice: "",
        carLocationStatus: "",
        carLocationGarage: "",
        carLocationBcode: "",
        carLocationAddress: "",
        carLocationAddressDetail: "",
        option: "",
    });

    const {
        rentType,
        vehiclePhotos,
        productStatus,
        mileage,
        year,
        month,
        vehicleNumber,
        autoUpCarId,
        fuelType,
        displacement,
        vehicleType,
        outColorId,
        innerColorId,
        mission,
        transmission,
        trim,
        releasePrice,
        carLocationStatus,
        carLocationGarage,
        carLocationBcode,
        carLocationAddress,
        carLocationAddressDetail,
        option,
    } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        if (target.type === "checkbox") {
            const dataTarget = target?.getAttribute("data-target");

            // Checkbox인 경우 처리
            if (target.checked) {
                // Checkbox가 체크된 경우 해당 값을 배열에 추가
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: [...prevState[dataTarget], inputName],
                }));
            } else {
                // Checkbox가 체크 해제된 경우 해당 값을 배열에서 제거
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: prevState[dataTarget].filter((item) => item !== inputName),
                }));
            }
        } else {
            // 다른 input 요소인 경우 처리 (예: input type="text")
            setFormData((prevState) => ({
                ...prevState,
                [inputName]: value,
            }));
        }
    };

    const fetchGarageList = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/delivery_location`);

            const garageList = response.data.data;

            const locations = [];

            garageList.map((garage, idx) => {
                locations.push({
                    value: garage.id,
                    label: `${garage.address} ${garage.addressDetail}`,
                    name: garage.name || "",
                    address: garage.address || "",
                    addressDetail: garage.addressDetail || "",
                    bcode: garage.bcode || "",
                });
            });

            locations.push({
                value: "직접 입력",
                label: "직접 입력",
            });

            setLocations(locations);
            return locations;
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchColorList = async () => {
        try {
            const response = await fetchData("/api/share/car_color/v1");

            const colorList = response.data.data;

            const colors = [];

            colorList.map((color, idx) => {
                colors.push({
                    value: color.id,
                    label: color.nameKr,
                });
            });

            return colors;
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 셀렉트박스
    const [options, setOptions] = useState({
        productStatusOptions: [],
        yearOptions: [],
        monthOptions: [],
        exteriorColorOptions: [],
        interiorColorOptions: [],
        transmissionOptions: [],
        garageOptions: [],
    });

    const {
        productStatusOptions,
        yearOptions,
        monthOptions,
        exteriorColorOptions,
        interiorColorOptions,
        transmissionOptions,
        garageOptions,
    } = options;

    const fetchSelectOptions = async () => {
        const productStatusOptionsPromise = getProductStatusList();
        const yearOptionsPromise = getYearList();
        const monthOptionsPromise = getMonthList();
        // const exteriorColorOptionsPromise = getColorList();
        // const interiorColorOptionsPromise = getColorList();
        const transmissionOptionsPromise = getTransmissionList();

        const [productStatus, year, month, outColorId, innerColorId, transmission] = await Promise.all([
            productStatusOptionsPromise,
            yearOptionsPromise,
            monthOptionsPromise,
            // exteriorColorOptionsPromise,
            // interiorColorOptionsPromise,
            transmissionOptionsPromise,
        ]);

        const garageList = await fetchGarageList();
        const colorList = await fetchColorList();

        if (garageList.length === 0) {
            setFormData((prevState) => ({
                ...prevState,
                carLocationStatus: true,
            }));
        }

        setOptions({
            productStatusOptions: productStatus,
            yearOptions: year,
            monthOptions: month,
            exteriorColorOptions: colorList,
            interiorColorOptions: colorList,
            transmissionOptions: transmission,
            garageOptions: garageList,
        });
    };

    const fetchCarColorOptions = async () => {
        try {
            if (store.colors.length === 0) {
                // API 호출을 Redux 액션을 통해 실행하고 결과를 스토어에 저장합니다.
                const result = await dispatch(fetchColors());
                console.log(result.payload);
            }
        } catch (error) {
            // 오류 처리 로직 추가
        }
    };

    useEffect(() => {
        fetchSelectOptions();
        // fetchCarColorOptions();
    }, []);

    // 이미지 파일 처리
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);

        // 현재 업로드된 파일의 개수와 새로운 파일의 개수를 합산
        const totalFilesCount = formData.vehiclePhotos.length + newFiles.length;

        if (totalFilesCount > 20) {
            // 파일 개수가 20개를 초과할 경우 경고 메시지를 표시하고 추가 작업 중단
            showModal("파일 제한 초과", <p>최대 20개의 파일만 업로드할 수 있습니다.</p>, [{ text: "확인", handler: handleModalClose }]);
            return;
        }

        // 파일 개수가 20개 이하인 경우, 기존 파일 목록에 새 파일 추가
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: [...prevState.vehiclePhotos, ...newFiles],
        }));
    };

    const handleImageRemove = (index) => {
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: prevState.vehiclePhotos.filter((_, i) => i !== index),
        }));
    };

    // 대표 사진 드래그 앤 드롭
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const fileUrl = e.dataTransfer.getData("url");
        const fileId = e.dataTransfer.getData("id");
        const index = e.dataTransfer.getData("imgIndex");
        const selectedImage = formData.vehiclePhotos[index];

        setFormData((prevState) => ({
            ...prevState,
            mainThumbnail: fileUrl || selectedImage,
            mainThumbnailId: fileId || null,
        }));
    };

    const onDragStart = (e, id, fileId, fileUrl) => {
        if (fileUrl !== undefined) {
            e.dataTransfer.setData("url", fileUrl);
        } else {
            e.dataTransfer.setData("url", undefined);
        }

        if (fileId !== undefined) {
            e.dataTransfer.setData("id", fileId);
        } else {
            e.dataTransfer.setData("id", undefined);
        }

        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("imgIndex", id);
    };

    const onDragDrop = (e, index) => {
        e.preventDefault();

        const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
        if (sourceIndex === index) return;
        const updateImages = [...formData.vehiclePhotos];
        const [movedImage] = updateImages.splice(sourceIndex, 1);

        updateImages.splice(index, 0, movedImage);
        setFormData((prevState) => ({
            ...prevState,
            vehiclePhotos: updateImages,
        }));
    };
    const onDragOver = (e) => {
        e.preventDefault();
    };

    const removeThumbnail = () => {
        setFormData((prevState) => ({
            ...prevState,
            mainThumbnail: null,
        }));
    };

    // 스테이트 리프팅
    useEffect(() => {
        onVehicleInfoChange(formData);
    }, [formData, onVehicleInfoChange]);

    // 차량번호 조회
    const handleCheckVehicleNumber = async () => {
        if (!vehicleNumber) {
            showModal("", <p>차량 번호를 입력해주세요.</p>, [{ text: "확인", handler: handleModalClose }]);
            setFormData((prevState) => ({
                ...prevState,
                carInquiryStatus: false,
            }));
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetchData("/api/share/car/v1/search", { car_number: vehicleNumber });

            const newPrice = response.data.data.newPrice;
            // newPrice가 숫자인지 확인
            if (/^\d+$/.test(newPrice)) {
                setFormData((prevState) => ({
                    ...prevState,
                    carInquiryStatus: true,
                    autoUpCarId: response.data.data.id,
                    year: response.data.data.carMadedYear?.toString(),
                    month: response.data.data.carMadedMonth?.toString(),
                    fuelType: response.data.data.fuelType,
                    displacement: response.data.data.displacement,
                    vehicleType: response.data.data.modelDetailName,
                    mission: response.data.data.mission,
                    transmission: response.data.data.mission,
                    trim: response.data.data.trimName,
                    releasePrice: comma(newPrice),
                }));
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    carInquiryStatus: true,
                    autoUpCarId: response.data.data.id,
                    year: response.data.data.carMadedYear?.toString(),
                    month: response.data.data.carMadedMonth?.toString(),
                    fuelType: response.data.data.fuelType,
                    displacement: response.data.data.displacement,
                    vehicleType: response.data.data.modelDetailName,
                    mission: response.data.data.mission,
                    transmission: response.data.data.mission,
                    trim: response.data.data.trimName,
                    releasePrice: "",
                }));
                // handleToast("차량 가격 조회에 실패했습니다.");
            }
            handleToast("차량 번호가 확인되었습니다.");
        } catch (error) {
            showModal("오류가 발생했습니다.", <p>차량 번호를 확인해주세요.</p>, [{ text: "확인", handler: handleModalClose }]);
            setFormData((prevState) => ({
                ...prevState,
                carInquiryStatus: false,
                autoUpCarId: undefined,
                year: "",
                month: "",
                fuelType: "",
                displacement: "",
                vehicleType: "",
                mission: "",
                transmission: "",
                trim: "",
                releasePrice: "",
            }));
        } finally {
            setIsLoading(false);
        }
    };

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState(false);

    const openPostCode = () => {
        setAddressPopup(true);
    };
    const closePostCode = () => {
        setAddressPopup(false);
    };

    const handleAddress = (data) => {
        setFormData((prevState) => ({
            ...prevState,
            carLocationBcode: data.daumData.bcode,
            carLocationAddress: data.daumData.address,
        }));
        closePostCode();
    };

    useEffect(() => {
        if (initData !== undefined && locations.length !== 0) {
            const locationResult = locations.find((location) => location.bcode === initData?.carLocationBcode);
            console.log(locationResult);

            setFormData({
                rentType: initData?.rentInfo?.rentType || "",
                vehiclePhotos: initData?.carImages || [],
                mainThumbnail: initData?.mainImgUrl || null,
                mainThumbnailId: initData?.mainImgId || null,
                carInquiryStatus: false,
                productStatus: initData?.shareProductStatus || "",
                mileage: comma(initData?.mileage) || "",
                year: initData?.carMadedYear || "",
                month: initData?.carMadedMonth || "",
                vehicleNumber: initData?.carNumber || "",
                autoUpCarId: initData?.autoUpCarId || null,
                fuelType: initData?.fuelType || "",
                displacement: initData?.displacement || "",
                vehicleType: initData?.carType || "",
                outColorId: initData?.outColorId || "",
                innerColorId: initData?.innerColorId || "",
                mission: "",
                transmission: "",
                trim: initData?.trimName,
                releasePrice: "",
                carLocationStatus: locationResult ? false : true,
                carLocationGarage: locationResult?.value || "직접 입력",
                carLocationBcode: initData?.carLocationBcode || "",
                carLocationAddress: initData?.carLocationAddress || "",
                carLocationAddressDetail: initData?.carLocationAddressDetail || "",
                option: "",
            });
        }
    }, [initData, location]);

    useEffect(() => {
        if (initData !== undefined && vehicleNumber !== "") {
            handleCheckVehicleNumber();
        }
    }, [initData, vehicleNumber]);

    useEffect(() => {
        // setFormData((prev) => ({
        //     ...prev,
        //     releasePrice: "",
        // }));
    }, [newPriceDisabled]);

    // 모달
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: "",
        content: null,
        buttons: [],
    });

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const showModal = (title, content, buttons) => {
        setModalContent({ title, content, buttons });
        setModalOpen(true);
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {isLoading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <section id="section-01" className="section width-100">
                <div className="section-tit">
                    <h2>차량 정보</h2>
                </div>

                <div className="section-content">
                    <div className="form">
                        <div className="input-form width-100">
                            <label className="form-title">차량 유형</label>
                            <div className="form-field">
                                <div className="check-form">
                                    <div className="form-field">
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="LONG_RENT"
                                                    name="rentType"
                                                    value="LONG_RENT"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={rentType === "LONG_RENT"}
                                                />
                                                <label htmlFor="LONG_RENT">장기렌트</label>
                                            </div>
                                        </div>
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="SUBSCRIPTION"
                                                    name="rentType"
                                                    value="SUBSCRIPTION"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={rentType === "SUBSCRIPTION"}
                                                />
                                                <label htmlFor="SUBSCRIPTION">구독</label>
                                            </div>
                                        </div>
                                        <div className="input-radio">
                                            <div className="radiobox">
                                                <input
                                                    type="radio"
                                                    id="FIRST_RELEASE"
                                                    name="rentType"
                                                    value="FIRST_RELEASE"
                                                    onChange={(e) => handleChange(e)}
                                                    checked={rentType === "FIRST_RELEASE"}
                                                />
                                                <label htmlFor="FIRST_RELEASE">선출고</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">차량 사진</label>
                            <div className="form-field">
                                <button type="button" className="btn btn-primary btn-large" onClick={handleUploadClick}>
                                    <img src={CameraIcon} alt="" />
                                    차량사진 업로드
                                </button>
                                <span className="img-count">{formData?.vehiclePhotos?.length}/20</span>
                                <input type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                            </div>
                        </div>
                        <div className="image-list">
                            <div className="main-img" onDragOver={handleDragOver} onDrop={handleDrop}>
                                {formData?.vehiclePhotos?.length !== 0 && formData.mainThumbnail ? (
                                    <>
                                        {(() => {
                                            const isFile = formData.mainThumbnail && formData.mainThumbnail.type;

                                            if (isFile) {
                                                return (
                                                    <>
                                                        <img src={URL.createObjectURL(formData.mainThumbnail)} alt="Main Thumbnail1" />
                                                        <button onClick={removeThumbnail}></button>
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <img src={formData.mainThumbnail} alt="Main Thumbnail2" />
                                                        <button onClick={removeThumbnail}></button>
                                                    </>
                                                );
                                            }
                                        })()}
                                    </>
                                ) : (
                                    <div className="main-img_place">
                                        대표 사진을 <br />
                                        선택해주세요.
                                    </div>
                                )}
                            </div>
                            {formData?.vehiclePhotos?.map((file, index) => {
                                const isFile = file && file.url;

                                if (isFile) {
                                    // 서버에서 받은 차량 이미지 사진
                                    return (
                                        <div
                                            className="img"
                                            key={index}
                                            draggable
                                            onDragStart={(e) => onDragStart(e, index, file.uploadId, file.url)}
                                            onDragOver={onDragOver}
                                            onDrop={(e) => onDragDrop(e, index)}
                                        >
                                            <button
                                                type="button"
                                                className="btn-remove_img"
                                                onClick={() => handleImageRemove(index)}
                                            ></button>
                                            <img src={file.url} alt={`Vehicle ${index}`} />
                                        </div>
                                    );
                                } else if (!isFile) {
                                    // 유저가 추가한 파일 오브젝트
                                    return (
                                        <div
                                            className="img"
                                            key={index}
                                            draggable
                                            onDragStart={(e) => onDragStart(e, index)}
                                            onDragOver={onDragOver}
                                            onDrop={(e) => onDragDrop(e, index)}
                                        >
                                            <button
                                                type="button"
                                                className="btn-remove_img"
                                                onClick={() => handleImageRemove(index)}
                                            ></button>
                                            <img src={URL.createObjectURL(file)} alt={`Vehicle ${index}`} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div className="input-form">
                            <label className="form-title">상품상태</label>
                            <div className="form-field">
                                <div className="select-element">
                                    <Select
                                        options={productStatusOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="출고상태"
                                        value={productStatusOptions.filter((option) => option.value === productStatus)}
                                        onChange={(option) => handleChange({ option, name: "productStatus" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">주행거리</label>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="-"
                                        name="mileage"
                                        value={mileage || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <span className="input-esset">km</span>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">연도</label>
                            <div className="form-field disabled">
                                <div className="select-element">
                                    <Select
                                        options={yearOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="연도"
                                        // value={yearOptions.filter((option) => option.value === year)}
                                        value={yearOptions.find((option) => option.value === year)}
                                        onChange={(option) => handleChange({ option, name: "year" })}
                                    />
                                </div>
                                <div className="select-element">
                                    <Select
                                        options={monthOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="월"
                                        // value={monthOptions.filter((option) => option.value === month)}
                                        value={monthOptions.find((option) => option.value === month)}
                                        onChange={(option) => handleChange({ option, name: "month" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">차량번호</label>
                            <div className={uploadStatus === "MODIFY" ? "form-field disabled" : "form-field"}>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="-없이 입력"
                                        name="vehicleNumber"
                                        value={vehicleNumber || ""}
                                        onChange={handleChange}
                                        disabled={uploadStatus === "MODIFY"}
                                    />
                                </div>
                                {uploadStatus === "NEW" && (
                                    <button type="button" className="btn btn-primary btn-small" onClick={handleCheckVehicleNumber}>
                                        조회
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">연료</label>
                            <div className="form-field disabled">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="연료를 입력해주세요."
                                        name="fuelType"
                                        value={fuelType || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">배기량</label>
                            <div className="form-field disabled">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="배기량을 입력해주세요."
                                        name="displacement"
                                        value={displacement || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                                <span className="input-esset">cc</span>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">차종</label>
                            <div className="form-field disabled">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="차종을 입력해주세요."
                                        name="vehicleType"
                                        value={vehicleType || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">색상</label>
                            <div className="form-field">
                                <div className="select-element">
                                    <Select
                                        options={exteriorColorOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="외부색상"
                                        value={exteriorColorOptions.filter((option) => option.value === outColorId)}
                                        onChange={(option) => handleChange({ option, name: "outColorId" })}
                                    />
                                </div>
                                <div className="select-element">
                                    <Select
                                        options={interiorColorOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="내부색상"
                                        value={interiorColorOptions.filter((option) => option.value === innerColorId)}
                                        onChange={(option) => handleChange({ option, name: "innerColorId" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">변속기기</label>
                            <div className="form-field disabled">
                                {/* <div className="select-element">
                                    <Select
                                        options={transmissionOptions}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        isSearchable={false}
                                        placeholder="변속기를 선택해주세요"
                                        value={transmissionOptions.filter((option) => option.value === transmission)}
                                        onChange={(option) => handleChange({ option, name: 'transmission' })}
                                    />
                                </div> */}
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="변속기를 입력해주세요."
                                        name="mission"
                                        value={mission || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">트림</label>
                            <div className="form-field disabled">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="트림을 입력해주세요."
                                        name="trim"
                                        value={trim || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">신차가격</label>
                            <div className={newPriceDisabled ? "form-field disabled" : "form-field"}>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="신차 가격을 입력해주세요."
                                        name="releasePrice"
                                        value={releasePrice || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">차고지 위치</label>
                            <div className="form-field direction-col">
                                {locations.length !== 0 && (
                                    <div className="select-element">
                                        <Select
                                            options={garageOptions}
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="차고지 목록"
                                            value={garageOptions.filter((option) => option.value === carLocationGarage)}
                                            onChange={(option) => {
                                                if (option.value === "직접 입력") {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        carLocationStatus: true,
                                                        carLocationGarage: "직접 입력",
                                                    }));
                                                } else {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        carLocationStatus: false,
                                                        carLocationGarage: option.value,
                                                        carLocationAddress: option.address,
                                                        carLocationAddressDetail: option.addressDetail,
                                                        carLocationBcode: option.bcode,
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {carLocationStatus === true && (
                                    <>
                                        <div className="input-element width-100">
                                            <input
                                                type="text"
                                                placeholder="도로명, 건물명, 번지 검색"
                                                name="carLocationAddress"
                                                value={carLocationAddress || ""}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                            <button type="button" className="btn-esset" onClick={openPostCode}>
                                                <FontAwesomeIcon
                                                    icon={faMagnifyingGlass}
                                                    style={{ color: "#fdc02f", fontSize: "18px", marginRight: "10px" }}
                                                />
                                            </button>
                                        </div>
                                        <div className="input-element width-100">
                                            <input
                                                type="text"
                                                placeholder="상세 주소"
                                                name="carLocationAddressDetail"
                                                value={carLocationAddressDetail || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="input-form">
                            <label className="form-title">선택옵션</label>
                            <div className="form-field disabled">
                                <div className="textarea-element">
                                    <textarea name="option" value={option || ""} onChange={handleChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 주소 검색 창 (DaumPost 컴포넌트) */}
            {addressPopup && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}

            {modalOpen && (
                <Modal
                    title={modalContent.title}
                    content={modalContent.content}
                    buttons={modalContent.buttons}
                    onClose={handleModalClose}
                    layer={true}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
