/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faMagnifyingGlass, faRotateRight, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { Modal, Toast } from "components";

import { fetchData } from "api";
import { comma } from "util";
import {
    CancelElecAgreement,
    CustomerInfo,
    CarInfo,
    FeeInfo,
    ContractManagerInfo,
    ChangeGrade,
    AffiliatedDealerInfo,
    DealerDetail,
} from "./modal";

import MasterIcon from "resources/icon/grade-master.png";
import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const MypageDealer = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const navigate = useNavigate();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            if (isLoggedIn === null) {
            } else if (!isLoggedIn) {
                navigate("/login");
            }
        } else {
            hasMounted.current = true;

            if (isLoggedIn === false) {
                navigate("/login");
            }
        }
    }, [isLoggedIn, navigate, hasMounted]);

    const userData = useSelector((state) => state.login.userData);
    const [loading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [calculateStatus, setCalculateStatus] = useState(null);

    const fetchMySummary = async () => {
        setLoading(true);

        try {
            const response = await fetchData("/api/share/summary/v1/my_page");

            setSummaryData(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
            setLoading(false);
        }
    };

    const fetchTableData = async (params) => {
        setLoading(true);

        try {
            // const params = {
            //     started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            //     ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            //     search_keyword: searchKeyword,
            // };

            const response = await fetchData("/api/share/contract/v1", params);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMySummary();
        fetchTableData();
    }, []);

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const searchData = () => {
        const params = {
            contract_status_list: contractStatus,
            calculate_status: calculateStatus,
            started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            search_keyword: searchKeyword,
        };

        fetchTableData(params);
    };

    useEffect(() => {
        searchData();
    }, [startDate, endDate, contractStatus, calculateStatus]);

    const initParams = () => {
        setSearchKeyword("");
        setStartDate(null);
        setEndDate(null);
        setContractStatus(null);
        setCalculateStatus(null);

        const params = {
            contract_status_list: "",
            calculate_status: "",
            started_at: null,
            ended_at: null,
            search_keyword: "",
        };

        fetchTableData(params);
    };

    const contractStatusOptions = [
        { value: "REQUEST", label: "접수중" },
        { value: "ON_PROCESS", label: "진행중" },
        { value: "FINISH", label: "계약완료" },
        { value: "DELIVERY_FINISH", label: "탁송신청완료" },
        { value: "RETURN", label: "반납" },
        { value: "RELEASE_READY", label: "출고준비중" },
        { value: "RELEASE_FINISH", label: "출고완료" },
        { value: "REJECT", label: "진행 불가" },
        { value: "CANCEL", label: "중도 해지" },
    ];

    const calculateStatusOptions = [
        { value: "PENDING", label: "정산전" },
        { value: "CANCEL", label: "환수" },
        { value: "FINISH", label: "정산완료" },
    ];

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="mypage container">
                <div className="mypage-head">
                    <div className="myinfo">
                        <div className="member">
                            <div className="member-icon">
                                {(() => {
                                    const gradeMap = {
                                        MASTER: { icon: MasterIcon, className: "color-txt-master", label: "master" },
                                        GOLD: { icon: GoldIcon, className: "color-txt-gold", label: "gold" },
                                        SILVER: { icon: SilverIcon, className: "color-txt-silver", label: "silver" },
                                        BRONZE: { icon: BronzeIcon, className: "color-txt-bronze", label: "bronze" },
                                        NORMAL: { icon: NormalIcon, className: "color-txt-normal", label: "normal" },
                                    };
                                    const grade = userData?.shareGrade;

                                    if (grade) {
                                        const { icon, className, label } = gradeMap[grade];
                                        return (
                                            <>
                                                <img src={icon} alt="" />
                                                <span className={`member-class-txt ${className}`}>{label}</span>
                                            </>
                                        );
                                    }

                                    return null;
                                })()}
                            </div>
                            <div className="member-detail">
                                <span>
                                    <b>{userData?.name}</b> 님
                                </span>
                                {/* <button type="button" className="btn-detail_member">
                                    등급설정
                                </button> */}
                            </div>
                        </div>
                        <div className="links">
                            <Link to={"/settlement"} className="btn-account">
                                정산서 보기 <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                            <Link to={"/password/reset"} className="btn-account">
                                비밀번호 변경 <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        </div>
                    </div>

                    <div className="summary">
                        <div className="summary-head">
                            <span className="date">
                                {new Date().toLocaleDateString("ko-KR", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })}{" "}
                                기준
                            </span>
                        </div>
                        <div className="summary-content">
                            <div className="item-list">
                                <div className="item">
                                    <div className="item-head">
                                        <span>나의 계약랭킹</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-blue">
                                            {summaryData?.contractRanking ? summaryData?.contractRanking : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>전체 계약접수</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-blue">
                                            {summaryData?.contractTotalCount ? summaryData?.contractTotalCount : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>계약완료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-blue">
                                            {summaryData?.contractFinishCount ? summaryData?.contractFinishCount : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>계약진행 중</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-blue">
                                            {summaryData?.contractOnProcessCount ? summaryData?.contractOnProcessCount : "-"}
                                        </span>
                                        {/* <span className="sub-txt">(0%)</span> */}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>계약취소(불가)</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-blue">
                                            {summaryData?.contractCancelCount ? summaryData?.contractCancelCount : "-"}
                                        </span>
                                        {/* <span className="sub-txt">(0%)</span> */}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>전체 수수료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-black">
                                            {summaryData?.commissionTotal ? comma(summaryData?.commissionTotal) : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>일시납 수수료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-black">
                                            {summaryData?.commissionFull ? comma(summaryData?.commissionFull) : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>분납 수수료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-black">
                                            {summaryData?.commissionDivide ? comma(summaryData?.commissionDivide) : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>전월 분납 수수료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-black">
                                            {summaryData?.commissionDivideLastMonth ? comma(summaryData?.commissionDivideLastMonth) : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>환급 수수료</span>
                                    </div>
                                    <div className="item-body">
                                        <span className="color-black">
                                            {summaryData?.commissionCancel ? comma(summaryData?.commissionCancel) : "-"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mypage-content">
                    <div className="mypage_table-container">
                        <div className="mypage_table-head">
                            <div className="mypage_table-tit">
                                <span>계약현황</span>
                            </div>
                            <div className="mypage_table-filter">
                                <div className="filter-option filter-search" style={{ marginRight: "auto" }}>
                                    <input
                                        type="text"
                                        placeholder="차량번호, 딜러명, 고객명을 검색해주세요."
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                    <button type="button" onClick={searchData}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                                <div className="filter-option filter-date">
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    <div className="input-datepicker">
                                        <DatePicker
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText=""
                                            selected={startDate}
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(date) => {
                                                if (endDate && date > endDate) {
                                                    handleToast("시작 날짜는 종료 날짜보다 이후일 수 없습니다.");
                                                    return;
                                                }
                                                setStartDate(date);
                                            }}
                                        />
                                    </div>
                                    -
                                    <div className="input-datepicker">
                                        <DatePicker
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText=""
                                            selected={endDate}
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(date) => {
                                                if (startDate && date < startDate) {
                                                    handleToast("종료 날짜는 시작 날짜보다 이전일 수 없습니다.");
                                                    return;
                                                }
                                                setEndDate(date);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="filter-option filter-select">
                                    <div className="select-element">
                                        <Select
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="계약상태"
                                            options={contractStatusOptions}
                                            onChange={(value) => handleSelectChange(value, setContractStatus)}
                                            value={contractStatusOptions.filter((option) => option.value === contractStatus)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-option filter-select">
                                    <div className="select-element">
                                        <Select
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="정산현황"
                                            options={calculateStatusOptions}
                                            onChange={(value) => handleSelectChange(value, setCalculateStatus)}
                                            value={calculateStatusOptions.filter((option) => option.value === calculateStatus)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-option filter-refresh">
                                    <button type="button" className="btn-refresh" onClick={initParams}>
                                        초기화
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="mypage_table-wrapper">
                            <table>
                                <colgroup>
                                    <col width={"4.8%"} />
                                    <col width={"5.5%"} />
                                    <col width={"5.5%"} />
                                    <col width={"7.2%"} />
                                    <col width={"6.8%"} />
                                    <col width={"6.8%"} />
                                    <col width={"6.0%"} />
                                    <col width={"5.8%"} />
                                    <col width={"5.8%"} />
                                    <col width={"7.6%"} />
                                    <col width={"5.4%"} />
                                    <col width={"7.6%"} />
                                    <col width={"6.8%"} />
                                    <col width={"7.8%"} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="tht">계약NO</div>
                                        </th>
                                        <th>
                                            <div className="tht">접수일</div>
                                        </th>
                                        <th>
                                            <div className="tht">출고일</div>
                                        </th>
                                        <th>
                                            <div className="tht">상품구분</div>
                                        </th>
                                        <th>
                                            <div className="tht">차량번호</div>
                                        </th>
                                        <th>
                                            <div className="tht">모델(상세모델)</div>
                                        </th>
                                        <th>
                                            <div className="tht">고객명</div>
                                        </th>
                                        <th>
                                            <div className="tht">계약기간</div>
                                        </th>
                                        <th>
                                            <div className="tht">보증금</div>
                                        </th>
                                        <th>
                                            <div className="tht">분납여부</div>
                                        </th>
                                        <th>
                                            <div className="tht">대여료</div>
                                        </th>
                                        <th>
                                            <div className="tht">계약상태</div>
                                        </th>
                                        <th>
                                            <div className="tht">지급수수료</div>
                                        </th>
                                        <th>
                                            <div className="tht">정산현황</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {datas.length === 0 && (
                                        <tr>
                                            <td colSpan={14}>
                                                <div className="tdd" style={{ textAlign: "center" }}>
                                                    <span className="td-desc">조회된 데이터가 없습니다.</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )} */}
                                    {datas?.map((data, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{data?.id}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.requestedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.releasedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            <b>
                                                                {data?.rentType === "LONG_RENT"
                                                                    ? "장기렌트"
                                                                    : data?.rentType === "SUBSCRIPTION"
                                                                    ? "구독"
                                                                    : data?.rentType === "FIRST_RELEASE"
                                                                    ? "선출고"
                                                                    : "알 수 없음"}
                                                            </b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            <b>{data?.car?.carNumber}</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{data?.car?.modelName}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            <b>{data?.customerName}</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{data?.contractRangeMonth}개월</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{comma(data?.depositPrice)}원</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.depositType === "FULL" && "일시납"}
                                                            {data?.depositType === "DIVIDE_2" && "2회분납"}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{comma(data?.rentPrice)}원</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        {/* {data?.contractStatus === "REQUEST" && "접수중"}
                                                            {data?.contractStatus === "ON_PROCESS" && "진행중"}
                                                            {data?.contractStatus === "FINISH" && "계약완료"}
                                                            {data?.contractStatus === "DELIVERY_FINISH" && "탁송신청완료"}
                                                            {data?.contractStatus === "RETURN" && "반납"}
                                                            {data?.contractStatus === "RELEASE_READY" && "출고준비중"}
                                                            {data?.contractStatus === "RELEASE_FINISH" && "출고완료"}
                                                            {data?.contractStatus === "REJECT" && "진행 불가"}
                                                            {data?.contractStatus === "CANCEL" && "중도 해지"} */}

                                                        {data?.contractStatus === "REQUEST" && (
                                                            <span className="td-desc td-color-01">접수중</span>
                                                        )}
                                                        {data?.contractStatus === "ON_PROCESS" && (
                                                            <span className="td-desc td-color-01">진행중</span>
                                                        )}
                                                        {data?.contractStatus === "FINISH" && (
                                                            <span className="td-desc td-color-01">계약완료</span>
                                                        )}
                                                        {data?.contractStatus === "DELIVERY_FINISH" && (
                                                            <span className="td-desc td-color-02">탁송신청완료</span>
                                                        )}
                                                        {data?.contractStatus === "RETURN" && (
                                                            <span className="td-desc td-color-03">반납</span>
                                                        )}
                                                        {data?.contractStatus === "RELEASE_READY" && (
                                                            <span className="td-desc td-color-01">출고준비중</span>
                                                        )}
                                                        {data?.contractStatus === "RELEASE_FINISH" && (
                                                            <span className="td-desc td-color-02">출고완료</span>
                                                        )}
                                                        {data?.contractStatus === "REJECT" && (
                                                            <span
                                                                className="td-desc td-color-03"
                                                                onClick={() =>
                                                                    setCancelElecAgreementModalStatus(["REJECT", data.rejectReason])
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                진행 불가
                                                            </span>
                                                        )}
                                                        {data?.contractStatus === "CANCEL" && (
                                                            <span className="td-desc td-color-03">중도 해지</span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span
                                                            className={
                                                                data?.managerCommission === 0
                                                                    ? `td-desc td-color-03`
                                                                    : `td-desc td-color-01`
                                                            }
                                                        >
                                                            <b>{comma(data?.managerCommission)}원</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        {data?.contractStatus === "REJECT" ? (
                                                            <span className="td-desc td-color-03">
                                                                <b>정산미포함</b>
                                                            </span>
                                                        ) : (
                                                            <span className="td-desc td-color-01">
                                                                <b>
                                                                    {data?.calculateStatus === "PENDING" && "정산전"}
                                                                    {data?.calculateStatus === "CANCEL" && "환급"}
                                                                    {data?.calculateStatus === "FINISH" && "정산완료"}
                                                                </b>
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    {datas?.length === 0 && (
                                        <tr className="none-data">
                                            <td colSpan={14} style={{ height: "300px", verticalAlign: "middle" }}>
                                                <div className="tdd tac">조회된 차량이 없습니다.</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* 전자약정 발송 취소사유 모달 */}
            {/* <CancelElecAgreement /> */}

            {/* 고객 정보 모달 */}
            {/* <CustomerInfo /> */}

            {/* 차량 정보 모달 */}
            {/* <CarInfo /> */}

            {/* 수수료 정보 모달 */}
            {/* <FeeInfo /> */}

            {/* 계약담당자 정보 모달 */}
            {/* <ContractManagerInfo /> */}

            {/* 등급 변경 모달 */}
            {/* <ChangeGrade /> */}

            {/* 소속 딜러 정보 모달 */}
            {/* <AffiliatedDealerInfo /> */}

            {/* 딜러 정보 상세 모달 */}
            {/* <DealerDetail /> */}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
