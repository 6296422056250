/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

import { handleNumericInputWithoutComma, getDriverLicenseType } from "util";

export const AdditionalDrivers = ({ additionalDrivers, setAdditionalDrivers, handleCheckDriverLincese, verifyDriverLinceseSub }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: additionalDrivers.name || "", // 성명
        idNumber1: additionalDrivers.idNumber1 || "", // 주민등록번호 앞번호
        idNumber2: additionalDrivers.idNumber2 || "", // 주민등록번호 뒷번호
        driverLicenseType: additionalDrivers.driverLicenseType || "", // 면허종류
        driverLicenseNumber: additionalDrivers.driverLicenseNumber || "", // 면허번호
        expLt1Yn: additionalDrivers?.expLt1Yn || "N", // 운전면허 1년 미만 여부
    });

    const { name, idNumber1, idNumber2, driverLicenseType, driverLicenseNumber, expLt1Yn } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        if (target.type === "checkbox") {
            const dataTarget = target?.getAttribute("data-target");

            // Checkbox인 경우 처리
            if (target.checked) {
                // Checkbox가 체크된 경우 해당 값을 배열에 추가
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: [...prevState[dataTarget], inputName],
                }));
            } else {
                // Checkbox가 체크 해제된 경우 해당 값을 배열에서 제거
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: prevState[dataTarget].filter((item) => item !== inputName),
                }));
            }
        } else {
            // 다른 input 요소인 경우 처리 (예: input type="text")
            setFormData((prevState) => ({
                ...prevState,
                [inputName]: value,
            }));
        }
    };

    // 면허 검증
    const verificationLicense = () => {
        handleCheckDriverLincese("SUB");
    };

    // 셀렉트박스
    const [options, setOptions] = useState({
        driverLicenseOptions: [],
    });

    const { driverLicenseOptions } = options;

    const fetchSelectOptions = async () => {
        const driverLicenseOptionsPromise = getDriverLicenseType();

        const [driverLicenseTypeId] = await Promise.all([driverLicenseOptionsPromise]);

        setOptions({
            driverLicenseOptions: driverLicenseTypeId,
        });
    };

    useEffect(() => {
        fetchSelectOptions();
    }, []);

    // 스테이트 리프팅
    useEffect(() => {
        setAdditionalDrivers(formData);
    }, [formData, setAdditionalDrivers]);

    return (
        <div className="additional-customer">
            <div className="form">
                <div className="input-form grid-row">
                    <label className="form-title">성명</label>
                    <div className="form-field">
                        <div className="input-element">
                            <input type="text" placeholder="" name="name" value={name || ""} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="input-form grid-row">
                    <label className="form-title">주민등록번호</label>
                    <div className="form-field">
                        <div className="input-element">
                            <input
                                type="text"
                                placeholder=""
                                name="idNumber1"
                                value={idNumber1 || ""}
                                onChange={handleChange}
                                onInput={handleNumericInputWithoutComma}
                            />
                        </div>
                        -
                        <div className="input-element">
                            <input
                                type="text"
                                placeholder=""
                                name="idNumber2"
                                value={idNumber2 || ""}
                                onChange={handleChange}
                                onInput={handleNumericInputWithoutComma}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-form grid-row">
                    <label className="form-title">면허종류</label>
                    <div className="form-field">
                        <div className="select-element">
                            <Select
                                options={driverLicenseOptions}
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="면허 종류"
                                value={driverLicenseOptions.filter((option) => option.value === driverLicenseType)}
                                onChange={(option) => handleChange({ option, name: "driverLicenseType" })}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-form grid-row">
                    <label className="form-title">면허번호</label>
                    <div className="form-field">
                        <div className="input-element">
                            <input
                                type="number"
                                placeholder="-없이 입력"
                                name="driverLicenseNumber"
                                value={driverLicenseNumber || ""}
                                onChange={handleChange}
                                onInput={handleNumericInputWithoutComma}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-form grid-row">
                    <label className="form-title"></label>
                    <div className="form-field" style={{ minHeight: "unset" }}>
                        <div className="input-check">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="expLt1Yn"
                                        value="한도 입력"
                                        onChange={() => handleChange({ target: { name: "expLt1Yn", value: expLt1Yn === "Y" ? "N" : "Y" } })}
                                        checked={expLt1Yn === "Y"}
                                    />
                                    <div></div>
                                    <span>운전면허 취득 1년 미만</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-button">
                <button type="button" onClick={verificationLicense} disabled={verifyDriverLinceseSub}>
                    {/* <button type="button" onClick={verificationLicense}> */}
                    면허검증
                </button>
            </div>
        </div>
    );
};
