/* eslint-disable */
import React, { useEffect, useState } from "react";

export const FormStep1 = ({ data, termCheckedStatus, setTermCheckedStatus }) => {
    const [allChecked, setAllChecked] = useState(false);
    const [checkBoxes, setCheckBoxes] = useState({});
    const [termsData, setTermsData] = useState();
    const [carInspectionStatus, setCarInspectionStatus] = useState(false);

    useEffect(() => {
        if (!data) return;

        // 서버에서 받은 약관 데이터
        const termsData = data?.rentCarCorperation.rentCorpTerms;
        setTermsData(termsData);

        // 서버에서 받은 약관 데이터를 기반으로 checkbox 상태 초기화
        const initialCheckboxes = {};
        for (const term of termsData) {
            initialCheckboxes[term.rentCorpTermType] = false;
        }
        setCheckBoxes(initialCheckboxes);

        if (data?.car.rentInfo?.inspectionProducts?.length !== 0) {
            setCarInspectionStatus(true);
        }
    }, [data]);

    useEffect(() => {
        console.log(termsData?.length);
    }, [termsData]);

    const handleCheck = (event) => {
        const { name, checked } = event.target;

        // 전체 동의 체크박스 선택 시 모든 개별 체크박스 선택 여부 업데이트
        if (name === "agreeAll") {
            const updatedCheckBoxes = {};
            for (const termType in checkBoxes) {
                updatedCheckBoxes[termType] = checked;
            }
            setCheckBoxes(updatedCheckBoxes);
        } else {
            // 개별 체크박스 선택 시 해당 체크박스 상태 업데이트
            setCheckBoxes({ ...checkBoxes, [name]: checked });
        }
    };

    useEffect(() => {
        const allCheckedValues = Object.values(checkBoxes);
        const allChecked = allCheckedValues.every((value) => value === true);

        setAllChecked(allChecked);
        setTermCheckedStatus(allChecked);
    }, [checkBoxes]);

    useEffect(() => {
        if (termCheckedStatus === true) {
            const updatedCheckBoxes = {};
            for (const termType in checkBoxes) {
                updatedCheckBoxes[termType] = true;
            }
            setCheckBoxes(updatedCheckBoxes);
        }
    }, [termCheckedStatus]);

    return (
        <>
            <div className="tab" id="tab-01">
                <div className="form">
                    <div className="form-field">
                        {termsData?.length === 0 || !termsData ? (
                            <div style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", margin: "20px 0" }}>
                                <b>약관 내용이 없습니다.</b>
                            </div>
                        ) : (
                            <>
                                {/* 전체 동의 체크박스 */}
                                <div className="input-check">
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" name="agreeAll" checked={allChecked} onChange={handleCheck} />
                                            <div></div>
                                            <span>
                                                <b>전체 내용에 동의합니다.</b>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                {/* 개별 동의 체크박스 */}
                                {termsData?.map((term) => {
                                    if (term.rentCorpTermType !== "CAR_REPAIR") {
                                        return (
                                            <div className="input-check" key={term.id}>
                                                <div className="checkbox" style={{ width: "100%" }}>
                                                    <label style={{ width: "100%" }}>
                                                        <input
                                                            type="checkbox"
                                                            name={term.rentCorpTermType}
                                                            checked={checkBoxes[term.rentCorpTermType]}
                                                            onChange={handleCheck}
                                                        />
                                                        <div></div>
                                                        <span>
                                                            <em>(필수)</em>
                                                            {term.rentCorpTermType === "PERSONAL"
                                                                ? "개인정보 수집 이용에 관한 사항"
                                                                : term.rentCorpTermType === "PERSONAL_OFFER"
                                                                ? "개인정보의 제 3자 제공에 관한 사항"
                                                                : term.rentCorpTermType === "RENT_CONTRACT"
                                                                ? "자동차 임대차 계약 약관"
                                                                : term.rentCorpTermType === "DRIVER_LICENSE"
                                                                ? "운전자격 검증 확인서"
                                                                : term.rentCorpTermType === "RENT_RETURN"
                                                                ? "렌트카 차량회수 동의서"
                                                                : term.rentCorpTermType === "BANK_AUTO_TRANSFER"
                                                                ? "자동이체 동의서"
                                                                : "알 수 없음"}
                                                        </span>
                                                        <a href={term.url} download target="_blank" style={{ marginLeft: "auto" }}>
                                                            상세보기
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        if (carInspectionStatus === true) {
                                            return (
                                                <div className="input-check" key={term.id}>
                                                    <div className="checkbox" style={{ width: "100%" }}>
                                                        <label style={{ width: "100%" }}>
                                                            <input
                                                                type="checkbox"
                                                                name={term.rentCorpTermType}
                                                                checked={checkBoxes[term.rentCorpTermType]}
                                                                onChange={handleCheck}
                                                            />
                                                            <div></div>
                                                            <span>
                                                                <em>(필수)</em>
                                                                {term.rentCorpTermType === "CAR_REPAIR"
                                                                    ? "차량 정비 안내 동의서"
                                                                    : "알 수 없음"}
                                                            </span>
                                                            <a href={term.url} download target="_blank" style={{ marginLeft: "auto" }}>
                                                                상세보기
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
