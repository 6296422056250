/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";

export const ContractStep4 = ({ setSubmittedFiles }) => {
    const [fileLists, setFileLists] = useState([
        { files: [], type: "DRIVER_LICENSE" },
        { files: [], type: "DOCUMENT" },
        { files: [], type: "BANK" },
        { files: [], type: "BIZ_DOCUMENT" },
        { files: [], type: "POST_DOCUMENT" },
    ]);
    const fileInputRefs = useRef(new Array(fileLists.length));

    const documentTitles = {
        DRIVER_LICENSE: "운전면허증 / 운전경력증명서(최근 3년)",
        DOCUMENT: "계약자 관련 서류",
        BANK: "자동이체 통장사본",
        BIZ_DOCUMENT: "사업자 관련 서류",
        POST_DOCUMENT: "등기영수증",
    };

    const documentDescriptions = {
        DRIVER_LICENSE: ["• 분실 면허증이 아닌 재발급 신분증을 첨부해주세요.", "• 일련번호가 잘 보이도록 촬영하여 첨부해주세요.", "• 운전경력증명서는 정부24에서 발급 가능합니다."],
        DOCUMENT: ["• 등본, 초본, 가족관계증명서, 본인서명사실확인서를 첨부해주세요.", "• 서류를 사진촬영 또는 스캔하여 첨부해주세요."],
        BANK: ["• 자동이체 신청을 위한 통장사본을 첨부해주세요.", "• (본인명의의 통장사본이 아닐 경우 추가서류가 필요합니다.)"],
        BIZ_DOCUMENT: ["• 사업자 등록증, 법인 인감증명서, 법인 등기부등본을 첨부해주세요."],
        POST_DOCUMENT: ["• 계약에 필요한 서류를 2부씩 등기 발송 후 영수증을 첨부해주세요."],
    };

    const handleFileSelect = (event, itemIndex) => {
        const newFiles = Array.from(event.target.files);
        const updatedFileLists = [...fileLists];
        updatedFileLists[itemIndex].files = updatedFileLists[itemIndex].files.concat(newFiles);
        setFileLists(updatedFileLists);
    };

    const handleRemoveFile = (itemIndex, fileIndex) => {
        const updatedFileLists = [...fileLists];
        updatedFileLists[itemIndex].files.splice(fileIndex, 1);
        setFileLists(updatedFileLists);
    };

    useEffect(() => {
        console.log(fileLists);
        setSubmittedFiles(fileLists);
    }, [fileLists, setSubmittedFiles]);

    return (
        <div className="step">
            <div className="doc-list">
                {fileLists.map((item, index) => (
                    <div
                        key={index}
                        className={item.files.length > 0 ? "item active" : "item"}
                        onClick={(event) => {
                            const isThumbnailClick = event.target.closest(".thumbnail");
                            if (!isThumbnailClick) {
                                fileInputRefs.current[index].click();
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <div className="item-head">
                            <span>{documentTitles[item.type]}</span>
                            {item.type === "DRIVER_LICENSE" && <em>*필수</em>}
                        </div>
                        <div className="item-content">
                            {documentDescriptions[item.type].map((desc, descIndex) => (
                                <p key={descIndex}>{desc}</p>
                            ))}
                        </div>

                        <div className="file-thumbnails">
                            {item.files.map((file, fileIndex) => (
                                <div key={fileIndex} className="thumbnail">
                                    {file.type.includes("pdf") ? (
                                        <span>PDF</span>
                                    ) : (
                                        <img src={URL.createObjectURL(file)} alt="file thumbnail" />
                                    )}
                                    <button className="remove-thumbnail" onClick={() => handleRemoveFile(index, fileIndex)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            ))}
                        </div>
                        <input
                            type="file"
                            multiple
                            onChange={(event) => handleFileSelect(event, index)}
                            style={{ display: "none" }}
                            ref={(el) => (fileInputRefs.current[index] = el)}
                        />
                        <label htmlFor={`fileInput-${index}`} className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </label>
                    </div>
                ))}
            </div>
            <p className="doc-warn">* 운전면허증 외 다른 서류는 전자계약 시 고객이 추가할 수 있습니다.</p>
        </div>
    );
};
