/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData } from "api";
import { handleNumericInput, comma, uncomma } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export const DepositRentalFee = ({ initData, onRentalFeeChange, vehicleInformation, handleToast, setNewPriceDisabled }) => {
    const [formData, setFormData] = useState({
        contractPeriod: [], // 계약기간
        depositTwiceYn: undefined, // 보증금 2회분납
        comissionType: undefined, // 수수료
        months: [
            { month: 12, deposit: "", rental: "", comission: "" }, // 12개월
            { month: 24, deposit: "", rental: "", comission: "" }, // 24개월
            { month: 36, deposit: "", rental: "", comission: "" }, // 36개월
            { month: 48, deposit: "", rental: "", comission: "" }, // 48개월
            { month: 60, deposit: "", rental: "", comission: "" }, // 60개월
        ],
    });

    const { contractPeriod, depositTwiceYn, comissionType, months } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value !== undefined ? target.value : "";
        const name = target.name ? target.name : e.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const periods = [12, 24, 36, 48, 60];

    const handleContractPeriodChange = (period) => {
        setFormData((prevData) => {
            if (prevData.contractPeriod.includes(period)) {
                return {
                    ...prevData,
                    contractPeriod: prevData.contractPeriod.filter((p) => p !== period),
                };
            } else {
                return {
                    ...prevData,
                    contractPeriod: [...prevData.contractPeriod, period],
                };
            }
        });
    };

    const handleMonthInputChange = (e, month) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            months: prevState.months.map((item) => {
                if (item.month === month) {
                    return {
                        ...item,
                        [name]: value,
                    };
                }
                return item;
            }),
        }));
    };

    useEffect(() => {
        // 계약기간에 대한 데이터만 리프팅
        const serverFormData = {
            contractPrices: contractPeriod.map((period) => {
                const periodData = months.find((monthData) => monthData.month === period) || {};
                return {
                    contractRangeMonth: period,
                    depositPrice: Number(uncomma(periodData.deposit) || 0),
                    rentPrice: Number(uncomma(periodData.rental) || 0),
                    comissionPrice: Number(uncomma(periodData.comission) || 0),
                    baseComissionYn: periodData.comission === "" ? "Y" : "N",
                    baseComissionYn: comissionType === "BASE" ? "Y" : "N",
                };
            }),
            depositTwiceYn: depositTwiceYn,
            comissionType: comissionType,
        };

        onRentalFeeChange(serverFormData);
    }, [formData, onRentalFeeChange]);

    // 기본 수수료 진행 체크
    const fetchComissionBase = async () => {
        try {
            const params = {
                rent_type: vehicleInformation.rentType,
                autoup_car_id: vehicleInformation.autoUpCarId,
            };

            const response = await fetchData(`/api/share/base/v1/base_commission_info`, params);
            return response?.response?.status;
        } catch (error) {
            console.error("데이터 불러오는 중 에러 발생:", error);
            return error;
        }
    };

    useEffect(() => {
        if (initData !== undefined) {
            const contractPrices = initData?.rentInfo?.contractPrices;

            // 초기값 설정
            const initialMonths = formData.months.map((monthData) => {
                const matchingPrice = contractPrices.find((price) => price.contractRangeMonth === monthData.month);

                if (matchingPrice) {
                    return {
                        ...monthData,
                        deposit: comma(matchingPrice.depositPrice.toString()),
                        rental: comma(matchingPrice.rentPrice.toString()),
                        comission: comma(matchingPrice.comissionPrice.toString()),
                        baseComissionYn: initData?.rentInfo?.comissionType === "BASE" ? "Y" : "N",
                    };
                } else {
                    return monthData; // 일치하는 값이 없을 경우 그대로 반환
                }
            });

            // 개월수만 가져와서 contractPeriod 배열 생성
            const contractPeriod = contractPrices.map((price) => price.contractRangeMonth);

            setFormData((prevFormData) => ({
                ...prevFormData,
                contractPeriod,
                depositTwiceYn: initData?.rentInfo?.depositTwiceYn,
                comissionType: initData?.rentInfo?.comissionType,
                months: initialMonths,
            }));
        }
    }, [initData]);

    return (
        <section id="section-02" className="section width-100">
            <div className="section-tit">
                <h2>보증금/렌트료/수수료 (만 26세 기준)</h2>
            </div>

            <div className="section-content">
                <div className="form">
                    <div className="input-form">
                        <label className="form-title">계약기간</label>
                        <div className="button-field gap-0">
                            {periods.map((period) => (
                                <button
                                    key={period}
                                    type="button"
                                    className={`btn-multiple ${contractPeriod.includes(period) ? "active" : ""}`}
                                    onClick={() => handleContractPeriodChange(period)}
                                >
                                    {period}개월
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">보증금 2회분납</label>
                        <div className="button-field gap-6">
                            <button
                                type="button"
                                className={`btn ${depositTwiceYn === "Y" ? "btn-primary" : "btn-default"}`}
                                onClick={() => handleChange({ target: { name: "depositTwiceYn", value: "Y" } })}
                            >
                                가능
                            </button>
                            <button
                                type="button"
                                className={`btn ${depositTwiceYn === "N" ? "btn-primary" : "btn-default"}`}
                                onClick={() => handleChange({ target: { name: "depositTwiceYn", value: "N" } })}
                            >
                                불가능
                            </button>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">
                            수수료
                            <span className="form-icon">
                                <FontAwesomeIcon icon={faQuestionCircle} />

                                <div className="form-sub_guide">
                                    <table className="table_commission">
                                        <caption>공급사(렌트) 수수료표</caption>
                                        <colgroup>
                                            <col width="30%" />
                                            <col width="30%" />
                                            <col width="40%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>신차</th>
                                            </tr>
                                            <tr>
                                                <th>출고형태</th>
                                                <th>수수료율</th>
                                                <th>특이사항</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>선 출고</span>
                                                </td>
                                                <td>
                                                    <span>소비자가의 3.5%</span>
                                                </td>
                                                <td rowSpan={2}>
                                                    <span>수수료 상한선 200만원(VAT 별도)</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>매칭출고</span>
                                                </td>
                                                <td>
                                                    <span>소비자가의 4%</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>장기렌트/구독</th>
                                            </tr>
                                            <tr>
                                                <th>계약기간</th>
                                                <th>수수료율</th>
                                                <th>특이사항</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>12개월</span>
                                                </td>
                                                <td>
                                                    <span>600,000원</span>
                                                </td>
                                                <td rowSpan={5}>
                                                    <span>
                                                        "26세 대여료" 기준 × 기간 × 수수료율(%)
                                                        <br />
                                                        수수료 상한: 180만원 (VAT 포함)
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>24개월</span>
                                                </td>
                                                <td>
                                                    <span>4.75%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>36개월</span>
                                                </td>
                                                <td>
                                                    <span>3.75%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>48개월</span>
                                                </td>
                                                <td>
                                                    <span>3.25%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>60개월</span>
                                                </td>
                                                <td>
                                                    <span>2.75%</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </span>
                        </label>
                        <div className="button-field gap-6">
                            <button
                                type="button"
                                className={`btn ${comissionType === "BASE" ? "btn-primary" : "btn-default"}`}
                                onClick={async () => {
                                    if (vehicleInformation.carInquiryStatus === false) {
                                        handleToast("차량 정보를 조회해주세요.");
                                        return;
                                    }
                                    if (vehicleInformation.rentType === "") {
                                        handleToast("차량 유형을 선택해주세요.");
                                        return;
                                    }
                                    if (formData.contractPeriod.length === 0) {
                                        handleToast("계약 기간을 선택해주세요.");
                                        return;
                                    }

                                    // 활성화된 모든 계약 기간에 대해 렌트료가 입력되었는지 확인
                                    const isAllRentalsEntered = formData.contractPeriod.every((period) => {
                                        const periodData = formData.months.find((month) => month.month === period);
                                        return periodData && periodData.rental;
                                    });

                                    if (!isAllRentalsEntered) {
                                        // 렌트료가 입력되지 않은 경우, 알림 메시지 표시
                                        handleToast("모든 선택된 개월 수에 대한 렌트료를 입력해주세요.");
                                        return;
                                    }

                                    const result = await fetchComissionBase();

                                    if (result === 200 || result === 201) {
                                        setNewPriceDisabled(true);
                                    } else if (vehicleInformation.rentType === "FIRST_RELEASE") {
                                        setNewPriceDisabled(false);
                                        handleToast("차량 가격을 입력해주세요.");
                                    }

                                    // 모든 조건을 만족하면, 수수료 유형을 'BASE'로 변경
                                    handleChange({ target: { name: "comissionType", value: "BASE" } });
                                }}
                            >
                                기본 수수료 진행
                            </button>
                            <button
                                type="button"
                                className={`btn ${comissionType === "INPERSON" ? "btn-primary" : "btn-default"}`}
                                onClick={() => {
                                    handleChange({ target: { name: "comissionType", value: "INPERSON" } });

                                    if (vehicleInformation.rentType === "FIRST_RELEASE") {
                                        setNewPriceDisabled(true);
                                    }
                                }}
                            >
                                직접 입력
                            </button>
                        </div>
                    </div>
                    {months.map((monthData) => {
                        const period = monthData.month;
                        return contractPeriod.includes(period) ? (
                            <div key={period} className="input-form">
                                <label className="form-title">{period}개월</label>
                                <div className="form-field">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="보증금"
                                            name="deposit"
                                            value={monthData.deposit || ""}
                                            onChange={(e) => handleMonthInputChange(e, period)}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            placeholder="렌트료"
                                            name="rental"
                                            value={monthData.rental || ""}
                                            onChange={(e) => handleMonthInputChange(e, period)}
                                            onInput={handleNumericInput}
                                        />
                                    </div>
                                    {comissionType === "INPERSON" && (
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                placeholder="수수료(VAT별도)"
                                                name="comission"
                                                value={monthData.comission || ""}
                                                onChange={(e) => handleMonthInputChange(e, period)}
                                                onInput={handleNumericInput}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        </section>
    );
};
