// rootReducer.js
import { combineReducers } from 'redux';
import colorReducer from './colorSlice';
import loginReducer from './loginSlice';

const rootReducer = combineReducers({
  colors: colorReducer,
  login: loginReducer,
});

export default rootReducer;
