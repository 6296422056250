/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowUpWideShort,
    faArrowUpShortWide,
    faDownload,
    faTimesCircle,
    faPlus,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { TableRow, Toast } from "components";
import * as XLSX from "xlsx";
import { convertToKoreanWon, comma, downloadExcel } from "util";

export const Table = ({ loading, data, filters, setFilters, onSortChange, handleContractRangeMonth, compareWrapStatus }) => {
    const navigate = useNavigate();
    const [isCompareActive, setCompareActive] = useState(false); // 비교하기 버 상태
    const [activeRow, setActiveRow] = useState(null); // 비교하기 활성화된 행 인덱스
    // const [activeDurations, setActiveDurations] = useState([]); // 계약기간 선택 상태
    const [compareItems, setCompareItems] = useState([]); // 비교할 항목들을 저장하는 상태

    // 비교하기 상태
    const toggleCompare = () => {
        setCompareActive(!isCompareActive);
    };

    // 상세보기 선택
    const handleRowClick = (index) => {
        setActiveRow(index);
    };

    const handleButtonClick = (duration) => {
        const durationValue = duration.replace("개월", "");

        const updatedList = filters.contract_range_month_list.includes(durationValue)
            ? filters.contract_range_month_list.filter((value) => value !== durationValue)
            : [...filters.contract_range_month_list, durationValue];

        if (updatedList.length === 0) {
            handleToast("계약 기간을 1개 이상 선택해주세요.");
            return;
        } else {
            handleContractRangeMonth(updatedList);
        }
    };

    // 비교하기 아이템 추가 핸들러
    const handleCompareClick = (row) => {
        setCompareActive(true);

        if (compareItems.length < 3) {
            setCompareItems((prevItems) => {
                // 중복된 아이템이 없는지 확인
                if (!prevItems.find((item) => item.id === row.id)) {
                    return [...prevItems, row]; // 새 아이템 추가
                }
                return prevItems;
            });
        } else {
            handleToast("최대 3대까지 비교 가능합니다.");
        }
    };

    // 비교하기 아이템 제거 핸들러
    const handleRemoveClick = (id) => {
        setCompareItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };

    // 차량 비교하기 핸들러
    const handleCarCompare = () => {
        if (compareItems.length < 2) {
            handleToast("비교할 차량을 2대 이상 선택해주세요.");
        } else {
            const carIds = compareItems?.map((car) => car.id);

            // navigate(`/product/compare/${carIds}`);
            const url = `/product/compare/${carIds}`;
            window.open(url, "_blank");
        }
    };
    const [sortMileageStatus, setSortMileageStatus] = useState("");
    const [sortExposedStatus, setSortExposedStatus] = useState("");

    const toggleSortMileage = () => {
        let status;

        if (sortMileageStatus === "") {
            status = "asc";
        } else if (sortMileageStatus === "asc") {
            status = "desc";
        } else {
            status = "";
        }
        setSortMileageStatus(status);
        setSortExposedStatus("");
        onSortChange({
            mileage: status,
            shareExposedAt: "",
        });
    };

    const toggleSortExposed = () => {
        let status;

        if (sortExposedStatus === "") {
            status = "asc";
        } else if (sortExposedStatus === "asc") {
            status = "desc";
        } else {
            status = "";
        }
        setSortExposedStatus(status);
        setSortMileageStatus("");
        onSortChange({
            mileage: "",
            shareExposedAt: status,
        });
    };

    // 연료 타입을 반환하는 함수
    const getFuelType = (fuelType) => {
        switch (fuelType) {
            case "GAS": return "가솔린";
            case "DIESEL": return "디젤";
            case "LPG": return "LPG";
            case "GAS_LPG": return "가솔린(LPG겸용)";
            case "GAS_CNG": return "가솔린(CNG겸용)";
            case "GAS_HYBRID": return "가솔린 하이브리드";
            case "ELECTRIC": return "전기";
            case "HYDROGEN": return "수소";
            default: return "알 수 없음";
        }
    };

    // 계약기간에 따른 보증금과 대여료를 반환하는 함수
    const getContractPrices = (row, months) => {
        const contract = row.rentInfo?.contractPrices?.find(cp => cp.contractRangeMonth === months);
        return contract ? [contract.depositPrice.toLocaleString(), contract.rentPrice.toLocaleString()] : ["", ""];
    };


    // 보험 정보를 반환하는 함수
    const getInsuranceInfo = (row) => {
        // 보험 관련 정보 추출 로직 구현 필요
        return [
            `정비비용의 ${row.rentInfo?.dutyJacha?.toLocaleString()}%` ?? "",
            `최소 ${convertToKoreanWon(row.rentInfo?.indemJachaMin)} ~ 최대 ${convertToKoreanWon(row.rentInfo?.indemJachaMax)}`,
            row.rentInfo?.dutyDaein === -1 ? "무제한" : row.rentInfo?.dutyDaein?.toLocaleString() ?? "",
            row.rentInfo?.indemDaein ? convertToKoreanWon(row.rentInfo?.indemDaein) : "-",
            row.rentInfo?.dutyDaemul ? convertToKoreanWon(row.rentInfo?.dutyDaemul) : "-",
            row.rentInfo?.indemDaemul ? convertToKoreanWon(row.rentInfo?.indemDaemul) : "-",
            row.rentInfo?.dutyJason ? convertToKoreanWon(row.rentInfo?.dutyJason) : "-",
            row.rentInfo?.indemJason ? convertToKoreanWon(row.rentInfo?.indemJason) : "-",
            row.rentInfo?.dutyNoInsuranceInjury ? convertToKoreanWon(row.rentInfo?.dutyNoInsuranceInjury) : "-",
        ];
    };


    // 계약방식을 반환하는 함수
    const getContractType = (contractTypes) => {
        return contractTypes?.map(type => type === "ONLINE" ? "전자계약서" : type === "OFFLINE" ? "방문계약" : type).join(", ") ?? "";
    };

    // 정비상품 이름들을 콤마로 구분하여 문자열로 변환하는 함수
    const getInspectionProductNames = (inspectionProducts) => {
        return inspectionProducts?.map(product => product.name).join(", ") ?? "";
    };

    // 선택 옵션을 문자열로 변환하는 함수
    const getOptionTitles = (carAddOptionContents) => {
        return carAddOptionContents?.map(option =>
            option.carAddOptionContentItems.length === 0
                ? option.name
                : option.carAddOptionContentItems.map(sub_option => sub_option.title).join(", ")
        ).join(", ") ?? "";
    };

    const onDownloadExcel = () => {
        // 상위 헤더와 하위 헤더를 정의합니다.
        const headers = [
            // 첫 번째 행 (상위 헤더)
            [
                "상태", "구분", "차량번호", "모델명", "외장색", "연료", "Km",
                "12개월", "", "24개월", "", "36개월", "", "48개월", "", "60개월", "",
                "계약방식", "탁송비지원", "트림", "선택옵션",
                "대여료 추가 금액", "", "",
                "연간주행 가능거리", "보증금분납",
                "자차", "", "대인", "", "대물", "", "자손", "", "무보험상해", "긴급출동(연)", "정비상품"
            ],
            // 두 번째 행 (하위 헤더)
            [
                "", "", "", "", "", "", "",
                "보증금", "대여료", "보증금", "대여료", "보증금", "대여료", "보증금", "대여료", "보증금", "대여료",
                "", "", "", "",
                "1년미만 추가금액", "23세 추가금액", "21세 추가금액",
                "", "",
                "책임한도", "면책금", "책임한도", "면책금", "책임한도", "면책금", "책임한도", "면책금", "", "", ""
            ]
        ];

        // 데이터 배열을 준비합니다.
        const tableData = data?.data.map((row) => {
            console.log(row);
            return [
                // 1. 상태
                row.shareReleaseStatus === "OK" ? "출고가능" :
                    row.shareReleaseStatus === "IN_10_DAY" ? "10일이내 출고가능" :
                        row.shareReleaseStatus === "IN_5_DAY" ? "5일이내 출고가능" :
                            row.shareReleaseStatus === "IN_CONTRACT" ? "계약중" :
                                row.shareReleaseStatus === "NO" ? "출고불가" : "알 수 없음",
                // 2. 구분
                row.rentInfo?.rentType === "LONG_RENT" ? "장기렌트" :
                    row.rentInfo?.rentType === "SUBSCRIPTION" ? "구독" :
                        row.rentInfo?.rentType === "FIRST_RELEASE" ? "선출고" : "알 수 없음",
                // 3. 차량번호
                row?.carNumber ?? "",
                // 4. 모델명
                row?.modelDetailName ?? "",
                // 5. 외장색
                row?.color ?? "",
                // 6. 연료
                getFuelType(row?.fuelType),
                // 7. Km
                `${row?.mileage?.toLocaleString()}`,
                // 12개월 보증금, 대여료
                ...getContractPrices(row, 12),
                // 24개월 보증금, 대여료
                ...getContractPrices(row, 24),
                // 36개월 보증금, 대여료
                ...getContractPrices(row, 36),
                // 48개월 보증금, 대여료
                ...getContractPrices(row, 48),
                // 60개월 보증금, 대여료
                ...getContractPrices(row, 60),
                // 계약방식
                row.rentInfo?.contractTypes?.map(type =>
                    type === "ONLINE" ? "전자계약서" : type === "OFFLINE" ? "방문계약" : type
                ).join(", ") ?? "",
                // 탁송비지원
                row.rentInfo?.deliverySupportPrice === 0 ? "미지원" : convertToKoreanWon(row.rentInfo?.deliverySupportPrice),
                // 트림
                row?.trimName ?? "",
                // 선택옵션
                getOptionTitles(row.carAddOptionContents),
                // 대여료 추가 금액
                row.rentInfo?.expLt1RentPrice ? convertToKoreanWon(row.rentInfo?.expLt1RentPrice) : "-", // 1년 미만 추가금
                row.rentInfo?.age23RentPrice ? convertToKoreanWon(row.rentInfo?.age23RentPrice) : "-", // 23세 추가금
                row.rentInfo?.age21RentPrice ? convertToKoreanWon(row.rentInfo?.age21RentPrice) : "-", // 21세 추가금
                // 연간주행 가능거리
                row.rentInfo?.yearContractMileage?.toLocaleString() ?? "",
                // 보증금분납
                row.rentInfo?.depositTwiceYn === "Y" ? "가능" : "불가능",
                // 보험 정보
                ...getInsuranceInfo(row),
                // 긴급출동(연)
                `${row.rentInfo?.emergencyCallCount}회` ?? "",
                // 정비상품
                getInspectionProductNames(row.rentInfo?.inspectionProducts),
            ];
        });


        // 워크북 생성
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(headers.concat(tableData));

        // 병합할 셀 범위를 설정합니다.
        const mergeRanges = [
            { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, // '상태'
            { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }, // '구분'
            { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } }, // '차량번호'
            { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } }, // '모델명'
            { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } }, // '외장색'
            { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } }, // '연료'
            { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } }, // 'Km'
            { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } }, // '12개월'
            { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } }, // '24개월'
            { s: { r: 0, c: 11 }, e: { r: 0, c: 12 } }, // '36개월'
            { s: { r: 0, c: 13 }, e: { r: 0, c: 14 } }, // '48개월'
            { s: { r: 0, c: 15 }, e: { r: 0, c: 16 } }, // '60개월'
            { s: { r: 0, c: 17 }, e: { r: 1, c: 17 } }, // '계약방식'
            { s: { r: 0, c: 18 }, e: { r: 1, c: 18 } }, // '탁송비지원'
            { s: { r: 0, c: 19 }, e: { r: 1, c: 19 } }, // '트림'
            { s: { r: 0, c: 20 }, e: { r: 1, c: 20 } }, // '선택옵션'
            { s: { r: 0, c: 21 }, e: { r: 0, c: 23 } }, // '대여료 추가 금액'은 가로 3열 병합
            { s: { r: 0, c: 24 }, e: { r: 1, c: 24 } }, // '연간주행 가능거리'
            { s: { r: 0, c: 25 }, e: { r: 1, c: 25 } }, // '보증금분납'
            { s: { r: 0, c: 26 }, e: { r: 0, c: 27 } }, // '자차' 병합
            { s: { r: 0, c: 28 }, e: { r: 0, c: 29 } }, // '대인' 병합
            { s: { r: 0, c: 30 }, e: { r: 0, c: 31 } }, // '대물' 병합
            { s: { r: 0, c: 32 }, e: { r: 0, c: 33 } }, // '자손' 병합
            { s: { r: 0, c: 34 }, e: { r: 1, c: 34 } }, // '무보험상해' 2열 병합
            { s: { r: 0, c: 35 }, e: { r: 1, c: 35 } }, // '긴급출동(연)'
            { s: { r: 0, c: 36 }, e: { r: 1, c: 36 } }, // '정비상품'
        ];

        // 병합 설정 적용
        ws['!merges'] = mergeRanges;

        // 워크북에 워크시트를 추가하고 파일로 내보냅니다.
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'vehicles_data.xlsx');
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="compareWrap">
                <div className={`compareTit ${isCompareActive ? "active" : ""}`}>
                    <div className="compareDesc">
                        <button type="button" onClick={toggleCompare} className="">
                            비교하기 <FontAwesomeIcon icon={faAngleDown} />
                        </button>
                        <p>최대 3개까지 비교 가능합니다.</p>
                    </div>
                    <div className="compareButtons">
                        <button type="button" className="" onClick={toggleCompare}>
                            닫기
                        </button>
                        <button type="button" className="active" onClick={handleCarCompare}>
                            비교하기
                        </button>
                    </div>
                </div>
                {isCompareActive && (
                    <div className="selectedItems">
                        {compareItems.map((item) => (
                            <div key={item.id} className="item">
                                <div className="itemContents">
                                    <button type="button" className="btnRemove" onClick={() => handleRemoveClick(item.id)}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                    </button>
                                    <span className="itemName">{item.modelName}</span>
                                    <span className="itemDesc">{item.modelDetailName}</span>
                                    <span className="itemDistance">
                                        {item.carMadedYear}.{item.carMadedMonth}/{comma(item.mileage)}km
                                    </span>
                                    <span className="itemRentPrice">
                                        {item.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth}>
                                                {priceInfo.contractRangeMonth}개월&nbsp;&nbsp;
                                                {comma(priceInfo.depositPrice)}원&nbsp;&nbsp;
                                                {comma(priceInfo.rentPrice)}원
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        ))}
                        {compareItems.length < 3 && (
                            <div className="item">
                                <div className="itemNone">
                                    <span>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </span>
                                    <p>비교할 차량을 추가해주세요</p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="tableHead">
                <div className="itemContract">
                    <span>총 {data?.pagination?.totalElements}대</span>
                    <div>
                        <span>계약기간</span>
                        <ul>
                            {["12개월", "24개월", "36개월", "48개월", "60개월"].map((duration) => (
                                <li key={duration}>
                                    <button
                                        type="button"
                                        className={
                                            filters?.contract_range_month_list?.includes(`${duration.replaceAll("개월", "")}`)
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => handleButtonClick(duration)}
                                    >
                                        {duration}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="itemSort">
                    <button type="button" onClick={toggleSortMileage} className={sortMileageStatus ? `active-${sortMileageStatus}` : ""}>
                        주행 거리순
                        {sortMileageStatus === "desc" && <FontAwesomeIcon icon={faArrowUpWideShort} />}
                        {sortMileageStatus === "asc" && <FontAwesomeIcon icon={faArrowUpShortWide} />}
                    </button>
                    <button type="button" onClick={toggleSortExposed} className={sortExposedStatus ? `active-${sortExposedStatus}` : ""}>
                        최근 등록순
                        {sortExposedStatus === "desc" && <FontAwesomeIcon icon={faArrowUpWideShort} />}
                        {sortExposedStatus === "asc" && <FontAwesomeIcon icon={faArrowUpShortWide} />}
                    </button>
                    <button type="button" onClick={onDownloadExcel}>
                        엑셀 다운로드
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                </div>
            </div>

            <div className="tableCont">
                <table>
                    <colgroup>
                        <col style={{ width: "30%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "7%" }} />
                        <col style={{ width: "9%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>모델명/상세모델</th>
                            <th>트림</th>
                            <th>유종</th>
                            <th>외장색</th>
                            <th>주행거리/연식</th>
                            <th>계약기간</th>
                            <th>보증금</th>
                            <th>월렌트료</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.map((row, index) => {
                            // if (!isDurationActive(row.rentInfo.contractPrices)) {
                            //     return null; // activeDurations와 일치하지 않으면 렌더링하지 않음
                            // }
                            return (
                                <TableRow
                                    key={index}
                                    loading={loading}
                                    row={row}
                                    active={index === activeRow}
                                    setActiveRow={setActiveRow}
                                    compareItems={compareItems}
                                    onCompareClick={handleCompareClick}
                                    onClick={() => handleRowClick(index)}
                                    filters={filters}
                                />
                            );
                        })}

                        {data?.data?.length === 0 && (
                            <tr className="none-data">
                                <td colSpan={8} style={{ height: "300px", verticalAlign: "middle" }}>
                                    <div className="tdd tac">조회된 차량이 없습니다.</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="tableFoot">
                <div className="pagination">
                    {data?.data?.length !== 0 && loading === false && (
                        <div className="numList">
                            <div
                                className="btnPrev"
                                onClick={() => {
                                    setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        page: data?.pagination?.prevPage,
                                    }));
                                }}
                                aria-current={data?.pagination?.isFirst ? false : true}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                            <div
                                className="btnNext"
                                onClick={() => {
                                    setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        page: data?.pagination?.nextPage,
                                    }));
                                }}
                                aria-current={data?.pagination?.isLast ? false : true}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                            <ul className="currentPage">
                                {Array(data?.pagination?.endPage)
                                    .fill()
                                    .map(
                                        (_, i) =>
                                            i >= data?.pagination?.startPage - 1 && (
                                                <li
                                                    key={i}
                                                    onClick={() => {
                                                        setFilters((prevFilters) => ({
                                                            ...prevFilters,
                                                            page: i,
                                                        }));
                                                    }}
                                                    className={data?.pagination?.page === i ? "active" : null}
                                                >
                                                    {i + 1}
                                                </li>
                                            )
                                    )}
                            </ul>
                            {data?.pagination?.endPage !== data?.pagination?.totalPages && (
                                <div className="lastPage">
                                    <li
                                        onClick={() => {
                                            setFilters((prevFilters) => ({
                                                ...prevFilters,
                                                page: data?.pagination?.totalPages - 1,
                                            }));
                                        }}
                                    >
                                        {data?.pagination?.totalPages}
                                    </li>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
