/* eslint-disable */
import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { convertPhone } from "util";
import { DaumPost, Modal, Toast } from "components";

export const DetailConsignment = ({ setModal, showModal, handleModalClose, dim, contractId, datas, fetchTable, cancelDelivery }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [btnSubmitStatus, setBtnSubmitStatus] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const [formData, setFormData] = useState({
        srcAddress: "", // 출발지 주소
        srcAddressDetail: "", // 출발지 상세주소
        srcBcode: "", // 출발지 Bcode
        srcPhone: "", // 인계자 연락처
        destAddress: "", // 목적지 주소
        destAddressDetail: "", // 목적지 상세주소
        destBcode: "", // 목적지 Bcode
        destPhone: "", // 인수자 연락처
        managerPhone: "", // 담당자 연락처
        customerPhone: "", // 고객 연락처
    });

    const { srcAddress, srcAddressDetail, srcBcode, destAddress, destAddressDetail, destBcode, srcPhone, destPhone } = formData;

    // 계약 내역
    const fetchDeliveryData = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/${contractId}/delivery_request`);
            console.log(response.data.data);

            setData(response.data.data);
            setFormData((prevState) => ({
                ...prevState,
                srcAddress: response.data.data.srcAddress,
                srcAddressDetail: response.data.data.srcAddressDetail,
                srcBcode: response.data.data.srcBcode,
                srcPhone: response.data.data.srcPhone,
                destAddress: response.data.data.destAddress,
                destAddressDetail: response.data.data.destAddressDetail,
                destBcode: response.data.data.destBcode,
                destPhone: response.data.data.destPhone,
            }));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        if (contractId) {
            fetchDeliveryData();
        }
    }, []);

    const onCancel = () => {
        cancelDelivery(contractId);
    };

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState({
        isOpen: false,
        addressType: "",
    });

    const openPostCode = (addressType) => {
        setAddressPopup({
            isOpen: true,
            addressType: addressType,
        });
    };

    const closePostCode = () => {
        setAddressPopup({
            isOpen: false,
            addressType: "",
        });
    };

    const handleAddress = (data) => {
        console.log(addressPopup);

        if (addressPopup.addressType === "SRC") {
            setFormData((prevState) => ({
                ...prevState,
                srcAddress: data.daumData.address,
                srcAddressDetail: "",
                srcBcode: data.daumData.bcode,
            }));
        } else if (addressPopup.addressType === "DEST") {
            setFormData((prevState) => ({
                ...prevState,
                destAddress: data.daumData.address,
                destAddressDetail: "",
                destBcode: data.daumData.bcode,
            }));
        }
        closePostCode();
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {dim && <div className="modal-layer"></div>}

            {loading === false && (
                <div className="modal modal-type-1 modal-mypage width-555">
                    <div className="modal-title">
                        <span className="modal-tit-main">
                            <span>탁송 정보</span>
                        </span>
                        <button type="button" className="btn-close" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>

                    <div className="modal-content">
                        <div className="consignment_item-list">
                            <div className="item">
                                <div className="item-head">
                                    <span>차량번호</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{data?.carNumber}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>차종</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{data?.carModelDetailName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>출발시간</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{data?.dateTime.replaceAll("T", "")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>출발지</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>
                                            {srcAddress} {srcAddressDetail}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>인계자 연락처</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{srcPhone}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>도착지</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>
                                            {destAddress} {destAddressDetail}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>인수자 연락처</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{destPhone}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-bottom">
                        <button
                            type="button"
                            className="btn-modal"
                            onClick={() => {
                                showModal("", <p>해당 차량의 탁송을 취소하시겠습니까?</p>, [
                                    {
                                        text: "취소",
                                        handler: () => {
                                            handleModalClose();
                                        },
                                    },
                                    {
                                        text: "확인",
                                        handler: () => {
                                            onCancel();
                                        },
                                    },
                                ]);
                            }}
                        >
                            탁송 취소
                        </button>
                    </div>
                </div>
            )}

            {addressPopup.isOpen && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
