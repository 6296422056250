/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { comma, formatDriverLicenseNumber, convertPhone, convertResidentRegistrationNumber, getAgeCategory } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { DaumPost, Toast } from "components";
import { handleNumericInputWithoutComma } from "util";

export const FormStep5 = ({ data, takeoverInfo, setTakeoverInfo }) => {
    const [formData, setFormData] = useState({
        emergencyPhone: takeoverInfo?.emergencyPhone || "",
        emergencyRelationShip: takeoverInfo?.emergencyRelationShip || "",
        address: takeoverInfo?.address || "",
        addressDetail: takeoverInfo?.addressDetail || "",
        bcode: takeoverInfo?.bcode || "",
    });
    const { emergencyPhone, emergencyRelationShip, address, addressDetail, bcode } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    // 스테이트 리프팅
    useEffect(() => {
        setTakeoverInfo(formData);
    }, [formData, setTakeoverInfo]);

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState({
        isOpen: false,
        addressType: "",
    });

    const openPostCode = (addressType) => {
        setAddressPopup({
            isOpen: true,
            addressType: addressType,
        });
    };

    const closePostCode = () => {
        setAddressPopup({
            isOpen: false,
            addressType: "",
        });
    };

    const handleAddress = (data) => {
        console.log(data);
        setFormData((prevState) => ({
            ...prevState,
            address: data.daumData.address,
            addressDetail: "",
            bcode: data.daumData.bcode,
        }));
        closePostCode();
    };

    return (
        <>
            <div className="tab" id="tab-05">
                <div className="item-wrapper border-separation">
                    <div className="item-head">
                        <span>고객 정보</span>
                    </div>
                    <div className="item-list grid-row">
                        <div className="item width-100">
                            <span className="item-tit">성명</span>
                            <span className="item-desc">{data?.mainDriver.name}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">면허번호</span>
                            <span className="item-desc">{formatDriverLicenseNumber(data?.mainDriver.driverLicenseNumber)}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">전화번호</span>
                            <span className="item-desc">{data?.customerPhone}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">주민번호</span>
                            <span className="item-desc">{convertResidentRegistrationNumber(data?.mainDriver.idNumber)}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">주소</span>
                            <span className="item-desc">
                                {data?.customerAddress} {data?.customerAddressDetail}
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">긴급연락처</span>
                            <span className="item-input">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="긴급연락처 입력(가족)"
                                        name="emergencyPhone"
                                        value={emergencyPhone || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInputWithoutComma}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">관계</span>
                            <span className="item-input">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="긴급연락처 관계 입력(가족)"
                                        name="emergencyRelationShip"
                                        value={emergencyRelationShip || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                {data?.drivers.length > 1 && (
                    <div className="item-wrapper border-separation">
                        <div className="item-head">
                            <span>추가 운전자 정보</span>
                        </div>
                        {data?.drivers.map((driver, idx) => {
                            if (driver.mainDriverYn === "Y") return;

                            return (
                                <div className="item-list grid-row" key="idx">
                                    <div className="item width-100">
                                        <span className="item-tit">성명</span>
                                        <span className="item-desc">{driver.name}</span>
                                    </div>
                                    <div className="item width-100">
                                        <span className="item-tit">면허번호</span>
                                        <span className="item-desc">{formatDriverLicenseNumber(driver.driverLicenseNumber)}</span>
                                    </div>
                                    <div className="item width-100">
                                        <span className="item-tit">주민번호</span>
                                        <span className="item-desc">{convertResidentRegistrationNumber(driver.idNumber)}</span>
                                    </div>
                                    <div className="item width-100">
                                        <span className="item-tit">운전연령</span>
                                        <span className="item-desc">{getAgeCategory(driver.idNumber)}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>탁송 정보</span>
                    </div>
                    <div className="item-list grid-row">
                        <div className="item width-100">
                            <span className="item-tit">탁송 비용</span>
                            <span className="item-desc">렌트카사 지원({comma(data?.car.rentInfo.deliverySupportPrice)}원)</span>
                        </div>
                        {/* <div className="item width-100 direction-col">
                            <span className='item-tit'>인수 방법</span>
                            <span className='item-warn'>* 탁송 배송을 통한 인수, 인수 지역에 따라 탁송료가 달라집니다.<br />
                            탁송비는 결제 정보에서 확인 가능합니다</span>
                            <div className="item-button-row">
                                <button type='button' className='btn btn-third btn-large'>방문픽업</button>
                                <button type='button' className='btn btn-primary btn-large'>탁송</button>
                            </div>
                        </div>
                        <div className="item width-100 direction-col">
                            <span className='item-tit'>인수 장소</span>
                            <div className="item-button-row">
                                <button type='button' className='btn btn-third btn-large'>고객정보 주소와 동일</button>
                                <button type='button' className='btn btn-primary btn-large'>다른 주소 입력</button>
                            </div>
                        </div> */}
                        <div className="item width-100 direction-col">
                            <span className="item-tit">인수 주소</span>
                            <span className="item-input">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="주소 검색"
                                        name="address"
                                        value={address || ""}
                                        onChange={handleChange}
                                        onClick={() => {
                                            openPostCode();
                                        }}
                                        readOnly
                                    />
                                    <button
                                        type="button"
                                        className="btn-search_address"
                                        onClick={() => {
                                            openPostCode();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="상세주소 입력"
                                        name="addressDetail"
                                        value={addressDetail || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {addressPopup.isOpen && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}
        </>
    );
};
