/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JSZip from "jszip";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Modal, Toast } from "components";
import { ContractGuide, AdditionalDocs, DetailShare } from "./modal";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { convertToKoreanWon, comma } from "util";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import shareIcon from "resources/icon/share-nodes-solid.svg";
import downloadIcon from "resources/icon/download-solid.svg";

export const ProductDetail = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const navigate = useNavigate();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            if (isLoggedIn === null) {
            } else if (!isLoggedIn) {
                navigate("/login");
            }
        } else {
            hasMounted.current = true;

            if (isLoggedIn === false) {
                navigate("/login");
            }
        }
    }, [isLoggedIn, navigate, hasMounted]);

    const { carId } = useParams();

    // 기존 상태 정의
    const [data, setData] = useState([]); // 상품 정보 상태
    const [loading, setLoading] = useState(true); // 데이터 로딩 상태
    const [modalContractStatus, setModalContractStatus] = useState(false); // 계약요청 모달 상태
    const [modalShareStatus, setModalShareStatus] = useState(false); // 공유하기 팝업

    // 이미지 슬라이더 설정
    const settings = {
        dots: true, // 슬라이더 하단의 도트 표시 여부
        infinite: true, // 무한 슬라이딩 여부
        speed: 500, // 슬라이드 전환 속도
        slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
        slidesToScroll: 1, // 슬라이드 스크롤 수
    };

    // 필터링된 데이터 가져오기
    const fetchProductData = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/share/product/v1/${carId}`);
            console.log(response.data.data);
            setData(response.data.data);
            setLoading(false);
        } catch (error) {
            handleToast(error.response.data.message);
        }
    };

    // 출고 요청
    const fetchReleaseRequest = async () => {
        try {
            const response = await createData(`/api/share/product/v1/${carId}/release_request`);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
        }
    };

    // 출고요청 클릭
    const onRequestRelease = () => {
        fetchReleaseRequest();
    };

    // 계약요청 클릭
    const onRequestContract = () => {
        setModalContractStatus(true);
    };

    const onShare = () => {
        setModalShareStatus(true);
    };

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    // const sample = [
    //     "https://kr.object.ncloudstorage.com/chada/image/20220312/anonymous/anonymous_2_557338000.png",
    //     "https://kr.object.ncloudstorage.com/chada/image/20220312/anonymous/anonymous_2_557338000.png",
    //     "https://kr.object.ncloudstorage.com/chada/image/20220312/anonymous/anonymous_2_557338000.png",
    //     "https://kr.object.ncloudstorage.com/chada/image/20220312/anonymous/anonymous_2_557338000.png",
    //     "https://kr.object.ncloudstorage.com/chada/image/20220312/anonymous/anonymous_2_557338000.png",
    //     // "https://slbqqrroebck10319426.gcdn.ntruss.com/image/20240201/minseo_ag/minseo_ag_202421_132414765094000.png",
    //     // "https://slbqqrroebck10319426.gcdn.ntruss.com/image/20240201/minseo_ag/minseo_ag_202421_132414765094000.png",
    //     // "https://slbqqrroebck10319426.gcdn.ntruss.com/image/20240201/minseo_ag/minseo_ag_202421_132414765094000.png",
    //     // "https://slbqqrroebck10319426.gcdn.ntruss.com/image/20240201/minseo_ag/minseo_ag_202421_132414765094000.png",
    //     // "https://slbqqrroebck10319426.gcdn.ntruss.com/image/20240201/minseo_ag/minseo_ag_202421_132414765094000.png",
    //     // "https://picsum.photos/seed/picsum/900",
    //     // "https://picsum.photos/seed/test/900",
    //     // "https://picsum.photos/seed/hello/900",
    //     // "https://picsum.photos/seed/world/900",
    //     // "https://picsum.photos/seed/bluearchive/900",
    // ];

    const download = ({ fileName, data, url }) => {
        const aTag = document.createElement("a");
        let url_internal = "";
        if (url) {
            url_internal = url;
        } else if (data) {
            if (!fileName) return;
            if (typeof data === "string") {
                url_internal = URL.createObjectURL(new Blob([data]));
            } else {
                url_internal = URL.createObjectURL(data);
            }
        } else return;
        aTag.setAttribute("href", url_internal);
        aTag.setAttribute("download", fileName || "");
        aTag.click();
        URL.revokeObjectURL(url_internal);
    };

    // 차량사진 다운로드
    const downloadImagesAsZip = async () => {
        setLoading(true);
        const zip = new JSZip();

        // carImages 배열에서 URL을 추출하여 사용
        for (let i = 0; i < data?.carImages.length; i++) {
            const imageUrl = data?.carImages[i].objectUrl;
            console.log(`Downloading image from: ${imageUrl}`);
            await axios({
                url: imageUrl,
                method: "GET",
                responseType: "arraybuffer",
                withCredentials: false,
            })
                .then(async ({ data }) => {
                    const filename = `image_${i}.jpg`;
                    zip.file(filename, data, { binary: true });
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
            download({ fileName: `car_images.zip`, data: content });
            setLoading(false);
        });
    };

    useEffect(() => {
        if (carId === undefined) return;

        fetchProductData();
    }, [carId]);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading ? (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            ) : (
                <div className="productDetail container">
                    <div className="detail-info">
                        <div className="detail-info_head">
                            {/* <span className='page-index'>
                                차량리스트 &gt; 장기렌트
                            </span> */}
                            <div className="product-title">
                                <span className="product-name">{data?.modelName}</span>
                                <span className="product-model">
                                    {data?.modelDetailName} {data?.className}&nbsp;{data?.trimName}
                                </span>
                            </div>
                        </div>

                        <div className="detail-info_img">
                            <Slider {...settings} className="custom-slider">
                                {data?.carImages.map((img, idx) => {
                                    return (
                                        <div key={idx} className="slide-item">
                                            <img src={img.url} />
                                        </div>
                                    );
                                })}
                            </Slider>

                            <div className="tags">
                                {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && <span className="tag">만 21세 이상 가능</span>}
                                {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && <span className="tag">만 23세 이상 가능</span>}
                                {data?.rentInfo?.depositTwiceYn === "Y" && <span className="tag">보증금 분납 가능</span>}
                                {data?.rentInfo?.expLt1Yn === "Y" && <span className="tag">운전면허 1년 미만</span>}
                            </div>
                            {/* <button type="button" className="btn-favorite">
                                <FontAwesomeIcon icon={faHeart} />
                            </button> */}
                        </div>

                        <div className="detail-section">
                            <div className="detail-section_tit">
                                <span>차량정보</span>
                            </div>
                            <div className="detail-section_content">
                                <div className="item-list grid-4">
                                    <div className="item">
                                        <div className="item-tit">차량번호</div>
                                        <div className="item-desc">{data?.carNumber}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">주행거리</div>
                                        <div className="item-desc">{comma(data?.mileage)}km</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">연료</div>
                                        <div className="item-desc">
                                            {data?.fuelType === "GAS"
                                                ? "가솔린"
                                                : data?.fuelType === "DIESEL"
                                                ? "디젤"
                                                : data?.fuelType === "LPG"
                                                ? "LPG"
                                                : data?.fuelType === "GAS_LPG"
                                                ? "가솔린(LPG겸용)"
                                                : data?.fuelType === "GAS_CNG"
                                                ? "가솔린(CNG겸용)"
                                                : data?.fuelType === "GAS_HYBRID"
                                                ? "가솔린 하이브리드"
                                                : data?.fuelType === "ELECTRIC"
                                                ? "전기"
                                                : data?.fuelType === "HYDROGEN"
                                                ? "수소"
                                                : "알 수 없음"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">색상</div>
                                        <div className="item-desc">{data?.color}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">신차가격</div>
                                        <div className="item-desc">{comma(data?.carPrice?.newSalePrice || "-")}원</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">연식</div>
                                        <div className="item-desc">
                                            {data?.carMadedYear}.{data?.carMadedMonth}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">배기량</div>
                                        <div className="item-desc">{comma(data?.displacement)}cc</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">변속기</div>
                                        <div className="item-desc">
                                            {data?.motorType === "AUTOMATIC"
                                                ? "자동"
                                                : data?.motorType === "MANUAL"
                                                ? "수동"
                                                : data?.motorType === "CVT"
                                                ? "CVT"
                                                : data?.motorType === "DUAL"
                                                ? "듀얼클러치"
                                                : "알 수 없음"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="detail-section">
                            <div className="detail-section_tit">
                                <span>보증금/렌트료 (만 26세 이상 기준)</span>
                            </div>
                            <div className="detail-section_content none-box">
                                <div className="item-list grid-4 grid-table">
                                    {/* 약정개월 */}
                                    <div className="item">
                                        <div className="item-tit">약정개월</div>
                                        <div className="item-col">
                                            {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                <span key={index} className="col">
                                                    {price.contractRangeMonth}개월
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* 보증금 */}
                                    <div className="item">
                                        <div className="item-tit">보증금</div>
                                        <div className="item-col">
                                            {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                <span key={index} className="col">
                                                    {price.depositPrice.toLocaleString()}원
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* 렌트료 */}
                                    <div className="item">
                                        <div className="item-tit">렌트료</div>
                                        <div className="item-col">
                                            {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                <span key={index} className="col">
                                                    {price.rentPrice.toLocaleString()}원
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* 수수료 */}
                                    <div className="item">
                                        <div className="item-tit">수수료 (VAT 별도)</div>
                                        <div className="item-col">
                                            {data?.rentInfo?.contractPrices?.map((price, index) => (
                                                <span key={index} className="col">
                                                    {price.myComissionPrice.toLocaleString()}원
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="notice">* 만 23세, 만 21세의 경우 렌트료가 추가됩니다.</p>
                        </div>

                        <div className="detail-section">
                            <div className="detail-section_tit">
                                <span>선택 옵션</span>
                            </div>
                            <div className="detail-section_content">
                                <div className="item-head">
                                    {data?.carAddOptionContents.length === 0 ? (
                                        <span>옵션이 추가되지 않은 차량입니다.</span>
                                    ) : (
                                        <span>
                                            {(() => {
                                                const total = data?.carAddOptionContents.reduce((acc, option) => acc + option.price, 0);
                                                const formattedTotal = Math.floor(total / 10000); // 소수점 제거

                                                return <em>{formattedTotal}만원</em>;
                                            })()}
                                            &nbsp;상당의 옵션이 추가된 차량입니다.
                                        </span>
                                    )}
                                    {/* <button type='button' className='btn-catalog'>카탈로그 보기</button> */}
                                </div>
                                <div className="item-list option-list grid-2">
                                    {data?.carAddOptionContents?.map((option, index) => {
                                        if (option.carAddOptionContentItems.length === 0) {
                                            return (
                                                <div key={index} className="item option-item">
                                                    <div className="item-tit">{option.name}</div>
                                                </div>
                                            );
                                        } else {
                                            return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                                return (
                                                    <div key={sub_index} className="item option-item">
                                                        <div className="item-tit">
                                                            <span>{sub_option.title}</span>
                                                            {sub_option.content !== "" && (
                                                                <button type="button">
                                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                                    <div className="guide_popup">
                                                                        {/* <span className="guide_popup-tit">계약 가능 연령</span> */}
                                                                        <p className="guide_popup-desc">{sub_option.content}</p>
                                                                    </div>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            });
                                        }
                                    })}
                                </div>
                            </div>
                            <p className="notice">
                                * 선택옵션의 가격은 해당 옵션의 현재 가격이 아니며, 신차 출고 당시의 옵션 가격을 표시하고 있습니다.
                            </p>
                        </div>

                        <div className="detail-section">
                            <div className="detail-section_tit">
                                <span>계약 조건</span>
                            </div>
                            <div className="detail-section_content">
                                <div className="item-list grid-4">
                                    <div className="item">
                                        <div className="item-tit">탁송비용 지원</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.deliverySupportPrice
                                                ? `렌트카사 지원(${comma(data?.rentInfo?.deliverySupportPrice)}원)`
                                                : "미지원"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">정비상품</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.inspectionProducts?.length !== 0
                                                ? data?.rentInfo?.inspectionProducts?.map((row, idx) => {
                                                      return (
                                                          <>
                                                              <p>{row.name}</p>
                                                          </>
                                                      );
                                                  })
                                                : "미포함"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">계약방식</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.contractTypes.includes("ONLINE") && "전자계약"}
                                            {data?.rentInfo?.contractTypes.includes("ONLINE") &&
                                                data?.rentInfo?.contractTypes.includes("OFFLINE") &&
                                                " / "}
                                            {data?.rentInfo?.contractTypes.includes("OFFLINE") && "방문계약"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">약정 주행가능거리</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.yearContractMileage ? `${comma(data?.rentInfo?.yearContractMileage)}km` : "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="detail-section">
                            <div className="detail-section_tit">
                                <span>보험 조건</span>
                            </div>
                            <div className="detail-section_content">
                                <div className="detail-section_content-head">
                                    <span>책임한도</span>
                                </div>
                                <div className="item-list grid-4">
                                    <div className="item">
                                        <div className="item-tit">무보험 상해</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyNoInsuranceInjury
                                                ? `가입(${convertToKoreanWon(data?.rentInfo?.dutyNoInsuranceInjury)})`
                                                : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대인</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyDaein !== -1
                                                ? `${convertToKoreanWon(data?.rentInfo?.dutyDaein)}`
                                                : "무제한"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대물</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyDaemul ? `${convertToKoreanWon(data?.rentInfo?.dutyDaemul)}` : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">자손(사망)</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyJason ? `${convertToKoreanWon(data?.rentInfo?.dutyJason)}` : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">자차</div>
                                        <div className="item-desc">{`정비 비용의 ${data?.rentInfo?.dutyJacha}%`}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">긴급출동 횟수</div>
                                        <div className="item-desc">{data?.rentInfo?.emergencyCallCount}회</div>
                                    </div>
                                </div>
                            </div>

                            <div className="detail-section_content none-box" style={{ margin: "20px 0" }}>
                                <div className="detail-section_content-head">
                                    <span>면책금</span>
                                    <span className="added-txt">* 보험접수 발생시 부과됩니다.</span>
                                </div>
                                <div className="item-list grid-4 grid-table">
                                    <div className="item" style={{ width: "24%" }}>
                                        <div className="item-tit"></div>
                                        <div className="item-col">
                                            <span className="col color-grey">만 26세 이상</span>
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                <span className="col color-grey">만 23세 이상</span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                <span className="col color-grey">만 21세 이상</span>
                                            )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                <span className="col color-grey">
                                                    면허 1년 미만
                                                    <br />
                                                    (만 26세 기준)
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item" style={{ width: "24%" }}>
                                        <div className="item-tit">대인</div>
                                        <div className="item-col">
                                            <span className="col">{comma(data?.rentInfo?.indemDaein)}원</span>
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age23IndemPrice)}원
                                                </span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age21IndemPrice)}원
                                                </span>
                                            )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.expLt1IndemPrice)}원
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item" style={{ width: "24%" }}>
                                        <div className="item-tit">대물</div>
                                        <div className="item-col">
                                            <span className="col">{comma(data?.rentInfo?.indemDaemul)}원</span>
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age23IndemPrice)}원
                                                </span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age21IndemPrice)}원
                                                </span>
                                            )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                <span className="col">
                                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.expLt1IndemPrice)}원
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item" style={{ width: "28%" }}>
                                        <div className="item-tit">자차</div>
                                        <div className="item-col">
                                            <span className="col">
                                                {`최소 ${convertToKoreanWon(data?.rentInfo?.indemJachaMin)} ~ 최대 ${convertToKoreanWon(
                                                    data?.rentInfo?.indemJachaMax
                                                )}`}
                                            </span>
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                                <span className="col">
                                                    최소{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMin + data?.rentInfo?.age23IndemPrice)} ~
                                                    최대{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMax + data?.rentInfo?.age23IndemPrice)}
                                                </span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                                <span className="col">
                                                    최소{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMin + data?.rentInfo?.age21IndemPrice)} ~
                                                    최대{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMax + data?.rentInfo?.age21IndemPrice)}
                                                </span>
                                            )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && (
                                                <span className="col">
                                                    최소{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMin + data?.rentInfo?.expLt1IndemPrice)} ~
                                                    최대{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMax + data?.rentInfo?.expLt1IndemPrice)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="detail-section_content">
                                <div className="detail-section_content-head">
                                    <span>운전자 범위</span>
                                </div>
                                <div className="item-list flex-column grid-2">
                                    <div className="item">
                                        <div className="item-tit">개인 계약</div>
                                        <div className="item-row">{data?.rentInfo?.personalDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">개인 사업자 계약</div>
                                        <div className="item-row">{data?.rentInfo?.personalBizDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">법인 사업자 계약</div>
                                        <div className="item-row">{data?.rentInfo?.corpDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">구독 계약</div>
                                        <div className="item-row">{data?.rentInfo?.subscriptionDriverRange}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-action">
                        <div className="action-box">
                            <span className="product-name">{data?.modelName}</span> <br />
                            <span className="product-model">
                                {data?.modelDetailName} <br /> {data?.trimName}
                            </span>
                            {data?.shareProductStatus === "PENDING" && (
                                <button type="button" className="btn btn-release" onClick={onRequestRelease}>
                                    출고요청
                                </button>
                            )}
                            {data?.shareProductStatus === "RELEASE_REQUEST" && (
                                <button type="button" className="btn btn-release disabled">
                                    출고요청 확인중
                                </button>
                            )}
                            {data?.shareProductStatus === "SELL" && (
                                <button type="button" className="btn btn-contract" onClick={onRequestContract}>
                                    계약요청
                                </button>
                            )}
                            {data?.shareProductStatus === "CONTRACT_REQUEST" && (
                                <button type="button" className="btn btn-contract" onClick={onRequestContract}>
                                    계약요청
                                </button>
                            )}
                            {data?.shareProductStatus === "CONTRACT_ING" && (
                                <button type="button" className="btn btn-contract disabled">
                                    계약중
                                </button>
                            )}
                            {data?.shareProductStatus === "FINISH" && (
                                <button type="button" className="btn btn-finish disabled">
                                    계약완료
                                </button>
                            )}
                        </div>

                        <div className="action-share" onClick={onShare}>
                            <span>공유하기</span>
                            <span className="action-icon">
                                <img src={shareIcon} />
                            </span>
                        </div>

                        <div className="action-download_img" onClick={downloadImagesAsZip}>
                            <span>차량 사진 다운로드</span>
                            <span className="action-icon">
                                <img src={downloadIcon} />
                            </span>
                        </div>

                        <div className="action-desc">
                            <div className="action-desc_tit">
                                <span>특약</span>
                            </div>
                            <div className="action-desc_body">
                                {data?.rentInfo?.depositTwiceYn === "Y" && (
                                    <p>
                                        <em>• 보증금 2회 분납 가능</em>
                                    </p>
                                )}
                                {data?.rentInfo?.expLt1Yn === "Y" && (
                                    <p>
                                        <em>• 운전면허 1년 미만 계약 가능</em>
                                    </p>
                                )}

                                {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") && (
                                    <p>
                                        <em>• 만 21세 이상 가능</em> <br />
                                        &nbsp;&nbsp;&nbsp;* 월 렌트료 {convertToKoreanWon(data?.rentInfo?.age21RentPrice)} 추가 <br />
                                        &nbsp;&nbsp;&nbsp;* 면책금 기본 + {convertToKoreanWon(data?.rentInfo?.age21IndemPrice)} 추가
                                    </p>
                                )}
                                {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") && (
                                    <p>
                                        <em>• 만 23세 이상 가능</em> <br />
                                        &nbsp;&nbsp;&nbsp;* 월 렌트료 {convertToKoreanWon(data?.rentInfo?.age23RentPrice)} 추가 <br />
                                        &nbsp;&nbsp;&nbsp;* 면책금 기본 + {convertToKoreanWon(data?.rentInfo?.age23IndemPrice)} 추가
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="action-desc">
                            <div className="action-desc_tit">
                                <span>계약 유의사항</span>
                            </div>
                            <div className="action-desc_body">
                                <p>
                                    <em>{data?.rentInfo?.contractNote}</em>
                                </p>
                            </div>
                        </div>

                        <div className="action-desc">
                            <div className="action-desc_tit">
                                <span>구비서류</span>
                            </div>
                            <div className="action-desc_body">
                                <p>
                                    {`- 기본서류(개인)
                                    운전면허증
                                    주민등록등본
                                    주민등록초본
                                    가족관계증명서
                                    본인서명사실확인서
                                    운전경력증명서
                                    통장사본

                                    - 개인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    사업자통장사본

                                    - 법인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    법인등기부등본
                                    법인인감증명서
                                    법인통장사본`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* 계약하기 모달 */}
            {modalContractStatus && <ContractGuide carId={carId} carData={data} setModalContractStatus={setModalContractStatus} />}

            {/* 공유하기 모달 */}
            {modalShareStatus && (
                <DetailShare
                    carData={data}
                    setModalShareStatus={setModalShareStatus}
                    setLoading={setLoading}
                    showModal={showModal}
                    handleModalClose={handleModalClose}
                />
            )}

            {/* 공유하기 url 모달 */}
            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
