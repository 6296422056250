/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Garage, Terms, Insurance, SpecialContract, ContractNote, BankInfo } from "./contract";
import { Toast } from "components";

export const ManagementContract = ({ setModal }) => {
    const [formStatus, setFormStatus] = useState(0);

    const confirmMsg = () => {
        handleToast("저장이 완료되었습니다.");
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-type-2" style={{ minHeight: "400px" }}>
                <div className="modal-title">
                    <span className="modal-tit-main">
                        {formStatus === 0 && <span>계약서 관리</span>}
                        {formStatus === 1 && <span>차고지 설정</span>}
                        {formStatus === 2 && <span>약관 관리</span>}
                        {formStatus === 3 && <span>책임 한도/면책금 관리</span>}
                        {formStatus === 4 && <span>특약 설정</span>}
                        {formStatus === 5 && <span>계약 유의사항 관리</span>}
                        {formStatus === 6 && <span>계좌 관리</span>}
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        {formStatus === 0 && (
                            <div className="contract-manage-list">
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(1)}>
                                    차고지 설정
                                </button>
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(2)}>
                                    약관 관리
                                </button>
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(3)}>
                                    책임한도/면책금 관리
                                </button>
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(4)}>
                                    특약 설정
                                </button>
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(5)}>
                                    계약 유의사항 관리
                                </button>
                                <button type="button" className="btn-contract_option" onClick={() => setFormStatus(6)}>
                                    계좌 관리
                                </button>
                            </div>
                        )}
                        {formStatus === 1 && <Garage setFormStatus={setFormStatus} handleToast={handleToast} />}
                        {formStatus === 2 && <Terms setFormStatus={setFormStatus} handleToast={handleToast} />}
                        {formStatus === 3 && <Insurance setFormStatus={setFormStatus} handleToast={handleToast} />}
                        {formStatus === 4 && <SpecialContract setFormStatus={setFormStatus} handleToast={handleToast} />}
                        {formStatus === 5 && <ContractNote setFormStatus={setFormStatus} handleToast={handleToast} />}
                        {formStatus === 6 && <BankInfo setFormStatus={setFormStatus} handleToast={handleToast} />}
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
