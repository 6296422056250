/* eslint-disable */
import React, { useState, useEffect } from "react";
import { convertToKoreanWon, comma } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const DetailShareStep3 = ({ data, sliderRef, step1Data, step2Data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [filteredServerData, setFilteredServerData] = useState([]);
    useEffect(() => {
        const filteredData = data.rentInfo.contractPrices?.filter((item) =>
            step2Data.contractRangeMonthTypes?.includes(`CONTRACT_${item.contractRangeMonth.toString()}`)
        );

        setFilteredServerData(filteredData);
    }, [data, step2Data]);

    const sumOptionPrice = () => {
        let totalOptionPrice = 0;
        data?.carAddOptionContents?.forEach((element) => {
            totalOptionPrice += element.price;
            //console.log(element);
        });
        return comma(totalOptionPrice);
    };

    return (
        <div className="step">
            {step2Data.shareCarInfoTypes?.includes("CAR_INFO") && (
                <div className="preview-img custom-slider">
                    <Slider ref={sliderRef} {...settings}>
                        {data?.carImages?.map((image) => (
                            <div className="img-item" key={image.id}>
                                <img src={image.url} alt={`Car Image ${image.id}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}

            {step2Data.shareCarInfoTypes?.includes("RENT_PRICE") && (
                <div className="detail-section" style={{ borderBottom: "none" }}>
                    <div className="detail-section_tit">
                        <span>보증금/렌트료</span>
                    </div>
                    <div className="detail-section_content">
                        <div className="item-list grid-3 grid-table">
                            <div className="item">
                                <div className="item-tit">약정개월</div>
                                <div className="item-col">
                                    {filteredServerData.map((item) => (
                                        <span key={item.id} className="col">
                                            {item.contractRangeMonth}개월
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">보증금</div>
                                <div className="item-col">
                                    {filteredServerData.map((item) => {
                                        const ageType = step2Data?.ageType; // "ALL", "AGE_21", "AGE_23", "AGE_26"
                                        let finalPrice = item.depositPrice; // 기본값 설정

                                        // ageType에 따라 가격 계산
                                        // switch (ageType) {
                                        //     case "AGE_21":
                                        //         finalPrice += data?.rentInfo.age21IndemPrice ?? 0; // age21IndemPrice가 존재하면 더함
                                        //         break;
                                        //     case "AGE_23":
                                        //         finalPrice += data?.rentInfo.age23IndemPrice ?? 0; // age23IndemPrice가 존재하면 더함
                                        //         break;
                                        // }

                                        // expLt1Yn가 "Y"이고 data의 expLt1Yn도 "Y"인 경우 추가 비용 반영
                                        // if (step2Data?.expLt1Yn === "Y" && data?.rentInfo.expLt1Yn === "Y") {
                                        //     finalPrice += data?.rentInfo.expLt1IndemPrice ?? 0;
                                        // }

                                        return (
                                            <span key={item.id} className="col">
                                                {comma(finalPrice)}원
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">렌트료</div>
                                <div className="item-col">
                                    {filteredServerData.map((item) => {
                                        const ageType = step2Data?.ageType; // "ALL", "AGE_21", "AGE_23", "AGE_26"
                                        let finalPrice = item.rentPrice; // 기본값 설정

                                        // ageType에 따라 가격 계산
                                        switch (ageType) {
                                            case "AGE_21":
                                                finalPrice += data?.rentInfo.age21RentPrice ?? 0; // age21RentPrice 존재하면 더함
                                                break;
                                            case "AGE_23":
                                                finalPrice += data?.rentInfo.age23RentPrice ?? 0; // age23RentPrice 존재하면 더함
                                                break;
                                        }

                                        // expLt1Yn가 "Y"이고 data의 expLt1Yn도 "Y"인 경우 추가 비용 반영
                                        if (step2Data?.expLt1Yn === "Y" && data?.rentInfo.expLt1Yn === "Y") {
                                            finalPrice += data?.rentInfo.expLt1RentPrice ?? 0;
                                        }

                                        return (
                                            <span key={item.id} className="col">
                                                {comma(finalPrice)}원
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {step2Data.shareCarInfoTypes?.includes("CAR_INFO") && (
                <div className="detail-section">
                    <div className="detail-section_tit">
                        <span>차량정보</span>
                    </div>
                    <div className="detail-section_content">
                        <div className="item-list grid-4">
                            <div className="item">
                                <div className="item-tit">차량번호</div>
                                <div className="item-desc">{data?.carNumber}</div>
                            </div>
                            <div className="item">
                                <div className="item-tit">주행거리</div>
                                <div className="item-desc">{comma(data?.mileage)}km</div>
                            </div>
                            <div className="item">
                                <div className="item-tit">연료</div>
                                <div className="item-desc">
                                    {data?.fuelType === "GAS"
                                        ? "가솔린"
                                        : data?.fuelType === "DIESEL"
                                        ? "디젤"
                                        : data?.fuelType === "LPG"
                                        ? "LPG"
                                        : data?.fuelType === "GAS_LPG"
                                        ? "가솔린(LPG겸용)"
                                        : data?.fuelType === "GAS_CNG"
                                        ? "가솔린(CNG겸용)"
                                        : data?.fuelType === "GAS_HYBRID"
                                        ? "가솔린 하이브리드"
                                        : data?.fuelType === "ELECTRIC"
                                        ? "전기"
                                        : data?.fuelType === "HYDROGEN"
                                        ? "수소"
                                        : "알 수 없음"}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">색상</div>
                                <div className="item-desc">{data?.color}</div>
                            </div>
                            <div className="item">
                                <div className="item-tit">신차가격</div>
                                <div className="item-desc">{comma(data?.carPrice?.newSalePrice || "-")}원</div>
                            </div>
                            <div className="item">
                                <div className="item-tit">연식</div>
                                <div className="item-desc">
                                    {data?.carMadedYear}.{data?.carMadedMonth}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">배기량</div>
                                <div className="item-desc">{comma(data?.displacement)}cc</div>
                            </div>
                            <div className="item">
                                <div className="item-tit">변속기</div>
                                <div className="item-desc">
                                    {data?.motorType === "AUTOMATIC"
                                        ? "자동"
                                        : data?.motorType === "MANUAL"
                                        ? "수동"
                                        : data?.motorType === "CVT"
                                        ? "CVT"
                                        : data?.motorType === "DUAL"
                                        ? "듀얼클러치"
                                        : "알 수 없음"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {step2Data.shareCarInfoTypes?.includes("MAJOR_OPTION") && (
                <div className="detail-section">
                    <div className="detail-section_tit">
                        <span>선택 옵션</span>
                    </div>
                    <div className="detail-section_content">
                        <div className="item-head">
                            {data?.carAddOptionContents.length === 0 ? (
                                <span>옵션이 추가되지 않은 차량입니다.</span>
                            ) : (
                                <span>
                                    {(() => {
                                        const total = data?.carAddOptionContents.reduce((acc, option) => acc + option.price, 0);
                                        const formattedTotal = Math.floor(total / 10000); // 소수점 제거

                                        return <em>{formattedTotal}만원</em>;
                                    })()}
                                    &nbsp;상당의 옵션이 추가된 차량입니다.
                                </span>
                            )}
                        </div>
                        <div className="item-list grid-2" style={{ gap: "8px 0" }}>
                            {data?.carAddOptionContents?.map((option, index) => {
                                if (option.carAddOptionContentItems.length === 0) {
                                    return (
                                        <div
                                            key={index}
                                            className="item option-item"
                                            style={{
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                textAlign: "left",
                                                margin: "none",
                                            }}
                                        >
                                            <div className="item-tit">{option.name}</div>
                                        </div>
                                    );
                                } else {
                                    return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                        return (
                                            <div
                                                key={sub_index}
                                                className="item option-item"
                                                style={{
                                                    alignItems: "flex-start",
                                                    justifyContent: "flex-start",
                                                    textAlign: "left",
                                                    margin: "none",
                                                }}
                                            >
                                                <div className="item-tit">
                                                    <span>{sub_option.title}</span>
                                                    {sub_option.content !== "" && (
                                                        <button type="button">
                                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                                            <div className="guide_popup">
                                                                <p className="guide_popup-desc">{sub_option.content}</p>
                                                            </div>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    });
                                }
                            })}
                        </div>
                    </div>
                    {data?.carAddOptionContents.length !== 0 && (
                        <p className="notice">
                            선택옵션의 가격은 해당 옵션의 현재 가격이 아니며, 신차 출고 당시의 옵션 가격을 표시하고 있습니다.
                        </p>
                    )}
                </div>
            )}

            {step2Data.shareCarInfoTypes?.includes("CONTRACT_CONDITION") && (
                <div className="detail-section">
                    <div className="detail-section_tit">
                        <span>계약 조건</span>
                    </div>
                    <div className="detail-section_content">
                        <div className="item-list grid-3">
                            <div className="item">
                                <div className="item-tit">탁송비용</div>
                                <div className="item-desc">
                                    {data?.rentInfo.deliverySupportPrice
                                        ? `${comma(data?.rentInfo.deliverySupportPrice)}원 지원`
                                        : "미지원"}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">정비상품</div>
                                <div className="item-desc">
                                    {data?.rentInfo?.inspectionProducts.length !== 0
                                        ? data?.rentInfo?.inspectionProducts?.map((row, idx) => {
                                              return (
                                                  <>
                                                      <p>{row.name}</p>
                                                  </>
                                              );
                                          })
                                        : "미포함"}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-tit">계약방식</div>
                                <div className="item-desc">
                                    {data?.rentInfo?.contractTypes.includes("ONLINE") && "전자계약"}
                                    {data?.rentInfo?.contractTypes.includes("ONLINE") &&
                                        data?.rentInfo?.contractTypes.includes("OFFLINE") &&
                                        " / "}
                                    {data?.rentInfo?.contractTypes.includes("OFFLINE") && "방문계약"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="detail-section_content none-box" style={{ margin: "20px 0" }}>
                <div className="detail-section_content-head">
                    <span>면책금</span>
                    <span className="added-txt">* 보험접수 발생시 부과됩니다.</span>
                </div>
                <div className="item-list grid-4 grid-table">
                    <div className="item">
                        <div className="item-tit"></div>
                        <div className="item-col">
                            <span className="col color-grey">만 26세 이상</span>
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                <span className="col color-grey">만 23세 이상</span>
                            }
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                <span className="col color-grey">만 21세 이상</span>
                            }
                            {data?.rentInfo?.expLt1Yn === "Y" &&
                                <span className="col color-grey">
                                    면허 1년 미만
                                    <br />
                                    (만 26세 기준)
                                </span>
                            }
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-tit">대인</div>
                        <div className="item-col">
                            <span className="col">{comma(data?.rentInfo?.indemDaein)}원</span>
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age23IndemPrice)}원
                                </span>
                            }
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age21IndemPrice)}원
                                </span>
                            }
                            {data?.rentInfo?.expLt1Yn === "Y" &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.expLt1IndemPrice)}원
                                </span>
                            }
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-tit">대물</div>
                        <div className="item-col">
                            <span className="col">{comma(data?.rentInfo?.indemDaemul)}원</span>
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age23IndemPrice)}원
                                </span>
                            }
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age21IndemPrice)}원
                                </span>
                            }
                            {data?.rentInfo?.expLt1Yn === "Y" &&
                                <span className="col">
                                    {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.expLt1IndemPrice)}원
                                </span>
                            }
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-tit">자차</div>
                        <div className="item-col">
                            <span className="col">
                                {`최소 ${convertToKoreanWon(data?.rentInfo?.indemJachaMin)} ~ 최대 ${convertToKoreanWon(
                                    data?.rentInfo?.indemJachaMax
                                )}`}</span>
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                <span className="col">
                                    최소 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.age23IndemPrice
                                    )} ~ 최대 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.age23IndemPrice
                                    )}
                                </span>
                            }
                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                <span className="col">
                                    최소 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.age21IndemPrice
                                    )} ~ 최대 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.age21IndemPrice
                                    )}
                                </span>
                            }
                            {data?.rentInfo?.expLt1Yn === "Y" &&
                                <span className="col">
                                    최소 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMin + data?.rentInfo?.expLt1IndemPrice
                                    )} ~ 최대 {convertToKoreanWon(
                                        data?.rentInfo?.indemJachaMax + data?.rentInfo?.expLt1IndemPrice
                                    )}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div> */}

            {step2Data.shareCarInfoTypes?.includes("DUTY_PRICE") &&
                step2Data.shareCarInfoTypes?.includes("INDEM_PRICE") &&
                step2Data.shareCarInfoTypes?.includes("DRIVER_RANGE") && (
                    <div className="detail-section type_insurance">
                        <div className="detail-section_tit">
                            <span>보험 조건</span>
                        </div>

                        {step2Data.shareCarInfoTypes?.includes("DUTY_PRICE") && (
                            <div className="detail-section_content">
                                <div className="detail-section_content-head">
                                    <span>책임한도</span>
                                </div>
                                <div className="item-list grid-5">
                                    <div className="item">
                                        <div className="item-tit">무보험 상해</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyNoInsuranceInjury
                                                ? `가입(${convertToKoreanWon(data?.rentInfo?.dutyNoInsuranceInjury)})`
                                                : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대인</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyDaein !== -1
                                                ? `${convertToKoreanWon(data?.rentInfo?.dutyDaein)}`
                                                : "무제한"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대물</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyDaemul ? `${convertToKoreanWon(data?.rentInfo?.dutyDaemul)}` : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">자손(사망)</div>
                                        <div className="item-desc">
                                            {data?.rentInfo?.dutyJason ? `${convertToKoreanWon(data?.rentInfo?.dutyJason)}` : "미가입"}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">자차</div>
                                        <div className="item-desc">{`정비 비용의 ${data?.rentInfo?.dutyJacha}%`}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step2Data.shareCarInfoTypes?.includes("INDEM_PRICE") && (
                            <div className="detail-section_content">
                                <div className="detail-section_content-head">
                                    <span>면책금</span>
                                    <span className="added-txt">* 보험접수 발생시 부과됩니다.</span>
                                </div>
                                <div className="item-list grid-4 grid-table">
                                    <div className="item">
                                        <div className="item-tit fz-0">&nbsp;</div>
                                        <div className="item-col">
                                            {(step2Data.ageType === "ALL" || step2Data.ageType === "AGE_26") && (
                                                <span className="col color-grey">만 26세 이상</span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_23") && (
                                                    <span className="col color-grey">만 23세 이상</span>
                                                )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_21") && (
                                                    <span className="col color-grey">만 21세 이상</span>
                                                )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && step2Data.expLt1Yn === "Y" && (
                                                <>
                                                    {step2Data.ageType === "ALL" && (
                                                        <span className="col color-grey">
                                                            면허 1년 미만
                                                            <br />
                                                            (만 26세 기준)
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_26" && (
                                                        <span className="col color-grey">
                                                            면허 1년 미만
                                                            <br />
                                                            (만 26세 기준)
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_23" && (
                                                        <span className="col color-grey">
                                                            면허 1년 미만
                                                            <br />
                                                            (만 23세 기준)
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_21" && (
                                                        <span className="col color-grey">
                                                            면허 1년 미만
                                                            <br />
                                                            (만 21세 기준)
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대인</div>
                                        <div className="item-col">
                                            {(step2Data.ageType === "ALL" || step2Data.ageType === "AGE_26") && (
                                                <span className="col">{comma(data?.rentInfo?.indemDaein)}원</span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_23") && (
                                                    <span className="col">
                                                        {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age23IndemPrice)}원
                                                    </span>
                                                )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_21") && (
                                                    <span className="col">
                                                        {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.age21IndemPrice)}원
                                                    </span>
                                                )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && step2Data.expLt1Yn === "Y" && (
                                                <>
                                                    {step2Data.ageType === "ALL" && (
                                                        <span className="col">
                                                            {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.expLt1IndemPrice)}원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_26" && (
                                                        <span className="col">
                                                            {comma(data?.rentInfo?.indemDaein + data?.rentInfo?.expLt1IndemPrice)}원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_23" && (
                                                        <span className="col">
                                                            {comma(
                                                                data?.rentInfo?.indemDaein +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age23IndemPrice
                                                            )}
                                                            원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_21" && (
                                                        <span className="col">
                                                            {comma(
                                                                data?.rentInfo?.indemDaein +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age21IndemPrice
                                                            )}
                                                            원
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">대물</div>
                                        <div className="item-col">
                                            {(step2Data.ageType === "ALL" || step2Data.ageType === "AGE_26") && (
                                                <span className="col">{comma(data?.rentInfo?.indemDaemul)}원</span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_23") && (
                                                    <span className="col">
                                                        {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age23IndemPrice)}원
                                                    </span>
                                                )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_21") && (
                                                    <span className="col">
                                                        {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.age21IndemPrice)}원
                                                    </span>
                                                )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && step2Data.expLt1Yn === "Y" && (
                                                <>
                                                    {step2Data.ageType === "ALL" && (
                                                        <span className="col">
                                                            {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.expLt1IndemPrice)}원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_26" && (
                                                        <span className="col">
                                                            {comma(data?.rentInfo?.indemDaemul + data?.rentInfo?.expLt1IndemPrice)}원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_23" && (
                                                        <span className="col">
                                                            {comma(
                                                                data?.rentInfo?.indemDaemul +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age23IndemPrice
                                                            )}
                                                            원
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_21" && (
                                                        <span className="col">
                                                            {comma(
                                                                data?.rentInfo?.indemDaemul +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age21IndemPrice
                                                            )}
                                                            원
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">자차</div>
                                        <div className="item-col">
                                            {(step2Data.ageType === "ALL" || step2Data.ageType === "AGE_26") && (
                                                <span className="col">
                                                    최소 {convertToKoreanWon(data?.rentInfo?.indemJachaMin)} ~ <br /> 최대{" "}
                                                    {convertToKoreanWon(data?.rentInfo?.indemJachaMax)}
                                                </span>
                                            )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_23") && (
                                                    <span className="col">
                                                        최소{" "}
                                                        {convertToKoreanWon(
                                                            data?.rentInfo?.indemJachaMin + data?.rentInfo?.age23IndemPrice
                                                        )}{" "}
                                                        ~ <br /> 최대{" "}
                                                        {convertToKoreanWon(
                                                            data?.rentInfo?.indemJachaMax + data?.rentInfo?.age23IndemPrice
                                                        )}
                                                    </span>
                                                )}
                                            {data?.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                                (step2Data.ageType === "ALL" || step2Data.ageType === "AGE_21") && (
                                                    <span className="col">
                                                        최소{" "}
                                                        {convertToKoreanWon(
                                                            data?.rentInfo?.indemJachaMin + data?.rentInfo?.age21IndemPrice
                                                        )}{" "}
                                                        ~ <br /> 최대{" "}
                                                        {convertToKoreanWon(
                                                            data?.rentInfo?.indemJachaMax + data?.rentInfo?.age21IndemPrice
                                                        )}
                                                    </span>
                                                )}
                                            {data?.rentInfo?.expLt1Yn === "Y" && step2Data.expLt1Yn === "Y" && (
                                                <>
                                                    {step2Data.ageType === "ALL" && (
                                                        <span className="col">
                                                            최소{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMin + data?.rentInfo?.expLt1IndemPrice
                                                            )}{" "}
                                                            ~ <br /> 최대{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMax + data?.rentInfo?.expLt1IndemPrice
                                                            )}
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_26" && (
                                                        <span className="col">
                                                            최소{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMin + data?.rentInfo?.expLt1IndemPrice
                                                            )}{" "}
                                                            ~ <br /> 최대{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMax + data?.rentInfo?.expLt1IndemPrice
                                                            )}
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_23" && (
                                                        <span className="col">
                                                            최소{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMin +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age23IndemPrice
                                                            )}{" "}
                                                            ~ <br /> 최대{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMax +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age23IndemPrice
                                                            )}
                                                        </span>
                                                    )}
                                                    {step2Data.ageType === "AGE_21" && (
                                                        <span className="col">
                                                            최소{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMin +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age21IndemPrice
                                                            )}{" "}
                                                            ~ <br /> 최대{" "}
                                                            {convertToKoreanWon(
                                                                data?.rentInfo?.indemJachaMax +
                                                                    data?.rentInfo?.expLt1IndemPrice +
                                                                    data?.rentInfo?.age21IndemPrice
                                                            )}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step2Data.shareCarInfoTypes?.includes("DRIVER_RANGE") && (
                            <div className="detail-section_content">
                                <div className="detail-section_content-head">
                                    <span>운전자 범위</span>
                                </div>
                                <div className="item-list driver_range">
                                    <div className="item">
                                        <div className="item-tit">개인 계약</div>
                                        <div className="item-txt">{data?.rentInfo?.personalDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">개인 사업자 계약</div>
                                        <div className="item-txt">{data?.rentInfo?.personalBizDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">법인 사업자 계약</div>
                                        <div className="item-txt">{data?.rentInfo?.corpDriverRange}</div>
                                    </div>
                                    <div className="item">
                                        <div className="item-tit">구독 계약</div>
                                        <div className="item-txt">{data?.rentInfo?.subscriptionDriverRange}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

            {step2Data.shareCarInfoTypes?.includes("CONTRACT_DOCUMENT") && (
                <div className="detail-section">
                    <div className="detail-section_tit">
                        <span>구비서류</span>
                    </div>
                    <div className="detail-desc">
                        <p>
                            {`- 기본서류(개인)
                                    운전면허증
                                    주민등록등본
                                    주민등록초본
                                    가족관계증명서
                                    본인서명사실확인서
                                    운전경력증명서
                                    통장사본

                                    - 개인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    사업자통장사본

                                    - 법인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    법인등기부등본
                                    법인인감증명서
                                    법인통장사본`}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};
