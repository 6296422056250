/* eslint-disable */
import React, { useState, useEffect } from "react";

export const DetailShareStep1 = ({ initData, onStep1Change }) => {
    const [step1Data, setStep1Data] = useState({
        customerName: initData?.customerName || "",
        customerPhone: initData?.customerPhone || "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStep1Data((prev) => ({ ...prev, [name]: value }));
        onStep1Change({ ...step1Data, [name]: value });
    };

    return (
        <div className="step">
            <div className="form">
                <div className="input-form">
                    <label className="form-title">
                        성함 <em>*</em> <i>(공유 받으시는 분의 성함을 입력해주세요.)</i>
                    </label>
                    <div className="form-field">
                        <div className="input-element">
                            <input
                                type="text"
                                name="customerName"
                                value={step1Data.customerName}
                                onChange={handleChange}
                                placeholder="성함 입력"
                            />
                        </div>
                    </div>
                </div>
                <div className="input-form">
                    <label className="form-title">
                        연락처 <em>*</em> <i>(공유 받으시는 분의 연락처를 입력해주세요.)</i>
                    </label>
                    <div className="form-field">
                        <div className="input-element">
                            <input
                                type="text"
                                name="customerPhone"
                                value={step1Data.customerPhone}
                                onChange={handleChange}
                                placeholder="-없이 입력"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
