/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SubscribeEstimate, NewCarEstimate } from "./modal";

import styles from "./Header.module.scss";
import searchIcon from "resources/icon/search-pr.svg";

const Header = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    // const { isLoggedIn, role, grade } = useSelector((state) => state.login);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn); // Redux 스토어에서 로그인 상태 가져오기
    const userRole = useSelector((state) => state.login.userData?.roles);
    const isSupplierCorp = useSelector((state) => state.login.userData?.roles.includes("RENT_MASTER"));

    const [pathname, setPathname] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        navigate(`/product/list?search_keyword=${encodeURIComponent(searchTerm)}`, { replace: true });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    useEffect(() => {
        const searchKeyword = searchParams.get("search_keyword");

        if (searchKeyword) setSearchTerm(searchKeyword);
    }, [location.search]);

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname) setPathname(pathname);
    }, [location]);

    const [subscribeEstimateModalStatus, setSubscribeEstimateModalStatus] = useState(false);
    const [newCarEstimateModalStatus, setNewCarEstimateModalStatus] = useState(false);

    const requestSubscribeEstimate = () => {
        setSubscribeEstimateModalStatus(true);
    };

    const requestNewCarEstimate = () => {
        setNewCarEstimateModalStatus(true);
    };

    return (
        <>
            <header>
                <div className={styles.headerInner}>
                    <div className={styles.headerLeft}>
                        <Link to={"/"} className={styles.logo}>
                            <img src="/images/share-logo.png" alt="" />
                        </Link>
                        {!isSupplierCorp && (
                            <ul className={styles.gnb}>
                                <li className={pathname === "/product/list" ? `${styles.active}` : ""}>
                                    <Link to={"/product/list"}>차량리스트</Link>
                                </li>
                                <li>
                                    <button type="button" onClick={requestSubscribeEstimate}>
                                        구독견적요청
                                    </button>
                                </li>
                                <li>
                                    <button type="button" onClick={requestNewCarEstimate}>
                                        신차견적요청
                                    </button>
                                </li>
                                {/* <li>
                                    <Link>가이드북</Link>
                                </li> */}
                            </ul>
                        )}
                    </div>
                    <div className={styles.headerRight}>
                        {!isSupplierCorp && (
                            <div className={styles.gsb}>
                                <div className={styles.inputSearch}>
                                    <input
                                        type="text"
                                        placeholder="모델명을 입력하세요."
                                        onChange={handleSearchInputChange}
                                        onKeyPress={handleKeyPress}
                                        value={searchTerm}
                                    />
                                    <button type="button" onClick={handleSearch}>
                                        <img src={searchIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                        )}
                        <ul className={styles.gob}>
                            <li className={`${styles.user} ${isLoggedIn ? styles.active : ""}`}>
                                <button type="button">
                                    <svg
                                        id="PersonGr"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.986"
                                        height="23.986"
                                        viewBox="0 0 23.986 23.986"
                                    >
                                        <g id="그룹_74952" data-name="그룹 74952" transform="translate(-212.746 -225.808)">
                                            <g id="그룹_74948" data-name="그룹 74948" transform="translate(220.501 228.794)">
                                                <path
                                                    id="패스_6472"
                                                    data-name="패스 6472"
                                                    fill="#bfbfbf"
                                                    d="M224.733 237.283a4.244 4.244 0 1 1 4.245-4.244 4.249 4.249 0 0 1-4.245 4.244zm0-6.889a2.645 2.645 0 1 0 2.646 2.645 2.647 2.647 0 0 0-2.646-2.645z"
                                                    transform="translate(-220.489 -228.794)"
                                                />
                                            </g>
                                            <path
                                                id="패스_6473"
                                                data-name="패스 6473"
                                                fill="#bfbfbf"
                                                d="M227.049 240.106a5.123 5.123 0 0 1 5.115 5.069l-14.816.019a5.1 5.1 0 0 1 5.1-5.088h4.605m0-1.6h-4.605a6.692 6.692 0 0 0-6.7 6.688 1.581 1.581 0 0 0 1.582 1.581h14.836a1.581 1.581 0 0 0 1.582-1.581 6.692 6.692 0 0 0-6.7-6.688z"
                                                transform="translate(0 .019)"
                                            />
                                        </g>
                                    </svg>
                                </button>
                                <div className={styles.modalMypage}>
                                    {isLoggedIn ? (
                                        // 로그인 상태
                                        <>
                                            {userRole?.includes("AG_MASTER") && <Link to="/mypage/master">마이페이지</Link>}
                                            {userRole?.includes("AG_DEALER") && <Link to="/mypage/dealer">마이페이지</Link>}
                                            {userRole?.includes("RENT_MASTER") && <Link to="/mypage/supplier">마이페이지</Link>}
                                            <Link to="/logout">로그아웃</Link>
                                        </>
                                    ) : (
                                        // 로그아웃 상태
                                        <>
                                            <Link to={"/login"}>로그인</Link>
                                            <Link to={"/join"}>회원가입</Link>
                                        </>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>

            {subscribeEstimateModalStatus && <SubscribeEstimate setModal={setSubscribeEstimateModalStatus} />}
            {newCarEstimateModalStatus && <NewCarEstimate setModal={setNewCarEstimateModalStatus} />}
        </>
    );
};

export default Header;
