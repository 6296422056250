/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import doneIcon from "resources/icon/done-pr.svg";

import styles from "./YourId.module.scss";

export const YourId = () => {
  const location = useLocation();
  const [id, setId] = useState("");

  useEffect(() => {
    console.log(location.state);
    if (location.state != null) {
      setId(location.state.loginId);
    }
  }, []);

  return (
    <>
      <div className={`${styles.yourId}` + " global-width"}>
        <div className={styles.yourId__logo}>
          <img src={doneIcon} alt="" />
        </div>
        <div className={`${styles.yourId__mainTxt}` + " heading-2 fw-medium"}>
          해당 번호로 가입된 아이디는 <br />
          <em>{id}</em> 입니다
        </div>
        <p className={`${styles.yourId__subTxt}` + " pm"}>비밀번호가 기억이 안나신다면 비밀번호 찾기를 진행하세요.</p>

        <div className={`${styles.yourId__buttons}` + " button-form"}>
          <Link to="/findPw" className="btn btn-xl btn--primary">
            비밀번호 찾기
          </Link>
          <Link to="/login" className="btn btn-xl btn--outline_gray">
            로그인 하기
          </Link>
        </div>
      </div>
    </>
  );
};
