/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import {
    handleNumericInputWithoutComma,
    limitInputMaxLength,
    comma,
    handleNumericInput,
    getDepositCountList,
    calculateKoreanAge,
    getAgeCategory,
} from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faRotateRight, faMagnifyingGlass, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const ContractStep1 = ({
    customerInfo,
    setCustomerInfo,
    checkAuthStatus,
    setCheckAuthStatus,
    confirmAuthStatus,
    setConfirmAuthStatus,
    handleToast,
}) => {
    const [authId, setAuthId] = useState(null);
    const [formData, setFormData] = useState({
        name: customerInfo?.name || "",
        idNumberF: customerInfo?.idNumberF || "",
        idNumberB: customerInfo?.idNumberB || "",
        phone: customerInfo?.phone || "",
        authNum: customerInfo?.authNum || "",
        mobileType: customerInfo?.mobileType || "",
    });
    const { name, phone, authNum, mobileType, idNumberF, idNumberB } = formData;

    // 통신사
    const mobileTypeOptions = [
        { value: "KT", label: "KT" },
        { value: "SKT", label: "SKT" },
        { value: "LG", label: "LG" },
    ];

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    // 스테이트 리프팅
    useEffect(() => {
        setCustomerInfo(formData);
    }, [formData, setCustomerInfo]);

    // 인증번호 요청
    const requestAuthNum = async () => {
        if (name === "") {
            handleToast("성함을 입력해주세요.");
            return;
        } else if (idNumberF === "" || idNumberB === "") {
            handleToast("주민번호 앞 7자리를 입력해주세요.");
            return;
        } else if (mobileType === "") {
            handleToast("통신사를 선태해주세요.");
            return;
        } else if (phone === "") {
            handleToast("휴대폰 번호를 입력해주세요.");
            return;
        }

        try {
            const response = await createData("/api/share/auth/v1/nice/phone/request", {
                idNumber: idNumberF + idNumberB,
                mobileType: mobileType,
                phone: phone,
                name: name,
            });

            handleToast(response.data.message);
            setAuthId(response.data.data.id);
            setCheckAuthStatus(true);
        } catch (error) {
            console.log(error);
            handleToast(error.response.data.message);
            setCheckAuthStatus(false);
            return false;
        }
    };

    // 인증번호 확인
    const checkAuthNum = async () => {
        try {
            const response = await createData("/api/share/auth/v1/nice/phone/verify", {
                id: authId,
                authNum: authNum,
            });

            handleToast(response.data.message);
            setConfirmAuthStatus(true);
        } catch (error) {
            handleToast(error.response.data.message);
            setConfirmAuthStatus(false);
            return false;
        }
    };

    return (
        <>
            <section id="section-01">
                <div className="content-tit" style={{ marginTop: "0" }}>
                    <h6>본인확인</h6>
                    <p>본인 확인을 위해 성함과 휴대폰번호를 입력해주세요.</p>
                </div>

                <div className="form">
                    <div className="input-form">
                        <label className="form-title">성함</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input type="text" placeholder="성함 입력" name="name" value={name || ""} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <div className="form-title">
                            <span className="fz16">주민번호 앞 7자리</span>
                        </div>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="number"
                                    name="idNumberF"
                                    className=""
                                    placeholder="ex) 901201"
                                    onChange={handleChange}
                                    value={idNumberF}
                                    onInput={limitInputMaxLength}
                                    maxLength="6"
                                />
                            </div>
                            <div className="input-element">
                                <label className="idNumberLabel">
                                    <input
                                        type="number"
                                        name="idNumberB"
                                        className=""
                                        placeholder=""
                                        onChange={handleChange}
                                        value={idNumberB}
                                        onInput={limitInputMaxLength}
                                        maxLength="1"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <div className="form-title">
                            <span className="fz16">통신사</span>
                        </div>
                        <div className="form-field">
                            <div className="select-element">
                                <Select
                                    className="custom-select"
                                    classNamePrefix="custom-select"
                                    options={mobileTypeOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    closeMenuOnSelect={true}
                                    placeholder="통신사 선택"
                                    onChange={(e) => {
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            mobileType: e.value,
                                        }));
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">휴대폰 번호</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder="-없이 입력"
                                    name="phone"
                                    value={phone || ""}
                                    onChange={handleChange}
                                    onInput={handleNumericInputWithoutComma}
                                />
                            </div>
                            {!checkAuthStatus && (
                                <button type="button" className="btn btn-primary btn-small" onClick={requestAuthNum}>
                                    인증번호 요청
                                </button>
                            )}
                        </div>
                        {checkAuthStatus && (
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        placeholder="6자리 인증번호 입력"
                                        name="authNum"
                                        value={authNum || ""}
                                        onChange={handleChange}
                                        onInput={handleNumericInputWithoutComma}
                                    />
                                </div>
                                <button type="button" className="btn btn-primary btn-small" onClick={requestAuthNum}>
                                    재발송
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="button-row">
                        <button type="button" className="btn btn-primary btn-large" disabled={!checkAuthStatus} onClick={checkAuthNum}>
                            인증번호 확인
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};
