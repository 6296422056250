import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma, uncomma, handleNumericInput } from "util";
import Select from "react-select";

export const BankInfo = ({ setFormStatus, handleToast }) => {
    const [bankList, setBankList] = useState(null);
    const [formData, setFormData] = useState({
        bankId: "",
        bankName: "",
        bankAccount: "",
        bankOwner: "",
    });

    const { bankId, bankName, bankAccount, bankOwner } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                bankId: formData.bankId,
                bankAccount: formData.bankAccount,
                bankOwner: formData.bankOwner,
            };
            console.log(payload);

            const response = await updateData(`/api/share/rent_corp/contract/v1/bank_info`, payload);
            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchBankList = async () => {
        try {
            const response = await fetchData(`/api/share/bank/v1`);
            setBankList(response.data.data.map((bank) => ({ value: bank.id, label: bank.name })));

            return true;
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
            return false;
        }
    };

    const fetchContractData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            const contractData = response.data.data;
            const selectedBank = bankList?.find((bank) => bank.label === contractData.refundBankName);

            setFormData({
                bankId: selectedBank ? selectedBank.value : "",
                bankName: selectedBank ? selectedBank.value : "",
                bankAccount: contractData.refundBankAccount || "",
                bankOwner: contractData.refundBankOwner || "",
            });
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const initialize = async () => {
        const result = await fetchBankList();
    };

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (bankList) {
            fetchContractData();
        }
    }, [bankList]);

    return (
        <>
            <div className="consignment_item-list">
                <div className="item">
                    <div className="item-head">
                        <span>은행명</span>
                    </div>
                    <div className="item-content">
                        <div className="select-element width-100">
                            <Select
                                options={bankList}
                                className="custom-select"
                                classNamePrefix="custom-select"
                                isSearchable={false}
                                placeholder="은행 선택"
                                value={bankList?.filter((option) => option.value === bankId)}
                                onChange={(option) => handleChange({ option, name: "bankId" })}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>계좌번호</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element">
                            <input
                                type="text"
                                name="bankAccount"
                                value={bankAccount || ""}
                                onChange={handleChange}
                                placeholder="계좌번호 입력"
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>예금주</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element">
                            <input type="text" name="bankOwner" value={bankOwner || ""} onChange={handleChange} placeholder="예금주 입력" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>
        </>
    );
};
