/* eslint-disable */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export const FilterItem = ({ title, icon, children, dropdown }) => {
    // 드롭다운 상태를 관리하기 위한 state 변수와 setter 함수
    const [isDropdownOpen, setIsDropdownOpen] = useState(dropdown);

    // 드롭다운 상태를 전환하는 함수
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className={`filterItem ${isDropdownOpen ? "open" : "closed"}`}>
            {title && (
                <div className="itemTit">
                    <span>
                        {title}
                        {title === "계약 연령" && (
                            <button type="button">
                                <FontAwesomeIcon icon={icon} />
                                <div className="guide_popup">
                                    <p className="guide_popup-desc">계약 연령에 따라 렌트료 및 면책금이 변경될 수 있습니다.</p>
                                </div>
                            </button>
                        )}
                    </span>
                    <button type="button" onClick={toggleDropdown}>
                        <FontAwesomeIcon icon={isDropdownOpen ? faAngleUp : faAngleDown} />
                    </button>
                </div>
            )}
            {isDropdownOpen && <div className="itemContent">{children}</div>}
        </div>
    );
};
