/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ContractStep1, ContractStep2, ContractStep3 } from "./contractStep";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { Toast } from "components";
import warningIcon from "resources/icon/warning.png";
import confirmIcon from "resources/icon/done-pr.svg";

export const Contract = () => {
    const { contractId } = useParams();
    const signatureRef = useRef();
    const [signInfo, setSignInfo] = useState(null);
    const [signStatus, setSignStatus] = useState([false, false, false, false, false, false]);
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [checkAuthStatus, setCheckAuthStatus] = useState(false);
    const [confirmAuthStatus, setConfirmAuthStatus] = useState(false);
    const [termCheckedStatus, setTermCheckedStatus] = useState(false);
    const [contractStatus, setContractStatus] = useState(false);

    const [customerInfo, setCustomerInfo] = useState();
    const [takeoverInfo, setTakeoverInfo] = useState();
    const [submittedFiles, setSubmittedFiles] = useState([]);

    const fetchContract = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/customer/v1/contract/${contractId}`);
            setData(response.data?.data);
            setLoading(false);
        } catch (error) {
            setData(false);
            setLoading(false);
            handleToast(error.response.data?.message, Infinity);
        }
    };

    const submitContract = async () => {
        setLoading(true);

        try {
            let uploads = [];
            for (const item of submittedFiles) {
                for (const file of item.files) {
                    const formData = new FormData();
                    formData.append("file", file);

                    const response = await createFormData("/api/share/upload/v1/image", formData);
                    const uploadId = response.data.data.id; // 서버에서 반환된 uploadId

                    uploads.push({
                        uploadId: uploadId,
                        contractUploadType: item.type,
                    });
                }
            }

            const requestData = {
                customerName: customerInfo.name,
                customerPhone: customerInfo.phone,
                customerSignUploadId: signInfo.id,
                emergencyCallNumber: takeoverInfo.emergencyPhone,
                emergencyRelation: takeoverInfo.emergencyRelationShip,
                delivery: {
                    bcode: takeoverInfo.bcode,
                    address: takeoverInfo.address,
                    addressDetail: takeoverInfo.addressDetail,
                },
                contractUploads: uploads,
            };

            const response = await createData(`/api/customer/v1/contract/${contractId}/sign`, requestData);

            handleToast(response.data.message);
            setContractStatus(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setContractStatus(false);
            handleToast(error.response.data?.message, Infinity);
        }
    };

    useEffect(() => {
        if (contractId === undefined) return;

        fetchContract();
    }, [contractId]);

    // 서명 터치
    const touchSign = () => {
        setSignStatus((prevStatus) => {
            const newStatus = [...prevStatus];
            newStatus[tabIndex - 2] = true;
            return newStatus;
        });
    };

    // 서명 초기화 함수
    const clearSignature = () => {
        if (signatureRef.current.isEmpty()) {
            handleToast("서명을 입력해주세요.");
            return;
        }

        signatureRef.current.clear();
    };

    // 이미지 저장 및 API 전송
    const saveSign = async () => {
        const type = "CONVERT_FILE"; // 파일 download 혹은 file 객체로 변환
        console.log(signatureRef.current.isEmpty());
        if (signatureRef.current.isEmpty()) {
            handleToast("서명을 입력해주세요.");
            return;
        }
        if (signatureRef.current.getTrimmedCanvas()?.width <= 10 && signatureRef.current.getTrimmedCanvas()?.height <= 10) {
            handleToast("서명을 입력해주세요.");
            return;
        }

        const dataURL = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");

        if (type === "DOWNLOAD") {
            downloadImage(dataURL);
        } else if (type === "CONVERT_FILE") {
            await requestSignImgId(dataURL); // 사인 이미지 전달 / response ID값 저장
        }
    };

    // 이미지 다운로드
    const downloadImage = (dataURL) => {
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "sign.png";
        link.click();
    };

    // 서명 이미지 id값 반환
    const requestSignImgId = async (dataURL) => {
        const blobBin = atob(dataURL.split(",")[1]); // base64 데이터 디코딩
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], { type: "image/png" }); // Blob 생성
        const imgFiles = blobToFile(blob);

        uploadImageData(imgFiles);
    };

    // 이미지 파일을 FormData로 변환
    const blobToFile = (blob) => {
        const fileName = "sign.png"; // 파일명 설정
        const file = new File([blob], fileName, { type: "image/png" }); // File 객체 생성
        return file;
    };

    // 이미지 데이터를 서버에 업로드
    const uploadImageData = async (imgFiles) => {
        const fd = new FormData(); // formData 생성
        fd.append("file", imgFiles); // file data 추가

        try {
            const response = await createFormData("/api/share/upload/v1/image", fd);
            setSignInfo(response.data.data);
            handleToast(response.data.message);
        } catch (error) {
            setSignInfo(null);
            handleToast(error.response.data.message);
        }
    };

    const prevStep = () => {
        if (tabIndex <= 0) return;

        setTabIndex(tabIndex - 1);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 10);
    };

    const nextStep = () => {
        // 계약서 제출
        if (tabIndex === 7) {
            const isDriverLicenseEmpty = submittedFiles?.some((item) => item.type === "DRIVER_LICENSE" && item.files.length === 0);

            if (isDriverLicenseEmpty) {
                handleToast("운전면허증을 첨부해주세요.");
                return;
            } else {
                submitContract();
                return;
            }
        }

        // 계약서 계속해서 작성
        if (tabIndex === 0) {
            if (confirmAuthStatus === false) {
                handleToast("핸드폰 인증을 완료해주세요.");
                return;
            }
        } else if (tabIndex === 1) {
            if (termCheckedStatus === false) {
                handleToast("약관에 동의해주세요.");
                return;
            }
            if (signInfo === null) {
                handleToast("서명을 저장해주세요.");
                return;
            }
        } else if (tabIndex === 2) {
            if (signStatus[tabIndex - 2] === false) {
                handleToast("서명을 터치해주세요.");
                return;
            }
        } else if (tabIndex === 3) {
            if (signStatus[tabIndex - 2] === false) {
                handleToast("서명을 터치해주세요.");
                return;
            }
        } else if (tabIndex === 4) {
            if (signStatus[tabIndex - 2] === false) {
                handleToast("서명을 터치해주세요.");
                return;
            }
        } else if (tabIndex === 5) {
            if (signStatus[tabIndex - 2] === false) {
                handleToast("서명을 터치해주세요.");
                return;
            }

            if (!takeoverInfo.emergencyPhone) {
                handleToast("긴급연락처를 입력해주세요.");
                return;
            } else if (!takeoverInfo.emergencyRelationShip) {
                handleToast("긴급연락처 관계를 입력해주세요.");
                return;
            } else if (!takeoverInfo.address) {
                handleToast("인수 주소를 입력해주세요.");
                return;
            }
        } else if (tabIndex === 6) {
            if (signStatus[tabIndex - 2] === false) {
                handleToast("서명을 터치해주세요.");
                return;
            }
        }

        setTabIndex(tabIndex + 1);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 50);
    };

    useEffect(() => {
        console.log(confirmAuthStatus);

        if (confirmAuthStatus === true) {
            nextStep();
        }
    }, [confirmAuthStatus]);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            {data === false ? (
                <div className="mobile-app">
                    <div className="app-content share-detail">
                        <div className="data-empty">
                            <img src={warningIcon} alt="" />
                            <p>내용을 조회할 수 없습니다.</p>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {contractStatus === false ? (
                        <div className="mobile-app">
                            <div className="app-title">
                                <h1>계약서</h1>
                                {/* <button type="button" className="btn-close">
                                    <FontAwesomeIcon icon={faXmark} />
                                </button> */}
                            </div>

                            <div className="app-content contract-detail">
                                {tabIndex === 0 && (
                                    <ContractStep1
                                        customerInfo={customerInfo}
                                        setCustomerInfo={setCustomerInfo}
                                        checkAuthStatus={checkAuthStatus}
                                        setCheckAuthStatus={setCheckAuthStatus}
                                        confirmAuthStatus={confirmAuthStatus}
                                        setConfirmAuthStatus={setConfirmAuthStatus}
                                        handleToast={handleToast}
                                    />
                                )}
                                {tabIndex >= 1 && tabIndex <= 6 && (
                                    <ContractStep2
                                        tabIndex={tabIndex}
                                        termCheckedStatus={termCheckedStatus}
                                        setTermCheckedStatus={setTermCheckedStatus}
                                        takeoverInfo={takeoverInfo}
                                        setTakeoverInfo={setTakeoverInfo}
                                        data={data}
                                    />
                                )}
                                {tabIndex === 7 && <ContractStep3 submittedFiles={submittedFiles} setSubmittedFiles={setSubmittedFiles} />}

                                {tabIndex === 1 && (
                                    <div
                                        className="sign-wrapper"
                                        // style={
                                        //     tabIndex === 1
                                        //         ? { visibility: "visible", position: "relative" }
                                        //         : { visibility: "hidden", position: "absolute" }
                                        // }
                                    >
                                        <div className="sign-tit">
                                            <h6>계약서 확인 및 서명등록</h6>
                                            <p>
                                                차량 임대차 계약서가 도착했습니다. <br />
                                                고객님의 <strong>서명</strong>을 모두 정자로 <strong>입력</strong>해 주세요 <em>(날인X)</em>
                                            </p>
                                        </div>

                                        <div className="sign-form">
                                            <SignatureCanvas ref={signatureRef} width={"100%"} canvasProps={{ className: "sigCanvas" }} />
                                            <button type="button" className="btn-reset_sign" onClick={clearSignature}>
                                                <FontAwesomeIcon icon={faRotateRight} />
                                                지우기
                                            </button>
                                        </div>

                                        <div className="sign-button-row">
                                            {/* <button type="button" className="btn btn-secondary btn-large">
                                                인감 등록
                                            </button> */}
                                            <button type="button" className="btn btn-primary btn-large" onClick={saveSign}>
                                                서명 저장하기
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {tabIndex >= 2 && tabIndex <= 6 && (
                                    <div className="sign-wrapper">
                                        <div className="sign-tit">
                                            <p>위의 내용을 확인 하고 서명을 터치해 주세요.</p>
                                        </div>

                                        <div className="sign-form border-dashed" onClick={touchSign}>
                                            {signStatus[tabIndex - 2] ? <img src={signInfo?.url} alt="" /> : <></>}
                                            {signStatus[tabIndex - 2] ? "" : "서명을 터치해주세요."}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {tabIndex !== 0 && (
                                <div className="app-footer">
                                    <button type="button" className="btn btn-prev btn-large" onClick={() => prevStep()}>
                                        이전
                                    </button>
                                    <button type="button" className="btn btn-next btn-large" onClick={() => nextStep()}>
                                        {tabIndex === 7 ? "제출" : "다음"}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="mobile-app">
                            <div className="app-content share-detail">
                                <div className="data-empty">
                                    <img src={confirmIcon} alt="" />
                                    <p>계약 서명이 완료되었습니다.</p>

                                    {/* <div className="button-form" style={{ width: "180px", marginTop: "24px" }}>
                                        <button
                                            type="button"
                                            className="btn btn-xl btn--primary primaryFill btnLarge"
                                            onClick={() => {
                                                window.close();
                                            }}
                                        >
                                            닫기
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
