/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Summary, VehicleTable, ReleaseTable, ContractTable, FinishTable } from "./supplier";
import { Modal } from "components";
import { ChangeGrade, AffiliatedDealerInfo } from "./modal";

export const MypageSupplier = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const navigate = useNavigate();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            if (isLoggedIn === null) {
            } else if (!isLoggedIn) {
                navigate("/login");
            }
        } else {
            hasMounted.current = true;

            if (isLoggedIn === false) {
                navigate("/login");
            }
        }
    }, [isLoggedIn, navigate, hasMounted]);

    // ETC
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedRowIdx, setSelectedRowIdx] = useState(null);

    // 모달 State
    const [changeGradeModalStatus, setChangeGradeModalStatus] = useState(false);
    const [affiliatedDealerInfoModalStatus, setAffiliatedDealerInfoModalStatus] = useState(false);
    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    return (
        <>
            <div className="mypage container">
                <Summary />

                <div className="mypage-content">
                    <div className="mypage_table-container">
                        <div className="mypage_table-head type-head_sub">
                            <div className="mypage_table-tit">
                                <span className={tabIndex === 0 ? "active" : "unactive"} onClick={() => setTabIndex(0)}>
                                    차량정보
                                </span>
                                <span className={tabIndex === 1 ? "active" : "unactive"} onClick={() => setTabIndex(1)}>
                                    출고요청 내역
                                </span>
                                <span className={tabIndex === 2 ? "active" : "unactive"} onClick={() => setTabIndex(2)}>
                                    계약요청 내역
                                </span>
                                <span className={tabIndex === 3 ? "active" : "unactive"} onClick={() => setTabIndex(3)}>
                                    계약 내역
                                </span>
                            </div>
                        </div>

                        {tabIndex === 0 && <VehicleTable />}

                        {tabIndex === 1 && <ReleaseTable />}

                        {tabIndex === 2 && <ContractTable />}

                        {tabIndex === 3 && <FinishTable />}
                    </div>
                </div>
            </div>

            {/* 소속 딜러 정보 모달 */}
            {affiliatedDealerInfoModalStatus && <AffiliatedDealerInfo setModal={setAffiliatedDealerInfoModalStatus} />}

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}
        </>
    );
};
