/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "components";

export const ReleaseStatus = ({ setModal, setConfirm, rowId, fetchReleaseData }) => {
    const [loading, setLoading] = useState(false);
    const [productStatus, setProductStatus] = useState(null);

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const productStatusOptions = [
        { value: "OK", label: "출고 가능" },
        { value: "IN_10_DAY", label: "10일 이내 출고 가능" },
        { value: "IN_5_DAY", label: "5일 이내 출고 가능" },
        { value: "IN_CONTRACT", label: "계약중" },
        { value: "NO", label: "출고 불가" },
    ];

    const updateProductStatus = async () => {
        setLoading(true);

        try {
            const requestData = {
                shareReleaseStatus: productStatus,
            };

            const response = await updateData(`/api/share/rent_corp/product/v1/${rowId}/release_status`, requestData);
            fetchReleaseData();
            setConfirm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setConfirm();
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const handleUpdate = () => {
        updateProductStatus();
    };

    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            <div className="modal modal-type-1 modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>출고 상태 선택</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        <div className="content-tit">
                            <span>출고 가능 여부를 선택해주세요.</span>
                        </div>
                        <div className="content-form">
                            <div className="select-element">
                                <Select
                                    className="custom-select"
                                    classNamePrefix="custom-select"
                                    isSearchable={false}
                                    placeholder="출고 가능 여부 선택"
                                    options={productStatusOptions}
                                    onChange={(value) => handleSelectChange(value, setProductStatus)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-bottom">
                    <button type="button" className="btn-modal" onClick={handleUpdate} disabled={!productStatus}>
                        완료
                    </button>
                </div>
            </div>

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
