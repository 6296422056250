/* eslint-disable */
import React, { useEffect } from "react";

export const Modal = ({ title, content, buttons, onClose, layer }) => {
    useEffect(() => {
        console.log(layer);
    }, []);
    return (
        // <div className={layer ? "modal-backdrop" : "modal-backdrop invisible"} onClick={onClose}>
        <div className={layer ? "modal-backdrop" : "modal-backdrop invisible"}>
            <div className="modal">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <h2>{title}</h2>
                    <div>{content}</div>
                </div>
                <div className="modal-buttons">
                    {buttons.map((button, index) => (
                        <button key={index} onClick={button.handler}>
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
