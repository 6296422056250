import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const CompareShare = ({setModal}) => {
  return (
    <>
        <div className='modal-layer'></div>

        <div className='modal modal-type-1'>
            <div className="modal-title">공유하기</div>

            <button type="button" className="btn-close" onClick={() => setModal(false)}>
                <FontAwesomeIcon icon={faXmark} />
            </button>

            <div className="modal-content">
                <div className="form">
                    <div className="input-form">
                        <label className="form-title">이름</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">연락처</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="input-form disabled">
                        <div className="form-field disabled">
                            <div className="input-element">
                                <input type="text" />
                            </div>
                            <button type='button'>URL복사</button>
                        </div>
                    </div>
                </div>    
            </div>

            <div className="modal-bottom">
                <button type='button' className='btn-modal'>공유하기</button>
            </div>
        </div>
    </>
  )
}