// /* eslint-disable */
import React, { useState, useEffect } from "react";

import { useLocation, useSearchParams } from "react-router-dom";
import { fetchData } from "api";
import { Filter, ManagementTable } from "pages/product/list";
import { convertArrayToCommaSeparatedString, parseCommaSeparatedStringToArray, parseQueryString } from "util";

export const VehicleTable = () => {
    // 차량 정보 State
    const location = useLocation();
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    // 기존 상태 정의
    const [data, setData] = useState([]); // 상품 목록 상태
    const [loading, setLoading] = useState(true); // 데이터 로딩 상태
    const [paramLoading, setParamLoading] = useState(true); // 파라미터 식별 유무 상태

    // 필터 상태 추가
    const initialFilters = {
        share_product_status: parseCommaSeparatedStringToArray(searchParams.get("share_product_status") || ""),
        release_avail_yn: parseCommaSeparatedStringToArray(searchParams.get("release_avail_yn") || ""),
        exp_lt_1_yn: parseCommaSeparatedStringToArray(searchParams.get("exp_lt_1_yn") || ""),
        contract_age_types: searchParams.get("contract_age_types") || "",
        min_rent_price: searchParams.get("min_rent_price") || "",
        max_rent_price: searchParams.get("max_rent_price") || "",
        car_types: parseCommaSeparatedStringToArray(searchParams.get("car_types") || ""),
        car_manufacturer_name_list: parseCommaSeparatedStringToArray(searchParams.get("car_manufacturer_name_list") || ""),
        fuel_types: parseCommaSeparatedStringToArray(searchParams.get("fuel_types") || ""),
        contract_range_month: parseCommaSeparatedStringToArray(searchParams.get("contract_range_month") || ""),
        search_keyword: searchParams.get("search_keyword") || "",
        rent_types: parseCommaSeparatedStringToArray(searchParams.get("rent_types") || ""),
        page: parseInt(searchParams.get("page")) || 0,
        size: parseInt(searchParams.get("size")) || 20,
        sort: parseCommaSeparatedStringToArray(searchParams.get("sort") || ""),
    };

    // 필터 초기화
    const emptyFilters = {
        share_product_status: "",
        release_avail_yn: "",
        exp_lt_1_yn: "",
        contract_age_types: "",
        min_rent_price: "",
        max_rent_price: "",
        car_types: "",
        car_manufacturer_name_list: "",
        fuel_types: "",
        contract_range_month: "",
        search_keyword: "",
        rent_types: "",
        page: 0,
        size: 20,
        sort: "",
    };

    const [filters, setFilters] = useState(initialFilters);

    const handleResetFilters = () => {
        setFilters(emptyFilters);
    };

    // 필터 상태 업데이트 함수
    const handleFilterChange = (e, option = {}) => {
        const { name, value } = e.target;

        if (name === "rent_price") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                min_rent_price: option.min_value,
                max_rent_price: option.max_value,
            }));
        } else if (name === "contract_age_types") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                contract_age_types: option.contract_age_types,
            }));
        } else if (
            name === "car_types" ||
            name === "car_manufacturer_name_list" ||
            name === "share_product_status" ||
            name === "release_avail_yn" ||
            name === "exp_lt_1_yn"
        ) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: option,
            }));
        } else if (name === "contract_range_month") {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    };

    // 정렬 상태 업데이트 함수
    const onSortChange = (datas) => {
        let param;

        if (datas.mileage !== "" && datas.shareExposedAt !== "") {
            param = `mileage,${datas.mileage}&shareExposedAt,${datas.shareExposedAt}`;
        } else if (datas.mileage !== "" && datas.shareExposedAt === "") {
            param = `mileage,${datas.mileage}`;
        } else if (datas.mileage === "" && datas.shareExposedAt !== "") {
            param = `shareExposedAt,${datas.shareExposedAt}`;
        } else {
            param = "";
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            sort: param,
        }));
    };

    // 차량 정보 조회
    const fetchProductData = async () => {
        setLoading(true);

        try {
            const queryParams = new URLSearchParams(filters);

            const response = await fetchData(`/api/share/rent_corp/product/v1?${queryParams.toString()}`);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("필터링된 상품 목록을 불러오는 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        // URL 변경 감지 및 데이터 로딩 로직
        const urlFilters = {
            share_product_status: parseCommaSeparatedStringToArray(searchParams.get("share_product_status") || ""),
            release_avail_yn: parseCommaSeparatedStringToArray(searchParams.get("release_avail_yn") || ""),
            exp_lt_1_yn: parseCommaSeparatedStringToArray(searchParams.get("exp_lt_1_yn") || ""),
            contract_age_types: searchParams.get("contract_age_types") || initialFilters.contract_age_types,
            min_rent_price: searchParams.get("min_rent_price") || initialFilters.min_rent_price,
            max_rent_price: searchParams.get("max_rent_price") || initialFilters.max_rent_price,
            car_types: parseCommaSeparatedStringToArray(searchParams.get("car_types") || ""),
            car_manufacturer_name_list: parseCommaSeparatedStringToArray(searchParams.get("car_manufacturer_name_list") || ""),
            fuel_types: parseCommaSeparatedStringToArray(searchParams.get("fuel_types") || ""),
            contract_range_month: parseCommaSeparatedStringToArray(searchParams.get("contract_range_month") || ""),
            search_keyword: searchParams.get("search_keyword") || initialFilters.search_keyword || "",
            rent_types: parseCommaSeparatedStringToArray(searchParams.get("rent_types") || ""),
            page: parseInt(searchParams.get("page")) || initialFilters.page,
            size: parseInt(searchParams.get("size")) || initialFilters.size,
            sort: parseCommaSeparatedStringToArray(searchParams.get("sort") || ""),
        };

        setFilters(urlFilters);
        setParamLoading(false);
    }, [location.search]);

    useEffect(() => {
        // 페이지 최초 진입 URL 파라미터 setFilters 적용 확인
        if (paramLoading) return;

        // 모든 배열 필드를 콤마로 구분된 문자열로 변환
        const filtersForURL = { ...filters };
        filtersForURL.share_product_status = convertArrayToCommaSeparatedString(filters.share_product_status);
        filtersForURL.release_avail_yn = convertArrayToCommaSeparatedString(filters.release_avail_yn);
        filtersForURL.exp_lt_1_yn = convertArrayToCommaSeparatedString(filters.exp_lt_1_yn);
        filtersForURL.fuel_types = convertArrayToCommaSeparatedString(filters.fuel_types);
        filtersForURL.car_types = convertArrayToCommaSeparatedString(filters.car_types);
        filtersForURL.car_manufacturer_name_list = convertArrayToCommaSeparatedString(filters.car_manufacturer_name_list);
        filtersForURL.rent_types = convertArrayToCommaSeparatedString(filters.rent_types);
        filtersForURL.contract_range_month = convertArrayToCommaSeparatedString(filters.contract_range_month);
        filtersForURL.sort = convertArrayToCommaSeparatedString(filters.sort);

        // 현재 URL의 쿼리 매개변수와 비교
        if (JSON.stringify(parseQueryString(location.search)) !== JSON.stringify(filters)) {
            console.log(filtersForURL);
            setSearchParams(filtersForURL);
            fetchProductData("");
        }
    }, [filters]);

    const onSearch = (keyword) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            search_keyword: keyword,
        }));
    };

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="productList">
                <div className="contents">
                    <div className="filterWrap">
                        <Filter
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            onResetFilters={handleResetFilters}
                            compareWrapStatus={false}
                        />
                    </div>
                    <div className="tableWrap">
                        <ManagementTable
                            loading={loading}
                            data={data}
                            filters={filters}
                            setFilters={setFilters}
                            onFilterChange={handleFilterChange}
                            onSortChange={onSortChange}
                            fetchProductData={fetchProductData}
                            onSearch={onSearch}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
