/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { comma, convertToKoreanWon } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export const FormStep2 = ({ data }) => {
    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <>
            <div className="tab" id="tab-02">
                <div className="item-wrapper border-separation">
                    <div className="item-head">
                        <span>렌터카 정보</span>
                    </div>
                    <div className="item-list grid-row">
                        <div className="item width-100">
                            <span className="item-tit">상호명</span>
                            <span className="item-desc">{data?.rentCarCorperation.name}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">대표번호</span>
                            <span className="item-desc">{data?.rentCarCorperation.corpPhone}</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">주소</span>
                            <span className="item-desc">
                                {data?.rentCarCorperation.address} {data?.rentCarCorperation.addressDetail}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper">
                    <div className="item-head">
                        <span>계약조건</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item">
                            <span className="item-tit">대여기간</span>
                            <span className="item-desc">{data?.contractRangeMonth}개월</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">보증급 분납</span>
                            <span className="item-desc">
                                {data?.depositType === "FULL" && "일시납"}
                                {data?.depositType === "DIVIDE_2" && "2회분납"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">대여일시</span>
                            <span className="item-desc">{data?.contractedAt ? data?.contractedAt.split("T")[0] : "-"}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">반납일시</span>
                            <span className="item-desc">{data?.returnedAt ? data?.returnedAt.split("T")[0] : "-"}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">보증금</span>
                            <span className="item-desc">{comma(data?.depositPrice)}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">렌탈료</span>
                            <span className="item-desc">{comma(data?.rentPrice)}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">정비상품</span>
                            <span className="item-desc">
                                {data?.rentInfo?.inspectionProducts
                                    ? data?.rentInfo?.inspectionProducts?.map((row, idx) => {
                                          return (
                                              <>
                                                  <p>{row.name}</p>
                                              </>
                                          );
                                      })
                                    : "정비불포함"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">현재 주행거리</span>
                            <span className="item-desc">{comma(data?.car.mileage)}km</span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper">
                    <div className="item-head">
                        <span>차량정보</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item">
                            <span className="item-tit">모델명</span>
                            <span className="item-desc">{data?.car.modelDetailName}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">차량번호</span>
                            <span className="item-desc">{data?.car.carNumber}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">연료</span>
                            <span className="item-desc">
                                {data?.car.fuelType === "GAS"
                                    ? "가솔린"
                                    : data?.car.fuelType === "DIESEL"
                                    ? "디젤"
                                    : data?.car.fuelType === "LPG"
                                    ? "LPG"
                                    : data?.car.fuelType === "GAS_LPG"
                                    ? "가솔린(LPG겸용)"
                                    : data?.car.fuelType === "GAS_CNG"
                                    ? "가솔린(CNG겸용)"
                                    : data?.car.fuelType === "GAS_HYBRID"
                                    ? "가솔린 하이브리드"
                                    : data?.car.fuelType === "ELECTRIC"
                                    ? "전기"
                                    : data?.car.fuelType === "HYDROGEN"
                                    ? "수소"
                                    : "알 수 없음"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">색상</span>
                            <span className="item-desc">{data?.car.color}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">연식/년도</span>
                            <span className="item-desc">
                                {data?.car.carMadedYear}.{data?.car.carMadedMonth}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">약정 주행거리</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.yearContractMileage ? `${comma(data?.car.rentInfo.yearContractMileage)}km` : "-"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">변속기</span>
                            <span className="item-desc">
                                {data?.car.motorType === "AUTOMATIC"
                                    ? "자동"
                                    : data?.car.motorType === "MANUAL"
                                    ? "수동"
                                    : data?.car.motorType === "CVT"
                                    ? "CVT"
                                    : data?.car.motorType === "DUAL"
                                    ? "듀얼클러치"
                                    : "알 수 없음"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">배기량</span>
                            <span className="item-desc">{comma(data?.car.displacement)}cc</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">신차가격</span>
                            <span className="item-desc">{data?.car.newPrice ? comma(data?.car.newPrice) : "-"}</span>
                        </div>
                        <div className="item">
                            <span className="item-tit">차량 인수 옵션</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.takeOverType === "FULL_CHOICE"
                                    ? "만기선택형"
                                    : data?.car.rentInfo.takeOverType === "TAKE_OVER"
                                    ? "인수형"
                                    : data?.car.rentInfo.takeOverType === "RETURN"
                                    ? "반납형"
                                    : "알 수 없음"}
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">선택옵션</span>
                            {/* <span className="item-desc">
                                {data?.car.majorOptions
                                    ? data?.car.majorOptions?.map((option, index) => <span>{option.nameKr}</span>)
                                    : "-"}
                            </span> */}
                            <span className="item-desc">
                                {data?.car.carAddOptionContents.length === 0 ? (
                                    <span>옵션이 추가되지 않은 차량입니다.</span>
                                ) : (
                                    <></>
                                    // <span>
                                    //     {(() => {
                                    //         const total = data?.car.carAddOptionContents.reduce((acc, option) => acc + option.price, 0);
                                    //         const formattedTotal = Math.floor(total / 10000); // 소수점 제거

                                    //         return <em>{formattedTotal}만원</em>;
                                    //     })()}
                                    //     &nbsp;상당의 옵션이 추가된 차량입니다.
                                    // </span>
                                )}
                            </span>
                            <div className="item-list option-list grid-2">
                                {data?.car.carAddOptionContents?.map((option, index) => {
                                    if (option.carAddOptionContentItems.length === 0) {
                                        return (
                                            <div key={index} className="item option-item">
                                                <div className="option-tit">{option.name}</div>
                                            </div>
                                        );
                                    } else {
                                        return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                            return (
                                                <div key={sub_index} className="item option-item">
                                                    <div className="item-tit">
                                                        <span>{sub_option.title}</span>
                                                        {sub_option.content !== "" && (
                                                            <button type="button">
                                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                                                <div className="guide_popup">
                                                                    {/* <span className="guide_popup-tit">계약 가능 연령</span> */}
                                                                    <p className="guide_popup-desc">{sub_option.content}</p>
                                                                </div>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        });
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="item-wrapper">
                    <div className="item-head">
                        <span>특약</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-pre">
                                {data?.car.rentInfo.depositTwiceYn === "Y" && (
                                    <p>
                                        <em>• 보증금 2회 분납 가능</em>
                                    </p>
                                )}
                                {data?.car.rentInfo.expLt1Yn === "Y" && (
                                    <p>
                                        <em>• 운전면허 1년 미만 계약 가능</em>
                                    </p>
                                )}
                                {data?.car.rentInfo.contractAgeTypes?.includes("AGE_21") && (
                                    <p>
                                        <em>• 만 21세 이상 가능</em>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;
                                        <span>* 월 렌트료 {convertToKoreanWon(data?.car.rentInfo.age21RentPrice)} 추가</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;
                                        <span>* 면책금 기본 + {convertToKoreanWon(data?.car.rentInfo.age21IndemPrice)} 추가</span>
                                    </p>
                                )}
                                {data?.car.rentInfo.contractAgeTypes?.includes("AGE_23") && (
                                    <p>
                                        <em>• 만 23세 이상 가능</em>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;
                                        <span>* 월 렌트료 {convertToKoreanWon(data?.car.rentInfo.age23RentPrice)} 추가</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;
                                        <span>* 면책금 기본 + {convertToKoreanWon(data?.car.rentInfo.age23IndemPrice)} 추가</span>
                                    </p>
                                )}
                            </span>
                        </div>
                    </div>
                </div> */}
                <div className="item-wrapper">
                    <div className="item-head">
                        <span>계약 유의사항</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-pre">
                                {data?.car.rentInfo.contractNote && (
                                    <p>
                                        <em>{data?.car.rentInfo.contractNote}</em>
                                    </p>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>구비서류</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-pre">
                                {`- 기본서류(개인)
                                    운전면허증
                                    주민등록등본
                                    주민등록초본
                                    가족관계증명서
                                    본인서명사실확인서
                                    운전경력증명서
                                    통장사본

                                    - 개인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    사업자통장사본

                                    - 법인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    법인등기부등본
                                    법인인감증명서
                                    법인통장사본`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
