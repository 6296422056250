/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { comma } from "util";

export const CarInfo = ({ data, setModal }) => {
    useEffect(() => {
        console.log(data);
    }, []);

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>차량 정보</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="content-list">
                        <div className="item">
                            <div className="item-head">
                                <span>차량번호</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.car.carNumber}</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>모델명</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.car.modelDetailName}</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>계약기간</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.contractRangeMonth}개월</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>구분</span>
                            </div>
                            <div className="item-content">
                                <span>
                                    {data?.rentType === "LONG_RENT"
                                        ? "장기렌트"
                                        : data?.rentType === "SUBSCRIPTION"
                                        ? "구독"
                                        : data?.rentType === "FIRST_RELEASE"
                                        ? "선출고"
                                        : "알 수 없음"}
                                </span>
                            </div>
                        </div>
                        <div className="hr"></div>
                        <div className="item">
                            <div className="item-head">
                                <span>보증금</span>
                            </div>
                            <div className="item-content">
                                <span>{comma(data?.depositPrice)} 원</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>대여료</span>
                            </div>
                            <div className="item-content">
                                <span>{comma(data?.rentPrice)} 원</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
