/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleRight, faMagnifyingGlass, faRotateRight, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { ChangeGrade } from "../modal";
import { fetchData } from "api";
import { comma } from "util";
import { Modal, Toast } from "components";

import MasterIcon from "resources/icon/grade-master.png";
import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const DealerDetail = ({ memberId, setDealerDetailModalStatus, dim, updateAffiliatedDealerInfo }) => {
    const [loading, setLoading] = useState(true); // 데이터 로딩 상태
    const [myData, setMyData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);

    const fetchMySummary = async () => {
        try {
            const response = await fetchData(`/api/share/ag_corp/v1/member/${memberId}/summary`);

            setSummaryData(response.data.data);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchMyData = async () => {
        try {
            const response = await fetchData(`/api/share/ag_corp/v1/member/${memberId}`);
            console.log(response.data.data);
            setMyData(response.data.data);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchTableData = async (params) => {
        setLoading(true);

        try {
            // const params = {
            //     started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            //     ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            //     search_keyword: searchKeyword,
            //     member_id: memberId,
            // };

            const response = await fetchData("/api/share/ag_corp/v1/contract", params);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
            setLoading(false);
        }
    };

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const searchData = () => {
        const params = {
            contract_status_list: contractStatus,
            started_at: startDate ? `${startDate.toISOString().split("T")[0]}T00:00:00` : "",
            ended_at: endDate ? `${endDate.toISOString().split("T")[0]}T23:59:59` : "",
            search_keyword: searchKeyword,
            member_id: memberId,
        };

        fetchTableData(params);
    };

    useEffect(() => {
        searchData();
    }, [startDate, endDate, contractStatus]);

    const initParams = () => {
        setSearchKeyword("");
        setStartDate(null);
        setEndDate(null);
        setContractStatus(null);

        const params = {
            contract_status_list: "",
            started_at: null,
            ended_at: null,
            search_keyword: "",
            member_id: memberId,
        };

        fetchTableData(params);
    };

    const fetchInitData = async () => {
        setLoading(true);
        try {
            await fetchMySummary();
            await fetchMyData();
            await initParams();
        } catch (error) {}
    };

    useEffect(() => {
        fetchInitData();
    }, []);

    const updateLifting = () => {
        fetchMyData();
        updateAffiliatedDealerInfo();
    };

    const [changeGradeModalStatus, setChangeGradeModalStatus] = useState(false);

    const contractStatusOptions = [
        { value: "REQUEST", label: "접수중" },
        { value: "ON_PROCESS", label: "진행중" },
        { value: "FINISH", label: "계약완료" },
        { value: "DELIVERY_FINISH", label: "탁송신청완료" },
        { value: "RETURN", label: "반납" },
        { value: "RELEASE_READY", label: "출고준비중" },
        { value: "RELEASE_FINISH", label: "출고완료" },
        { value: "REJECT", label: "진행 불가" },
        { value: "CANCEL", label: "중도 해지" },
    ];

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {dim && <div className="modal-layer"></div>}

            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal modal-dealer_detail">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>딜러 정보 상세보기</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setDealerDetailModalStatus(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="mypage-head">
                        <div className="myinfo">
                            <div className="member">
                                <div className="member-icon">
                                    {(() => {
                                        const gradeMap = {
                                            MASTER: { icon: MasterIcon, className: "color-txt-master", label: "master" },
                                            GOLD: { icon: GoldIcon, className: "color-txt-gold", label: "gold" },
                                            SILVER: { icon: SilverIcon, className: "color-txt-silver", label: "silver" },
                                            BRONZE: { icon: BronzeIcon, className: "color-txt-bronze", label: "bronze" },
                                            NORMAL: { icon: NormalIcon, className: "color-txt-normal", label: "normal" },
                                        };
                                        const grade = myData?.member?.shareGrade;

                                        if (grade) {
                                            const { icon, className, label } = gradeMap[grade];
                                            return (
                                                <>
                                                    <img src={icon} alt="" />
                                                    <span className={`member-class-txt ${className}`}>{label}</span>
                                                </>
                                            );
                                        }

                                        return null;
                                    })()}
                                </div>
                                <div className="member-detail">
                                    <span>
                                        <b>{myData?.member?.name}</b> 님
                                    </span>
                                    <span className="member-tel">{myData?.member?.phone}</span>
                                </div>
                            </div>
                            <div className="links">
                                <button type="button" className="btn-url" onClick={() => setChangeGradeModalStatus(true)}>
                                    등급 변경 <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                                <button type="button" className="btn-account">
                                    정산서 보기 <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                            </div>
                        </div>

                        <div className="summary">
                            <div className="summary-head">
                                <span className="date">
                                    {new Date().toLocaleDateString("ko-KR", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}{" "}
                                    기준
                                </span>
                            </div>
                            <div className="summary-content">
                                <div className="item-list">
                                    <div className="item">
                                        <div className="item-head">
                                            <span>계약랭킹 TOP</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-blue">
                                                {summaryData?.contractRanking ? summaryData?.contractRanking : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>전체 계약접수</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-blue">
                                                {summaryData?.contractTotalCount ? summaryData?.contractTotalCount : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>계약완료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-blue">
                                                {summaryData?.contractFinishCount ? summaryData?.contractFinishCount : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>계약진행 중</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-blue">
                                                {summaryData?.contractOnProcessCount ? summaryData?.contractOnProcessCount : "-"}
                                            </span>
                                            {/* <span className="sub-txt">(0%)</span> */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>계약취소(불가)</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-blue">
                                                {summaryData?.contractCancelCount ? summaryData?.contractCancelCount : "-"}
                                            </span>
                                            {/* <span className="sub-txt">(0%)</span> */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>전체 수수료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-black">
                                                {summaryData?.commissionTotal ? comma(summaryData?.commissionTotal) : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>일시납 수수료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-black">
                                                {summaryData?.commissionFull ? comma(summaryData?.commissionFull) : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>분납 수수료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-black">
                                                {summaryData?.commissionDivide ? comma(summaryData?.commissionDivide) : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>전월 분납 수수료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-black">
                                                {summaryData?.commissionDivideLastMonth
                                                    ? comma(summaryData?.commissionDivideLastMonth)
                                                    : "-"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span>환급 수수료</span>
                                        </div>
                                        <div className="item-body">
                                            <span className="color-black">
                                                {summaryData?.commissionCancel ? comma(summaryData?.commissionCancel) : "-"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mypage-content">
                        <div className="mypage_table-container">
                            <div className="mypage_table-head type-head_sub">
                                <div className="mypage_table-tit">
                                    <span>계약현황</span>
                                </div>
                                <div className="mypage_table-filter">
                                    <div className="filter-option filter-search">
                                        <input
                                            type="text"
                                            placeholder="고객명을 검색해주세요."
                                            value={searchKeyword}
                                            onChange={(e) => {
                                                setSearchKeyword(e.target.value);
                                            }}
                                        />
                                        <button type="button" onClick={searchData}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </button>
                                    </div>
                                    <div className="filter-option filter-date">
                                        <FontAwesomeIcon icon={faCalendarDays} />
                                        <div className="input-datepicker">
                                            <DatePicker
                                                locale={ko}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText=""
                                                selected={startDate}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(date) => {
                                                    if (endDate && date > endDate) {
                                                        handleToast("시작 날짜는 종료 날짜보다 이후일 수 없습니다.");
                                                        return;
                                                    }
                                                    setStartDate(date);
                                                }}
                                            />
                                        </div>
                                        -
                                        <div className="input-datepicker">
                                            <DatePicker
                                                locale={ko}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText=""
                                                selected={endDate}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(date) => {
                                                    if (startDate && date < startDate) {
                                                        handleToast("종료 날짜는 시작 날짜보다 이전일 수 없습니다.");
                                                        return;
                                                    }
                                                    setEndDate(date);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-option filter-select">
                                        <div className="select-element">
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                isSearchable={false}
                                                placeholder="계약상태"
                                                options={contractStatusOptions}
                                                onChange={(value) => handleSelectChange(value, setContractStatus)}
                                                value={contractStatusOptions.filter((option) => option.value === contractStatus)}
                                            />
                                        </div>
                                        {/* <div className="select-element">
                                                    <Select
                                                        options={[
                                                            { value: "전체", label: "전체" },
                                                            { value: "계약접수", label: "계약접수" },
                                                            { value: "입금대기", label: "입금대기" },
                                                            { value: "출고 준비중", label: "출고 준비중" },
                                                            { value: "출고 완료", label: "출고 완료" },
                                                            { value: "계약취소/불가", label: "계약취소/불가" },
                                                            { value: "환수", label: "환수" },
                                                        ]}
                                                        className="custom-select"
                                                        classNamePrefix="custom-select"
                                                        isSearchable={false}
                                                        placeholder="계약상태"
                                                    />
                                                </div> */}
                                    </div>
                                    {/* <div className="filter-option filter-select">
                                    <div className="select-element">
                                        <Select
                                            options={[
                                                { value: "전체", label: "전체" },
                                                { value: "정산완료", label: "정산완료" },
                                                { value: "정산예정", label: "정산예정" },
                                                { value: "환수완료", label: "환수완료" },
                                                { value: "환수예정", label: "환수예정" },
                                            ]}
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="정산현황"
                                        />
                                    </div>
                                </div> */}
                                    <div className="filter-option filter-refresh">
                                        <button type="button" className="btn-refresh" onClick={initParams}>
                                            초기화
                                            <FontAwesomeIcon icon={faRotateRight} />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="mypage_table-wrapper">
                                <table>
                                    <colgroup>
                                        <col width={"4.8%"} />
                                        <col width={"5.5%"} />
                                        <col width={"5.5%"} />
                                        <col width={"7.2%"} />
                                        <col width={"6.8%"} />
                                        <col width={"6.8%"} />
                                        <col width={"6.0%"} />
                                        <col width={"5.8%"} />
                                        <col width={"5.8%"} />
                                        <col width={"7.6%"} />
                                        <col width={"5.4%"} />
                                        <col width={"7.6%"} />
                                        <col width={"6.8%"} />
                                        <col width={"7.8%"} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="tht">계약NO</div>
                                            </th>
                                            <th>
                                                <div className="tht">접수일</div>
                                            </th>
                                            <th>
                                                <div className="tht">출고일</div>
                                            </th>
                                            <th>
                                                <div className="tht">상품구분</div>
                                            </th>
                                            <th>
                                                <div className="tht">차량번호</div>
                                            </th>
                                            <th>
                                                <div className="tht">차량 모델</div>
                                            </th>
                                            <th>
                                                <div className="tht">고객명</div>
                                            </th>
                                            <th>
                                                <div className="tht">계약기간</div>
                                            </th>
                                            <th>
                                                <div className="tht">보증금</div>
                                            </th>
                                            <th>
                                                <div className="tht">분납여부</div>
                                            </th>
                                            <th>
                                                <div className="tht">대여료</div>
                                            </th>
                                            <th>
                                                <div className="tht">계약상태</div>
                                            </th>
                                            <th>
                                                <div className="tht">지급수수료</div>
                                            </th>
                                            <th>
                                                <div className="tht">정산현황</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datas.length === 0 && (
                                            <tr>
                                                <td colSpan={9} style={{ textAlign: "center", height: "120px" }}>
                                                    <div className="tdd">
                                                        <span className="td-desc">조회된 데이터가 없습니다.</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {datas?.map((data, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{data?.id}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                {data?.requestedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                {data?.releasedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                <b>
                                                                    {data?.rentType === "LONG_RENT"
                                                                        ? "장기렌트"
                                                                        : data?.rentType === "SUBSCRIPTION"
                                                                        ? "구독"
                                                                        : data?.rentType === "FIRST_RELEASE"
                                                                        ? "선출고"
                                                                        : "알 수 없음"}
                                                                </b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                <b>{data?.car?.carNumber}</b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{data?.car?.modelName}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                <b>{data?.customerName}</b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{data?.contractRangeMonth}개월</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{comma(data?.depositPrice)}원</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                {data?.depositType === "FULL" && "일시납"}
                                                                {data?.depositType === "DIVIDE_2" && "2회분납"}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{comma(data?.rentPrice)}원</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            {data?.contractStatus === "REQUEST" && (
                                                                <span className="td-desc td-color-01">접수중</span>
                                                            )}
                                                            {data?.contractStatus === "ON_PROCESS" && (
                                                                <span className="td-desc td-color-01">진행중</span>
                                                            )}
                                                            {data?.contractStatus === "FINISH" && (
                                                                <span className="td-desc td-color-01">계약완료</span>
                                                            )}
                                                            {data?.contractStatus === "DELIVERY_FINISH" && (
                                                                <span className="td-desc td-color-02">탁송신청완료</span>
                                                            )}
                                                            {data?.contractStatus === "RETURN" && (
                                                                <span className="td-desc td-color-03">반납</span>
                                                            )}
                                                            {data?.contractStatus === "RELEASE_READY" && (
                                                                <span className="td-desc td-color-01">출고준비중</span>
                                                            )}
                                                            {data?.contractStatus === "RELEASE_FINISH" && (
                                                                <span className="td-desc td-color-02">출고완료</span>
                                                            )}
                                                            {data?.contractStatus === "REJECT" && (
                                                                <span
                                                                    className="td-desc td-color-03"
                                                                    onClick={() =>
                                                                        setCancelElecAgreementModalStatus(["REJECT", data.rejectReason])
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    진행 불가
                                                                </span>
                                                            )}
                                                            {data?.contractStatus === "CANCEL" && (
                                                                <span className="td-desc td-color-03">중도 해지</span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span
                                                                className={
                                                                    data?.managerCommission === 0
                                                                        ? `td-desc td-color-03`
                                                                        : `td-desc td-color-01`
                                                                }
                                                            >
                                                                <b>{comma(data?.managerCommission)}원</b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            {data?.contractStatus === "REJECT" ? (
                                                                <span className="td-desc td-color-03">
                                                                    <b>정산미포함</b>
                                                                </span>
                                                            ) : (
                                                                <span className="td-desc td-color-01">
                                                                    <b>
                                                                        {data?.calculateStatus === "PENDING" && "정산전"}
                                                                        {data?.calculateStatus === "CANCEL" && "환급"}
                                                                        {data?.calculateStatus === "FINISH" && "정산완료"}
                                                                    </b>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {/* {datas.map((data, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                <b>{data?.customerName}</b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                {data?.contractedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">
                                                                {data?.releasedAt?.split("T")[0].slice(2).replaceAll("-", "/")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc td-color-01">{data?.contractStatusStr}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{data?.car?.carModel}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{comma(data?.depositPrice)}원</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{comma(data?.rentPrice)}원</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            <span className="td-desc">{comma(data?.managerCommission)}원</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tdd">
                                                            {data?.depositStatus === "PENDING" && (
                                                                <span className="td-desc td-color-01">
                                                                    <b>입금전</b>
                                                                </span>
                                                            )}
                                                            {data?.depositStatus === "FINISH" && (
                                                                <span className="td-desc td-color-01">
                                                                    <b>입금완료</b>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 등급 설정 모달 */}
            {changeGradeModalStatus && (
                <ChangeGrade setModal={setChangeGradeModalStatus} memberId={memberId} myData={myData} updateLifting={updateLifting} />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
