import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const AdditionalDocs = () => {
    return (
        <div className="modal modal-type-2">
            <div className="modal-title">
                <span className="modal-tit-main">추가 필요서류</span>
            </div>

            <div className="modal-content">
                <div className="doc-list">
                    <div className="item">
                        <div className="item-head">
                            <span>운전면허증 / 운전경력증명서(최근 3년)</span>
                            <em>*필수</em>
                        </div>
                        <div className="item-content">
                            <p>• 분실 면허증이 아닌 재발급 신분증을 첨부해주세요.</p>
                            <p>• 일련번호가 잘 보이도록 촬영하여 첨부해주세요.</p>
                            <p>• 운전경력증명서는 정부24에서 발급 가능합니다.</p>
                        </div>
                        <button type="button" className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="item">
                        <div className="item-head">
                            <span>계약자 관련 서류</span>
                        </div>
                        <div className="item-content">
                            <p>• 등본,초본,가족관계증명서,본인서명사실확인서를 첨부해주세요.</p>
                            <p>• 서류를 사진촬영 또는 스캔 하여 첨부해주세요.</p>
                        </div>
                        <button type="button" className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="item">
                        <div className="item-head">
                            <span>자동이체 통장사본</span>
                        </div>
                        <div className="item-content">
                            <p>• 자동이체 신청을 위한 통장사본을 첨부해주세요. </p>
                            <p>(본인명의의 통장사본이 아닐경우 추가서류가 필요합니다.)</p>
                        </div>
                        <button type="button" className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="item">
                        <div className="item-head">
                            <span>사업자 관련 서류</span>
                        </div>
                        <div className="item-content">
                            <p>• 사업자 등록증, 법인 인감증명서,법인 등기부등본을 첨부해주세요. </p>
                        </div>
                        <button type="button" className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="item">
                        <div className="item-head">
                            <span>등기영수증</span>
                        </div>
                        <div className="item-content">
                            <p>•계약에 필요한 서류를 2부씩 등기 발송후 영수증을 첨부해주세요.</p>
                        </div>
                        <button type="button" className="btn-detail">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>
                <p className="doc-warn">* 운전면허증 외 다른 서류는 전자계약 시 고객이 추가할 수 있습니다.</p>
            </div>

            <div className="modal-bottom">
                <button type="button" className="btn-modal">
                    이전
                </button>
                <button type="button" className="btn-modal">
                    완료
                </button>
            </div>
        </div>
    );
};
