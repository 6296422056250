/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { comma } from "util";

export const CustomerInfo = ({ data, setModal }) => {
    useEffect(() => {
        console.log(data);
    }, []);
    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>고객 정보</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="content-list">
                        <div className="item">
                            <div className="item-head">
                                <span>고객명</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.customerName}</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>생년월일</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.customerIdNumber}</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>연락처</span>
                            </div>
                            <div className="item-content">
                                <span>{data?.customerPhone}</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>주소</span>
                            </div>
                            <div className="item-content">
                                <span>
                                    {data?.customerAddress} {data?.customerAddressDetail}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
