/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Toast } from "components";

export const DetailShareStep2 = ({ initData, data, onStep2Change }) => {
    const contractMonths = data.rentInfo.contractPrices.map((price) => ({
        value: `CONTRACT_${String(price.contractRangeMonth)}`,
        label: `${price.contractRangeMonth} 개월`,
    }));

    const [step2Data, setStep2Data] = useState({
        customerType: initData?.customerType || "PERSONAL",
        ageType: initData?.ageType || "ALL",
        contractRangeMonthTypes: initData?.contractRangeMonthTypes || contractMonths.map((month) => month.value),
        shareCarInfoTypes: initData?.shareCarInfoTypes || [
            "CAR_INFO",
            "RENT_PRICE",
            "MAJOR_OPTION",
            "CONTRACT_CONDITION",
            "DUTY_PRICE",
            "INDEM_PRICE",
            "DRIVER_RANGE",
            "INSURANCE",
            "CONTRACT_NOTE",
            "CONTRACT_DOCUMENT",
        ],
        expLt1Yn: initData?.expLt1Yn || "N",
    });

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setStep2Data((prev) => ({ ...prev, [name]: value }));
        onStep2Change({ ...step2Data, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        let newValues = step2Data[name];

        if (name === "contractRangeMonthTypes") {
            if (value === "ALL") {
                if (checked) {
                    newValues = contractMonths.map((month) => month.value);
                } else {
                    newValues = [];
                }
            } else {
                if (checked) {
                    newValues = [...step2Data.contractRangeMonthTypes, value];
                    newValues = newValues.filter((item) => item !== "ALL");
                } else {
                    newValues = step2Data.contractRangeMonthTypes.filter((item) => item !== value);
                }
            }
        } else {
            if (name === "shareCarInfoTypes") {
                console.log(value);
                if (value !== "CONTRACT_CONDITION") {
                    handleToast("해당 항목은 필수 공유 대상입니다.");
                    return;
                }
            }

            if (checked) {
                if (!newValues.includes(value)) {
                    newValues.push(value);
                }
            } else {
                newValues = newValues.filter((item) => item !== value);
            }
        }

        setStep2Data((prev) => ({ ...prev, [name]: newValues }));
        // onStep2Change({ ...step2Data, [name]: newValues });
    };

    useEffect(() => {
        onStep2Change(step2Data);
    }, [step2Data]);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="step">
                {/* 계약자 유형 라디오 버튼 */}
                <div className="form">
                    <div className="radio-form">
                        <label className="form-title">계약자 유형</label>
                        <div className="form-field">
                            <div className="input-radio">
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="PERSONAL"
                                        name="customerType"
                                        value="PERSONAL"
                                        onChange={handleRadioChange}
                                        checked={step2Data.customerType === "PERSONAL"}
                                    />
                                    <label htmlFor="PERSONAL">개인</label>
                                </div>
                            </div>
                            <div className="input-radio">
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="BIZ_PERSONAL"
                                        name="customerType"
                                        value="BIZ_PERSONAL"
                                        onChange={handleRadioChange}
                                        checked={step2Data.customerType === "BIZ_PERSONAL"}
                                    />
                                    <label htmlFor="BIZ_PERSONAL">개인 사업자</label>
                                </div>
                            </div>
                            <div className="input-radio">
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="BIZ_CORP"
                                        name="customerType"
                                        value="BIZ_CORP"
                                        onChange={handleRadioChange}
                                        checked={step2Data.customerType === "BIZ_CORP"}
                                    />
                                    <label htmlFor="BIZ_CORP">법인 사업자</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 계약나이 라디오 버튼 */}
                <div className="form">
                    <div className="radio-form">
                        <label className="form-title">계약나이</label>
                        <div className="form-field">
                            <div className="input-radio">
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="ageAll"
                                        name="ageType"
                                        value="ALL"
                                        onChange={handleRadioChange}
                                        checked={step2Data.ageType === "ALL"}
                                    />
                                    <label htmlFor="ageAll">전체</label>
                                </div>
                            </div>
                            {data?.rentInfo.contractAgeTypes?.includes("AGE_21") && (
                                <div className="input-radio">
                                    <div className="radiobox">
                                        <input
                                            type="radio"
                                            id="age21"
                                            name="ageType"
                                            value="AGE_21"
                                            onChange={handleRadioChange}
                                            checked={step2Data.ageType === "AGE_21"}
                                        />
                                        <label htmlFor="age21">만 21세 이상</label>
                                    </div>
                                </div>
                            )}
                            {data?.rentInfo.contractAgeTypes?.includes("AGE_23") && (
                                <div className="input-radio">
                                    <div className="radiobox">
                                        <input
                                            type="radio"
                                            id="age23"
                                            name="ageType"
                                            value="AGE_23"
                                            onChange={handleRadioChange}
                                            checked={step2Data.ageType === "AGE_23"}
                                        />
                                        <label htmlFor="age23">만 23세 이상</label>
                                    </div>
                                </div>
                            )}
                            <div className="input-radio">
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="age26"
                                        name="ageType"
                                        value="AGE_26"
                                        onChange={handleRadioChange}
                                        checked={step2Data.ageType === "AGE_26"}
                                    />
                                    <label htmlFor="age26">만 26세 이상</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 운전면허 1년미만 체크 버튼 */}
                {data?.rentInfo.expLt1Yn === "Y" && (
                    <div className="form">
                        <div className="radio-form">
                            <label className="form-title">운전면허 1년미만</label>
                            <div className="form-field">
                                <div className="input-check">
                                    <div className="checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="expLt1Yn"
                                                value="expLt1Yn"
                                                onChange={(e) => {
                                                    setStep2Data((prev) => ({
                                                        ...prev,
                                                        expLt1Yn: step2Data.expLt1Yn === "Y" ? "N" : "Y",
                                                    }));
                                                }}
                                                checked={step2Data.expLt1Yn === "Y"}
                                            />
                                            <div></div>
                                            <span>운전면허 1년 미만시 체크</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="form">
                    <div className="radio-form">
                        <label className="form-title">계약기간</label>
                        <div className="form-field">
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="contractRangeMonthTypes"
                                            value="ALL"
                                            onChange={handleCheckboxChange}
                                            checked={
                                                step2Data.contractRangeMonthTypes?.length === data.rentInfo.contractPrices.length
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <div></div>
                                        <span>전체</span>
                                    </label>
                                </div>
                            </div>
                            {contractMonths.map((month) => (
                                <div className="input-check" key={month.value}>
                                    <div className="checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="contractRangeMonthTypes"
                                                value={month.value}
                                                onChange={handleCheckboxChange}
                                                checked={step2Data.contractRangeMonthTypes?.includes(month.value)}
                                            />
                                            <div></div>
                                            <span>{month.label}</span>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="form">
                    <div className="radio-form">
                        <label className="form-title">필수 공유목록</label>
                        <div className="form-field">
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="CAR_INFO"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("CAR_INFO")}
                                        />
                                        <div></div>
                                        <span>차량사진/정보</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="RENT_PRICE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("RENT_PRICE")}
                                        />
                                        <div></div>
                                        <span>보증금/렌트료</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="MAJOR_OPTION"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("MAJOR_OPTION")}
                                        />
                                        <div></div>
                                        <span>선택옵션</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="DUTY_PRICE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("DUTY_PRICE")}
                                        />
                                        <div></div>
                                        <span>보험조건</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="INDEM_PRICE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("INDEM_PRICE")}
                                        />
                                        <div></div>
                                        <span>면책금</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="DRIVER_RANGE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("DRIVER_RANGE")}
                                        />
                                        <div></div>
                                        <span>운전자 범위</span>
                                    </label>
                                </div>
                            </div>
                            {/* <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="INSURANCE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("INSURANCE")}
                                        />
                                        <div></div>
                                        <span>특약</span>
                                    </label>
                                </div>
                            </div> */}
                            {/* <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="CONTRACT_NOTE"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("CONTRACT_NOTE")}
                                        />
                                        <div></div>
                                        <span>계약유의사항</span>
                                    </label>
                                </div>
                            </div> */}
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="CONTRACT_DOCUMENT"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("CONTRACT_DOCUMENT")}
                                        />
                                        <div></div>
                                        <span>구비서류</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form">
                    <div className="radio-form">
                        <label className="form-title">선택 공유목록</label>
                        <div className="form-field">
                            <div className="input-check">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="shareCarInfoTypes"
                                            value="CONTRACT_CONDITION"
                                            onChange={handleCheckboxChange}
                                            checked={step2Data?.shareCarInfoTypes?.includes("CONTRACT_CONDITION")}
                                        />
                                        <div></div>
                                        <span>계약조건</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
