/* eslint-disable */
import React, { useState } from 'react'

export const Alert = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

  return (
    <>
        <div className='alert container'>
            <div className="alert-tit">
                <h1>알림내역</h1>
            </div>

            <div className="alert-content">
                <table>
                    <colgroup>
                        <col width={"80%"}/>
                        <col width={"20%"}/>
                    </colgroup>

                    <thead>
                        <tr>
                            <th>알림 내용</th>
                            <th>알림 일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='active'>
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab corrupti veritatis quae tempora voluptatum repellendus hic explicabo maxime, inventore error cupiditate rem nobis possimus eius illo? Tenetur enim dolores corporis?
                            Voluptatem dolore ut accusantium totam, minima at minus error hic cumque necessitatibus, quam ducimus animi placeat velit quas nemo ratione dignissimos, ipsam aperiam. Facere recusandae animi sapiente porro quas accusamus?
                            Sapiente aperiam error deleniti facilis neque, cumque, accusamus rerum, molestiae nemo et alias! Iste, impedit ducimus distinctio quo exercitationem cumque totam magnam aut suscipit ea debitis asperiores doloremque tenetur ipsa.
                            Distinctio atque ab quia iure quisquam recusandae, porro optio! At molestiae dolore consectetur, illo modi, aliquid, amet optio veritatis debitis sed sapiente ab maiores possimus fugit unde consequatur quia reprehenderit.
                            Explicabo eligendi veritatis eaque optio placeat, voluptatibus enim! Fuga reprehenderit ipsa ab optio esse, veritatis earum, in cum eligendi ad nam vero quam nemo. Laborum aspernatur vitae architecto eos repellendus.</span>
                            </td>
                            <td>
                                <span>2023-09-09</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab corrupti veritatis quae tempora voluptatum repellendus hic explicabo maxime, inventore error cupiditate rem nobis possimus eius illo? Tenetur enim dolores corporis?
                            Voluptatem dolore ut accusantium totam, minima at minus error hic cumque necessitatibus, quam ducimus animi placeat velit quas nemo ratione dignissimos, ipsam aperiam. Facere recusandae animi sapiente porro quas accusamus?
                            Sapiente aperiam error deleniti facilis neque, cumque, accusamus rerum, molestiae nemo et alias! Iste, impedit ducimus distinctio quo exercitationem cumque totam magnam aut suscipit ea debitis asperiores doloremque tenetur ipsa.
                            Distinctio atque ab quia iure quisquam recusandae, porro optio! At molestiae dolore consectetur, illo modi, aliquid, amet optio veritatis debitis sed sapiente ab maiores possimus fugit unde consequatur quia reprehenderit.
                            Explicabo eligendi veritatis eaque optio placeat, voluptatibus enim! Fuga reprehenderit ipsa ab optio esse, veritatis earum, in cum eligendi ad nam vero quam nemo. Laborum aspernatur vitae architecto eos repellendus.</span>
                            </td>
                            <td>
                                <span>2023-09-09</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab corrupti veritatis quae tempora voluptatum repellendus hic explicabo maxime, inventore error cupiditate rem nobis possimus eius illo? Tenetur enim dolores corporis?
                            Voluptatem dolore ut accusantium totam, minima at minus error hic cumque necessitatibus, quam ducimus animi placeat velit quas nemo ratione dignissimos, ipsam aperiam. Facere recusandae animi sapiente porro quas accusamus?
                            Sapiente aperiam error deleniti facilis neque, cumque, accusamus rerum, molestiae nemo et alias! Iste, impedit ducimus distinctio quo exercitationem cumque totam magnam aut suscipit ea debitis asperiores doloremque tenetur ipsa.
                            Distinctio atque ab quia iure quisquam recusandae, porro optio! At molestiae dolore consectetur, illo modi, aliquid, amet optio veritatis debitis sed sapiente ab maiores possimus fugit unde consequatur quia reprehenderit.
                            Explicabo eligendi veritatis eaque optio placeat, voluptatibus enim! Fuga reprehenderit ipsa ab optio esse, veritatis earum, in cum eligendi ad nam vero quam nemo. Laborum aspernatur vitae architecto eos repellendus.</span>
                            </td>
                            <td>
                                <span>2023-09-09</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab corrupti veritatis quae tempora voluptatum repellendus hic explicabo maxime, inventore error cupiditate rem nobis possimus eius illo? Tenetur enim dolores corporis?
                            Voluptatem dolore ut accusantium totam, minima at minus error hic cumque necessitatibus, quam ducimus animi placeat velit quas nemo ratione dignissimos, ipsam aperiam. Facere recusandae animi sapiente porro quas accusamus?
                            Sapiente aperiam error deleniti facilis neque, cumque, accusamus rerum, molestiae nemo et alias! Iste, impedit ducimus distinctio quo exercitationem cumque totam magnam aut suscipit ea debitis asperiores doloremque tenetur ipsa.
                            Distinctio atque ab quia iure quisquam recusandae, porro optio! At molestiae dolore consectetur, illo modi, aliquid, amet optio veritatis debitis sed sapiente ab maiores possimus fugit unde consequatur quia reprehenderit.
                            Explicabo eligendi veritatis eaque optio placeat, voluptatibus enim! Fuga reprehenderit ipsa ab optio esse, veritatis earum, in cum eligendi ad nam vero quam nemo. Laborum aspernatur vitae architecto eos repellendus.</span>
                            </td>
                            <td>
                                <span>2023-09-09</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab corrupti veritatis quae tempora voluptatum repellendus hic explicabo maxime, inventore error cupiditate rem nobis possimus eius illo? Tenetur enim dolores corporis?
                            Voluptatem dolore ut accusantium totam, minima at minus error hic cumque necessitatibus, quam ducimus animi placeat velit quas nemo ratione dignissimos, ipsam aperiam. Facere recusandae animi sapiente porro quas accusamus?
                            Sapiente aperiam error deleniti facilis neque, cumque, accusamus rerum, molestiae nemo et alias! Iste, impedit ducimus distinctio quo exercitationem cumque totam magnam aut suscipit ea debitis asperiores doloremque tenetur ipsa.
                            Distinctio atque ab quia iure quisquam recusandae, porro optio! At molestiae dolore consectetur, illo modi, aliquid, amet optio veritatis debitis sed sapiente ab maiores possimus fugit unde consequatur quia reprehenderit.
                            Explicabo eligendi veritatis eaque optio placeat, voluptatibus enim! Fuga reprehenderit ipsa ab optio esse, veritatis earum, in cum eligendi ad nam vero quam nemo. Laborum aspernatur vitae architecto eos repellendus.</span>
                            </td>
                            <td>
                                <span>2023-09-09</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}

