/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./NotFound.module.scss";
import WarnIcon from "resources/icon/warning.png";

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.notFound}>
            <img src={WarnIcon} alt="" />
            <h1>요청하신 페이지를 찾을 수 없습니다.</h1>
            <button
                type="button"
                onClick={() => {
                    navigate("/");
                }}
                style={{
                    marginTop: "40px",
                }}
            >
                <span>메인으로 돌아가기</span>
            </button>
        </div>
    );
};
