/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import LogoIcon from "resources/icon/share-logo.png";
import * as Common from "resources/js/Common.js";
import { Modal, Toast } from "components";

import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { useDispatch } from "react-redux";
import { loginSuccess } from "redux/loginSlice";

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        loginId: "",
        password: "",
    });
    const { loginId, password } = formData;

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // 로그인 함수
    const handleSubmit = async () => {
        setIsLoading(true);

        // 입력값 검증
        if (!loginId.trim() || !password.trim()) {
            handleToast("아이디와 비밀번호를 확인해주세요.");
            return;
        }

        try {
            const response = await createData("/token/exchange", formData);
            const accessToken = response.data.access_token;
            localStorage.setItem("access_token", response.data.access_token);
            Common.setCookie("chada_login_status", "login", 1);

            if (accessToken) {
                const response2 = await fetchData("/api/share/member/v1/me");
                const userData = response2.data.data;
                dispatch(loginSuccess({ accessToken, userData }));

                if (userData.roles.includes("RENT_MASTER")) {
                    navigate("/mypage/supplier");
                } else {
                    navigate("/product/list");
                }
            }
        } catch (error) {
            console.log(error.response?.data.message);
            handleToast(error.response?.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    const findAccountInfo = () => {
        showModal(
            "",
            <p>
                아이디 찾기 및 비밀번호 찾기 <br /> 기능은 관리자에게 문의 바랍니다. <br />
                <br />
                02-1234-1234
            </p>,
            [
                { text: "연결하기", handler: handleModalClose },
                { text: "확인", handler: handleModalClose },
            ]
        );
    };

    // 모달 State
    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className={`${styles.login} form-container form-width`}>
                <div className={styles.login__logo}>
                    <img src={LogoIcon} alt="" />
                </div>

                <form className={styles.login__form} action="" onSubmit={handleSubmit}>
                    <div className="input-form">
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    name="loginId"
                                    value={loginId}
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder="아이디 (이메일)"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form mt-15">
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder="비밀번호"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="button-form mt-25">
                        <button type="button" className="btn btnLarge primaryFill" onClick={handleSubmit}>
                            로그인
                        </button>
                    </div>
                </form>

                <ul className={styles.login__mnb}>
                    <li>
                        <button type="button" onClick={findAccountInfo}>
                            아이디 찾기
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={findAccountInfo}>
                            비밀번호 찾기
                        </button>
                    </li>
                </ul>
            </div>

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
