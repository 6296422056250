// /* eslint-disable */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMagnifyingGlass, faRotateRight } from "@fortawesome/free-solid-svg-icons";

import { SetGrade } from ".";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma } from "util";
import { Modal, Toast } from "components";

import MasterIcon from "resources/icon/grade-master.png";
import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const ApprovalDealer = ({ setApprovalDealerModalStatus }) => {
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태
    const [datas, setDatas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");

    const fetchTableData = async (params) => {
        setLoading(true);

        try {
            const params = {
                ag_member_status_list: "JOIN_REQUEST",
            };
            console.log(params);

            const response = await fetchData("/api/share/ag_corp/v1/member", params);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, []);

    const searchDatas = () => {
        const params = {
            search_keyword: searchKeyword,
        };

        fetchTableData(params);
    };

    const initParams = async () => {
        setSearchKeyword("");

        const params = {
            search_keyword: "",
        };

        fetchTableData(params);
    };

    const approvalMember = async (memberId) => {
        setLoading(true);

        try {
            const response = await updateData(`/api/share/ag_corp/v1/member/${memberId}/join/confirm`);
            fetchTableData();
            handleToast(response.data.message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const denyMember = async (memberId) => {
        setLoading(true);

        try {
            const response = await updateData(`/api/share/ag_corp/v1/member/${memberId}/join/deny`);
            fetchTableData();
            handleToast(response.data.message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const [gradeModalStatus, setGradeModalStatus] = useState(false);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            <div className="modal modal-dealer">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>회원 가입 승인</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setApprovalDealerModalStatus(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="dealer_table-container">
                        <div className="dealer_table-head">
                            <div className="dealer_table-tit">
                                <span>신규 회원 목록</span>
                            </div>
                            <div className="dealer_table-filter">
                                <div className="filter-option filter-search">
                                    <input
                                        type="text"
                                        placeholder="딜러명, 고객명을 검색해주세요."
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                    <button type="button" onClick={searchDatas}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                                <div className="filter-option filter-refresh">
                                    <button type="button" className="btn-refresh" onClick={initParams}>
                                        초기화
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="dealer_table-wrapper">
                            <table>
                                <colgroup>
                                    <col width={"25%"} />
                                    <col width={"25%"} />
                                    <col width={"15%"} />
                                    <col width={"30%"} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th align="center">
                                            <div className="tht">등급/딜러명</div>
                                        </th>
                                        <th>
                                            <div className="tht">연락처</div>
                                        </th>
                                        <th>
                                            <div className="tht">가입 일시</div>
                                        </th>
                                        <th>
                                            <div className="tht">승인 여부</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas.length === 0 && (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="tdd" style={{ textAlign: "center" }}>
                                                    <span className="td-desc">조회된 데이터가 없습니다.</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {datas?.map((data, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.member?.shareGrade === "MASTER" && <img src={MasterIcon} alt="" />}
                                                            {data?.member?.shareGrade === "GOLD" && <img src={GoldIcon} alt="" />}
                                                            {data?.member?.shareGrade === "SILVER" && <img src={SilverIcon} alt="" />}
                                                            {data?.member?.shareGrade === "BRONZE" && <img src={BronzeIcon} alt="" />}
                                                            {data?.member?.shareGrade === "NORMAL" && <img src={NormalIcon} alt="" />}
                                                            <b>{data?.member?.name}</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{data?.member?.phone}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.member?.createdAt
                                                                ?.split("T")[0]
                                                                .replace(/^.{2}/, "")
                                                                .replaceAll("-", ".")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="approval-btn-list">
                                                            <button
                                                                type="button"
                                                                className="btn btn-approval"
                                                                onClick={() => approvalMember(data.member.id)}
                                                            >
                                                                승인
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-cancel"
                                                                onClick={() => denyMember(data.member.id)}
                                                            >
                                                                취소
                                                            </button>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* 등급 설정 모달 */}
            {gradeModalStatus && <SetGrade setGradeModalStatus={setGradeModalStatus} />}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
