/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMagnifyingGlass, faRotateRight } from "@fortawesome/free-solid-svg-icons";

import { SetGrade } from ".";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma } from "util";
import { DealerDetail, ChangeGrade } from "../modal";

import { useDispatch } from "react-redux";
import { fetchUserData } from "redux/store";

import MasterIcon from "resources/icon/grade-master.png";
import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const AffiliatedDealerInfo = ({ setAffiliatedDealerInfoModalStatus }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태
    const [datas, setDatas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [dealerDetailModalStatus, setDealerDetailModalStatus] = useState(false);
    const [selectedDealer, setSelectedDealer] = useState(null);

    const fetchTableData = async (params) => {
        setLoading(true);

        try {
            const params = {
                search_keyword: searchKeyword,
            };
            console.log(params);

            const response = await fetchData("/api/share/ag_corp/v1/member", params);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, []);

    const searchDatas = () => {
        const params = {
            search_keyword: searchKeyword,
        };

        fetchTableData(params);
    };

    const initParams = async () => {
        setSearchKeyword("");

        const params = {
            search_keyword: "",
        };

        fetchTableData(params);
    };

    const [gradeModalStatus, setGradeModalStatus] = useState(false);

    const updateLifting=()=> {
        fetchTableData();
        dispatch(fetchUserData(localStorage.getItem("access_token")));
    }

    return (
        <>
            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            <div className="modal modal-dealer">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>소속 딜러 정보</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setAffiliatedDealerInfoModalStatus(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="dealer-summary-wrapper">
                        <div className="dealer-summary summary-all">
                            <div className="summary-head">
                                <span>전체 딜러수</span>
                            </div>
                            <div className="summary-content">
                                <span>{datas?.length}명</span>
                            </div>
                        </div>

                        <div className="dealer-summary">
                            <div className="summary-head">
                                <span>등급별 딜러(4가지)</span>
                                <button type="button" className="btn-grade_set" onClick={() => setGradeModalStatus(true)}>
                                    등급설정
                                </button>
                            </div>
                            <div className="summary-content">
                                <div className="dealer-list">
                                    <div className="grade-item grade-gold">
                                        <div className="grade-icon">
                                            <img src={GoldIcon} alt="" />
                                            <span className="grade-txt">gold</span>
                                        </div>
                                        <div className="grade-count">
                                            <span className="grade-txt">
                                                {datas.reduce((count, data) => {
                                                    return count + (data?.member?.shareGrade === "GOLD" ? 1 : 0);
                                                }, 0)}
                                                명
                                            </span>
                                        </div>
                                    </div>
                                    <div className="grade-item grade-silver">
                                        <div className="grade-icon">
                                            <img src={SilverIcon} alt="" />
                                            <span className="grade-txt">silver</span>
                                        </div>
                                        <div className="grade-count">
                                            <span className="grade-txt">
                                                {datas.reduce((count, data) => {
                                                    return count + (data?.member?.shareGrade === "SILVER" ? 1 : 0);
                                                }, 0)}
                                                명
                                            </span>
                                        </div>
                                    </div>
                                    <div className="grade-item grade-bronze">
                                        <div className="grade-icon">
                                            <img src={BronzeIcon} alt="" />
                                            <span className="grade-txt">bronze</span>
                                        </div>
                                        <div className="grade-count">
                                            <span className="grade-txt">
                                                {datas.reduce((count, data) => {
                                                    return count + (data?.member?.shareGrade === "BRONZE" ? 1 : 0);
                                                }, 0)}
                                                명
                                            </span>
                                        </div>
                                    </div>
                                    <div className="grade-item grade-normal">
                                        <div className="grade-icon">
                                            <img src={NormalIcon} alt="" />
                                            <span className="grade-txt">normal</span>
                                        </div>
                                        <div className="grade-count">
                                            <span className="grade-txt">
                                                {datas.reduce((count, data) => {
                                                    return count + (data?.member?.shareGrade === "NORMAL" ? 1 : 0);
                                                }, 0)}
                                                명
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dealer_table-container">
                        <div className="dealer_table-head">
                            <div className="dealer_table-tit">
                                <span>딜러정보</span>
                            </div>
                            <div className="dealer_table-filter">
                                <div className="filter-option filter-search">
                                    <input
                                        type="text"
                                        placeholder="딜러명, 고객명을 검색해주세요."
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                    <button type="button" onClick={searchDatas}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                                <div className="filter-option filter-refresh">
                                    <button type="button" className="btn-refresh" onClick={initParams}>
                                        초기화
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="dealer_table-wrapper">
                            <table>
                                <colgroup>
                                    <col width={"20%"} />
                                    <col width={"20%"} />
                                    <col width={"16%"} />
                                    <col width={"16%"} />
                                    <col width={"28%"} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="tht">등급/딜러명</div>
                                        </th>
                                        <th>
                                            <div className="tht">연락처</div>
                                        </th>
                                        <th>
                                            <div className="tht">가입 일시</div>
                                        </th>
                                        <th>
                                            <div className="tht">총 계약대수</div>
                                        </th>
                                        <th>
                                            <div className="tht">총 수수료</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas?.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="tdd" style={{ textAlign: "center" }}>
                                                    <span className="td-desc">조회된 데이터가 없습니다.</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {datas?.map((data, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <div
                                                        className="tdd"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDealer(data?.member.id);
                                                            setDealerDetailModalStatus(true);
                                                        }}
                                                    >
                                                        <span className="td-desc">
                                                            {data?.member?.shareGrade === "MASTER" && <img src={MasterIcon} alt="" />}
                                                            {data?.member?.shareGrade === "GOLD" && <img src={GoldIcon} alt="" />}
                                                            {data?.member?.shareGrade === "SILVER" && <img src={SilverIcon} alt="" />}
                                                            {data?.member?.shareGrade === "BRONZE" && <img src={BronzeIcon} alt="" />}
                                                            {data?.member?.shareGrade === "NORMAL" && <img src={NormalIcon} alt="" />}
                                                            <b>{data?.member?.name}</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{data?.member?.phone}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {data?.member?.createdAt
                                                                ?.split("T")[0]
                                                                .replace(/^.{2}/, "")
                                                                .replaceAll("-", ".")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{comma(data?.totalContractCount)}대</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">{comma(data?.totalCommission)}원</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* 딜러 정보 상세 모달 */}
            {dealerDetailModalStatus && <DealerDetail memberId={selectedDealer} setDealerDetailModalStatus={setDealerDetailModalStatus} updateAffiliatedDealerInfo={updateLifting} />}

            {/* 등급 설정 모달 */}
            {gradeModalStatus && <SetGrade setGradeModalStatus={setGradeModalStatus} />}
        </>
    );
};
