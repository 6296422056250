/* eslint-disable */
import React from "react";
import { useToast } from "context";

import styles from "./Toast.module.scss";

export const Toast = ({ visible, onClose, msg = "메세지 없음" }) => {
    return (
        <>
            <div
                className={visible ? `${styles.toast} ${styles.active}` : `${styles.toast}`}
                onClick={visible === Infinity ? () => onClose(false) : null}
                style={visible === Infinity ? { cursor: "pointer" } : null}
            >
                <span>{msg}</span>
            </div>
        </>
    );
};
