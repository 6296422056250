/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faArrowUpWideShort,
    faArrowUpShortWide,
    faMagnifyingGlass,
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma } from "util";
import { Toast } from "components";
import Select from "react-select";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ManagementTable = ({ loading, data, onSortChange, filters, setFilters, onFilterChange, fetchProductData, onSearch }) => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState("");

    const updateProductExposed = async (value, carId) => {
        try {
            const requestData = {
                shareExposedYn: value.value,
            };

            const response = await updateData(`/api/share/rent_corp/product/v1/${carId}/exposed_yn`, requestData);

            fetchProductData();
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const handleDataExposedChange = (value, carId) => {
        updateProductExposed(value, carId);
    };

    const exposedOptions = [
        { value: "Y", label: "노출" },
        { value: "N", label: "미노출" },
    ];

    const [sortMileageStatus, setSortMileageStatus] = useState("");
    const [sortExposedStatus, setSortExposedStatus] = useState("");

    const toggleSortMileage = () => {
        let status;

        if (sortMileageStatus === "") {
            status = "asc";
        } else if (sortMileageStatus === "asc") {
            status = "desc";
        } else {
            status = "";
        }
        setSortMileageStatus(status);
        setSortExposedStatus("");
        onSortChange({
            mileage: status,
            shareExposedAt: "",
        });
    };

    const toggleSortExposed = () => {
        let status;

        if (sortExposedStatus === "") {
            status = "asc";
        } else if (sortExposedStatus === "asc") {
            status = "desc";
        } else {
            status = "";
        }
        setSortExposedStatus(status);
        setSortMileageStatus("");
        onSortChange({
            mileage: "",
            shareExposedAt: status,
        });
    };

    const onKeywordSearch = () => {
        onSearch(searchKeyword);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(searchKeyword);
        }
    };

    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="tableHead">
                <div className="itemContract">
                    <span>총 {data?.pagination?.totalElements}대</span>
                    <div className="filter-option filter-search">
                        <input
                            type="text"
                            name="searchKeyword"
                            value={searchKeyword}
                            placeholder="차량번호를 검색해주세요."
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                            onKeyPress={handleKeyPress}
                        />
                        <button type="button" onClick={onKeywordSearch}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                    </div>
                </div>
                <div className="itemSort">
                    <button type="button" onClick={toggleSortMileage} className={sortMileageStatus ? `active-${sortMileageStatus}` : ""}>
                        주행 거리순
                        {sortMileageStatus === "desc" && <FontAwesomeIcon icon={faArrowUpWideShort} />}
                        {sortMileageStatus === "asc" && <FontAwesomeIcon icon={faArrowUpShortWide} />}
                    </button>
                    <button type="button" onClick={toggleSortExposed} className={sortExposedStatus ? `active-${sortExposedStatus}` : ""}>
                        최근 등록순
                        {sortExposedStatus === "desc" && <FontAwesomeIcon icon={faArrowUpWideShort} />}
                        {sortExposedStatus === "asc" && <FontAwesomeIcon icon={faArrowUpShortWide} />}
                    </button>
                </div>
            </div>

            <div className="tableCont">
                <table>
                    <colgroup>
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "24%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "12%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>차량번호</th>
                            <th>모델명/상세모델</th>
                            <th>유종</th>
                            <th>외장색</th>
                            <th>주행거리/연식</th>
                            <th>계약기간</th>
                            <th>보증금</th>
                            <th>월렌트료</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.map((row, index) => {
                            return loading ? (
                                <tr key={index}>
                                    <td>
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td className="td_model">
                                        <div className="tdd">
                                            <span className="model_name">
                                                <Skeleton width={150} height={20} />
                                            </span>
                                            <span className="model_detail">
                                                <Skeleton width={350} height={20} />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <Skeleton width={150} height={20} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <Skeleton width={100} height={20} />
                                        </div>
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td className="td_deposit">
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td className="td_rent">
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                    <td className="td_rent">
                                        <div className="tdd">
                                            <Skeleton width={80} height={20} />
                                            <Skeleton width={80} height={20} />
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index}>
                                    <td>
                                        <div className="tdd">{row?.carNumber}</div>
                                    </td>
                                    <td className="td_model">
                                        <div className="tdd">
                                            <span className="model_name">{row?.modelName}</span>
                                            <span className="model_status">
                                                {row?.shareReleaseStatus && (
                                                    <span className="status_yellow">
                                                        {row.shareReleaseStatus === "OK"
                                                            ? "출고가능"
                                                            : row.shareReleaseStatus === "IN_10_DAY"
                                                                ? "10일이내 출고가능"
                                                                : row.shareReleaseStatus === "IN_5_DAY"
                                                                    ? "5일이내 출고가능"
                                                                    : row.shareReleaseStatus === "IN_CONTRACT"
                                                                        ? "계약중"
                                                                        : row.shareReleaseStatus === "NO"
                                                                            ? "출고불가"
                                                                            : "알 수 없음"}
                                                    </span>
                                                )}

                                                {row?.rentInfo?.rentType && (
                                                    <span className="status_orange">
                                                        {row.rentInfo?.rentType === "LONG_RENT"
                                                            ? "장기렌트"
                                                            : row.rentInfo?.rentType === "SUBSCRIPTION"
                                                                ? "구독"
                                                                : row.rentInfo?.rentType === "FIRST_RELEASE"
                                                                    ? "선출고"
                                                                    : "알 수 없음"}
                                                    </span>
                                                )}
                                            </span>
                                            <span className="model_detail">
                                                {row?.modelDetailName} <br /> {row?.className} <br /> {row?.trimName}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">
                                            {row?.fuelType === "GAS"
                                                ? "가솔린"
                                                : row?.fuelType === "DIESEL"
                                                    ? "디젤"
                                                    : row?.fuelType === "LPG"
                                                        ? "LPG"
                                                        : row?.fuelType === "GAS_LPG"
                                                            ? "가솔린(LPG겸용)"
                                                            : row?.fuelType === "GAS_CNG"
                                                                ? "가솔린(CNG겸용)"
                                                                : row?.fuelType === "GAS_HYBRID"
                                                                    ? "가솔린 하이브리드"
                                                                    : row?.fuelType === "ELECTRIC"
                                                                        ? "전기"
                                                                        : row?.fuelType === "HYDROGEN"
                                                                            ? "수소"
                                                                            : "알 수 없음"}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tdd">{row?.color}</div>
                                    </td>
                                    <td>
                                        <div className="tdd">{comma(row?.mileage)}km</div>
                                        <div className="tdd">
                                            {row?.carMadedYear}.{row?.carMadedMonth}
                                        </div>
                                    </td>
                                    <td>
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                {priceInfo.contractRangeMonth}개월
                                            </div>
                                        ))}
                                    </td>
                                    <td className="td_deposit">
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                {comma(priceInfo.depositPrice)}
                                            </div>
                                        ))}
                                    </td>
                                    <td className="td_rent">
                                        {row?.rentInfo?.contractPrices?.map((priceInfo) => (
                                            <div key={priceInfo.contractRangeMonth} className="tdd">
                                                {comma(priceInfo.rentPrice)}
                                            </div>
                                        ))}
                                    </td>
                                    <td style={{ paddingRight: "20px" }}>
                                        <div className="select-element">
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                isSearchable={false}
                                                placeholder="노출상태"
                                                options={exposedOptions}
                                                value={exposedOptions.find((option) => option.value === row.shareExposedYn)}
                                                onChange={(value) => {
                                                    handleDataExposedChange(value, row.id);
                                                }}
                                            />
                                        </div>
                                        <div className="tdd">
                                            <span className="td-button">
                                                <button
                                                    type="button"
                                                    className="btn btn-status btn-type-01"
                                                    onClick={() => {
                                                        console.log(row.id);
                                                        navigate(`/product/upload/${row.id}`);
                                                    }}
                                                >
                                                    차량정보 수정
                                                </button>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                        {data?.data?.length === 0 && (
                            <tr className="none-data">
                                <td colSpan={9} style={{ height: "300px", verticalAlign: "middle" }}>
                                    <div className="tdd tac">조회된 차량이 없습니다.</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="tableFoot">
                <div className="pagination">
                    {data?.data?.length !== 0 && loading === false && (
                        <div className="numList">
                            <div
                                className="btnPrev"
                                onClick={() => {
                                    setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        page: data?.pagination?.prevPage,
                                    }));
                                }}
                                aria-current={data?.pagination?.isFirst ? false : true}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                            <div
                                className="btnNext"
                                onClick={() => {
                                    setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        page: data?.pagination?.nextPage,
                                    }));
                                }}
                                aria-current={data?.pagination?.isLast ? false : true}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                            <ul className="currentPage">
                                {Array(data?.pagination?.endPage)
                                    .fill()
                                    .map(
                                        (_, i) =>
                                            i >= data?.pagination?.startPage - 1 && (
                                                <li
                                                    key={i}
                                                    onClick={() => {
                                                        setFilters((prevFilters) => ({
                                                            ...prevFilters,
                                                            page: i,
                                                        }));
                                                    }}
                                                    className={data?.pagination?.page === i ? "active" : null}
                                                >
                                                    {i + 1}
                                                </li>
                                            )
                                    )}
                            </ul>
                            {data?.pagination?.endPage !== data?.pagination?.totalPages && (
                                <div className="lastPage">
                                    <li
                                        onClick={() => {
                                            setFilters((prevFilters) => ({
                                                ...prevFilters,
                                                page: data?.pagination?.totalPages - 1,
                                            }));
                                        }}
                                    >
                                        {data?.pagination?.totalPages}
                                    </li>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
