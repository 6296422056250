import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma, uncomma, handleNumericInput } from "util";

export const ContractNote = ({ setFormStatus, handleToast }) => {
    const [formData, setFormData] = useState({
        contractNote: "",
    });

    const { contractNote } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                contractNote: contractNote,
            };
            console.log(payload);

            const response = await updateData(`/api/share/rent_corp/contract/v1/contract_note`, payload);
            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchContractData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            const contractData = response.data.data;

            setFormData({
                contractNote: contractData.contractNote || "",
            });
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchContractData();
    }, []);

    return (
        <>
            <div className="content-item">
                <div className="content-form">
                    <div className="textarea-element">
                        <textarea
                            name="contractNote"
                            value={contractNote || ""}
                            onChange={handleChange}
                            placeholder="계약 유의사항 내용을 입력해주세요."
                        ></textarea>
                    </div>
                </div>
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>
        </>
    );
};
