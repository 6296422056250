// loginSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: null,
    accessToken: "",
    userData: null,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.accessToken = action.payload;
            state.userData = action.payload.userData;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.accessToken = "";
            state.userData = null;
        },
    },
});

export const { loginSuccess, logout } = loginSlice.actions;
export default loginSlice.reducer;
