import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { convertToKoreanWon, comma, getAgeCategory } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Toast } from "components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import warningIcon from "resources/icon/warning.png";

export const Share = () => {
    const [activeSection, setActiveSection] = useState(null);
    const shareImageRef = useRef(null);
    const vehicleInformationRef = useRef(null);
    const depositRentalFeeRef = useRef(null);
    const contractConditionsRef = useRef(null);
    const liabilityLimitRef = useRef(null);
    const deductibleRef = useRef(null);
    const driverCoverageRef = useRef(null);

    // useEffect(() => {
    //     // 스크롤 섹션
    //     const handleScroll = () => {
    //         const offsets = {
    //             shareImage: shareImageRef.current.offsetTop,
    //             vehicleInformation: vehicleInformationRef.current.offsetTop,
    //             depositRentalFee: depositRentalFeeRef.current.offsetTop,
    //             contractConditions: contractConditionsRef.current.offsetTop,
    //             liabilityLimit: liabilityLimitRef.current.offsetTop,
    //             deductible: deductibleRef.current.offsetTop,
    //             driverCoverage: driverCoverageRef.current.offsetTop,
    //         };

    //         const scrolledPosition = window.scrollY - 40;
    //         let active = null;
    //         for (const section in offsets) {
    //             if (scrolledPosition >= offsets[section]) {
    //                 active = section;
    //             } else {
    //                 break;
    //             }
    //         }
    //         setActiveSection(active);
    //     };

    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // useEffect(() => {
    //     setActiveSection("shareImage");
    // }, [])

    // const scrollToSection = (ref) => {
    //     const additionalOffset = -40;

    //     window.scrollTo({
    //         top: ref.current.offsetTop + additionalOffset,
    //         behavior: "smooth",
    //     });
    // };

    const initialState = {
        isLoading: false,
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [state, setState] = useState(initialState);

    // 차량 정보 수정
    const { shareHistoryId } = useParams();
    const [uploadStatus, setUploadStatus] = useState("NEW");
    const [data, setData] = useState();

    // 필터링된 데이터 가져오기
    const fetchProductData = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const response = await fetchData(`api/share/car_share/v1/${shareHistoryId}`);
            setData(response.data?.data);
            console.log(response.data?.data);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        } catch (error) {
            setData(false);
            setState((prevState) => ({ ...prevState, isLoading: false }));
            handleToast(error.response.data?.message, Infinity);
        }
    };

    useEffect(() => {
        if (shareHistoryId === undefined) return;

        setUploadStatus("MODIFY");
        fetchProductData();
    }, [shareHistoryId]);

    const filteredContractPrices = data?.car.rentInfo?.contractPrices.filter((priceInfo) => {
        if (data?.contractRangeMonthTypes.includes("ALL")) {
            return true;
        } else if (data?.contractRangeMonthTypes.includes("CONTRACT_12") && priceInfo.contractRangeMonth === 12) {
            return true;
        } else if (data?.contractRangeMonthTypes.includes("CONTRACT_24") && priceInfo.contractRangeMonth === 24) {
            return true;
        } else if (data?.contractRangeMonthTypes.includes("CONTRACT_36") && priceInfo.contractRangeMonth === 36) {
            return true;
        } else if (data?.contractRangeMonthTypes.includes("CONTRACT_48") && priceInfo.contractRangeMonth === 48) {
            return true;
        } else if (data?.contractRangeMonthTypes.includes("CONTRACT_60") && priceInfo.contractRangeMonth === 60) {
            return true;
        }
        return false;
    });

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    const sumOptionPrice = () => {
        let totalOptionPrice = 0;
        data?.car.carAddOptionContents?.forEach((element) => {
            totalOptionPrice += element.price;
            //console.log(element);
        });
        return comma(totalOptionPrice);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {state.isLoading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            {data === false ? (
                <div className="mobile-app">
                    <div className="app-content share-detail">
                        <div className="data-empty">
                            <img src={warningIcon} alt="" />
                            <p>차량을 조회할 수 없습니다.</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mobile-app">
                    <div className="app-content share-detail">
                        <div className="share-image-container" ref={shareImageRef}>
                            <Slider {...settings} className="image-wrapper custom-slider">
                                {data?.car.carImages.map((img, idx) => {
                                    return (
                                        <div key={idx} className="image-item">
                                            <img src={img.url} />
                                        </div>
                                    );
                                })}
                            </Slider>

                            <div className="share-pagination"></div>
                        </div>

                        {/* <div className="share-tab-menu">
                        <ul>
                            <li
                                className={activeSection === "shareImage" ? "active" : ""}
                                onClick={() => scrollToSection(shareImageRef)}
                            >
                                <a>차량 이미지</a>
                            </li>
                            <li
                                className={activeSection === "depositRentalFee" ? "active" : ""}
                                onClick={() => scrollToSection(depositRentalFeeRef)}
                            >
                                <a>보증금/렌트료</a>
                            </li>
                            <li
                                className={activeSection === "vehicleInformation" ? "active" : ""}
                                onClick={() => scrollToSection(vehicleInformationRef)}
                            >
                                <a>차량 정보</a>
                            </li>
                            <li
                                className={activeSection === "contractConditions" ? "active" : ""}
                                onClick={() => scrollToSection(contractConditionsRef)}
                            >
                                <a>계약조건</a>
                            </li>
                            <li
                                className={activeSection === "liabilityLimit" ? "active" : ""}
                                onClick={() => scrollToSection(liabilityLimitRef)}
                            >
                                <a>책임한도</a>
                            </li>
                            <li
                                className={activeSection === "driverCoverage" ? "active" : ""}
                                onClick={() => scrollToSection(driverCoverageRef)}
                            >
                                <a>운전자범위</a>
                            </li>
                            <li className={activeSection === "deductible" ? "active" : ""} onClick={() => scrollToSection(deductibleRef)}>
                                <a>특약</a>
                            </li>
                        </ul>
                    </div> */}

                        <div className="share-section">
                            <div className="item-list-col">
                                <div className="item">
                                    <span className="item-tit">담당자</span>
                                    <span className="item-desc">{data?.managerName}</span>
                                </div>
                                <div className="item">
                                    <span className="item-tit">연락처</span>
                                    <span className="item-desc">{data?.managerPhone}</span>
                                </div>
                            </div>
                        </div>

                        <div className="share-section">
                            <div className="model-name">{data?.car.modelName}</div>
                            <div className="model-detail">
                                {data?.car.modelDetailName} {data?.car.className} {data?.car.trimName}
                            </div>
                        </div>

                        {data?.shareCarInfoTypes?.includes("RENT_PRICE") && (
                            <div className="share-section" ref={depositRentalFeeRef}>
                                <div className="section-head">
                                    <span>보증금/렌트료</span>
                                </div>
                                <div className="item-list-row devide-3">
                                    <div className="item">
                                        <span className="item-tit">약정개월</span>
                                        {filteredContractPrices?.map((priceInfo) => (
                                            <span key={priceInfo.contractRangeMonth} className="item-desc">
                                                {priceInfo.contractRangeMonth}개월
                                            </span>
                                        ))}
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">보증금</span>
                                        {filteredContractPrices?.map((priceInfo) => (
                                            <span key={priceInfo.contractRangeMonth} className="item-desc">
                                                {comma(priceInfo.depositPrice)}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">렌트료</span>
                                        {filteredContractPrices?.map((priceInfo) => {
                                            let rentPrice = priceInfo.rentPrice;
                                            if (data?.ageType === "AGE_21") {
                                                rentPrice += data?.car.rentInfo?.age21RentPrice || 0;
                                            } else if (data?.ageType === "AGE_23") {
                                                rentPrice += data?.car.rentInfo?.age23RentPrice || 0;
                                            }

                                            if (data?.expLt1Yn === "Y") {
                                                rentPrice += data?.car.rentInfo?.expLt1RentPrice || 0;
                                            }

                                            return (
                                                <span key={priceInfo.contractRangeMonth} className="item-desc">
                                                    {comma(rentPrice)}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("CAR_INFO") && (
                            <div className="share-section" ref={vehicleInformationRef}>
                                <div className="section-head">
                                    <span>차량정보</span>
                                </div>
                                <div className="item-list-row devide-3">
                                    <div className="item">
                                        <span className="item-tit">차량번호</span>
                                        <span className="item-desc">{data?.car.carNumber}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">주행거리</span>
                                        <span className="item-desc">{comma(data?.car.mileage)}km</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">연료</span>
                                        <span className="item-desc">
                                            {data?.car.fuelType === "GAS"
                                                ? "가솔린"
                                                : data?.car.fuelType === "DIESEL"
                                                ? "디젤"
                                                : data?.car.fuelType === "LPG"
                                                ? "LPG"
                                                : data?.car.fuelType === "GAS_LPG"
                                                ? "가솔린(LPG겸용)"
                                                : data?.car.fuelType === "GAS_CNG"
                                                ? "가솔린(CNG겸용)"
                                                : data?.car.fuelType === "GAS_HYBRID"
                                                ? "가솔린 하이브리드"
                                                : data?.car.fuelType === "ELECTRIC"
                                                ? "전기"
                                                : data?.car.fuelType === "HYDROGEN"
                                                ? "수소"
                                                : "알 수 없음"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">색상</span>
                                        <span className="item-desc">{data?.car.color}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">신차가격</span>
                                        <span className="item-desc">{data?.car.newPrice ? comma(data?.car.newPrice) + "원" : ""}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">연식</span>
                                        <span className="item-desc">
                                            {data?.car.carMadedYear}.{data?.car.carMadedMonth}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">배기량</span>
                                        <span className="item-desc">{comma(data?.car.displacement)}cc</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">변속기</span>
                                        <span className="item-desc">
                                            {data?.car.motorType === "AUTOMATIC"
                                                ? "자동"
                                                : data?.car.motorType === "MANUAL"
                                                ? "수동"
                                                : data?.car.motorType === "CVT"
                                                ? "CVT"
                                                : data?.car.motorType === "DUAL"
                                                ? "듀얼클러치"
                                                : "알 수 없음"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("MAJOR_OPTION") && (
                            <div className="share-section">
                                <div className="section-head">
                                    <span>선택옵션</span>
                                    <p>
                                        <em>{sumOptionPrice() + "원"}</em> 상당의 선택이 옵션이 추가된 차량입니다.
                                    </p>
                                </div>

                                <div className="option-list">
                                    {/* {data?.car.carAddOptionContents?.map((option, index) => (
                                        <div key={index} className="item">
                                            <span className="item-tit">{option.name}</span>
                                        </div>
                                    ))} */}
                                    {data?.car.carAddOptionContents?.map((option, index) => {
                                        if (option.carAddOptionContentItems.length === 0) {
                                            return (
                                                <div key={index} className="item option-item">
                                                    <div className="item-desc">{option.name}</div>
                                                </div>
                                            );
                                        } else {
                                            return option.carAddOptionContentItems.map((sub_option, sub_index) => {
                                                return (
                                                    <div key={sub_index} className="item option-item">
                                                        <div className="item-desc">
                                                            <span>{sub_option.title}</span>
                                                            {/* {sub_option.content !== "" && (
                                                                <button type="button">
                                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                                    <div className="guide_popup">
                                                                        <p className="guide_popup-desc">{sub_option.content}</p>
                                                                    </div>
                                                                </button>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                );
                                            });
                                        }
                                    })}
                                </div>

                                <div className="option-txt">
                                    * 선택옵션의 가격은 해당 오변의 현태가격이 아니며, 신차 출고 당시의 옵션 가격을 표시하고 있습니다.
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("CONTRACT_CONDITION") && (
                            <div className="share-section" ref={contractConditionsRef}>
                                <div className="section-head">
                                    <span>계약조건</span>
                                </div>
                                <div className="item-list-row devide-3">
                                    <div className="item">
                                        <span className="item-tit">탁송비용 지원</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo.deliverySupportPrice
                                                ? `${comma(data?.car.rentInfo.deliverySupportPrice)}원 지원`
                                                : "미지원"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">정비상품</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo?.inspectionProducts.length !== 0
                                                ? data?.car.rentInfo?.inspectionProducts?.map((row, idx) => {
                                                      return <p key={idx}>{row.name}</p>;
                                                  })
                                                : "미포함"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">계약방식</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo.contractTypes?.includes("ONLINE") && "전자계약"}
                                            {data?.car.rentInfo.contractTypes?.includes("ONLINE") &&
                                                data?.car.rentInfo.contractTypes?.includes("OFFLINE") &&
                                                " / "}
                                            {data?.car.rentInfo.contractTypes?.includes("OFFLINE") && "방문계약"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("INSURANCE") && (
                            <div className="share-section" ref={liabilityLimitRef}>
                                <div className="section-head">
                                    <span>보험조건</span>
                                    <p>책임한도</p>
                                </div>
                                <div className="item-list-row devide-3">
                                    <div className="item">
                                        <span className="item-tit">무보험 상해</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo?.dutyNoInsuranceInjury
                                                ? `가입(${convertToKoreanWon(data?.car.rentInfo?.dutyNoInsuranceInjury)})`
                                                : "미가입"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">대인</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo?.dutyDaein === -1
                                                ? `무제한`
                                                : `${convertToKoreanWon(data?.car.rentInfo?.dutyDaein)}`}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">대물</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo?.dutyDaemul ? `${convertToKoreanWon(data?.car.rentInfo?.dutyDaemul)}` : "-"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">자손(사망)</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo?.dutyJason ? `${convertToKoreanWon(data?.car.rentInfo?.dutyJason)}` : "-"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">자차</span>
                                        <span className="item-desc">
                                            {data?.car.rentInfo.dutyJacha ? `정비 비용의 ${data?.car.rentInfo?.dutyJacha}%` : "미가입"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("INDEM_PRICE") && (
                            <div className="share-section">
                                <div className="section-head">
                                    <span>
                                        면책금 <i>* 보험접수 발생시 부과됩니다.</i>
                                    </span>
                                </div>
                                <div className="item-list-row devide-4">
                                    <div className="item">
                                        <span className="item-tit" style={{ visibility: "hidden" }}>
                                            타이틀
                                        </span>
                                        {(data?.ageType === "ALL" || data?.ageType === "AGE_26") && (
                                            <span className="item-tit">만 26세 이상</span>
                                        )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_23") && (
                                                <span className="item-tit">만 23세 이상</span>
                                            )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_21") && (
                                                <span className="item-tit">만 21세 이상</span>
                                            )}
                                        {data?.car.rentInfo?.expLt1Yn === "Y" && (data?.ageType === "ALL" || data?.expLt1Yn === "Y") && (
                                            <span className="item-tit">운전면허 1년 미만</span>
                                        )}
                                    </div>
                                    <div className="item" style={{ textAlign: "right" }}>
                                        <span className="item-tit">대인</span>
                                        {(data?.ageType === "ALL" || data?.ageType === "AGE_26") && (
                                            <span className="item-desc">{comma(data?.car.rentInfo.indemDaein)}원</span>
                                        )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_23") && (
                                                <span className="item-desc">
                                                    {comma(data?.car.rentInfo.indemDaein + data?.car.rentInfo.age23IndemPrice)}원
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_21") && (
                                                <span className="item-desc">
                                                    {comma(data?.car.rentInfo.indemDaein + data?.car.rentInfo.age21IndemPrice)}원
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.expLt1Yn === "Y" && (data?.ageType === "ALL" || data?.expLt1Yn === "Y") && (
                                            <span className="item-desc">
                                                {comma(data?.car.rentInfo.indemDaein + data?.car.rentInfo.expLt1IndemPrice)}원
                                            </span>
                                        )}
                                    </div>
                                    <div className="item" style={{ textAlign: "right" }}>
                                        <span className="item-tit">대물</span>
                                        {(data?.ageType === "ALL" || data?.ageType === "AGE_26") && (
                                            <span className="item-desc">{comma(data?.car.rentInfo.indemDaemul)}원</span>
                                        )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_23") && (
                                                <span className="item-desc">
                                                    {comma(data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age23IndemPrice)}원
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_21") && (
                                                <span className="item-desc">
                                                    {comma(data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age21IndemPrice)}원
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.expLt1Yn === "Y" && (data?.ageType === "ALL" || data?.expLt1Yn === "Y") && (
                                            <span className="item-desc">
                                                {comma(data?.car.rentInfo.indemDaemul + data?.car.rentInfo.expLt1IndemPrice)}원
                                            </span>
                                        )}
                                    </div>
                                    <div className="item" style={{ textAlign: "right" }}>
                                        <span className="item-tit">자차</span>
                                        {(data?.ageType === "ALL" || data?.ageType === "AGE_26") && (
                                            <span className="item-desc">
                                                최소 {convertToKoreanWon(data?.car.rentInfo?.indemJachaMin)} ~ <br /> 최대{" "}
                                                {convertToKoreanWon(data?.car.rentInfo?.indemJachaMax)}
                                            </span>
                                        )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_23") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_23") && (
                                                <span className="item-desc">
                                                    최소{" "}
                                                    {convertToKoreanWon(
                                                        data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age23IndemPrice
                                                    )}{" "}
                                                    ~ <br /> 최대{" "}
                                                    {convertToKoreanWon(
                                                        data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age23IndemPrice
                                                    )}
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.contractAgeTypes?.includes("AGE_21") &&
                                            (data?.ageType === "ALL" || data?.ageType === "AGE_21") && (
                                                <span className="item-desc">
                                                    최소{" "}
                                                    {convertToKoreanWon(
                                                        data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age21IndemPrice
                                                    )}{" "}
                                                    ~ <br /> 최대{" "}
                                                    {convertToKoreanWon(
                                                        data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age21IndemPrice
                                                    )}
                                                </span>
                                            )}
                                        {data?.car.rentInfo?.expLt1Yn === "Y" && (data?.ageType === "ALL" || data?.expLt1Yn === "Y") && (
                                            <span className="item-desc">
                                                최소{" "}
                                                {convertToKoreanWon(
                                                    data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.expLt1IndemPrice
                                                )}{" "}
                                                ~ <br /> 최대{" "}
                                                {convertToKoreanWon(
                                                    data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.expLt1IndemPrice
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.shareCarInfoTypes?.includes("DRIVER_RANGE") && (
                            <div className="share-section" ref={driverCoverageRef}>
                                <div className="section-head">
                                    <p>운전자 범위</p>
                                </div>
                                <div className="item-list-col-over">
                                    <div className="item">
                                        <span className="item-tit">개인 계약</span>
                                        <span className="item-desc">{data?.car.rentInfo.personalDriverRange}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">개인 사업자 계약</span>
                                        <span className="item-desc">{data?.car.rentInfo.personalBizDriverRange}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">법인 사업자 계약</span>
                                        <span className="item-desc">{data?.car.rentInfo.corpDriverRange}</span>
                                    </div>
                                    <div className="item">
                                        <span className="item-tit">구독 계약</span>
                                        <span className="item-desc">{data?.car.rentInfo.subscriptionDriverRange}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* {data?.shareCarInfoTypes?.includes("DUTY_PRICE") && (
                            <div className="share-section" ref={deductibleRef}>
                                <div className="section-head">
                                    <span>특약</span>
                                </div>

                                <div className="description-box">
                                    {data?.car.rentInfo.depositTwiceYn === "Y" && (
                                        <p>
                                            <em>• 보증금 2회 분납 가능</em>
                                        </p>
                                    )}
                                    {data?.car.rentInfo.expLt1Yn === "Y" && (
                                        <p>
                                            <em>• 운전면허 1년 미만 계약 가능</em>
                                        </p>
                                    )}
                                    {data?.car.rentInfo.contractAgeTypes?.includes("AGE_21") && (
                                        <p>
                                            <em>• 만 21세 이상 가능</em>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;
                                            <span>* 월 렌트료 {convertToKoreanWon(data?.car.rentInfo.age21RentPrice)} 추가</span>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;
                                            <span>* 면책금 기본 + {convertToKoreanWon(data?.car.rentInfo.age21IndemPrice)} 추가</span>
                                        </p>
                                    )}
                                    {data?.car.rentInfo.contractAgeTypes?.includes("AGE_23") && (
                                        <p>
                                            <em>• 만 23세 이상 가능</em>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;
                                            <span>* 월 렌트료 {convertToKoreanWon(data?.car.rentInfo.age23RentPrice)} 추가</span>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;
                                            <span>* 면책금 기본 + {convertToKoreanWon(data?.car.rentInfo.age23IndemPrice)} 추가</span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )} */}

                        {/* {data?.shareCarInfoTypes?.includes("CONTRACT_NOTE") && (
                            <div className="share-section">
                                <div className="section-head">
                                    <span>계약 유의사항</span>
                                </div>

                                <div className="description-box">
                                    {data?.car.rentInfo.contractNote === "" ? (
                                        "내용 없음"
                                    ) : (
                                        <p>
                                            <em>{data?.car.rentInfo.contractNote}</em>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )} */}

                        {data?.shareCarInfoTypes?.includes("CONTRACT_DOCUMENT") && (
                            <div className="share-section">
                                <div className="section-head">
                                    <span>구비서류</span>
                                </div>

                                <div className="description-box">
                                    <p>
                                        {`- 기본서류(개인)
                                    운전면허증
                                    주민등록등본
                                    주민등록초본
                                    가족관계증명서
                                    본인서명사실확인서
                                    운전경력증명서
                                    통장사본

                                    - 개인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    사업자통장사본

                                    - 법인사업자
                                    기본서류(대표자)
                                    사업자등록증
                                    법인등기부등본
                                    법인인감증명서
                                    법인통장사본`}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};

export default Share;
