/* eslint-disable */
import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { convertPhone } from "util";
import { DaumPost, Modal, Toast } from "components";

export const ApplicationConsignment = ({ setModal, showModal, handleModalClose, dim, contractId, deliveryType, datas, fetchTable }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [btnSubmitStatus, setBtnSubmitStatus] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const [formData, setFormData] = useState({
        srcAddress: "", // 출발지 주소
        srcAddressDetail: "", // 출발지 상세주소
        srcBcode: "", // 출발지 Bcode
        srcPhone: "", // 인계자 연락처
        destAddress: "", // 목적지 주소
        destAddressDetail: "", // 목적지 상세주소
        destBcode: "", // 목적지 Bcode
        destPhone: "", // 인수자 연락처
        managerPhone: "", // 담당자 연락처
        customerPhone: "", // 고객 연락처
    });

    const { srcAddress, srcAddressDetail, srcBcode, destAddress, destAddressDetail, destBcode, srcPhone, destPhone } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : null);
    };

    const hourOptions = [
        { value: "1", label: "01시" },
        { value: "2", label: "02시" },
        { value: "3", label: "03시" },
        { value: "4", label: "04시" },
        { value: "5", label: "05시" },
        { value: "6", label: "06시" },
        { value: "7", label: "07시" },
        { value: "8", label: "08시" },
        { value: "9", label: "09시" },
        { value: "10", label: "10시" },
        { value: "11", label: "11시" },
        { value: "12", label: "12시" },
        { value: "13", label: "13시" },
        { value: "14", label: "14시" },
        { value: "15", label: "15시" },
        { value: "16", label: "16시" },
        { value: "17", label: "17시" },
        { value: "18", label: "18시" },
        { value: "19", label: "19시" },
        { value: "20", label: "20시" },
        { value: "21", label: "21시" },
        { value: "22", label: "22시" },
        { value: "23", label: "23시" },
        { value: "24", label: "24시" },
    ];

    const minuteOptions = [
        { value: "0", label: "00분" },
        { value: "1", label: "01분" },
        { value: "2", label: "02분" },
        { value: "3", label: "03분" },
        { value: "4", label: "04분" },
        { value: "5", label: "05분" },
        { value: "6", label: "06분" },
        { value: "7", label: "07분" },
        { value: "8", label: "08분" },
        { value: "9", label: "09분" },
        { value: "10", label: "10분" },
        { value: "11", label: "11분" },
        { value: "12", label: "12분" },
        { value: "13", label: "13분" },
        { value: "14", label: "14분" },
        { value: "15", label: "15분" },
        { value: "16", label: "16분" },
        { value: "17", label: "17분" },
        { value: "18", label: "18분" },
        { value: "19", label: "19분" },
        { value: "20", label: "20분" },
        { value: "21", label: "21분" },
        { value: "22", label: "22분" },
        { value: "23", label: "23분" },
        { value: "24", label: "24분" },
        { value: "25", label: "25분" },
        { value: "26", label: "26분" },
        { value: "27", label: "27분" },
        { value: "28", label: "28분" },
        { value: "29", label: "29분" },
        { value: "30", label: "30분" },
        { value: "31", label: "31분" },
        { value: "32", label: "32분" },
        { value: "33", label: "33분" },
        { value: "34", label: "34분" },
        { value: "35", label: "35분" },
        { value: "36", label: "36분" },
        { value: "37", label: "37분" },
        { value: "38", label: "38분" },
        { value: "39", label: "39분" },
        { value: "40", label: "40분" },
        { value: "41", label: "41분" },
        { value: "42", label: "42분" },
        { value: "43", label: "43분" },
        { value: "44", label: "44분" },
        { value: "45", label: "45분" },
        { value: "46", label: "46분" },
        { value: "47", label: "47분" },
        { value: "48", label: "48분" },
        { value: "49", label: "49분" },
        { value: "50", label: "50분" },
        { value: "51", label: "51분" },
        { value: "52", label: "52분" },
        { value: "53", label: "53분" },
        { value: "54", label: "54분" },
        { value: "55", label: "55분" },
        { value: "56", label: "56분" },
        { value: "57", label: "57분" },
        { value: "58", label: "58분" },
        { value: "59", label: "59분" },
    ];

    const requestDelivery = async () => {
        // setLoading(true);

        try {
            const formattedDate = startDate.toISOString();
            const formattedTime = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;

            let url;
            let requestData = {};

            if (deliveryType === "DELIVERY") {
                url = `/api/share/rent_corp/contract/v1/${contractId}/delivery`;
                requestData = {
                    dateTime: `${formattedDate.slice(0, 10)}T${formattedTime}:00.000Z`,
                    srcAddress: srcAddress,
                    srcAddressDetail: srcAddressDetail,
                    srcBcode: srcBcode,
                    srcPhone: srcPhone,
                    destAddress: destAddress,
                    destAddressDetail: destAddressDetail,
                    destBcode: destBcode,
                    destPhone: destPhone,
                };
            } else if (deliveryType === "RETURN") {
                url = `/api/share/rent_corp/contract/v1/${contractId}/return`;
                requestData.returnedAt = datas.startDate;
                requestData.returnReason = datas.returnReason;
                requestData.deliveryRequest = {
                    dateTime: `${formattedDate.slice(0, 10)}T${formattedTime}:00.000Z`,
                    srcAddress: srcAddress,
                    srcAddressDetail: srcAddressDetail,
                    srcBcode: srcBcode,
                    srcPhone: srcPhone,
                    destAddress: destAddress,
                    destAddressDetail: destAddressDetail,
                    destBcode: destBcode,
                    destPhone: destPhone,
                };
            } else if (deliveryType === "REFUND") {
                url = `/api/share/rent_corp/contract/v1/${contractId}/refund`;
                requestData.refundedAt = datas.startDate;
                requestData.returnReason = datas.returnReason;
                requestData.deliveryRequest = {
                    dateTime: `${formattedDate.slice(0, 10)}T${formattedTime}:00.000Z`,
                    srcAddress: srcAddress,
                    srcAddressDetail: srcAddressDetail,
                    srcBcode: srcBcode,
                    srcPhone: srcPhone,
                    destAddress: destAddress,
                    destAddressDetail: destAddressDetail,
                    destBcode: destBcode,
                    destPhone: destPhone,
                };
            }

            const response = await updateData(url, requestData);

            console.log(response);

            showModal("", <p>{response.data.message}</p>, [
                {
                    text: "확인",
                    handler: () => {
                        handleModalClose();
                        if (fetchTable) fetchTable();
                        setModal(false);
                    },
                },
            ]);

            //TODO 환수나 반납인 경우에는 재등록 여부 모달창 띄우기

            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data?.message, Infinity);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 계약 내역
    const fetchDetailData = async () => {
        setLoading(true);

        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/${contractId}`);
            setData(response.data.data);

            if (deliveryType === "DELIVERY") {
                setFormData((prevState) => ({
                    ...prevState,
                    srcAddress: response.data.data.car.carLocationAddress,
                    srcAddressDetail: response.data.data.car.carLocationAddressDetail,
                    srcBcode: response.data.data.car.carLocationBcode,
                    srcPhone: response.data.data.contractManager?.phone,
                    destAddress: response.data.data.customerAddress,
                    destAddressDetail: response.data.data.customerAddressDetail,
                    destBcode: response.data.data.customerAddressBcode,
                    destPhone: response.data.data.customerPhone,
                }));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        console.log(contractId);
        if (contractId) fetchDetailData();
    }, []);

    const onSubmit = () => {
        requestDelivery();
    };

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState({
        isOpen: false,
        addressType: "",
    });

    const openPostCode = (addressType) => {
        setAddressPopup({
            isOpen: true,
            addressType: addressType,
        });
    };

    const closePostCode = () => {
        setAddressPopup({
            isOpen: false,
            addressType: "",
        });
    };

    const handleAddress = (data) => {
        console.log(addressPopup);

        if (addressPopup.addressType === "SRC") {
            setFormData((prevState) => ({
                ...prevState,
                srcAddress: data.daumData.address,
                srcAddressDetail: "",
                srcBcode: data.daumData.bcode,
            }));
        } else if (addressPopup.addressType === "DEST") {
            setFormData((prevState) => ({
                ...prevState,
                destAddress: data.daumData.address,
                destAddressDetail: "",
                destBcode: data.daumData.bcode,
            }));
        }
        closePostCode();
    };

    useEffect(() => {
        if (startDate !== null && hour !== null && minute !== null && srcAddress !== null && destAddress) {
            setBtnSubmitStatus(true);
        } else {
            setBtnSubmitStatus(false);
        }
    }, [startDate, hour, minute, srcAddress, destAddress]);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {dim && <div className="modal-layer"></div>}

            {loading === false && (
                <div className="modal modal-type-1 modal-mypage width-555">
                    <div className="modal-title">
                        <span className="modal-tit-main">
                            {deliveryType === "DELIVERY" && <span>탁송 신청</span>}
                            {deliveryType === "RETURN" && <span>반납 신청</span>}
                            {deliveryType === "REFUND" && <span>환수 신청</span>}
                        </span>
                        <button type="button" className="btn-close" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>

                    <div className="modal-content">
                        <div className="consignment_item-list">
                            <div className="item">
                                <div className="item-head">
                                    <span>차량번호</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{data?.car.carNumber}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>차종</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <span>{data?.car.modelDetailName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>출발시간</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <div className="input-datepicker">
                                            <FontAwesomeIcon icon={faCalendarDays} style={{ color: "#ddd", marginRight: "10px" }} />
                                            <DatePicker
                                                locale={ko}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText=""
                                                selected={startDate}
                                                minDate={new Date()} // 오늘 날짜를 최소 선택 가능 날짜로 설정
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(date) => setStartDate(date)}
                                            />
                                        </div>
                                        <div className="select-element">
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                isSearchable={false}
                                                placeholder="시"
                                                options={hourOptions}
                                                onChange={(value) => handleSelectChange(value, setHour)}
                                            />
                                        </div>
                                        <div className="select-element">
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                isSearchable={false}
                                                placeholder="분"
                                                options={minuteOptions}
                                                onChange={(value) => handleSelectChange(value, setMinute)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>출발지</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <div className="input-element disabled">
                                            <input type="text" name="srcAddress" value={srcAddress || ""} onChange={handleChange} />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                openPostCode("SRC");
                                            }}
                                        >
                                            변경
                                        </button>
                                    </div>
                                    <div className="input-form">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                name="srcAddressDetail"
                                                value={srcAddressDetail || ""}
                                                onChange={handleChange}
                                                placeholder="상세주소"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>인계자 연락처</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <div className="input-element">
                                            <input type="text" name="srcPhone" value={srcPhone || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>도착지</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <div className="input-element disabled">
                                            <input type="text" name="destAddress" value={destAddress || ""} onChange={handleChange} />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                openPostCode("DEST");
                                            }}
                                        >
                                            변경
                                        </button>
                                    </div>
                                    <div className="input-form">
                                        <div className="input-element">
                                            <input
                                                type="text"
                                                name="destAddressDetail"
                                                value={destAddressDetail || ""}
                                                onChange={handleChange}
                                                placeholder="상세주소"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>인수자 연락처</span>
                                </div>
                                <div className="item-content">
                                    <div className="input-form">
                                        <div className={deliveryType === "DELIVERY" ? "input-element disabled" : "input-element"}>
                                            <input type="text" name="destPhone" value={destPhone || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-bottom">
                        <button
                            type="button"
                            className="btn-modal"
                            onClick={() => {
                                onSubmit();
                                // setModal(false);
                                // showModal("", <p>탁송이 완료되었습니다.</p>, [{ text: "확인", handler: handleModalClose }]);
                            }}
                            disabled={!btnSubmitStatus}
                        >
                            신청
                        </button>
                    </div>
                </div>
            )}

            {addressPopup.isOpen && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
