/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Modal_info, Toast, ProgressBar, VerifyPhone } from "components";
import Select from "react-select";
import styles from "./Join.module.scss";
import "resources/styles/origin.scss";

import fileUploadIcon from "resources/icon/PluscirclePr.svg";
import fileDeleteIcon from "resources/icon/ClosecircleGr.svg";

import { fetchData, createData, createFormData, updateData, deleteData } from "api";

export const Join = () => {
    let navigate = useNavigate();
    const { corpId } = useParams();

    // 상태 관리
    const tabCount = 5;
    const [tabIndex, setTabIndex] = useState(1);
    const [progressValue, setProgressValue] = useState(0);
    const [allowStep, setAllowStep] = useState(1);
    const [btnState, setBtnState] = useState(false);
    const [isManagerCheck, setIsManagerCheck] = useState(false);
    const [isManagerAuth, setIsManagerAuth] = useState(false);
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [findDataId, setFindDataId] = useState();
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [terms, setTerms] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [imgFile, setImgFile] = useState(null);
    const [imgSrc, setImgSrc] = useState("");
    const [filename, setFilename] = useState("명함 업로드");
    const [mobileType, setMobileType] = useState("KT");
    const [idWarnStatus, setIdWarnStatus] = useState(true);
    const [idWarnTxt, setIdWarnTxt] = useState("");
    const [pwWarnStatus, setPwWarnStatus] = useState(true);
    const [pwWarnTxt, setPwWarnTxt] = useState("");
    const [licenseWarnStatus, setLicenseWarnStatus] = useState(true);
    const [corpWarnStatus, setCorpWarnStatus] = useState(true);
    const [corpWarnTxt, setCorpWarnTxt] = useState("");
    const [formData, setFormData] = useState({
        loginId: "",
        password: "",
        confirmPwd: "",
        corpName: "법인명",
        corpRegNumber: "123123",
        address: "대전",
        addressDetail: "대덕구",
        repName: "대표자",
        idNumberF: "",
        idNumberB: "",
        managerPhone: "",
        managerPhoneAuth: "",
        managerName: "",
        managerDivision: "",
        managerPosition: "",
        managerEmail: "",
    });
    const {
        loginId,
        password,
        confirmPwd,
        corpName,
        corpRegNumber,
        address,
        addressDetail,
        repName,
        managerName,
        managerDivision,
        managerPosition,
        idNumberF,
        idNumberB,
        managerPhone,
        managerPhoneAuth,
        managerEmail,
    } = formData;

    const handleTabChange = async () => {
        switch (tabIndex) {
            case 0:
                if (!validateTerms()) return;
                break;
            case 1:
                if (!(await validateIdAndPassword())) return;
                break;
            case 2:
                if (!validateCorpInfo()) return;
                break;
            case 3:
                if (!validateManagerInfo()) return;
                break;
            case 4:
                if (!(await submitFinalStep())) return;
                break;
            default:
                break;
        }

        // 공통 로직: 다음 탭으로 이동
        moveToNextTab();
    };

    const validateTerms = () => {
        // 이용약관 검증 로직
        return checkedItems.includes("provision") && checkedItems.includes("privacy") && checkedItems.includes("advertise");
    };

    const validateIdAndPassword = async () => {
        // 아이디와 비밀번호 검증 로직
        if (loginId.length < 6) {
            setIdWarnStatus(false);
            setIdWarnTxt("최소 6글자 이상 입력해주세요.");
            return;
        }
        if (password !== confirmPwd) {
            setPwWarnStatus(false);
            setPwWarnTxt("비밀번호가 일치하지 않습니다.");
            return;
        }
        if (password.length < 8) {
            setPwWarnStatus(false);
            setPwWarnTxt("최소 8글자 이상 입력해주세요.");
            return;
        }
        if ((await verifyLoginIdAvailability()) === false) {
            // 아이디 중복 검사
            setIdWarnStatus(false);
            setIdWarnTxt("아이디가 이미 존재합니다.");
            return;
        }
        setPwWarnStatus(true);
        setIdWarnStatus(true);
        return true;
    };

    const validateCorpInfo = () => {
        // 법인 정보 검증 로직
        setLicenseWarnStatus(true);
        setCorpWarnStatus(true);
        return true;
    };

    const validateManagerInfo = () => {
        // 담당자 정보 검증 로직
        return true;
    };

    const submitFinalStep = async () => {
        // 마지막 단계 처리 로직
        const result1 = await fetchImageData();
        if (result1 === false) {
            handleToast("명함 사진을 업로드해주세요.");
            return;
        }
        const result2 = await fetchFormData(result1.data.data.id);

        if (result2 === false) {
            return;
        } else {
            navigate("/welcome");
        }
    };

    const moveToNextTab = () => {
        const nextTabIndex = tabIndex + 1 < tabCount ? tabIndex + 1 : tabIndex;
        setTabIndex(nextTabIndex);
        setProgressValue((nextTabIndex + 1) * (100 / tabCount));

        setBtnState(false);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 10);
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        // 초기 데이터 가져오기
        await getCorpDatas(corpId);
        // await fetchTerms();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // 통신사
    const mobileTypeOptions = [
        { value: "KT", label: "KT" },
        { value: "SKT", label: "SKT" },
        { value: "LG", label: "LG" },
    ];

    // 명합 파일 업로드
    const resetFileData = () => {
        if (filename === "명함 업로드") return;
        setFilename("명함 업로드");
        setImgSrc("");
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        setImgFile(files);

        if (files.length === 0) {
            resetFileData();
            return;
        }

        setFilename(files[0].name);
        convertToBase64(files[0]);
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImgSrc(reader.result);
            // setImgBase64(prev => [...prev, reader.result]);
        };
        reader.onerror = (error) => {
            console.error("Error: ", error);
            resetFileData();
        };
    };

    //* 주소 검색 팝업 데이터1
    const [addressPopup, setAddressPopup] = useState(false);
    const [daumAddress, setDaumAddress] = useState("");
    const [addressPost, setAddressPost] = useState("");
    const [daumData, setDaumData] = useState();

    // 팝업창 열기
    const openPostCode = () => {
        setAddressPopup(true);
    };
    // 팝업창 닫기
    const closePostCode = () => {
        setAddressPopup(false);
    };

    useEffect(() => {
        setFormData({
            ...formData,
            ["address"]: daumAddress,
        });
    }, [daumAddress, addressPost]);

    // 이미지 객체 전송 및 이미지 ID값 반환
    const fetchImageData = async () => {
        if (!imgFile) {
            return false;
        }

        try {
            const fd = new FormData();
            Object.values(imgFile).forEach((file) => fd.append("file", file));

            return createFormData("/api/share/upload/v1/image", fd);
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 가입 정보 formdata 전송
    const fetchFormData = async (imageId) => {
        try {
            await createData("/api/share/join/v1", {
                name: managerName,
                loginId: loginId,
                password: password,
                phone: managerPhone,
                email: managerEmail,
                division: managerDivision,
                position: managerPosition,
                businessCardImgUploadId: imageId,
                agCorperationId: corpId,
            });

            return true;
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // ID 중복확인
    const fetchCheckId = async (id) => {
        try {
            return fetchData(`/api/share/join/v1/exist_login_id`, { login_id: id });
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 법인 정보 확인
    const getCorpDatas = async (corpId) => {
        try {
            const response = await fetchData(`/api/share/join/v1/ag_corp/${corpId}`);
            const data = response.data.data;

            setFormData({
                ...formData,
                ["corpName"]: data.name,
                ["corpRegNumber"]: data.corpRegNumber,
                ["address"]: data.address,
                ["addressDetail"]: data.addressDetail,
                ["repName"]: data.repName,
            });
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 사업자 등록번호, 법인 등록번호 중복확인
    const fetchCheckBusiness = async (licenseNumber) => {
        try {
            return fetchData(`/api/user/join/v1/exist_business_number`, { license_number: licenseNumber });
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 담당자 연락처 중복확인
    const fetchCheckManager = async () => {
        try {
            const result = await fetchData(`/api/share/join/v1/exist_phone`, { phone: managerPhone });
            return result;
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 인증번호 요청
    const fetchSend = async (authData) => {
        try {
            const response = await createData("/api/share/auth/v1/nice/phone/request", {
                phone: managerPhone,
                idNumber: idNumberF + idNumberB,
                mobileType: mobileType,
                phone: managerPhone,
                name: managerName,
            });

            setFindDataId(response.data.data.id);
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 인증번호 확인
    const fetchValidation = async (validationData) => {
        try {
            return await createData("/api/share/auth/v1/nice/phone/verify", {
                id: findDataId,
                authNum: managerPhoneAuth,
            });
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 이용약관 내용 가져오기
    const fetchTerms = async () => {
        try {
            return fetchData("/api/user/term/v1");
        } catch (error) {
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 유효성 검사
    const allAgreeHandler = (checked) => {
        setIsAllChecked(checked);
        setCheckedItems(checked ? ["provision", "privacy", "advertise"] : []);
    };

    const agreeHandler = (checked, value) => {
        setCheckedItems(checked ? [...checkedItems, value] : checkedItems.filter((el) => el !== value));
    };

    useEffect(() => {
        console.log(tabIndex);
        switch (tabIndex) {
            case 0:
                updateTabOneStatus();
                break;
            case 1:
                updateTabTwoStatus();
                break;
            case 2:
                setBtnState(true);
                setAllowStep(3);
                break;
            case 3:
                setBtnState(isManagerAuth);
                setAllowStep(isManagerAuth ? 4 : 3);
                break;
            case 4:
                updateTabFiveStatus();
                break;
            default:
                break;
        }
    }, [
        tabIndex,
        checkedItems,
        loginId,
        password,
        confirmPwd,
        managerName,
        managerPhone,
        managerPhoneAuth,
        managerEmail,
        isManagerAuth,
        isManagerCheck,
    ]);

    const updateTabOneStatus = () => {
        const allChecked = checkedItems.includes("provision") && checkedItems.includes("privacy") && checkedItems.includes("advertise");
        setBtnState(allChecked);
        // setAllowStep(allChecked ? 2 : 1);
        setIsAllChecked(checkedItems.length >= 3);
    };

    const updateTabTwoStatus = () => {
        const validLogin = loginId.length >= 6 && password.length >= 8 && password === confirmPwd;
        const idWarn = idWarnStatus || loginId.length >= 6;
        const pwWarn = pwWarnStatus || (password.length >= 8 && confirmPwd.length >= 8);

        setBtnState(validLogin);
        setAllowStep(validLogin && allowStep >= 3 ? 3 : 2);
        setIdWarnStatus(idWarn);
        setPwWarnStatus(pwWarn);
        validateIdAndPassword();
    };

    const updateTabFiveStatus = () => {
        console.log(managerName, managerPhone, managerPhoneAuth, managerEmail);
        console.log(isManagerCheck, isManagerAuth);
        const validManagerInfo = managerName !== "" && managerPhone !== "" && managerPhoneAuth !== "" && managerEmail !== "";
        const validAuth = isManagerCheck && isManagerAuth;

        setBtnState(validManagerInfo && validAuth);
        setAllowStep(validManagerInfo && validAuth ? 5 : 4);
    };

    // 유효성 검사 함수
    const validateManagerName = () => managerName.length > 0;
    const validateIdNumber = () => idNumberF.length === 6 && idNumberB.length > 0;
    const validateManagerPhone = () => managerPhone.length > 10;

    // 담당자 연락처 인증
    const verifyManagerContact = async () => {
        if (!validateManagerName()) {
            handleToast("이름을 입력해주세요.");
            return;
        }

        if (!validateIdNumber()) {
            handleToast("주민번호를 정확히 입력해주세요.");
            return;
        }

        if (!validateManagerPhone()) {
            handleToast("연락처를 정확히 입력해주세요.");
            return;
        }

        const isPhoneAvailable = await fetchCheckManager();
        console.log(isPhoneAvailable);
        if (!isPhoneAvailable) {
            handleToast("이미 사용 중인 연락처입니다.");
            setIsManagerCheck(false);
            return;
        }

        await fetchSend(); // 핸드폰 인증번호 요청
        setIsManagerCheck(true);
    };

    // 담당자 연락처 인증번호 확인
    const verifyManagerPhoneAuth = async () => {
        const isAuthValid = await fetchValidation();
        if (!isAuthValid) {
            handleToast("인증번호가 올바르지 않습니다.");
            setIsManagerAuth(false);
            return;
        }

        setIsManagerAuth(true);
        setAllowStep(5);
        handleToast("인증되었습니다.");
    };

    // 아이디 입력창 onBlur
    const verifyLoginIdAvailability = async () => {
        if (!loginId) {
            // 아이디가 비어있는 경우 적절한 처리
            return false;
        }

        try {
            const isIdAvailable = await fetchCheckId(loginId);
            return isIdAvailable;
        } catch (error) {
            // API 요청 실패 시 처리
            handleToast(error.response.data.message);
            return false;
        }
    };

    // 약관 팝업
    const displayTermContentInModal = (idx) => {
        const termTypeMap = {
            1: "SERVICE",
            2: "PERSONAL",
            3: "NOTIFICATION",
        };

        const termContent = findTermContentByType(termTypeMap[idx]);
        if (termContent) {
            setModalContent(termContent);
            setModalState(true);
        } else {
            handleToast("약관 내용을 찾을 수 없습니다.");
        }
    };

    const findTermContentByType = (type) => {
        const term = terms.find((term) => term.termType === type);
        return term?.content;
    };

    // 토스트 팝업
    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    // 한글만 허용하는 함수
    function allowOnlyKoreanCharacters(event) {
        // const regExp = /[^0-9a-zA-Z]/g; // 숫자와 영문자만 허용
        const regExp = /[^ㄱ-ㅎ|가-힣]/g;
        event.target.value = event.target.value.replace(regExp, "");
    }

    // 한글 + 특수문자 허용
    function allowOnlyKoreanAndSpecialCharacters(event) {
        const regExp = /[^\Wㄱ-ㅎ가-힣]/g;
        event.target.value = event.target.value.replace(regExp, "");
    }


    // 영문만 허용하는 함수
    function allowOnlyEnglishAndNumbers(event) {
        const regExp = /[^\w]/g;
        event.target.value = event.target.value.replace(regExp, "");
    }

    // 입력 길이 제한 함수
    function limitInputMaxLength(event) {
        const maxLength = event.target.maxLength;
        if (event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        }
    }

    return (
        <>
            <div className={`${styles.join}` + " form-container form-width"}>
                <div className={styles.join__header}>
                    <h1 className="heading-1">회원가입</h1>
                    <ProgressBar percent={progressValue} />
                </div>
                {/********** TAB 1 : 이용약관 동의 **********/}
                <div className={styles.tabs}>
                    {/* <div
                        className={tabIndex === 0 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`}
                        onClick={() => {
                            if (allowStep >= 1) {
                                setTabIndex(0);
                            } else {
                                handleToast("");
                            }
                        }}
                    >
                        <span>1</span>
                    </div> */}
                    <div
                        className={tabIndex === 1 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`}
                        onClick={() => {
                            if (allowStep >= 2) {
                                setTabIndex(1);
                            } else {
                                handleToast("이용 약관을 확인하고 동의해주세요.");
                            }
                        }}
                    >
                        <span>1</span>
                    </div>
                    <div
                        className={tabIndex === 2 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`}
                        onClick={() => {
                            if (allowStep >= 3) {
                                setTabIndex(2);
                            } else {
                                handleToast("로그인에 사용할 아이디와 비밀번호를 입력해주세요.");
                            }
                        }}
                    >
                        <span>2</span>
                    </div>
                    <div
                        className={tabIndex === 3 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`}
                        onClick={() => {
                            if (allowStep >= 4) {
                                setTabIndex(3);
                            } else {
                                handleToast("소속된 법인의 정보를 확인해주세요.");
                            }
                        }}
                    >
                        <span>3</span>
                    </div>
                    <div
                        className={tabIndex === 4 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`}
                        onClick={() => {
                            if (allowStep >= 5) {
                                setTabIndex(4);
                            } else {
                                handleToast("서비스 이용을 위한 연락처를 인증해주세요.");
                            }
                        }}
                    >
                        <span>4</span>
                    </div>
                </div>
                {/* <div className={tabIndex == 0 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
                    <div className={`${styles.join__content__mainTxt}` + " heading-3"}>이용약관</div>
                    <p className={`${styles.join__content__subTxt}` + " pm"}>이용 약관을 확인하고 동의해주세요.</p>
                    <div className={styles.join__content__termsBox}>
                        <div className={`${styles.join__content__termsBox__all}` + " checkbox"}>
                            <label>
                                <input
                                    type="checkbox"
                                    value="agree"
                                    onChange={(e) => allAgreeHandler(e.currentTarget.checked, e.target.value)}
                                    checked={isAllChecked}
                                />
                                <div></div>
                                <span>전체 동의</span>
                            </label>
                        </div>
                        <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
                            <label>
                                <input
                                    type="checkbox"
                                    value="provision"
                                    onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                    checked={checkedItems.includes("provision") ? true : false}
                                />
                                <div></div>
                                <span>(필수) 서비스 이용약관</span>
                            </label>
                            <button
                                type="button"
                                className="btn-read"
                                onClick={() => {
                                    displayTermContentInModal(1);
                                }}
                            >
                                보기
                            </button>
                        </div>
                        <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
                            <label>
                                <input
                                    type="checkbox"
                                    value="privacy"
                                    onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                    checked={checkedItems.includes("privacy") ? true : false}
                                />
                                <div></div>
                                <span>(필수) 개인정보 수집∙이용 동의</span>
                            </label>
                            <button
                                type="button"
                                className="btn-read"
                                onClick={() => {
                                    displayTermContentInModal(2);
                                }}
                            >
                                보기
                            </button>
                        </div>
                        <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
                            <label>
                                <input
                                    type="checkbox"
                                    value="advertise"
                                    onChange={(e) => agreeHandler(e.currentTarget.checked, e.target.value)}
                                    checked={checkedItems.includes("advertise") ? true : false}
                                />
                                <div></div>
                                <span>(필수) 구매 진행 알림톡 수신 동의 </span>
                            </label>
                            <button
                                type="button"
                                className="btn-read"
                                onClick={() => {
                                    displayTermContentInModal(3);
                                }}
                            >
                                보기
                            </button>
                        </div>
                    </div>
                </div> */}
                {/* 모달 */}
                {modalState ? <Modal_info modalState={setModalState} dim={false} title="이용약관" content={modalContent} /> : null}

                {/********** TAB 2 : 아이디&비밀번호 입력 **********/}
                <div className={tabIndex == 1 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
                    <div className={`${styles.join__content__mainTxt}` + " heading-3"}>아이디&amp;비밀번호</div>
                    <p className={`${styles.join__content__subTxt}` + " pm"}>로그인에 사용할 아이디와 비밀번호를 입력해주세요.</p>
                    <div className={styles.join__content__formContainer}>
                        {/* 아이디 */}
                        <div className={idWarnStatus === true ? "input-form" : "input-form error"}>
                            <p className="form-title">
                                <span>아이디</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="loginId"
                                        className=""
                                        placeholder="아이디 입력"
                                        onKeyUp={allowOnlyEnglishAndNumbers}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                // spacebar 막기
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={handleChange}
                                        value={loginId}
                                    // onBlur={verifyLoginIdAvailability}
                                    />
                                </div>
                            </div>
                            {!idWarnStatus && <p className="alert-msg error">{idWarnTxt}</p>}
                        </div>
                        {/* 패스워드 */}
                        <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
                            <p className="form-title">
                                <span>비밀번호</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="password"
                                        name="password"
                                        className=""
                                        placeholder="비밀번호 입력"
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                // spacebar 막기
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={handleChange}
                                        value={password}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="password"
                                        name="confirmPwd"
                                        className=""
                                        placeholder="비밀번호 재입력"
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                // spacebar 막기
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={handleChange}
                                        value={confirmPwd}
                                    />
                                </div>
                            </div>
                            {!pwWarnStatus && <p className="alert-msg error">{pwWarnTxt}</p>}
                        </div>
                    </div>
                </div>

                {/********** TAB 3 : 소속 정보 입력 **********/}
                <div className={tabIndex == 2 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
                    <div className={`${styles.join__content__mainTxt}` + " heading-3"}>소속 정보</div>
                    <p className={`${styles.join__content__subTxt}` + " pm"}>소속된 법인이 맞는지 확인해주세요.</p>
                    <div className={styles.join__content__formContainer}>
                        {/* 법인명 */}
                        <div className="input-form disabled required">
                            <p className="form-title">
                                <span>법인명</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="corpName"
                                        className=""
                                        placeholder="법인명"
                                        onChange={handleChange}
                                        value={corpName}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 법인 등록번호 */}
                        <div className="input-form disabled">
                            <p className="form-title">
                                <span>법인 등록번호</span>
                                <em className="title-sub">(-없이 입력하세요)</em>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="corpRegNumber"
                                        className=""
                                        placeholder="법인 등록번호"
                                        onChange={handleChange}
                                        value={corpRegNumber}
                                    />
                                </div>
                            </div>
                            <p className="alert-msg error">{corpWarnTxt}</p>
                        </div>
                        {/* 사업자 주소 */}
                        <div className="input-form disabled">
                            <p className="form-title">
                                <span>사업자 주소</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="address"
                                        className=""
                                        placeholder="주소"
                                        onChange={handleChange}
                                        value={address}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="input-form disabled">
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="addressDetail"
                                        className=""
                                        placeholder="상세 주소"
                                        onChange={handleChange}
                                        value={addressDetail}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 대표자 성함 */}
                        <div className="input-form disabled required">
                            <p className="form-title">
                                <span>대표자 성함</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="repName"
                                        className=""
                                        placeholder="대표자 성함 입력"
                                        onKeyPress={(e) => {
                                            if (e.keyCode < 12592 || e.keyCode > 12687) {
                                                e.returnValue = false;
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={handleChange}
                                        value={repName}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/********** TAB 4 : 핸드폰 인증 **********/}
                <div className={tabIndex == 3 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
                    <div className={`${styles.join__content__mainTxt}` + " heading-3"}>연락처 인증</div>
                    <p className={`${styles.join__content__subTxt}` + " pm"}>서비스 이용을 위한 연락처를 인증해주세요.</p>
                    <div className={styles.join__content__formContainer}>
                        {/* 성함 */}
                        <div className="input-form required">
                            <p className="form-title">
                                <span>성함</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="managerName"
                                        className=""
                                        placeholder="성함 입력 (한글 입력)"
                                        onKeyUp={allowOnlyKoreanAndSpecialCharacters}
                                        onChange={handleChange}
                                        value={managerName}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 주민번호 */}
                        <div className="input-form required">
                            <p className="form-title">
                                <span>주민번호 앞 7자리</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="number"
                                        name="idNumberF"
                                        className=""
                                        placeholder="ex) 901201"
                                        onChange={handleChange}
                                        value={idNumberF}
                                        onInput={limitInputMaxLength}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="input-element">
                                    <label className="idNumberLabel">
                                        <input
                                            type="number"
                                            name="idNumberB"
                                            className=""
                                            placeholder=""
                                            onChange={handleChange}
                                            value={idNumberB}
                                            onInput={limitInputMaxLength}
                                            maxLength="1"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* 통신사 */}
                        <div className="input-form">
                            <div className="form-title">
                                <span className="fz16">통신사</span>
                            </div>
                            <div className="form-field">
                                <div className="select-element">
                                    <Select
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        options={mobileTypeOptions}
                                        defaultValue={mobileTypeOptions[0]}
                                        isClearable={false}
                                        isSearchable={false}
                                        closeMenuOnSelect={true}
                                        // styles={selectStyle_1()}
                                        onChange={(e) => {
                                            setMobileType(e.value);
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 연락처 */}
                        <div className="input-form required">
                            <p className="form-title">
                                <span>연락처</span>
                                <em className="title-sub">(-없이 입력하세요)</em>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="number"
                                        name="managerPhone"
                                        className=""
                                        placeholder="- 없이 입력"
                                        onChange={handleChange}
                                        value={managerPhone}
                                        onInput={limitInputMaxLength}
                                        maxLength="11"
                                    />
                                </div>
                                <button type="button" className="btn-auth" onClick={verifyManagerContact}>
                                    {isManagerCheck === false ? "인증하기" : "인증번호 재전송"}
                                </button>
                            </div>
                        </div>
                        {isManagerCheck === true && (
                            <div className="input-form required">
                                <div className="form-field">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            name="managerPhoneAuth"
                                            className=""
                                            placeholder="인증번호 입력"
                                            onChange={handleChange}
                                            value={managerPhoneAuth}
                                        />
                                    </div>
                                    <button type="button" className="btn-auth" onClick={verifyManagerPhoneAuth}>
                                        인증확인
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/********** TAB 5 : 담당자 정보 입력 **********/}
                <div className={tabIndex == 4 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
                    <div className={`${styles.join__content__mainTxt}` + " heading-3"}>회원 정보</div>
                    <p className={`${styles.join__content__subTxt}` + " pm"}>서비스 이용을 위한 정보를 입력하세요.</p>
                    <div className={styles.join__content__formContainer}>
                        {/* 성함 */}
                        <div className="input-form required disabled">
                            <p className="form-title">
                                <span>성함</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="managerName"
                                        className=""
                                        placeholder="성함 입력 (한글 입력)"
                                        onKeyUp={allowOnlyKoreanAndSpecialCharacters}
                                        // onKeyPress={e => {
                                        //   if (e.keyCode < 12592 || e.keyCode > 12687) {
                                        //     e.returnValue = false;
                                        //     e.preventDefault();
                                        //   }
                                        // }}
                                        onChange={handleChange}
                                        value={managerName}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 부서 */}
                        <div className="input-form">
                            <p className="form-title">
                                <span>부서</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="managerDivision"
                                        className=""
                                        placeholder="부서 입력"
                                        onChange={handleChange}
                                        value={managerDivision}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 직급 */}
                        <div className="input-form">
                            <p className="form-title">
                                <span>직급</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="managerPosition"
                                        className=""
                                        placeholder="직급 입력"
                                        onChange={handleChange}
                                        value={managerPosition}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 이메일 */}
                        <div className="input-form required">
                            <p className="form-title">
                                <span>이메일</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="managerEmail"
                                        className=""
                                        placeholder="예시) Mobiment@gmail.com"
                                        onChange={handleChange}
                                        value={managerEmail}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 명함 */}
                        <div className="input-form ">
                            <p className="form-title">
                                <span>명함</span>
                            </p>
                            <div className="form-field">
                                <div className="file-element">
                                    <span className="upload_name">{filename}</span>
                                    <label htmlFor="file">
                                        <img
                                            src={filename == "명함 업로드" ? fileUploadIcon : fileDeleteIcon}
                                            alt=""
                                            onClick={resetFileData}
                                        />
                                    </label>
                                    <input type="file" id="file" onChange={handleFileChange} />
                                </div>
                            </div>
                            <div className="form-image">
                                {filename != null && filename != "명함 업로드" ? (
                                    <div className="image-item">
                                        <img src={imgSrc} />
                                    </div>
                                ) : (
                                    <div className="image-empty"></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.join__buttons}` + " button-form"}>
                    <button
                        type="button"
                        className={btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"}
                        onClick={() => handleTabChange()}
                        disabled={!btnState}
                    >
                        다음
                    </button>
                </div>
            </div>

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
