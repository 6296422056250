/* eslint-disable */
import React, { useEffect } from "react";
import { Outlet } from "react-router";
import Header from "./Header";
import Footer from "./Footer";

import styles from "./Main.module.scss";

const Main = ({ children }) => {
  return (
    <>
      <div className={styles.body}>
        <Header />
        <div className={styles.main}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Main;
