/* eslint-disable */
import React, { useEffect, useState } from "react";

import styles from "./Modal_info.module.scss";

export const Modal_info = ({ modalState, dim, title, content, btnCancel, btnOk }) => {
  return (
    <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>
      <div className={styles.container}>
        <button
          className={styles.btn_close}
          onClick={() => modalState(false)}
        ></button>
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div className={styles.body}>
          {/* <p>{content}</p> */}
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
      </div>
    </div>
  );
};
