/* eslint-disable */
import React, { useEffect, useState } from "react";

import "./ProgressBar.scss";

export const ProgressBar = ({ percent }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(percent);
  }, [value, percent]);

  const styled = {
    width: `${value}%`,
    marginTop: `27px`,
  };

  return (
    <>
      <div className="progress-bar">
        <div className="progress" style={styled}></div>
        {/* <div className="progress" style={{ width: `${value}%` }}></div> */}
      </div>
    </>
  );
};
