/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import searchWhiteIcon from "resources/icon/search-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Garage, Terms, Insurance, SpecialContract, ContractNote, BankInfo } from "./contract";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";

export const ProfileSupplier = ({ setModal }) => {
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        name: "",
        licenseNumber: "",
        licenseImgFile: "",
        licenseImgOriginFileName: "",
        licenseImgUrl: "",
        licenseImgUploadId: "",
        corpRegNumber: "",
        address: "",
        addressDetail: "",
        bcode: "",
        repName: "",
        repPhone: "",
        managerName: "",
        managerDivision: "",
        managerPosition: "",
        managerPhone: "",
        managerEmail: "",
        taxEmail: "",
    });

    const {
        name,
        licenseNumber,
        licenseImgFile,
        licenseImgOriginFileName,
        licenseImgUrl,
        licenseImgUploadId,
        corpRegNumber,
        address,
        addressDetail,
        bcode,
        repName,
        repPhone,
        managerName,
        managerDivision,
        managerPosition,
        managerPhone,
        managerEmail,
        taxEmail,
    } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        if (target.type === "checkbox") {
            const dataTarget = target?.getAttribute("data-target");

            // Checkbox인 경우 처리
            if (target.checked) {
                // Checkbox가 체크된 경우 해당 값을 배열에 추가
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: [...prevState[dataTarget], inputName],
                }));
            } else {
                // Checkbox가 체크 해제된 경우 해당 값을 배열에서 제거
                setFormData((prevState) => ({
                    ...prevState,
                    [dataTarget]: prevState[dataTarget].filter((item) => item !== inputName),
                }));
            }
        } else {
            // 다른 input 요소인 경우 처리 (예: input type="text")
            setFormData((prevState) => ({
                ...prevState,
                [inputName]: value,
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            let payloadUploadId;

            // 파일이 있는 경우에만 처리
            if (!licenseImgFile) {
                // 이미 업로드된 파일인 경우, 기존의 uploadId를 사용
                payloadUploadId = licenseImgUploadId;
            } else {
                // 새로 업로드하는 파일인 경우, 서버로부터 uploadId를 받아옴
                const formData = new FormData();
                formData.append("file", licenseImgFile);

                const response = await createFormData("/api/share/upload/v1/file", formData);
                payloadUploadId = response.data.data.id; // 서버에서 반환된 uploadId
            }

            const payload = {
                address: formData.address,
                addressDetail: formData.addressDetail,
                bcode: formData.bcode,
                corpRegNumber: formData.corpRegNumber,
                licenseImgUploadId: payloadUploadId,
                licenseNumber: formData.licenseNumber,
                managerEmail: formData.managerEmail,
                managerName: formData.managerName,
                managerPhone: formData.managerPhone,
                name: formData.name,
                repName: formData.repName,
                repPhone: formData.repPhone,
            };

            const response = await updateData(`/api/share/rent_corp/v1`, payload);

            showModal("", <p>수정이 완료되었습니다.</p>, [{ text: "확인", handler: () => setModal(false) }]);
        } catch (error) {
            handleToast(error.response.data.message, Infinity);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchProfileData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            const result = response.data.data;
            console.log(result);

            setFormData({
                name: result?.name || "",
                licenseNumber: result?.licenseNumber || "",
                licenseImgOriginFileName: result?.licenseImgOriginFileName || "",
                licenseImgUrl: result?.licenseImgUrl || "",
                licenseImgUploadId: result?.licenseImgUploadId || "",
                corpRegNumber: result?.corpRegNumber || "",
                address: result?.address || "",
                addressDetail: result?.addressDetail || "",
                bcode: result?.bcode || "",
                repName: result?.repName || "",
                repPhone: result?.repPhone || "",
                managerName: result?.managerName || "",
                managerDivision: result?.managerDivision || "",
                managerPosition: result?.managerPosition || "",
                managerPhone: result?.managerPhone || "",
                managerEmail: result?.managerEmail || "",
                taxEmail: result?.taxEmail || "",
            });
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevState) => ({
                ...prevState,
                licenseImgFile: file,
                licenseImgOriginFileName: file.name,
                licenseImgUrl: file.url,
            }));
        }
    };

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    // 주소 정보
    const [addressPopup, setAddressPopup] = useState(false);

    const openPostCode = () => {
        setAddressPopup(true);
    };
    const closePostCode = () => {
        setAddressPopup(false);
    };

    const handleAddress = (data) => {
        setFormData((prevState) => ({
            ...prevState,
            bcode: data.daumData.bcode,
            address: data.daumData.address,
        }));
        closePostCode();
    };

    const [modalState, setModalState] = useState({
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
        layer: true,
    });

    const handleModalClose = () => {
        setModalState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setModalState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-type-2" style={{ minHeight: "400px" }}>
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>개인정보 수정</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        <div className="profile_form">
                            <div className="item-wrapper" style={{ borderBottom: "none", paddingBottom: "40px" }}>
                                <div className="item-wrap-tit">
                                    <span>회사 정보</span>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>사업자등록증</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form file-item">
                                            <div className="file_item-wrapper" onClick={handleFileClick}>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    onChange={handleFileInputChange}
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                />
                                                <span className="file-name">
                                                    {licenseImgOriginFileName ? licenseImgOriginFileName : "파일 선택"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>법인명</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input type="text" name="name" value={name} onChange={handleChange} placeholder="법인명" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>사업자등록번호</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="licenseNumber"
                                                    value={licenseNumber}
                                                    onChange={handleChange}
                                                    placeholder="사업자등록번호"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>법인등록번호</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="corpRegNumber"
                                                    value={corpRegNumber}
                                                    onChange={handleChange}
                                                    placeholder="법인등록번호"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>사업자 주소</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    value={address || ""}
                                                    onChange={handleChange}
                                                    onClick={openPostCode}
                                                    placeholder="사업자 주소"
                                                    readOnly
                                                />
                                            </div>
                                            <button type="button" style={{ width: "45px" }} onClick={openPostCode}>
                                                <img src={searchWhiteIcon} alt="" />
                                            </button>
                                        </div>
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="addressDetail"
                                                    value={addressDetail || ""}
                                                    onChange={handleChange}
                                                    placeholder="사업자 상세주소"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>대표자 성함</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="repName"
                                                    value={repName}
                                                    onChange={handleChange}
                                                    placeholder="대표자 성함"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>대표자 연락처</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="repPhone"
                                                    value={repPhone}
                                                    onChange={handleChange}
                                                    placeholder="대표자 연락처"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="item-wrapper">
                                <div className="item-wrap-tit">
                                    <span>담당자 정보</span>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>담당자 성함</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="managerName"
                                                    value={managerName}
                                                    onChange={handleChange}
                                                    placeholder="담당자 성함"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>담당자 연락처</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="managerPhone"
                                                    value={managerPhone}
                                                    onChange={handleChange}
                                                    placeholder="담당자 연락처"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-head">
                                        <span>담당자 이메일</span>
                                    </div>
                                    <div className="item-content">
                                        <div className="input-form">
                                            <div className="input-element">
                                                <input
                                                    type="text"
                                                    name="managerEmail"
                                                    value={managerEmail}
                                                    onChange={handleChange}
                                                    placeholder="담당자 이메일"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-bottom2">
                            <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                                완료
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />

            {addressPopup && <DaumPost onComplete={handleAddress} onClose={closePostCode} dim={false} autoClose />}

            {modalState.modalOpen && (
                <Modal
                    title={modalState.modalContent.title}
                    content={modalState.modalContent.content}
                    buttons={modalState.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={modalState.layer}
                />
            )}
        </>
    );
};
