import React, { useState, useEffect, useRef } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowUpFromBracket, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";

export const Terms = ({ setFormStatus, handleToast }) => {
    const [fileLists, setFileLists] = useState([
        { file: null, type: "RENT_CONTRACT" },
        { file: null, type: "PERSONAL" },
        { file: null, type: "PERSONAL_OFFER" },
        { file: null, type: "DRIVER_LICENSE" },
        { file: null, type: "RENT_RETURN" },
        { file: null, type: "BANK_AUTO_TRANSFER" },
        { file: null, type: "CAR_REPAIR" },
    ]);

    const documentTitles = {
        RENT_CONTRACT: "자동차 임대차 계약 약관",
        PERSONAL: "개인정보 수집 및 이용약관",
        PERSONAL_OFFER: "제 3자 정보제공 약관",
        DRIVER_LICENSE: "운전자격 검증 확인서",
        RENT_RETURN: "렌트카 차량회수 동의서",
        BANK_AUTO_TRANSFER: "자동이체 동의서",
        CAR_REPAIR: "차량 정비 안내 동의서",
    };

    const handleFileSelect = (event, index) => {
        const selectedFile = event.target.files[0];
        const updatedFileLists = [...fileLists];
        updatedFileLists[index].file = selectedFile;
        setFileLists(updatedFileLists);
    };

    const handleRemoveFile = (index) => {
        const updatedFileLists = [...fileLists];
        updatedFileLists[index].file = null;
        setFileLists(updatedFileLists);
    };

    const handleSubmit = async () => {
        try {
            let uploads = [];
            for (const item of fileLists) {
                if (item.file) {
                    let uploadId;
                    // 파일이 있는 경우에만 처리
                    if (item.file.uploadId) {
                        // 이미 업로드된 파일인 경우, 기존의 uploadId를 사용
                        uploadId = item.file.uploadId;
                    } else {
                        // 새로 업로드하는 파일인 경우, 서버로부터 uploadId를 받아옴
                        const formData = new FormData();
                        formData.append("file", item.file);

                        const response = await createFormData("/api/share/upload/v1/file", formData);
                        uploadId = response.data.data.id; // 서버에서 반환된 uploadId
                    }

                    uploads.push({
                        uploadId: uploadId,
                        rentCorpTermType: item.type,
                    });
                }
            }

            const requestBody = {
                terms: uploads,
            };

            const response = await updateData(`/api/share/rent_corp/contract/v1/terms`, requestBody);

            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            console.log(error);
            // handleToast(error.response.data?.message, Infinity);
        }
    };

    const fetchTermList = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);
            const termList = response?.data.data.rentCorpTerms; // 받아온 데이터
            console.log(termList);

            // 파일 정보를 저장할 배열
            let updatedFileLists = [
                { file: null, type: "RENT_CONTRACT" },
                { file: null, type: "PERSONAL" },
                { file: null, type: "PERSONAL_OFFER" },
                { file: null, type: "DRIVER_LICENSE" },
                { file: null, type: "RENT_RETURN" },
                { file: null, type: "BANK_AUTO_TRANSFER" },
                { file: null, type: "CAR_REPAIR" },
            ];

            // 받아온 데이터를 순회하면서 해당하는 type에 맞게 파일 정보 저장
            for (const term of termList) {
                // term의 rentCorpTermType에 해당하는 type을 찾아서 해당 파일 정보 저장
                const index = updatedFileLists.findIndex((item) => item.type === term.rentCorpTermType);
                if (index !== -1) {
                    updatedFileLists[index].file = {
                        uploadId: term.uploadId,
                        url: term.url,
                        name: term.fileName,
                    };
                }
            }

            // 업데이트된 파일 리스트를 상태로 설정
            setFileLists(updatedFileLists);
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchTermList();
    }, []);

    const handleDownload = (type) => {
        const file = fileLists.find((item) => item.type === type)?.file;
        if (file) {
            // 파일이 존재하는 경우에만 다운로드 실행
            window.open(file.url, "_blank");
        }
    };

    return (
        <>
            <div className="term_item-list">
                {fileLists.map((item, index) => (
                    <div key={index} className="item">
                        <div className="item-head">
                            <div className="item-tit">{documentTitles[item.type]} &gt;</div>
                            <div className="item-buttons">
                                <label htmlFor={`fileInput-${index}`} className="btn-upload_file">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                    PDF 업로드
                                </label>
                                <input
                                    id={`fileInput-${index}`}
                                    type="file"
                                    accept=".pdf"
                                    onChange={(event) => handleFileSelect(event, index)}
                                    style={{ display: "none" }}
                                />
                                <button
                                    type="button"
                                    className="btn-download_file"
                                    disabled={!item.file}
                                    onClick={() => handleDownload(item.type)}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="file-list">
                                {item.file && (
                                    <div className="file">
                                        <span className="file-tit">{item.file.name}</span>
                                        {item.file.size && <span className="file-volume">{(item.file.size / 1024).toFixed(2)} KB</span>}
                                        <button type="button" className="btn-remove-file" onClick={() => handleRemoveFile(index)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>
        </>
    );
};
