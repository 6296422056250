/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "components";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";

import MasterIcon from "resources/icon/grade-master.png";
import GoldIcon from "resources/icon/grade-gold.png";
import SilverIcon from "resources/icon/grade-silver.png";
import BronzeIcon from "resources/icon/grade-bronze.png";
import NormalIcon from "resources/icon/grade-normal.png";

export const ChangeGrade = ({ setModal, memberId, myData, updateLifting }) => {
    const [loading, setLoading] = useState(false); // 데이터 로딩 상태
    const [memberGrade, setMemberGrade] = useState();

    useEffect(() => {
        if (!myData) return;

        setMemberGrade(myData.member.shareGrade);
        console.log(myData.member.shareGrade);
    }, []);

    const updateMemberGrade = async () => {
        setLoading(true);

        try {
            const requestData = {
                shareGrade: memberGrade,
            };
            console.log(requestData);

            const response = await updateData(`/api/share/ag_corp/v1/member/${memberId}/share_grade`, requestData);
            handleToast(response.data.message);
            updateLifting();
            setLoading(false);
        } catch (error) {
            handleToast(error?.response?.data?.message);
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {loading && (
                <div className="loading no-layer">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}
            {/* <div className='modal-layer'></div> */}

            <div className="modal modal-mypage">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>등급 변경</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="grade-current">
                        <div className="grade-icon">
                            {(() => {
                                const gradeMap = {
                                    MASTER: { icon: MasterIcon, className: "color-txt-master", label: "master" },
                                    GOLD: { icon: GoldIcon, className: "color-txt-gold", label: "gold" },
                                    SILVER: { icon: SilverIcon, className: "color-txt-silver", label: "silver" },
                                    BRONZE: { icon: BronzeIcon, className: "color-txt-bronze", label: "bronze" },
                                    NORMAL: { icon: NormalIcon, className: "color-txt-normal", label: "normal" },
                                };
                                const grade = myData?.member?.shareGrade;

                                if (grade) {
                                    const { icon, className, label } = gradeMap[grade];
                                    return (
                                        <>
                                            <img src={icon} alt="" />
                                            <span className={`grade-txt ${className}`}>{label}</span>
                                        </>
                                    );
                                }

                                return null;
                            })()}
                        </div>
                        <div className="user-name">{myData?.member.name}</div>
                    </div>

                    <div className="grade-change">
                        <FontAwesomeIcon icon={faArrowDown} />
                    </div>

                    <div className="grade-list">
                        <div
                            className={memberGrade === "GOLD" ? "grade-item grade-gold active" : "grade-item grade-gold"}
                            onClick={() => setMemberGrade("GOLD")}
                        >
                            <div className="grade-icon">
                                <img src={GoldIcon} alt="" />
                                <span className="grade-txt">gold</span>
                            </div>
                        </div>
                        <div
                            className={memberGrade === "SILVER" ? "grade-item grade-silver active" : "grade-item grade-silver"}
                            onClick={() => setMemberGrade("SILVER")}
                        >
                            <div className="grade-icon">
                                <img src={SilverIcon} alt="" />
                                <span className="grade-txt">silver</span>
                            </div>
                        </div>
                        <div
                            className={memberGrade === "BRONZE" ? "grade-item grade-bronze active" : "grade-item grade-bronze"}
                            onClick={() => setMemberGrade("BRONZE")}
                        >
                            <div className="grade-icon">
                                <img src={BronzeIcon} alt="" />
                                <span className="grade-txt">bronze</span>
                            </div>
                        </div>
                        <div
                            className={memberGrade === "NORMAL" ? "grade-item grade-normal active" : "grade-item grade-normal"}
                            onClick={() => setMemberGrade("NORMAL")}
                        >
                            <div className="grade-icon">
                                <img src={NormalIcon} alt="" />
                                <span className="grade-txt">normal</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className="grade-desc">
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolorum maiores magnam eaque eos suscipit,
                            error incidunt molestiae necessitatibus eum, ut, totam distinctio quis libero iure. Ipsum possimus asperiores
                            aperiam!
                        </p>
                    </div> */}

                    <div className="button-row">
                        <button type="button" className="btn btn-grade" onClick={updateMemberGrade}>
                            변경
                        </button>
                    </div>
                </div>
            </div>

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
