import React, { useEffect, useState } from "react";
import searchWhiteIcon from "resources/icon/search-white.svg";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { DaumPost, Modal, Toast } from "components";

export const Garage = ({ setFormStatus, handleToast }) => {
    const [locations, setLocations] = useState([
        { name: "", address: "", addressDetail: "", bcode: "" },
        { name: "", address: "", addressDetail: "", bcode: "" },
        { name: "", address: "", addressDetail: "", bcode: "" },
    ]);

    const handleLocationChange = (index, field, value) => {
        setLocations((prevLocations) => {
            const newLocations = [...prevLocations];
            newLocations[index][field] = value;
            return newLocations;
        });
    };

    // 주소지 검색
    const [addressPopup, setAddressPopup] = useState({ isOpen: false, index: 0 });

    const openPostCode = (index) => {
        setAddressPopup({ isOpen: true, index });
    };

    const closePostCode = () => {
        setAddressPopup({ isOpen: false, index: 0 });
    };

    const handleAddress = (data) => {
        const updatedLocations = [...locations];
        const index = addressPopup.index;

        updatedLocations[index].address = data.daumData.address;
        updatedLocations[index].addressDetail = "";
        updatedLocations[index].bcode = data.daumData.bcode;

        setLocations(updatedLocations);
        closePostCode();
    };

    const handleSubmit = async () => {
        try {
            const payload = { locations };
            // const payload = [
            //     {
            //         name: "",
            //         address: "",
            //         addressDetail: "",
            //         bcode: "",
            //     },
            //     {
            //         name: "",
            //         address: "",
            //         addressDetail: "",
            //         bcode: "",
            //     },
            //     {
            //         name: "",
            //         address: "",
            //         addressDetail: "",
            //         bcode: "",
            //     },
            // ];

            console.log(payload);

            const response = await updateData(`/api/share/rent_corp/contract/v1/delivery_location`, payload);
            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchGarageList = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/delivery_location`);

            const garageList = response.data.data;

            const locations = [];
            for (let i = 0; i < 3; i++) {
                const garage = garageList[i] || {};
                locations.push({
                    name: garage.name || "",
                    address: garage.address || "",
                    addressDetail: garage.addressDetail || "",
                    bcode: garage.bcode || "",
                });
            }
            setLocations(locations);
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchGarageList();
    }, []);

    return (
        <>
            <div className="garage_item-list">
                {locations.map((location, index) => (
                    <div key={index} className="item-wrapper">
                        <div className="item-wrap-tit">
                            <span>{`차고지${index + 1}`}</span>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>차고지명</span>
                            </div>
                            <div className="item-content">
                                <div className="input-form">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            value={location.name}
                                            onChange={(e) => handleLocationChange(index, "name", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>출발지</span>
                            </div>
                            <div className="item-content">
                                <div className="input-form">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            value={location.address}
                                            onChange={(e) => handleLocationChange(index, "address", e.target.value)}
                                            disabled
                                        />
                                    </div>
                                    <button type="button" style={{ width: "45px" }} onClick={() => openPostCode(index)}>
                                        <img src={searchWhiteIcon} alt="" />
                                    </button>
                                </div>
                                <div className="input-form">
                                    <div className="input-element">
                                        <input
                                            type="text"
                                            value={location.addressDetail}
                                            onChange={(e) => handleLocationChange(index, "addressDetail", e.target.value)}
                                            placeholder="상세주소"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>

            {addressPopup.isOpen && <DaumPost onComplete={handleAddress} onClose={closePostCode} autoClose />}

            {/* <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} /> */}
        </>
    );
};
