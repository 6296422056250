/* eslint-disable */
import React, { useState, useRef } from "react";
import { findYoungestDriver, convertPhone, getAgeCategory, convertToKoreanWon, comma } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

export const FormStep3 = ({ data }) => {
    // 등록된 운전자중 가장 적은 나이를 가진 데이터
    const youngestDriver = findYoungestDriver(data.drivers);

    // const [youngestDriver, setYoungestDriver] = useState(null);

    // useEffect(() => {
    //     setYoungestDriver(findYoungestDriver(data?.drivers));
    // }, []);

    // useEffect(() => {
    //     console.log(youngestDriver);
    // }, [youngestDriver]);
    return (
        <>
            <div className="tab" id="tab-03">
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>자동차 보험</span>
                    </div>
                    <div className="item-list grid-row">
                        <div className="item width-100">
                            <span className="item-tit">운전자 연령</span>
                            <span className="item-desc">
                                <em>
                                    {getAgeCategory(youngestDriver.idNumber)} {youngestDriver.expLt1Yn === "Y" && "/ 면허취득 1년 미만"}
                                </em>
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">운전자 범위</span>
                            <span className="item-desc">
                                <em>계약자와 배우자 및 계약자와 배우자의 직계가족 </em>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>책임한도</span>
                    </div>
                    <div className="item-list grid-col group-3">
                        <div className="item">
                            <span className="item-tit">대인</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.dutyDaein === -1 ? "무제한" : `${convertToKoreanWon(data?.car.rentInfo.dutyDaein)}원`}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">대물</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.dutyDaemul ? `${convertToKoreanWon(data?.car.rentInfo.dutyDaemul)}` : "미가입"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">자손(사망)</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.dutyJason ? `${convertToKoreanWon(data?.car.rentInfo.dutyJason)}` : "미가입"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">무보험 상해</span>
                            <span className="item-desc">
                                {data?.car.rentInfo?.dutyNoInsuranceInjury
                                    ? `가입(${convertToKoreanWon(data?.car.rentInfo?.dutyNoInsuranceInjury)})`
                                    : "미가입"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">자차</span>
                            <span className="item-desc">
                                {data?.car.rentInfo.dutyJacha ? `정비 비용의 ${data?.car.rentInfo?.dutyJacha}%` : "미가입"}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">긴급출동</span>
                            <span className="item-desc">연 {data?.car.rentInfo.emergencyCallCount}회</span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>면책금</span>
                    </div>
                    <div className="item-list grid-col group-3">
                        <div className="item">
                            <span className="item-tit">대인</span>
                            <span className="item-desc">
                                {comma(data?.indemDaein)}원
                                {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaein)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaein + data?.car.rentInfo.age23IndemPrice)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaein + data?.car.rentInfo.age21IndemPrice)}원`
                                    : "알 수 없음"} */}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">대물</span>
                            <span className="item-desc">
                                {comma(data?.indemDaemul)}원
                                {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaemul)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age23IndemPrice)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                    ? `${comma(data?.car.rentInfo.indemDaemul + data?.car.rentInfo.age21IndemPrice)}원`
                                    : "알 수 없음"} */}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">자차</span>
                            <span className="item-desc">
                                {comma(data?.indemJachaMin)}원 ~ {comma(data?.indemJachaMax)}원
                                {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                    ? `최소 ${convertToKoreanWon(data?.car.rentInfo?.indemJachaMin)} ~ 최대 ${convertToKoreanWon(
                                          data?.car.rentInfo?.indemJachaMax
                                      )}`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                    ? `최소 ${convertToKoreanWon(
                                          data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age23IndemPrice
                                      )} ~ 최대 ${convertToKoreanWon(
                                          data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age23IndemPrice
                                      )}`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                    ? `최소 ${convertToKoreanWon(
                                          data?.car.rentInfo?.indemJachaMin + data?.car.rentInfo?.age21IndemPrice
                                      )} ~ 최대 ${convertToKoreanWon(
                                          data?.car.rentInfo?.indemJachaMax + data?.car.rentInfo?.age21IndemPrice
                                      )}`
                                    : "알 수 없음"} */}
                            </span>
                        </div>
                        <div className="item">
                            <span className="item-tit">자손</span>
                            <span className="item-desc">
                                {comma(data?.indemJason)}원
                                {/* {getAgeCategory(youngestDriver.idNumber) === "만 26세 이상"
                                    ? `${comma(data?.car.rentInfo.indemJason)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 23세 이상"
                                    ? `${comma(data?.car.rentInfo.indemJason + data?.car.rentInfo.age23IndemPrice)}원`
                                    : getAgeCategory(youngestDriver.idNumber) === "만 21세 이상"
                                    ? `${comma(data?.car.rentInfo.indemJason + data?.car.rentInfo.age21IndemPrice)}원`
                                    : "알 수 없음"} */}
                            </span>
                        </div>
                    </div>
                </div>
                {/* {youngestDriver.expLt1Yn === "N" && data?.car.rentInfo.expLt1Yn === "Y" && (
                    <span style={{ fontWeight: "600", color: "purple" }}>
                        운전면허 1년 미만 면책금 {comma(data?.car.rentInfo.expLt1IndemPrice)}원
                    </span>
                )} */}
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>사고차량</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>주의사항</b>
                            </span>
                            <span className="item-pre">
                                <em>교통사고 접수시 경찰 신고 또는 보험사 현장 출동이 없는 사고는 사고처리 불가</em>
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>사고차량 입고수리절차</b>
                            </span>
                            <span className="item-pre color-grey">지정 정비공장 외 임의 수리시 자차 처리 불가</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>12대 중과실 사고</b>
                            </span>
                            <span className="item-pre color-grey">
                                1. 신호위반 2.중앙선침범 3.속도위반(20km이상 과속) 4. 앞지르기 방법위반 5. 철길건널목 통과방법위반 6.
                                횡단보도사고 7. 무면허운전 8. 음주운전 9. 보도를 침범 10. 승객추락방지의무위반 11. 스쿨존사고 12.
                                화물고정고치 위반
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>보험사</b>
                            </span>
                            <span className="item-pre">
                                <em>
                                    {data?.car.rentInfo.insuranceCorpName}) 사고접수/긴급출동 : {data?.car.rentInfo.emergencyCallNumber}
                                </em>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
