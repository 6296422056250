/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import {
    VehicleInformation,
    DepositRentalFee,
    ContractConditions,
    LiabilityLimit,
    Deductible,
    DriverCoverage,
    SpecialAgreement,
    ContractPrecautions,
} from ".";
import { uncomma } from "util";
import { Modal, Toast } from "components";

export const ProductUpload = () => {
    const store = useSelector((state) => state);
    const userRole = useSelector((state) => state.login.userData?.roles);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const navigate = useNavigate();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            if (isLoggedIn === null) {
            } else if (!isLoggedIn) {
                navigate("/login");
            }

            if (userRole !== undefined && userRole?.includes("RENT_MASTER")) {
            } else {
                navigate("/401");
            }
        } else {
            hasMounted.current = true;

            if (isLoggedIn === false) {
                navigate("/login");
            }
        }
    }, [isLoggedIn, navigate, hasMounted, userRole]);

    const initialState = {
        isLoading: false,
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
    };

    const [state, setState] = useState(initialState);
    const [newPriceDisabled, setNewPriceDisabled] = useState(true);

    const [vehicleInformation, setVehicleInformation] = useState();
    const [depositRentalFee, setDepositRentalFee] = useState();
    const [contractConditions, setContractConditions] = useState();
    const [liabilityLimit, setLiabilityLimit] = useState();
    const [deductible, setDeductible] = useState();
    const [driverCoverage, setDriverCoverage] = useState();
    const [contractPrecautions, setContractPrecautions] = useState();

    const [activeSection, setActiveSection] = useState(null);
    const vehicleInformationRef = useRef(null);
    const depositRentalFeeRef = useRef(null);
    const contractConditionsRef = useRef(null);
    const liabilityLimitRef = useRef(null);
    const deductibleRef = useRef(null);
    const driverCoverageRef = useRef(null);

    // 폼 유효성 검사
    const isFormValid = () => {
        let missingField = "";
        let errorRef = null;

        // vehicleInformation 필드 검사
        if (!vehicleInformation.autoUpCarId) {
            missingField = "차량번호를 조회해주세요.";
            errorRef = vehicleInformationRef;
        } else if (vehicleInformation.vehiclePhotos.length === 0) {
            missingField = "차량 사진을 1장 이상 선택해주세요.";
            errorRef = vehicleInformationRef;
        } else if (vehicleInformation.mainThumbnail === null) {
            missingField = "차량 대표 사진을 선택해주세요.";
            errorRef = vehicleInformationRef;
        } else if (!vehicleInformation.innerColorId || !vehicleInformation.outColorId) {
            missingField = "색상을 선택해주세요.";
            errorRef = vehicleInformationRef;
        } else if (!vehicleInformation.rentType || vehicleInformation.rentType.length === 0) {
            missingField = "렌트 유형을 선택해주세요.";
            errorRef = vehicleInformationRef;
        } else if (!vehicleInformation.productStatus) {
            missingField = "상품 상태를 선택해주세요.";
            errorRef = vehicleInformationRef;
        } else if (!vehicleInformation.carLocationAddress) {
            missingField = "차고지 주소를 입력해주세요.";
            errorRef = vehicleInformationRef;
        } else if (!vehicleInformation.mileage) {
            missingField = "주행거리를 입력해주세요.";
            errorRef = vehicleInformationRef;
        }

        if (newPriceDisabled === false && vehicleInformation.releasePrice === "") {
            missingField = "신차 가격을 입력해주세요.";
            errorRef = vehicleInformationRef;
        }

        // depositRentalFee 필드 검사
        if (!missingField && depositRentalFee.contractPrices && depositRentalFee.contractPrices.length > 0) {
            for (const periodData of depositRentalFee.contractPrices) {
                if (!periodData.depositPrice) {
                    missingField = `${periodData.contractRangeMonth}개월 보증금을 입력해주세요.`;
                    errorRef = depositRentalFeeRef;
                    break;
                } else if (!periodData.rentPrice) {
                    missingField = `${periodData.contractRangeMonth}개월 렌트료를 입력해주세요.`;
                    errorRef = depositRentalFeeRef;
                    break;
                }
            }

            if (!missingField && depositRentalFee.depositTwiceYn === undefined) {
                missingField = "보증금 2회분납 여부를 선택해주세요.";
                errorRef = depositRentalFeeRef;
            }

            if (!missingField && depositRentalFee.comissionType === undefined) {
                missingField = "수수료 진행 방식을 선택해주세요.";
                errorRef = depositRentalFeeRef;
            }
        }

        // contractConditions 필드 검사
        if (!missingField) {
            if (contractConditions.supportDeliveryFee === undefined) {
                missingField = "탁송비 지원 유무를 선택해주세요.";
                errorRef = contractConditionsRef;
            } else if (!contractConditions.deliverySupportPrice && contractConditions.supportDeliveryFee === "Y") {
                missingField = "탁송비 지원 가격을 입력해주세요.";
                errorRef = contractConditionsRef;
            } else if (contractConditions.maintenanceProduct === undefined) {
                missingField = "정비상품 포함 유무를 선택해주세요.";
                errorRef = contractConditionsRef;
            } else if (!contractConditions.inspectionProducts && contractConditions.maintenanceProduct === "Y") {
                missingField = "정비상품을 입력해주세요.";
                errorRef = contractConditionsRef;
            } else if (!contractConditions.contractTypes || contractConditions.contractTypes.length === 0) {
                missingField = "계약 유형을 선택해주세요.";
                errorRef = contractConditionsRef;
            } else if (!contractConditions.takeOverType) {
                missingField = "만기 인수 옵션을 선택해주세요.";
                errorRef = contractConditionsRef;
            } else if (!contractConditions.yearContractMileage) {
                missingField = "연간약정 주행거리를 입력해주세요.";
                errorRef = contractConditionsRef;
            }
        }

        // liabilityLimit 필드 검사
        if (!missingField) {
            if (liabilityLimit.infinityDaein === false && !liabilityLimit.dutyDaein) {
                missingField = "대인 책임 한도를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.indemDaein) {
                missingField = "대인 면책금을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.dutyDaemul) {
                missingField = "대물 책임 한도를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.indemDaemul) {
                missingField = "대물 면책금을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.dutyJacha) {
                missingField = "자차 책임 한도를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.indemJachaMin) {
                missingField = "자차 최소 면책금을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.indemJachaMax) {
                missingField = "자차 최대 면책금을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (liabilityLimit.uninsuredInjuryCoverage === undefined) {
                missingField = "무보험 상해가입 여부를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.dutyNoInsuranceInjury && liabilityLimit.uninsuredInjuryCoverage === "Y") {
                missingField = "무보험 상해가입 금액을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.dutyJason) {
                missingField = "자손 책임 한도를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.indemJason) {
                missingField = "자손 책임 면책금을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.insuranceCorpName) {
                missingField = "보험사명을 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.emergencyCallNumber) {
                missingField = "긴급출동 번호를 입력해주세요.";
                errorRef = liabilityLimitRef;
            } else if (!liabilityLimit.emergencyCallCount) {
                missingField = "긴급출동 연 횟수를 입력해주세요.";
                errorRef = liabilityLimitRef;
            }
        }

        // deductible 필드 검사
        if (!missingField) {
            // if (!deductible.contractAgeTypes || deductible.contractAgeTypes.length === 0) {
            //     missingField = "계약 연령 선택해주세요.";
            //     errorRef = deductibleRef;
            // }
            if (!deductible.age21RentPrice && deductible.contractAgeTypes.includes("AGE_21")) {
                missingField = "21세 대여료 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            } else if (!deductible.age21IndemPrice && deductible.contractAgeTypes.includes("AGE_21")) {
                missingField = "21세 면책금 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            } else if (!deductible.age23RentPrice && deductible.contractAgeTypes.includes("AGE_23")) {
                missingField = "23세 대여료 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            } else if (!deductible.age23IndemPrice && deductible.contractAgeTypes.includes("AGE_23")) {
                missingField = "23세 면책금 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            } else if (!deductible.expLt1RentPrice && deductible.expLt1Yn === "Y") {
                missingField = "운전면허 1년 미만 대여료 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            } else if (!deductible.expLt1IndemPrice && deductible.expLt1Yn === "Y") {
                missingField = "운전면허 1년 미만 면책금 추가 금액을 입력해주세요.";
                errorRef = deductibleRef;
            }
        }

        if (missingField !== "") {
            handleToast(`${missingField}`);
            if (errorRef && errorRef.current) {
                window.scrollTo({
                    top: errorRef.current.offsetTop + 180,
                    behavior: "smooth",
                });
            }
            return false;
        }

        return true;
    };

    // handleUpload 함수
    const handleUpload = async () => {
        if (!isFormValid()) {
            return;
        }

        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const imageData = vehicleInformation.vehiclePhotos;
            const mainImage = vehicleInformation.mainThumbnail;

            // 이미지 파일 업로드를 위한 Promise 배열 생성
            const uploadPromises = imageData.map((file) => {
                const formData = new FormData();
                formData.append("file", file);
                return createFormData("/api/share/upload/v1/image", formData);
            });

            // 대표 이미지 업로드 (있는 경우)
            let mainImageUploadId = null;
            if (mainImage) {
                const mainImageFormData = new FormData();
                mainImageFormData.append("file", mainImage);
                const mainImageResponse = await createFormData("/api/share/upload/v1/image", mainImageFormData);
                mainImageUploadId = mainImageResponse.data.data.id;
            }

            // 모든 이미지 업로드 대기, 이미지 아이디 저장
            const imageResponses = await Promise.all(uploadPromises);
            const imageResponseIds = imageResponses.map((response) => response.data.data.id);

            const productData = {
                autoUpCarId: vehicleInformation.autoUpCarId,
                mileage: Number(uncomma(vehicleInformation.mileage)),
                innerColorId: Number(vehicleInformation.innerColorId),
                outColorId: Number(vehicleInformation.outColorId),
                rentType: vehicleInformation.rentType,
                carImageUploadIds: imageResponseIds,
                mainImgUploadId: mainImageUploadId,
                shareProductStatus: vehicleInformation.productStatus,
                year: Number(vehicleInformation.year),
                month: Number(vehicleInformation.month),
                carLocationBcode: vehicleInformation.carLocationBcode,
                carLocationAddress: vehicleInformation.carLocationAddress,
                carLocationAddressDetail: vehicleInformation.carLocationAddressDetail,
                depositTwiceYn: depositRentalFee.depositTwiceYn,
                comissionType: depositRentalFee.comissionType,
                contractPrices: depositRentalFee.contractPrices,
                deliverySupportPrice: Number(uncomma(contractConditions.deliverySupportPrice)),
                takeOverType: contractConditions.takeOverType,
                inspectionProducts: contractConditions.inspectionProducts,
                contractTypes: contractConditions.contractTypes,
                yearContractMileage: Number(uncomma(contractConditions.yearContractMileage)),
                dutyDaein: liabilityLimit.infinityDaein === true ? Number(-1) : Number(uncomma(liabilityLimit.dutyDaein)),
                indemDaein: Number(uncomma(liabilityLimit.indemDaein)),
                dutyDaemul: Number(uncomma(liabilityLimit.dutyDaemul)),
                indemDaemul: Number(uncomma(liabilityLimit.indemDaemul)),
                dutyJacha: Number(uncomma(liabilityLimit.dutyJacha)),
                indemJachaMin: Number(uncomma(liabilityLimit.indemJachaMin)),
                indemJachaMax: Number(uncomma(liabilityLimit.indemJachaMax)),
                dutyNoInsuranceInjury: Number(uncomma(liabilityLimit.dutyNoInsuranceInjury)),
                dutyJason: Number(uncomma(liabilityLimit.dutyJason)),
                indemJason: Number(uncomma(liabilityLimit.indemJason)),
                insuranceCorpName: liabilityLimit.insuranceCorpName,
                emergencyCallNumber: liabilityLimit.emergencyCallNumber,
                emergencyCallCount: Number(uncomma(liabilityLimit.emergencyCallCount)),
                contractAgeTypes: deductible.contractAgeTypes,
                age21RentPrice: Number(uncomma(deductible.age21RentPrice)),
                age21IndemPrice: Number(uncomma(deductible.age21IndemPrice)),
                age23RentPrice: Number(uncomma(deductible.age23RentPrice)),
                age23IndemPrice: Number(uncomma(deductible.age23IndemPrice)),
                expLt1Yn: deductible.expLt1Yn,
                expLt1RentPrice: Number(uncomma(deductible.expLt1RentPrice)),
                expLt1IndemPrice: Number(uncomma(deductible.expLt1IndemPrice)),
                personalDriverRange: driverCoverage.personalDriverRange,
                corpDriverRange: driverCoverage.corpDriverRange,
                personalBizDriverRange: driverCoverage.personalBizDriverRange,
                subscriptionDriverRange: driverCoverage.subscriptionDriverRange,
                contractNote: contractPrecautions.contractNote,
            };

            if (newPriceDisabled === false) {
                productData.newPrice = Number(uncomma(vehicleInformation.releasePrice));
            }

            console.log(productData);

            // 모든 이미지 업로드 성공 후 productData 전송
            const response = await createData("/api/share/rent_corp/product/v1", productData);
            showModal("차량이 등록되었습니다.", <p>추가 업로드 하시겠습니까?</p>, [
                {
                    text: "아니오",
                    handler: () => {
                        navigate("/mypage/supplier");
                    },
                },
                {
                    text: "확인",
                    handler: () => {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    },
                },
            ]);
        } catch (error) {
            handleToast(error.response.data.message, Infinity);
            console.error("데이터 업로드 중 오류 발생:", error);
        } finally {
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    // 차량 정보 수정
    const { carId } = useParams();
    const [uploadStatus, setUploadStatus] = useState("NEW");
    const [initData, setInitData] = useState();

    // 필터링된 데이터 가져오기
    const fetchProductData = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const response = await fetchData(`api/share/rent_corp/product/v1/${carId}`);
            setInitData(response.data.data);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, isLoading: false }));
            handleToast(error.response.data.message, Infinity);
        }
    };

    // handleUpdate 함수
    const handleUpdate = async () => {
        if (!isFormValid()) {
            return;
        }

        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const imageData = vehicleInformation.vehiclePhotos;
            const mainImage = vehicleInformation.mainThumbnail;

            // 기존 이미지 id값 추출
            const originImgIds = imageData.map((file) => {
                const isFile = file && file.url;

                if (isFile) {
                    return file.uploadId;
                }
            });

            // 이미지 파일 업로드를 위한 Promise 배열 생성
            const uploadPromises = imageData.map((file) => {
                const isFile = file && file.url;

                if (!isFile) {
                    const formData = new FormData();
                    formData.append("file", file);
                    return createFormData("/api/share/upload/v1/image", formData);
                }
            });

            // 대표 이미지 업로드 (있는 경우)
            let mainImageUploadId = null;
            console.log(vehicleInformation.mainThumbnailId);

            if (mainImage && mainImage.type) {
                const mainImageFormData = new FormData();
                mainImageFormData.append("file", mainImage);
                const mainImageResponse = await createFormData("/api/share/upload/v1/image", mainImageFormData);
                mainImageUploadId = mainImageResponse.data.data.id;
            } else {
                mainImageUploadId = vehicleInformation.mainThumbnailId;
            }

            // 모든 이미지 업로드 대기, 이미지 아이디 저장
            const imageResponses = await Promise.all(uploadPromises);
            const imageResponseIds = imageResponses?.map((response) => {
                if (response) return response.data.data.id;
            });

            const combinedIds = [...new Set([...originImgIds, ...imageResponseIds])].filter((id) => id !== undefined);
            // console.log(combinedIds);

            const productData = {
                autoUpCarId: vehicleInformation.autoUpCarId,
                mileage: Number(uncomma(vehicleInformation.mileage)),
                innerColorId: Number(vehicleInformation.innerColorId),
                outColorId: Number(vehicleInformation.outColorId),
                rentType: vehicleInformation.rentType,
                carImageUploadIds: combinedIds,
                mainImgUploadId: Number(mainImageUploadId),
                shareProductStatus: vehicleInformation.productStatus,
                year: Number(vehicleInformation.year),
                month: Number(vehicleInformation.month),
                carLocationBcode: vehicleInformation.carLocationBcode,
                carLocationAddress: vehicleInformation.carLocationAddress,
                carLocationAddressDetail: vehicleInformation.carLocationAddressDetail,
                depositTwiceYn: depositRentalFee.depositTwiceYn,
                comissionType: depositRentalFee.comissionType,
                contractPrices: depositRentalFee.contractPrices,
                deliverySupportPrice: Number(uncomma(contractConditions.deliverySupportPrice)),
                takeOverType: contractConditions.takeOverType,
                inspectionProducts: contractConditions.inspectionProducts,
                contractTypes: contractConditions.contractTypes,
                yearContractMileage: Number(uncomma(contractConditions.yearContractMileage)),
                dutyDaein: liabilityLimit.infinityDaein === true ? Number(-1) : Number(uncomma(liabilityLimit.dutyDaein)),
                indemDaein: Number(uncomma(liabilityLimit.indemDaein)),
                dutyDaemul: Number(uncomma(liabilityLimit.dutyDaemul)),
                indemDaemul: Number(uncomma(liabilityLimit.indemDaemul)),
                dutyJacha: Number(uncomma(liabilityLimit.dutyJacha)),
                indemJachaMin: Number(uncomma(liabilityLimit.indemJachaMin)),
                indemJachaMax: Number(uncomma(liabilityLimit.indemJachaMax)),
                dutyNoInsuranceInjury: Number(uncomma(liabilityLimit.dutyNoInsuranceInjury)),
                dutyJason: Number(uncomma(liabilityLimit.dutyJason)),
                indemJason: Number(uncomma(liabilityLimit.indemJason)),
                insuranceCorpName: liabilityLimit.insuranceCorpName,
                emergencyCallNumber: liabilityLimit.emergencyCallNumber,
                emergencyCallCount: Number(uncomma(liabilityLimit.emergencyCallCount)),
                contractAgeTypes: deductible.contractAgeTypes,
                age21RentPrice: Number(uncomma(deductible.age21RentPrice)),
                age21IndemPrice: Number(uncomma(deductible.age21IndemPrice)),
                age23RentPrice: Number(uncomma(deductible.age23RentPrice)),
                age23IndemPrice: Number(uncomma(deductible.age23IndemPrice)),
                expLt1Yn: deductible.expLt1Yn,
                expLt1RentPrice: Number(uncomma(deductible.expLt1RentPrice)),
                expLt1IndemPrice: Number(uncomma(deductible.expLt1IndemPrice)),
                personalDriverRange: driverCoverage.personalDriverRange,
                corpDriverRange: driverCoverage.corpDriverRange,
                personalBizDriverRange: driverCoverage.personalBizDriverRange,
                subscriptionDriverRange: driverCoverage.subscriptionDriverRange,
                contractNote: contractPrecautions.contractNote,
            };

            if (newPriceDisabled === false) {
                productData.newPrice = vehicleInformation.releasePrice;
            }

            // 모든 이미지 업로드 성공 후 productData 전송
            const response = await updateData(`/api/share/rent_corp/product/v1/${carId}`, productData);
            showModal("차량이 수정되었습니다.", <p>{response.data.message}</p>, [
                {
                    text: "확인",
                    handler: () => {
                        navigate("/mypage/supplier");
                        // window.location.reload();
                        // window.scrollTo(0, 0);
                    },
                },
            ]);
        } catch (error) {
            handleToast(error.response.data.message, Infinity);
            console.error("데이터 업로드 중 오류 발생:", error);
        } finally {
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    useEffect(() => {
        if (carId === undefined) return;

        setUploadStatus("MODIFY");
        fetchProductData();
    }, [carId]);

    useEffect(() => {
        // 스크롤 섹션
        const handleScroll = () => {
            const offsets = {
                vehicleInformation: vehicleInformationRef.current.offsetTop,
                depositRentalFee: depositRentalFeeRef.current.offsetTop,
                contractConditions: contractConditionsRef.current.offsetTop,
                liabilityLimit: liabilityLimitRef.current.offsetTop,
                deductible: deductibleRef.current.offsetTop,
                driverCoverage: driverCoverageRef.current.offsetTop,
            };

            const scrolledPosition = window.scrollY - 180;
            let active = null;
            for (const section in offsets) {
                if (scrolledPosition >= offsets[section]) {
                    active = section;
                } else {
                    break;
                }
            }
            setActiveSection(active);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToSection = (ref) => {
        const additionalOffset = 180;

        window.scrollTo({
            top: ref.current.offsetTop + additionalOffset,
            behavior: "smooth",
        });
    };

    // 모달
    const handleModalClose = () => {
        setState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {state.isLoading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="productUpload container">
                <div className="contents-title">
                    <h1>차량 업로드</h1>
                </div>
                <div className="contents-head">
                    <ul>
                        <li
                            className={activeSection === "vehicleInformation" ? "active" : ""}
                            onClick={() => scrollToSection(vehicleInformationRef)}
                        >
                            <a>차량 정보</a>
                        </li>
                        <li
                            className={activeSection === "depositRentalFee" ? "active" : ""}
                            onClick={() => scrollToSection(depositRentalFeeRef)}
                        >
                            <a>보증금/렌트료</a>
                        </li>
                        <li
                            className={activeSection === "contractConditions" ? "active" : ""}
                            onClick={() => scrollToSection(contractConditionsRef)}
                        >
                            <a>계약조건</a>
                        </li>
                        <li
                            className={activeSection === "liabilityLimit" ? "active" : ""}
                            onClick={() => scrollToSection(liabilityLimitRef)}
                        >
                            <a>책임한도</a>
                        </li>
                        <li className={activeSection === "deductible" ? "active" : ""} onClick={() => scrollToSection(deductibleRef)}>
                            <a>특약</a>
                        </li>
                        <li
                            className={activeSection === "driverCoverage" ? "active" : ""}
                            onClick={() => scrollToSection(driverCoverageRef)}
                        >
                            <a>운전자범위</a>
                        </li>
                    </ul>
                </div>

                <div className="contents-body">
                    <div className="section-container" ref={vehicleInformationRef}>
                        <VehicleInformation
                            uploadStatus={uploadStatus}
                            initData={initData}
                            onVehicleInfoChange={setVehicleInformation}
                            newPriceDisabled={newPriceDisabled}
                        />
                    </div>
                    <div className="section-container" ref={depositRentalFeeRef}>
                        <DepositRentalFee
                            initData={initData}
                            onRentalFeeChange={setDepositRentalFee}
                            vehicleInformation={vehicleInformation}
                            handleToast={handleToast}
                            setNewPriceDisabled={setNewPriceDisabled}
                        />
                    </div>
                    <div className="section-container" ref={contractConditionsRef}>
                        <ContractConditions initData={initData} onConditionsChange={setContractConditions} />
                    </div>
                    <div className="section-container" ref={liabilityLimitRef}>
                        <LiabilityLimit initData={initData} onLiabilityLimitChange={setLiabilityLimit} />
                    </div>
                    <div className="section-container" ref={deductibleRef}>
                        <Deductible initData={initData} onDeductibleChange={setDeductible} />
                    </div>
                    <div className="section-container" ref={driverCoverageRef}>
                        <DriverCoverage initData={initData} onDriverCoverageChange={setDriverCoverage} />
                    </div>
                    <div className="section-container">
                        <SpecialAgreement initData={initData} depositRentalFee={depositRentalFee} deductible={deductible} />
                        <ContractPrecautions initData={initData} onContractPrecautionsChange={setContractPrecautions} />
                    </div>
                </div>

                <div className="contents-footer">
                    {uploadStatus === "NEW" && (
                        <button type="button" className="btn btn-primary btn-large btn-upload" onClick={handleUpload}>
                            차량 업로드
                        </button>
                    )}
                    {uploadStatus === "MODIFY" && (
                        <button type="button" className="btn btn-primary btn-large btn-upload" onClick={handleUpdate}>
                            차량 정보 수정하기
                        </button>
                    )}
                </div>
            </div>

            {state.modalOpen && (
                <Modal
                    title={state.modalContent.title}
                    content={state.modalContent.content}
                    buttons={state.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={true}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
