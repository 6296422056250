/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/loginSlice';
import * as Common from 'resources/js/Common.js';

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // 로그아웃 처리
    const handleLogout = () => {
      // 리덕스 상태 업데이트
      dispatch(logout());

      // 로컬 스토리지 및 쿠키에서 인증 정보 제거
      localStorage.removeItem('access_token');
      Common.delCookie('chada_login_status');

      // 로그인 페이지 또는 홈으로 이동
      navigate("/login");
    };

    handleLogout();
  }, [dispatch, navigate]);

  return null;
};
