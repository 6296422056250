import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma, uncomma, handleNumericInput } from "util";

export const SpecialContract = ({ setFormStatus, handleToast }) => {
    const [formData, setFormData] = useState({
        age21RentPrice: 0,
        age21IndemPrice: 0,
        age23RentPrice: 0,
        age23IndemPrice: 0,
        expLt1RentPrice: 0,
        expLt1IndemPrice: 0,
    });

    const { age21RentPrice, age21IndemPrice, age23RentPrice, age23IndemPrice, expLt1RentPrice, expLt1IndemPrice } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                age21RentPrice: Number(uncomma(formData.age21RentPrice)),
                age21IndemPrice: Number(uncomma(formData.age21IndemPrice)),
                age23RentPrice: Number(uncomma(formData.age23RentPrice)),
                age23IndemPrice: Number(uncomma(formData.age23IndemPrice)),
                expLt1RentPrice: Number(uncomma(formData.expLt1RentPrice)),
                expLt1IndemPrice: Number(uncomma(formData.expLt1IndemPrice)),
            };

            const response = await updateData(`/api/share/rent_corp/contract/v1/special_contract`, payload);
            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchContractData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            const contractData = response.data.data;

            setFormData({
                age21RentPrice: contractData?.age21RentPrice === 0 ? "" : comma(contractData?.age21RentPrice) || "",
                age21IndemPrice: contractData?.age21IndemPrice === 0 ? "" : comma(contractData?.age21IndemPrice) || "",
                age23RentPrice: contractData?.age23RentPrice === 0 ? "" : comma(contractData?.age23RentPrice) || "",
                age23IndemPrice: contractData?.age23IndemPrice === 0 ? "" : comma(contractData?.age23IndemPrice) || "",
                expLt1RentPrice: contractData?.expLt1RentPrice === 0 ? "" : comma(contractData?.expLt1RentPrice) || "",
                expLt1IndemPrice: contractData?.expLt1IndemPrice === 0 ? "" : comma(contractData?.expLt1IndemPrice) || "",
            });
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchContractData();
    }, []);

    return (
        <>
            <div className="consignment_item-list">
                <div className="item grid-col">
                    <div className="item-head">
                        <span>만 21세 이상 추가금</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="age21RentPrice"
                                value={age21RentPrice || ""}
                                onChange={handleChange}
                                placeholder="대여료 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="age21IndemPrice"
                                value={age21IndemPrice || ""}
                                onChange={handleChange}
                                placeholder="면책금 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item grid-col">
                    <div className="item-head">
                        <span>만 23세 이상 추가금</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="age23RentPrice"
                                value={age23RentPrice || ""}
                                onChange={handleChange}
                                placeholder="대여료 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="age23IndemPrice"
                                value={age23IndemPrice || ""}
                                onChange={handleChange}
                                placeholder="면책금 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item grid-col">
                    <div className="item-head">
                        <span>운전면허 1년 미만 추가금</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="expLt1RentPrice"
                                value={expLt1RentPrice || ""}
                                onChange={handleChange}
                                placeholder="대여료 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="expLt1IndemPrice"
                                value={expLt1IndemPrice || ""}
                                onChange={handleChange}
                                placeholder="면책금 추가 금액"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>
        </>
    );
};
