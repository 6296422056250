/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Modal_info, Toast, ProgressBar, VerifyPhone } from "components";
import styles from "./FindPw.module.scss";

import axios from "axios";
import * as Common from "resources/js/Common.js";

// API URL
import { baseUrl } from "resources/js/ApiUrl.js";
const BASE_URL = baseUrl();
const AUTH_SEND_URL = "/api/user/auth/v1/phone/send";
const AUTH_VALIDATION_URL = "/api/user/auth/v1/phone/validation";
const PASSWORD_URL = "/api/user/auth/v1/password/reset";
const PHONE_CHECK_URL = "/api/user/join/v1/search/login_id";

export const FindPw = () => {
    // 비밀번호 찾기 탭 화면
    let navigate = useNavigate();
    const tabCount = 3;
    const [tabIndex, setTabIndex] = useState(0);
    const [btnState, setBtnState] = useState(false);
    const [findDataId, setFindDataId] = useState();
    const [authNum, setAuthNum] = useState();
    const [pwWarnStatus, setPwWarnStatus] = useState(true);
    const [pwWarnTxt, setPwWarnTxt] = useState("");

    // 인증번호 타이머 변수
    const [times, setTimes] = useState(180 - 1);
    let interval;
    let timer, minutes, seconds;

    useEffect(() => {
        if (tabIndex == 1) {
            initTimer(times);
        }
    }, [tabIndex]);

    // 비밀번호 찾기 프로그레스 바
    let [progressValue, setProgressValue] = useState(100 / tabCount);

    const progressUpdateHandler = async () => {
        if (btnState != false) {
            switch (tabIndex) {
                case 0: // 아이디/핸드폰번호 입력
                    if ((await fetchCheckManager()) === true) {
                        const userResult = await fetchSend();
                        if (userResult === false) {
                            return;
                        }
                    } else {
                        return;
                    }

                    // const userResult = await fetchSend();
                    // if (userResult === false) {
                    //   return;
                    // }
                    break;
                case 1: // 인증번호 입력
                    const validationResult = await fetchValidation(authKey_1 + authKey_2 + authKey_3 + authKey_4 + authKey_5 + authKey_6);
                    if (validationResult === false) {
                        return;
                    }
                    break;
                case 2: // 비밀번호 재설정
                    // 아이디/비밀번호 유효성 검사
                    if (pw !== pwChk) {
                        setPwWarnStatus(false);
                        setPwWarnTxt("비밀번호가 일치하지 않습니다.");
                        return;
                    }
                    if (pw.length < 8) {
                        setPwWarnStatus(false);
                        setPwWarnTxt("최소 8글자 이상 입력해주세요.");
                        return;
                    }
                    setPwWarnStatus(true);

                    const lastResult = await fetchUpdatePassword();
                    if (lastResult === false) {
                        return;
                    }
                    break;
            }

            setTabIndex((prev) => prev + 1);
            setProgressValue((prev) => prev + 100 / tabCount);
            setBtnState(false);

            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    /****************************************
     * TODO 항목별 API 목록
     ****************************************/
    // 인증번호 발송
    const fetchSend = async () => {
        try {
            const response = await axios.post(
                BASE_URL + AUTH_SEND_URL,
                { phone: prsnCntct, loginId: id }
                // {
                //   headers: {
                //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                //   },
                // }
            );

            setFindDataId(response.data.data.id);
            setAuthNum(response.data.data.authNum);
            return true;
        } catch (err) {
            handleToast(err.response?.data.message);
            return false;
        }
    };

    // 인증번호 확인
    const fetchValidation = async (authKeys) => {
        try {
            const response = await axios.post(
                BASE_URL + AUTH_VALIDATION_URL,
                { id: findDataId, phone: prsnCntct, authNum: authKeys }
                // {
                //   headers: {
                //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                //   },
                // }
            );

            return true;
        } catch (err) {
            handleToast(err.response?.data.message);
            return false;
        }
    };

    const fetchUpdatePassword = async () => {
        try {
            const response = await axios.put(BASE_URL + PASSWORD_URL, {
                loginId: id,
                phone: prsnCntct,
                password: pw,
            });

            navigate("/yourPw");
            return false;
        } catch (err) {
            handleToast(err.response?.data.message);
            return false;
        }
    };

    // 담당자 연락처 존재확인
    const fetchCheckManager = async () => {
        try {
            const response = await axios.get(BASE_URL + PHONE_CHECK_URL + `?manager_phone=${prsnCntct}`);

            return true;
        } catch (err) {
            handleToast(err.response?.data.message);
            return false;
        }
    };

    // 입력 데이터 세팅
    const [inputs, setInputs] = useState({
        id: "", // 아이디
        prsnCntct: "", // 담당자 연락처
        pw: "", // 비밀번호
        pwChk: "", // 비밀번호 재입력
    });
    const { id, prsnCntct, pw, pwChk } = inputs;

    const updateInputData = (e) => {
        const { name, value } = e.target;

        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const resetInputData = () => {
        setInputs({
            id: "",
            prsnCntct: "",
            pw: "",
            pwChk: "",
        });
    };

    // 탭 1 유효성 검사
    useEffect(() => {
        // 현재 탭 인덱스 값 확인 후 return;
        if (tabIndex != 0) {
            return;
        }
        //////////////////////////////////
        if (id != "" && prsnCntct != "") {
            if (prsnCntct.length >= 10) {
                setBtnState(true);
            } else {
                setBtnState(false);
            }
        } else {
            setBtnState(false);
        }
        // ID, PW input 별로 유효성 검사 처리

        ///////////////////////////////////
    }, [id, prsnCntct]);

    // 인증요청 데이터 세팅
    const [authKeys, setAuthKeys] = useState({
        authKey_1: "",
        authKey_2: "",
        authKey_3: "",
        authKey_4: "",
        authKey_5: "",
        authKey_6: "",
    });
    const { authKey_1, authKey_2, authKey_3, authKey_4, authKey_5, authKey_6 } = authKeys;
    const [authTimer, setAuthTimer] = useState("03:00");
    const [authInput, setAuthInput] = useState("");

    const updateAuthKey = (e) => {
        const value = e.target.value;

        setAuthInput(e.target.value);
        setAuthKeys({
            ...authKeys,
            ["authKey_1"]: value[0],
            ["authKey_2"]: value[1],
            ["authKey_3"]: value[2],
            ["authKey_4"]: value[3],
            ["authKey_5"]: value[4],
            ["authKey_6"]: value[5],
        });
    };

    const resetAuthKey = (e) => {
        const value = e.target.value;

        setAuthInput("");
        setAuthKeys({
            ...authKeys,
            ["authKey_1"]: null,
            ["authKey_2"]: null,
            ["authKey_3"]: null,
            ["authKey_4"]: null,
            ["authKey_5"]: null,
            ["authKey_6"]: null,
        });
    };

    const initTimer = (times) => {
        timer = times;
        interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setAuthTimer(minutes + ":" + seconds);

            if (--timer < 0) {
                timer = times;
            }
            if (timer === 0) {
                clearInterval(interval);
                setAuthTimer("만료");
            }
        }, 1000);
    };

    // 인증번호 요청
    const requestAuth = () => {
        // 1. authKeys 유효성 검사
        // 텍스트 변경
        // 타이머 가동
        // setTimes(180);
        // clearInterval(interval);
        // initTimer(times);
    };

    // 탭 2 유효성 검사
    useEffect(() => {
        // 현재 탭 인덱스 값 확인 후 return;
        if (tabIndex != 1) {
            return;
        }
        //////////////////////////////////
        if (authKey_1 != null && authKey_2 != null && authKey_3 != null && authKey_4 != null && authKey_5 != null && authKey_6 != null) {
            setBtnState(true);
        } else {
            setBtnState(false);
        }
        // ID, PW input 별로 유효성 검사 처리

        ///////////////////////////////////
    }, [authKey_1, authKey_2, authKey_3, authKey_4, authKey_5, authKey_6]);

    // 탭 3 유효성 검사
    useEffect(() => {
        if (tabIndex === 2) {
            if (pw != "" && pwChk != "") {
                setBtnState(true);
            } else {
                setBtnState(false);
            }

            if (pwWarnStatus === false && pw.length >= 8 && pwChk.length >= 8) {
                if (pw === pwChk) {
                    setPwWarnStatus(true);
                    return;
                }
            }
        }
    }, [pw, pwChk]);

    // 비밀번호 확인

    // input[type="number"] maxLength check
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    /****************************************
     * TODO 모달 & 토스트 팝업 상태
     ****************************************/
    //* 모달
    const [modal, setModal] = useState({
        open: false,
        dim: true,
        title: "",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback1: false,
        callback2: false,
    });

    //* 토스트 팝업 세팅
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt) => {
        setToastStatus(true);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, 1000);
        }
    }, [toastStatus]);

    return (
        <>
            <div className={`${styles.findPw}` + " form-width"}>
                <div className={styles.findPw__header}>
                    <h1 className="heading-1">비밀번호 찾기</h1>
                    <ProgressBar percent={progressValue} />
                </div>
                {/********** TAB 1 : 이용약관 동의 **********/}
                <div
                    className={tabIndex == 0 ? `${styles.findPw__content} ${styles.findPw__content__visible}` : `${styles.findPw__content}`}
                >
                    <div className={`${styles.findPw__content__mainTxt}` + " heading-3"}>회원정보 입력</div>
                    <p className={`${styles.findPw__content__subTxt}` + " pm"}>해당하는 회원정보를 입력해주세요.</p>
                    <div className={styles.findPw__content__formContainer}>
                        {/* 아이디 */}
                        <div className="input-form">
                            <p className="form-title">
                                <span>아이디</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="text"
                                        name="id"
                                        className=""
                                        placeholder="아이디 (이메일)"
                                        onChange={updateInputData}
                                        value={id}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 담당자 연락처 */}
                        <div className="input-form">
                            <p className="form-title">
                                <span>담당자 연락처</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="number"
                                        name="prsnCntct"
                                        className=""
                                        placeholder="- 없이 입력"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const onlyNumber = value.replace(/[^0-9]/g, ""); // value의 값이 숫자가 아닐경우 빈문자열로 replace

                                            if (onlyNumber === "" && prsnCntct.length === 1) {
                                                updateInputData(e);
                                            } else if (onlyNumber !== "") {
                                                updateInputData(e);
                                            }
                                        }}
                                        value={prsnCntct}
                                        onInput={maxLengthCheck}
                                        maxLength="11"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/********** TAB 2 : 아이디&비밀번호 입력 **********/}
                <div
                    className={tabIndex == 1 ? `${styles.findPw__content} ${styles.findPw__content__visible}` : `${styles.findPw__content}`}
                >
                    <div className={`${styles.findPw__content__mainTxt}` + " heading-3"}>인증번호 입력</div>
                    <p className={`${styles.findPw__content__subTxt}` + " pm"}>휴대폰 번호로 전송된 인증 번호를 입력해주세요.</p>
                    <div className={styles.findPw__content__formContainer}>
                        {/* 인증번호 */}
                        <div className="input-form">
                            <div className="form-field">
                                <div className="number-element">
                                    <input
                                        type="number"
                                        name="prsnCntctAuth"
                                        className=""
                                        onChange={updateAuthKey}
                                        onInput={maxLengthCheck}
                                        onClick={resetAuthKey}
                                        value={authInput}
                                        maxLength="6"
                                    />
                                    <span id="authKey_1" className="authKeyItems">
                                        {authKey_1}
                                    </span>
                                    <span id="authKey_2" className="authKeyItems">
                                        {authKey_2}
                                    </span>
                                    <span id="authKey_3" className="authKeyItems">
                                        {authKey_3}
                                    </span>
                                    <span id="authKey_4" className="authKeyItems">
                                        {authKey_4}
                                    </span>
                                    <span id="authKey_5" className="authKeyItems">
                                        {authKey_5}
                                    </span>
                                    <span id="authKey_6" className="authKeyItems">
                                        {authKey_6}
                                    </span>
                                </div>
                            </div>
                            <div className="auth-row">
                                <span className="remain_time">남은 시간 {authTimer}</span>
                                <button type="button" onClick={requestAuth}>
                                    인증번호 재요청
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/********** TAB 3 : 비밀번호 재설정 **********/}
                <div
                    className={tabIndex == 2 ? `${styles.findPw__content} ${styles.findPw__content__visible}` : `${styles.findPw__content}`}
                >
                    <div className={`${styles.findPw__content__mainTxt}` + " heading-3"}>비밀번호 재설정</div>
                    <p className={`${styles.findPw__content__subTxt}` + " pm"}>새롭게 사용할 비밀번호를 입력해주세요.</p>
                    <div className={styles.findPw__content__formContainer}>
                        {/* 새로운 비밀번호 */}
                        <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
                            <p className="form-title">
                                <span>새로운 비밀번호</span>
                            </p>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="password"
                                        name="pw"
                                        className=""
                                        placeholder="비밀번호"
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                // spacebar 막기
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={updateInputData}
                                        value={pw}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 비밀번호 재입력 */}
                        <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
                            <div className="form-field">
                                <div className="input-element">
                                    <input
                                        type="password"
                                        name="pwChk"
                                        className=""
                                        placeholder="비밀번호 재입력"
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                // spacebar 막기
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={updateInputData}
                                        value={pwChk}
                                    />
                                </div>
                            </div>
                            <p className="alert-msg error">{pwWarnTxt}</p>
                        </div>
                    </div>
                </div>

                <div className={`${styles.findPw__buttons}` + " button-form"}>
                    <button
                        type="button"
                        className={btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"}
                        onClick={() => progressUpdateHandler()}
                        disabled={!btnState}
                    >
                        다음
                    </button>
                </div>
            </div>

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />

            {/* 로그인 모달 */}
            {modal.open && (
                <ModalConfirm
                    dim={modal.dim}
                    setModal={setModal}
                    title={modal.title}
                    message={modal.message}
                    notxt={modal.notxt}
                    oktxt={modal.oktxt}
                    callback1={modal.callback1}
                    callback2={modal.callback2}
                />
            )}
        </>
    );
};
