/* eslint-disable */
import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export const FormStep4 = () => {
    return (
        <>
            <div className="tab" id="tab-04">
                <div className="item-wrapper border-none">
                    <div className="item-head">
                        <span>정비 서비스 및 기타내용</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>정비 서비스</b>
                            </span>
                            <span className="item-pre color-grey">
                            정비 및 소모품 교체는 고객이 부담합니다. 단, 차량인도후 3개월 이내
                                기능고장(엔진결함 등) 의 경우 수리비지원 및 동종차량으로 대차, 교차가 가능합니다.
                                {"\n"}
                                {"\n"}
                                정비상품- 사전 합의된 지정 정비점에서의 내방한 정비만 인정합니다(예약필수)단, 사전에 폅의된 지정 정비점
                                제휴가 안되어 있을경우 제조자 공식공업사 (블루헨즈, 오토큐)로 내방한 정비만 인정합니다.{" "}
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>계약연장 및 해지</b>
                            </span>
                            <span className="item-pre color-grey">
                                장기계약의 경우 계약종료 한달(30일)전 까지는 당사의 승인을 받야야 합니다. 계약 연장 희망시 고객님께서 먼저
                                렌터카로 연락하셔야 하며, 고객님의 연락이 없다면 연장하지 않는 것으로 간주합니다. 사전 연락이 없고 이체일이
                                경과될 경우 일할 계산되어 대여료가 청구됩니다.
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>탁송료(반납)</b>
                            </span>
                            <span className="item-pre color-grey">계약 만기 반납이 아닌 경우 탁송료는 고객님이 부담합니다.</span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>약정운행거리</b>
                            </span>
                            <span className="item-pre color-grey">
                                계약서에 나온 약정 km가 초과될 경우 1km당 110원의 추가요금이 부과됩니다.(유예거리 1,000km)
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>연락처,주소변경</b>
                            </span>
                            <span className="item-pre color-grey">
                                정보 변경시 렌트사에 먼저 연락해야합니다.
                                {"\n"}연락두절시 시동제어 및 계약해지될 수 있습니다.
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>과태료, 차량검사</b>
                            </span>
                            <span className="item-pre color-grey">
                                과태료 미납, 통행료 미납 및 차량 검사 불이행시 시동제어 될 수 있습니다.
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>검사대행 서비스 (대행업체진행)</b>
                            </span>
                            <div className="item-list grid-col">
                                <div className="item">
                                    <span className="item-tit">정기검사</span>
                                    <span className="item-desc">2년 1회</span>
                                </div>
                                <div className="item">
                                    <span className="item-tit">종합검사</span>
                                    <span className="item-desc">1년 1회</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="item width-100">
                            <span className="item-tit">
                                <b>서비스 품목</b>
                            </span>
                            <span className="item-pre color-grey">썬팅, 블랙박스 내비게이션(자가수리진행)</span>
                        </div> */}
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>특이사항</b>
                            </span>
                            <span className="item-pre color-grey">
                                <em>
                                    자동차키는 1개만 지급
                                    {"\n"}GPS 장착(도단 및 연체, 연락두절시 시동제어)
                                </em>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
