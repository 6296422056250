/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { ApplicationConsignment } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload, faXmark, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { DaumPost, Modal, Toast } from "components";

export const RequestRedemtion = ({ setModal, showModal, handleModalClose, contractId }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);

    const [formData, setFormData] = useState({
        returnReason: "",
    });

    const { returnReason } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const [applicationConsignmentModalStatus, setApplicationConsignmentModalStatus] = useState(false);

    const requestRefund = async () => {
        setLoading(true);

        try {
            const requestData = {
                refundedAt: startDate,
                returnReason: returnReason,
                deliveryRequest: null,
            };

            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/refund`, requestData);

            handleToast(response.data.message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleToast(error.response.data?.message, Infinity);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    // 계약 내역
    const fetchDetailData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/${contractId}`);
            setData(response.data.data);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        if (contractId) fetchDetailData();
    }, []);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    useEffect(() => {
        console.log(contractId);
    }, [contractId]);

    return (
        <>
            <div className="modal-layer"></div>

            <div className="modal modal-type-2">
                <div className="modal-title">
                    <span className="modal-tit-main">환수신청</span>
                    <button type="button" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-form">
                        <div className="content-tit">
                            <span>회수 일자를 선택해주세요.</span>
                        </div>
                        <div className="content-form">
                            <div className="input-form">
                                <div className="input-datepicker">
                                    <FontAwesomeIcon icon={faCalendarDays} style={{ color: "#ddd", marginRight: "10px" }} />
                                    <DatePicker
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText=""
                                        selected={startDate}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-form">
                        <div className="content-tit">
                            <span>환수 사유를 입력해주세요.</span>
                        </div>
                        <div className="content-form">
                            <div className="textarea-element">
                                <textarea
                                    name="returnReason"
                                    value={returnReason || ""}
                                    onChange={handleChange}
                                    placeholder="환수 사유를 입력해주세요."
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-bottom no-rounded">
                    <button type="button" className="btn-modal" onClick={() => setModal(false)}>
                        취소
                    </button>
                    <button
                        type="button"
                        className="btn-modal"
                        onClick={() => {
                            if (!startDate) {
                                handleToast("회수 일자를 선택해주세요.");
                                return;
                            } else if (!returnReason) {
                                handleToast("환수 사유를 입력해주세요.");
                                return;
                            }

                            showModal("", <p style={{ color: "#e24f40" }}>{data.car.carNumber} 차량을 탁송신청 하시겠습니까?</p>, [
                                {
                                    text: "신청 안함",
                                    handler: () => {
                                        handleModalClose();
                                        requestRefund();
                                    },
                                },
                                {
                                    text: "탁송 신청",
                                    handler: () => {
                                        handleModalClose();
                                        setApplicationConsignmentModalStatus(true);
                                    },
                                },
                            ]);
                        }}
                    >
                        환수
                    </button>
                </div>
            </div>

            {applicationConsignmentModalStatus && (
                <ApplicationConsignment
                    setModal={setApplicationConsignmentModalStatus}
                    showModal={showModal}
                    handleModalClose={() => {
                        handleModalClose();
                    }}
                    dim={false}
                    contractId={contractId}
                    deliveryType={"REFUND"}
                    datas={{ startDate, returnReason }}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
