/* eslint-disable */
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const ContractManagerInfo = ({ setModal, setChangeGradeModalStatus }) => {
    return (
        <>
            <div className='modal-layer'></div>

            <div className='modal modal-mypage'>
                <div className="modal-title">
                    <span className="modal-tit-main">
                        <span>계약담당자 정보</span>
                    </span>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="content-list">
                        <div className="item">
                            <div className="item-head">
                                <span>등급</span>
                            </div>
                            <div className="item-content">
                                <span>Bronze</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>담당자명</span>
                            </div>
                            <div className="item-content">
                                <span>홍길동</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>연락처</span>
                            </div>
                            <div className="item-content">
                                <span>010-1234-5678</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>가입일시</span>
                            </div>
                            <div className="item-content">
                                <span>2021-08-11</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>총 계약건수</span>
                            </div>
                            <div className="item-content">
                                <span>52건</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-head">
                                <span>총 수수료</span>
                            </div>
                            <div className="item-content">
                                <span>643,500 원</span>
                            </div>
                        </div>
                    </div>

                    <div className="button-row">
                        <button type="button" className="btn btn-grade" onClick={() => setChangeGradeModalStatus(true)}>등급변경</button>
                        <button type="button" className="btn btn-status">출고 현황 보기</button>
                    </div>
                </div>
            </div>
        </>
    )
}