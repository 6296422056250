/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faMagnifyingGlass, faRotateRight, faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import { Table } from "./settlement_sub";
import { fetchData } from "api";
import { comma } from "util";

import UserIcon from "resources/icon/user.svg";

export const Settlement = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn); // Redux 스토어에서 로그인 상태 가져오기
    const userRole = useSelector((state) => state.login.userData?.roles);
    const userData = useSelector((state) => state.login.userData);

    const [roleType, setRoleType] = useState(null);
    const [isSupplierCorp, setIsSupplierCorp] = useState(null);
    const navigate = useNavigate();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            if (isLoggedIn === null) {
            } else if (!isLoggedIn) {
                navigate("/login");
            }

            if (userRole !== undefined && userRole?.includes("RENT_MASTER")) {
                setIsSupplierCorp(true);
            } else {
                setIsSupplierCorp(false);
            }

            if (userRole !== undefined) {
                if (userRole?.includes("AG_MASTER")) {
                    setRoleType("AG_MASTER");
                } else if (userRole?.includes("AG_MEMBER") || userRole?.includes("AG_DEALER")) {
                    setRoleType("AG_DEALER");
                } else if (userRole?.includes("RENT_MASTER")) {
                    setRoleType("RENT_MASTER");
                }
            }
        } else {
            hasMounted.current = true;

            if (isLoggedIn === false) {
                navigate("/login");
            }
        }
    }, [isLoggedIn, navigate, hasMounted]);

    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [commissionType, setCommissionType] = useState("");
    const [calculateStatus, setCalculateStatus] = useState("");

    const [currentDate, setCurrentDate] = useState(new Date());
    const formattedDate = `${currentDate.getFullYear()}.${(currentDate.getMonth() + 1).toString().padStart(2, "0")}`;

    const fetchTableData = async (params) => {
        setLoading(true);

        try {
            let isSupplierCorp;
            let url = "/api/share/rent_corp/calculate/v1";
            if (userRole !== undefined && userRole?.includes("RENT_MASTER")) {
                isSupplierCorp = true;
                url = "/api/share/rent_corp/calculate/v1";
            } else if (userRole?.includes("AG_MASTER")) {
                isSupplierCorp = false;
                url = "/api/share/ag_corp/v1/calculate";
            } else {
                isSupplierCorp = false;
                url = "/api/share/calculate/v1";
            }

            const response = await fetchData(url, params);
            setDatas(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        if (userRole === null || userRole === undefined) return;

        const params = {
            search_keyword: searchKeyword,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            commission_type: commissionType,
            calculate_status: calculateStatus,
        };

        fetchTableData(params);
    }, [userRole, currentDate, commissionType, calculateStatus]);

    const handleDateChange = (increment) => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + increment, 1));
    };

    const handleLeftIconClick = () => handleDateChange(-1);
    const handleRightIconClick = () => handleDateChange(1);

    const handleSelectChange = (value, setter) => {
        setter(value ? value.value : "");
    };

    const searchData = () => {
        const params = {
            search_keyword: searchKeyword,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            commission_type: commissionType,
            calculate_status: calculateStatus,
        };

        fetchTableData(params);
    };

    useEffect(() => {
        searchData();
    }, [commissionType, calculateStatus]);

    const initParams = () => {
        setSearchKeyword("");
        setCommissionType(null);
        setCalculateStatus(null);

        const params = {
            search_keyword: "",
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            commission_type: "",
            calculate_status: "",
        };

        fetchTableData(params);
    };

    const commissionTypeOptions = [
        { value: "", label: "분납여부 전체" },
        { value: "FULL", label: "일시납" },
        { value: "DIVIDE_2", label: "2회분납" },
        { value: "REFUND", label: "환수수수료" },
    ];

    const calculateStatusOptions = [
        { value: "", label: "상태표기 전체" },
        { value: "PENDING", label: "정산전" },
        { value: "CANCEL", label: "환수" },
        { value: "FINISH", label: "정산완료" },
    ];

    return (
        <>
            {loading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="mypage container">
                <div className="settlement-head">
                    <div className="settlement-wrapper container">
                        <div className="myinfo">
                            <div className="member">
                                <div className="member-icon">
                                    <img src={UserIcon} alt="" />
                                    <span className="member-name">{userData?.name}</span>
                                </div>
                                <div className="member-options">
                                    <div className="view-type">
                                        <button type="button" className="btn-view">
                                            <span>연간보기</span>
                                        </button>
                                        <div className="btn-account">
                                            <FontAwesomeIcon icon={faAngleLeft} onClick={handleLeftIconClick} />
                                            <span>{formattedDate}</span>
                                            <FontAwesomeIcon icon={faAngleRight} onClick={handleRightIconClick} />
                                        </div>
                                    </div>
                                    <div className="view-download">
                                        <button type="button" className="btn-download">
                                            <span>다운로드</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="summary">
                            <div className="summary-content">
                                <div className="item-list">
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-01"></span>
                                            <span className="item-head-txt">총 계약 건</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-blue">{datas?.contractTotalCount}건</span>
                                                <span className="color-blue">{comma(datas?.commissionTotal)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-02"></span>
                                            <span className="item-head-txt">일시납 계약 건</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-blue">{datas?.contractFullCount}건</span>
                                                <span className="color-blue">{comma(datas?.commissionFull)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-02"></span>
                                            <span className="item-head-txt">분납 만기 건</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-blue">{datas?.contractDivideCount}건</span>
                                                <span className="color-blue">{comma(datas?.commissionDivide)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-03"></span>
                                            <span className="item-head-txt">환수 진행 건</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-red">{datas?.contractRefundCount}건</span>
                                                <span className="color-red">{comma(datas?.commissionRefund)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-01"></span>
                                            <span className="item-head-txt">이달 지급 수수료 총액</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-blue">{datas?.contractThisMonthCount}건</span>
                                                <span className="color-blue">{comma(datas?.commissionThisMonth)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            <span className="item-head-bar bar-color-01"></span>
                                            <span className="item-head-txt">추후 지급 수수료</span>
                                        </div>
                                        {!loading && (
                                            <div className="item-body">
                                                <span className="color-blue">{datas?.contractLaterCount}건</span>
                                                <span className="color-blue">{comma(datas?.commissionLater)}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mypage-content">
                    <div className="mypage_table-container">
                        <div className="mypage_table-head type-head_sub" style={{ borderBottom: "none" }}>
                            <div className="mypage_table-tit">
                                <span>{formattedDate?.split(".")[1].replace(/^0+/, "")}월 정산 현황내역</span>
                            </div>
                            <div className="mypage_table-filter">
                                <div className="filter-option filter-search">
                                    <input
                                        type="text"
                                        placeholder="고객명을 검색해주세요."
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                    <button type="button" onClick={searchData}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                                <div className="filter-option filter-select">
                                    <div className="select-element">
                                        <Select
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="분납여부"
                                            options={commissionTypeOptions}
                                            onChange={(value) => handleSelectChange(value, setCommissionType)}
                                            value={commissionTypeOptions?.filter((option) => option.value === commissionType)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-option filter-select">
                                    <div className="select-element">
                                        <Select
                                            className="custom-select"
                                            classNamePrefix="custom-select"
                                            isSearchable={false}
                                            placeholder="상태표기"
                                            options={calculateStatusOptions}
                                            onChange={(value) => handleSelectChange(value, setCalculateStatus)}
                                            value={calculateStatusOptions.filter((option) => option.value === calculateStatus)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-option filter-refresh">
                                    <button type="button" className="btn-refresh" onClick={initParams}>
                                        초기화
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="mypage_table-wrapper">
                            <table>
                                <colgroup>
                                    {isSupplierCorp !== null && isSupplierCorp === false && <col width={"5%"} />}
                                    <col width={"5%"} />
                                    <col width={"5%"} />
                                    <col width={"7%"} />
                                    <col width={"7%"} />
                                    <col width={"8%"} />
                                    <col width={"5%"} />
                                    <col width={"8%"} />
                                    <col width={"8%"} />
                                    <col width={"8%"} />
                                    <col width={"10%"} />
                                    <col width={"6%"} />
                                    <col width={"10%"} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        {isSupplierCorp !== null && isSupplierCorp === false && (
                                            <th>
                                                <div className="tht">영업자</div>
                                            </th>
                                        )}
                                        <th>
                                            <div className="tht">고객명</div>
                                        </th>
                                        <th>
                                            <div className="tht">출고일자</div>
                                        </th>
                                        <th>
                                            <div className="tht">계약현황</div>
                                        </th>
                                        <th>
                                            <div className="tht">상품구분</div>
                                        </th>
                                        <th>
                                            <div className="tht">모델(상세모델)</div>
                                        </th>
                                        <th>
                                            <div className="tht">계약기간</div>
                                        </th>
                                        <th>
                                            <div className="tht">보증금</div>
                                        </th>
                                        <th>
                                            <div className="tht">렌트료</div>
                                        </th>
                                        <th>
                                            <div className="tht">출고 수수료</div>
                                        </th>
                                        <th>
                                            <div className="tht">총 지급액</div>
                                        </th>
                                        <th>
                                            <div className="tht">분납여부</div>
                                        </th>
                                        <th>
                                            <div className="tht">비고</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 일시납: fullContracts */}
                                    {(commissionType === "FULL" || commissionType === "") && (
                                        <Table
                                            type="fullContracts"
                                            data={datas?.fullContracts}
                                            isSupplierCorp={isSupplierCorp}
                                            roleType={roleType}
                                        />
                                    )}
                                    {/* laterContracts */}
                                    {(commissionType === "DIVIDE_2" || commissionType === "") && (
                                        <Table
                                            type="laterContracts"
                                            data={datas?.laterContracts}
                                            isSupplierCorp={isSupplierCorp}
                                            roleType={roleType}
                                        />
                                    )}
                                    {/* refundContracts */}
                                    {(commissionType === "REFUND" || commissionType === "") && (
                                        <Table
                                            type="refundContracts"
                                            data={datas?.refundContracts}
                                            isSupplierCorp={isSupplierCorp}
                                            roleType={roleType}
                                        />
                                    )}

                                    {(() => {
                                        const calculateTotal = (contracts) => {
                                            let totalCommission, totalVAT;

                                            if (roleType === "AG_MASTER") {
                                                totalCommission = contracts?.reduce((acc, row) => acc + row.agCorperationCommission, 0);
                                                totalVAT = contracts?.reduce((acc, row) => acc + row.agCorperationCommission * 0.1, 0);
                                            } else if (roleType === "AG_DEALER") {
                                                totalCommission = contracts?.reduce((acc, row) => acc + row.managerCommission, 0);
                                                totalVAT = contracts?.reduce((acc, row) => acc + row.managerCommission * 0.1, 0);
                                            } else if (roleType === "RENT_MASTER") {
                                                totalCommission = contracts?.reduce((acc, row) => acc + row.rentCorpCommissio, 0);
                                                totalVAT = contracts?.reduce((acc, row) => acc + row.rentCorpCommissio * 0.1, 0);
                                            }

                                            // const totalCommission = contracts?.reduce((acc, row) => acc + row.agCorperationCommission, 0);
                                            // const totalVAT = contracts?.reduce((acc, row) => acc + row.agCorperationCommission * 0.1, 0);
                                            const totalAmount = totalCommission + totalVAT;
                                            return { totalCommission, totalVAT, totalAmount };
                                        };

                                        const {
                                            totalCommission: fullCommission,
                                            totalVAT: fullVAT,
                                            totalAmount: fullTotal,
                                        } = calculateTotal(datas?.fullContracts);
                                        const {
                                            totalCommission: laterCommission,
                                            totalVAT: laterVAT,
                                            totalAmount: laterTotal,
                                        } = calculateTotal(datas?.laterContracts);
                                        const {
                                            totalCommission: refundCommission,
                                            totalVAT: refundVAT,
                                            totalAmount: refundTotal,
                                        } = calculateTotal(datas?.refundContracts);

                                        return (
                                            <tr className="tr-group-lg bg-color-04">
                                                <td colSpan={isSupplierCorp !== null && isSupplierCorp === false ? 8 : 7}>
                                                    <div className="tdd tac">
                                                        <span className="td-desc">
                                                            <b>총 합계</b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        {/* <span className="td-desc">{comma(fullVAT + laterVAT + refundVAT)}원</span> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc">
                                                            {!isNaN(fullCommission + laterCommission + refundCommission) &&
                                                            typeof (fullCommission + laterCommission + refundCommission) !== "undefined"
                                                                ? `${comma(fullCommission + laterCommission + refundCommission)}원`
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc td-color-03">
                                                            <b>
                                                                {!isNaN(fullTotal + laterTotal + refundTotal) &&
                                                                typeof (fullTotal + laterTotal + refundTotal) !== "undefined"
                                                                    ? `${comma(fullTotal + laterTotal + refundTotal)}원 (VAT 포함)`
                                                                    : ""}
                                                            </b>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tdd">
                                                        <span className="td-desc"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
