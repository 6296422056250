import React, { useEffect } from "react";
import { comma } from "util";

export const Table = ({ type, data, isSupplierCorp, roleType }) => {
    let totalCommission, totalVAT;

    if (roleType === "AG_MASTER") {
        totalCommission = data?.reduce((acc, row) => acc + row.agCorperationCommission, 0);
        totalVAT = data?.reduce((acc, row) => acc + row.agCorperationCommission * 0.1, 0);
    } else if (roleType === "AG_DEALER") {
        totalCommission = data?.reduce((acc, row) => acc + row.managerCommission, 0);
        totalVAT = data?.reduce((acc, row) => acc + row.managerCommission * 0.1, 0);
    } else if (roleType === "RENT_MASTER") {
        totalCommission = data?.reduce((acc, row) => acc + row.rentCorpCommissio, 0);
        totalVAT = data?.reduce((acc, row) => acc + row.rentCorpCommissio * 0.1, 0);
    }

    // const totalCommission = data?.reduce((acc, row) => acc + row.agCorperationCommission, 0);
    // const totalVAT = data?.reduce((acc, row) => acc + row.agCorperationCommission * 0.1, 0);
    const totalAmount = totalCommission + totalVAT;
    return (
        <>
            {data?.length === 0 ? (
                <tr>
                    <td colSpan={isSupplierCorp !== null && isSupplierCorp === false ? 13 : 12} style={{ height: "120px" }}>
                        <div className="tdd" style={{ textAlign: "center" }}>
                            <span className="td-desc">
                                <b>조회된 데이터가 없습니다.</b>
                            </span>
                        </div>
                    </td>
                </tr>
            ) : (
                data?.map((row, idx) => {
                    return (
                        <tr key={idx} className={type}>
                            {isSupplierCorp !== null && isSupplierCorp === false && (
                                <td>
                                    <div className="tdd">
                                        <span className="td-desc">
                                            <b>{row.contract?.contractManager.name}</b>
                                        </span>
                                    </div>
                                </td>
                            )}
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">
                                        <b>{row.contract?.customerName}</b>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">
                                        {row.contract?.releasedAt
                                            ? row.contract?.releasedAt?.split("T")[0].slice(2).replaceAll("-", "/")
                                            : "-"}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    {row.contract?.contractStatus === "REQUEST" && <span className="td-desc td-color-02">접수중</span>}
                                    {row.contract?.contractStatus === "ON_PROCESS" && <span className="td-desc td-color-02">진행중</span>}
                                    {row.contract?.contractStatus === "FINISH" && <span className="td-desc td-color-01">계약완료</span>}
                                    {row.contract?.contractStatus === "DELIVERY_FINISH" && (
                                        <span className="td-desc td-color-01">탁송신청완료</span>
                                    )}
                                    {row.contract?.contractStatus === "RELEASE_READY" && (
                                        <span className="td-desc td-color-02">출고준비중</span>
                                    )}
                                    {row.contract?.contractStatus === "RELEASE_FINISH" && (
                                        <span className="td-desc td-color-01">출고완료</span>
                                    )}
                                    {row.contract?.contractStatus === "REJECT" && <span className="td-desc td-color-03">진행 불가</span>}
                                    {row.contract?.contractStatus === "RETURN" && <span className="td-desc td-color-03">반납</span>}
                                    {row.contract?.contractStatus === "CANCEL" && <span className="td-desc td-color-03">중도 해지</span>}
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">
                                        {row.contract?.rentType === "LONG_RENT"
                                            ? "장기렌트"
                                            : row.contract?.rentType === "SUBSCRIPTION"
                                            ? "구독"
                                            : row.contract?.rentType === "FIRST_RELEASE"
                                            ? "선출고"
                                            : "알 수 없음"}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">{row.contract?.car.modelName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">{row.contract?.contractRangeMonth}개월</span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">{comma(row.contract?.depositPrice)}원</span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">{comma(row.contract?.rentPrice)}원</span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">
                                        {roleType === "AG_MASTER"
                                            ? `${comma(row.agCorperationCommission)}원`
                                            : roleType === "AG_DEALER"
                                            ? `${comma(row.managerCommission)}원`
                                            : roleType === "RENT_MASTER"
                                            ? `${comma(row.rentCorpCommissio)}원`
                                            : null}
                                    </span>
                                </div>
                            </td>
                            {/* <td>
                                <div className="tdd">
                                    <span className="td-desc">{comma(row.agCorperationCommission * 0.1)}원</span>
                                </div>
                            </td> */}
                            <td>
                                <div className="tdd">
                                    <span className="td-desc td-color-03">
                                        {roleType === "AG_MASTER" ? (
                                            <b>{comma(row.agCorperationCommission + row.agCorperationCommission * 0.1)}원</b>
                                        ) : roleType === "AG_DEALER" ? (
                                            <b>{comma(row.managerCommission + row.managerCommission * 0.1)}원</b>
                                        ) : roleType === "RENT_MASTER" ? (
                                            <b>{comma(row.rentCorpCommissio + row.rentCorpCommissio * 0.1)}원</b>
                                        ) : null}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">
                                    <span className="td-desc">
                                        <b>
                                            {row.contract?.depositType === "FULL" && "일시납"}
                                            {row.contract?.depositType === "DIVIDE_2" && "2회분납"}
                                        </b>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="tdd">{/* <span className="td-desc td-color-01">{row.memo ? row.memo : "-"}</span> */}</div>
                            </td>
                        </tr>
                    );
                })
            )}
            <tr
                className={
                    type === "fullContracts"
                        ? "tr-group bg-color-01"
                        : type === "laterContracts"
                        ? "tr-group bg-color-02"
                        : type === "refundContracts"
                        ? "tr-group bg-color-03"
                        : "tr-group"
                }
            >
                <td colSpan={isSupplierCorp !== null && isSupplierCorp === false ? 8 : 7}>
                    <div className="tdd tac">
                        <span className="td-desc">{type === "fullContracts" && <b>일시납</b>}</span>
                        <span className="td-desc">{type === "laterContracts" && <b>2회분납 만기</b>}</span>
                        <span className="td-desc">{type === "refundContracts" && <b>환수</b>}</span>
                    </div>
                </td>
                <td>
                    <div className="tdd">{/* <span className="td-desc">{comma(totalVAT)}원</span> */}</div>
                </td>
                <td>
                    <div className="tdd">
                        <span className="td-desc">{totalCommission ? `${comma(totalCommission)}원` : "-"}</span>
                    </div>
                </td>
                <td>
                    <div className="tdd">
                        <span className="td-desc td-color-03">
                            <b>{totalAmount ? `${comma(totalAmount)}원 (VAT 포함)` : "-"}</b>
                        </span>
                    </div>
                </td>
                <td>
                    <div className="tdd">
                        <span className="td-desc"></span>
                    </div>
                </td>
                <td>
                    <div className="tdd">
                        <span className="td-desc">
                            {/* <span className="td-desc">
                                <b>3개월 미만 환수 50%</b>
                            </span> */}
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};
