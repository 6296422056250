/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData } from "api";

export const ContractPrecautions = ({ initData, onContractPrecautionsChange }) => {
    const [formData, setFormData] = useState({
        contractNote: "", // 계약 유의사항
    });

    const { contractNote } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value !== undefined ? target.value : "";
        const name = target.name ? target.name : e.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // 책임한도/면책금 기본값 API
    const fetchDefaultValues = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            setFormData((prevState) => ({
                ...prevState,
                contractNote: response.data.data.contractNote,
            }));
        } catch (error) {
            console.error("데이터 불러오는 중 에러 발생:", error);
        }
    };

    // 스테이트 리프팅
    useEffect(() => {
        onContractPrecautionsChange(formData);
    }, [formData, onContractPrecautionsChange]);

    useEffect(() => {
        if (initData !== undefined) {
            setFormData({
                contractNote: initData?.rentInfo?.contractNote,
            });
        }
    }, [initData]);

    return (
        <section id="section-08" className="section width-100">
            <div className="section-tit">
                <h2>계약 유의사항</h2>

                <button type="button" className="btn btn-primary btn-large" onClick={fetchDefaultValues}>
                    기본값 불러오기
                </button>
            </div>

            <div className="section-content">
                <div className="form">
                    <div className="input-form width-100">
                        <div className="form-field">
                            <div className="textarea-element">
                                <textarea
                                    placeholder="계약 유의사항 입력"
                                    name="contractNote"
                                    value={contractNote || ""}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
