/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { findYoungestDriver, getAgeCategory, comma } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";

export const FormStep6 = ({ data, takeoverInfo }) => {
    // 가장 적은 나이를 가진 데이터
    const youngestDriver = findYoungestDriver(data?.drivers);
    const [priceInfo, setPriceInfo] = useState(0);
    const [deliverInfo, setDeliverInfo] = useState();
    const [deliverPrice, setDeliverPrice] = useState(0);

    useEffect(() => {
        if (!data) return;

        const { depositPrice, depositType, rentPrice, car } = data;
        const age21RentPrice = getAgeCategory(youngestDriver.idNumber) === "만 21세 이상" ? car.rentInfo.age21RentPrice : null;
        const age23RentPrice = getAgeCategory(youngestDriver.idNumber) === "만 23세 이상" ? car.rentInfo.age23RentPrice : null;
        const deliverySupportPrice = car.rentInfo.deliverySupportPrice ?? 0;
        const contractPrice = 300000;

        console.log(depositPrice, rentPrice, age21RentPrice, age23RentPrice, contractPrice);

        const deliveryPrice = deliverPrice;
        const deliveryTotalPrice = Math.max(0, deliveryPrice - deliverySupportPrice);

        let depositTotalPrice;
        if (depositType === "DIVIDE_2") {
            depositTotalPrice = depositPrice / 2 + rentPrice + age21RentPrice + age23RentPrice - contractPrice;
        } else {
            depositTotalPrice = depositPrice + rentPrice + age21RentPrice + age23RentPrice - contractPrice;
        }

        const depositTotalPayment = depositTotalPrice + deliveryTotalPrice;
        console.log(depositTotalPrice, deliveryTotalPrice);

        setPriceInfo({
            depositPrice,
            devidePrice: depositType === "DIVIDE_2" ? depositPrice / 2 : null,
            rentPrice,
            age21RentPrice,
            age23RentPrice,
            contractPrice: contractPrice,
            depositTotalPrice,
            deliveryPrice,
            deliverySupportPrice,
            deliveryTotalPrice,
            depositTotalPayment,
        });
    }, [data, deliverPrice]);

    const fetchDeliveryPriceInfo = async () => {
        try {
            let requestData = {
                car_id: data?.carId,
                src_bcode: data?.car.carLocationBcode,
                dest_bcode: takeoverInfo.bcode,
            };

            const response = await fetchData(`/api/share/delivery/v1/price`, requestData);

            setDeliverInfo(response.data.data);
            setDeliverPrice(response.data.data.price);

            const maxDeliverySupportPrice = Math.min(response.data.data.price, data?.car.rentInfo.deliverySupportPrice ?? 0);

            setPriceInfo((prev) => ({
                ...prev,
                deliveryPrice: response.data.data.price,
                deliverySupportPrice: maxDeliverySupportPrice, // 조정된 deliverySupportPrice 사용
                deliveryTotalPrice: response.data.data.price - maxDeliverySupportPrice, // deliveryTotalPrice 업데이트
            }));
        } catch (error) {}
    };

    useEffect(() => {
        fetchDeliveryPriceInfo();
    }, []);

    return (
        <>
            <div className="tab" id="tab-06">
                <div className="item-wrapper border-separation">
                    <div className="item-head">
                        <span>연체 및 해지</span>
                    </div>
                    <div className="item-list grid-col">
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>대여료 연체</b>
                            </span>
                            <span className="item-pre color-grey">
                                임차인은 대여약관에 동의하며, 계각기간 중 이라도 대여료를 <em>3일</em> 연체시 <em>시동제어</em>,{" "}
                                <em>10일</em> 연체시 계약은 <em>자동 해지</em>되며, 임차인은 차량을 회수 할 수 있으며, 임차인은 이에 대해 민
                                형사상의 책임을 묻지 아니한다
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>중도해지 수수료(위약금)</b>
                            </span>
                            <span className="item-pre color-grey">
                                잔여기간 대여료의 총 합 x 해지 수수료율
                                {"\n"}해지수수료율
                                {"\n"}차량인도일로부터 1년 미만 30%
                                {"\n"}차량인도일로부터 1년 이상 20%
                                {"\n"}
                                {"\n"}
                                지급명령 신청서 신청일 부터 지급명령정본이 송달된 날까지 연5% 그다음날로부터 다 갚는 날까지 연 12%
                            </span>
                        </div>
                        <div className="item width-100">
                            <span className="item-tit">
                                <b>지연손해금</b>
                            </span>
                            <span className="item-pre color-grey">5% / 12%</span>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper border-separation">
                    <div className="item-head">
                        <span>입금내역 및 총금액</span>
                    </div>
                    <div className="receipt-wrapper">
                        <div className="receipt-list">
                            <div className="item">
                                <span className="item-tit">보증금</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">{comma(priceInfo?.depositPrice)}</span>
                            </div>

                            {priceInfo?.devidePrice !== null && (
                                <div className="item">
                                    <span className="item-tit">- 2회분납</span>
                                    <span className="item-sub">1회차</span>
                                    <span className="item-desc">{comma(priceInfo?.devidePrice)}</span>
                                </div>
                            )}
                            <div className="item">
                                <span className="item-tit">월 대여료</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">{comma(priceInfo?.rentPrice)}</span>
                            </div>

                            {priceInfo?.age21RentPrice !== null ? (
                                <div className="item">
                                    <span className="item-tit">- 만 21세이상 추가금</span>
                                    <span className="item-sub"></span>
                                    <span className="item-desc">+{comma(priceInfo?.age21RentPrice)}</span>
                                </div>
                            ) : null}

                            {priceInfo?.age23RentPrice !== null ? (
                                <div className="item">
                                    <span className="item-tit">- 만 23세이상 추가금</span>
                                    <span className="item-sub"></span>
                                    <span className="item-desc">+{comma(priceInfo?.age23RentPrice)}</span>
                                </div>
                            ) : null}
                        </div>
                        <div className="receipt-list">
                            <div className="item">
                                <span className="item-tit">- 계약금</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">-300,000</span>
                            </div>
                            <div className="item">
                                <span className="item-tit">합계금액</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">
                                    <b>{comma(priceInfo?.depositTotalPrice)}</b>
                                </span>
                            </div>
                        </div>
                        <div className="receipt-list">
                            <div className="item">
                                <span className="item-tit">
                                    [{deliverInfo?.srcAddress1} {deliverInfo?.srcAddress2}-{deliverInfo?.destAddress1}{" "}
                                    {deliverInfo?.destAddress2}] 탁송비
                                </span>
                                <span className="item-sub"></span>
                                <span className="item-desc">{comma(priceInfo?.deliveryPrice)}</span>
                            </div>
                            {priceInfo?.deliverySupportPrice !== 0 && (
                                <div className="item">
                                    <span className="item-tit">- 탁송비 지원금</span>
                                    <span className="item-sub"></span>
                                    <span className="item-desc">-{comma(priceInfo?.deliverySupportPrice)}</span>
                                </div>
                            )}
                            <div className="item">
                                <span className="item-tit">탁송비 총금액</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">{comma(priceInfo?.deliveryTotalPrice)}</span>
                            </div>
                        </div>
                        <div className="receipt-summary">
                            <div className="item">
                                <span className="item-tit">총 결제금액</span>
                                <span className="item-sub"></span>
                                <span className="item-desc">
                                    <b>{comma(priceInfo?.depositTotalPayment)}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
