/* eslint-disable */
import React, { useRef, useCallback } from "react";
import DaumPostcode from "react-daum-postcode";
import styles from "./DaumPost.module.scss";

export const DaumPost = ({ onComplete, onClose, dim }) => {
    const dimRef = useRef();

    const handleDimClick = useCallback(
        (event) => {
            console.log("1");
            if (dimRef.current === event.target) {
                console.log("2");
                onClose();
            }
        },
        [onClose]
    );

    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }

        // 주소 검색 완료 시 onComplete 콜백 호출
        onComplete({ fullAddress, zonecode: data.zonecode, daumData: data });
        onClose(); // 주소 검색 창 닫기
    };
    return (
        <div className={styles.modal}>
            <div
                ref={dimRef}
                className={dim === false ? `${styles.dim} ${styles.dim_transparent}` : `${styles.dim}`}
                onClick={handleDimClick}
            ></div>
            <div className={styles.container}>
                <button type="button" onClick={onClose} className={styles.btn_close}>
                    닫기
                </button>
                <DaumPostcode style={{ width: "100%", height: "100%" }} onComplete={handlePostCode} />
            </div>
        </div>
    );
};
