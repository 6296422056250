// store.js

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { fetchData } from "api";
import { loginSuccess, logout } from "./loginSlice";

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production", // 개발 환경에서만 활성화
});

export const fetchUserData = async (accessToken) => {
    try {
        const response = await fetchData("/api/share/member/v1/me");

        if (response.status === 200) {
            const userData = response.data.data;

            // 로그인 정보 업데이트
            store.dispatch(loginSuccess({ accessToken, userData }));
        } else {
            console.error("API 호출에 실패했습니다.");
            store.dispatch(logout());
        }
    } catch (error) {
        console.error("API 호출 중 에러 발생:", error);
        store.dispatch(logout());
    }
};

// 초기화 시 로그인 상태 확인
const initializeApp = () => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
        // 저장된 토큰이 있는 경우
        fetchUserData(accessToken);
    } else {
        // 저장된 토큰이 없는 경우 로그아웃 설정
        store.dispatch(logout());
    }
};

// 앱이 시작될 때 로그인 상태 확인
initializeApp();

export default store;
