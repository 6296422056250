/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Toast } from "components";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { ContractStep1, ContractStep2, ContractStep3, ContractStep4 } from ".";
import { handleNumericInput, getProductStatusList, getYearList, getMonthList, getDayList, getColorList, getTransmissionList } from "util";

export const ContractGuide = ({ carId, carData, setModalContractStatus }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [verifyDriverLincese, setVerifyDriverLincese] = useState(false);
    const [verifyDriverLinceseSub, setVerifyDriverLinceseSub] = useState(false);

    const initialState = {
        isLoading: false,
        modalOpen: false,
        modalContent: {
            title: "",
            content: null,
            buttons: [],
        },
    };
    const [state, setState] = useState(initialState);

    const [driverInfo, setDriverInfo] = useState({
        name: "", // 성명
        idNumber1: "", // 주민등록번호 앞번호
        idNumber2: "", // 주민등록번호 뒷번호
        driverLicenseType: "", // 면허종류
        driverLicenseNumber: "", // 면허번호
        expLt1Yn: "",
    });
    const [customerInfo, setCustomerInfo] = useState({
        rentType: "LONG_RENT",
        customerType: "PERSONAL",
        depositType: "",
        phone: "",
        address: "",
        contractRangeMonth: undefined,
        corpName: "",
        corpAddress: "",
        bizLicenseNumber: "",
        uploads: [],
        additionalDriverStatus: false,
    });
    const [additionalDrivers, setAdditionalDrivers] = useState([
        {
            name: "",
            idNumber1: "",
            idNumber2: "",
            driverLicenseType: "",
            driverLicenseNumber: "",
            expLt1Yn: "",
        },
    ]);
    const [submittedFiles, setSubmittedFiles] = useState([]);

    const nextStep = () => {
        if (currentStep === 1) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === 2) {
            if (!verifyDriverLincese) {
                handleToast("면허검증을 먼저 진행해주세요.");
                return;
            }
            if (driverInfo.idNumber1.length !== 6 || driverInfo.idNumber2.length !== 7) {
                handleToast("잘못된 주민등록번호 입력입니다.");
                return;
            }
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === 3) {
            if (!customerInfo.depositType) {
                handleToast("보증금 분납 여부를 선택해주세요.");
                return;
            }

            if (!customerInfo.phone) {
                handleToast("전화번호를 입력해주세요.");
                return;
            } else if (!customerInfo.address) {
                handleToast("주소를 입력해주세요.");
                return;
            } else if (!customerInfo.contractRangeMonth) {
                handleToast("개월수를 선택해주세요.");
                return;
            } else if (!customerInfo.depositPrice) {
                handleToast("보증금을 입력해주세요.");
                return;
            } else if (!customerInfo.rentType) {
                handleToast("렌탈료를 입력해주세요.");
                return;
            }

            if (customerInfo.customerType === "BIZ_CORP" || customerInfo.customerType === "BIZ_PERSONAL") {
                if (!customerInfo.corpName) {
                    handleToast("회사명을 입력해주세요.");
                    return;
                } else if (!customerInfo.corpAddress) {
                    handleToast("회사 주소를 입력해주세요.");
                    return;
                } else if (!customerInfo.bizLicenseNumber) {
                    handleToast("사업자 등록번호를 입력해주세요.");
                    return;
                }
            }

            if (customerInfo.additionalDriverStatus === true) {
                if (verifyDriverLinceseSub === false) {
                    handleToast("추가 운전자 면허검증을 완료해주세요.");
                    return;
                }
            }

            setCurrentStep(currentStep + 1);
        }
        if (currentStep === 4) {
            const isDriverLicenseEmpty = submittedFiles?.some((item) => item.type === "DRIVER_LICENSE" && item.files.length === 0);

            if (isDriverLicenseEmpty) {
                handleToast("운전면허증을 첨부해주세요.");
                return;
            }
            handleSubmit();
        }
    };

    const previousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSubmit = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            let uploads = [];
            for (const item of submittedFiles) {
                for (const file of item.files) {
                    const formData = new FormData();
                    formData.append("file", file);

                    const response = await createFormData("/api/share/upload/v1/image", formData);
                    const uploadId = response.data.data.id; // 서버에서 반환된 uploadId

                    uploads.push({
                        uploadId: uploadId,
                        contractUploadType: item.type,
                    });
                }
            }

            const requestData = {
                carId: Number(carId),
                rentType: customerInfo.rentType,
                driverLicense: {
                    driverLicenseType: driverInfo.driverLicenseType,
                    name: driverInfo.name,
                    idNumber: driverInfo.idNumber1 + driverInfo.idNumber2,
                    driverLicenseNumber: driverInfo.driverLicenseNumber,
                    expLt1Yn: driverInfo.expLt1Yn,
                },
                customerType: customerInfo.customerType,
                uploads: uploads,
            };

            if (customerInfo.additionalDriverStatus === true) {
                requestData.additionalDrivers = [
                    {
                        name: additionalDrivers.name,
                        idNumber: additionalDrivers.idNumber1 + additionalDrivers.idNumber2,
                        driverLicenseType: additionalDrivers.driverLicenseType,
                        driverLicenseNumber: additionalDrivers.driverLicenseNumber,
                        expLt1Yn: additionalDrivers.expLt1Yn,
                    },
                ];
            }

            if (customerInfo.customerType === "PERSONAL") {
                requestData.personalCustomer = {
                    depositType: customerInfo.depositType,
                    phone: customerInfo.phone,
                    address: customerInfo.address,
                    contractRangeMonth: Number(customerInfo.contractRangeMonth),
                };
            } else if (customerInfo.customerType === "BIZ_PERSONAL") {
                requestData.personalBiz = {
                    depositType: customerInfo.depositType,
                    phone: customerInfo.phone,
                    address: customerInfo.address,
                    corpAddress: customerInfo.corpAddress,
                    bizLicenseNumber: customerInfo.bizLicenseNumber,
                    contractRangeMonth: Number(customerInfo.contractRangeMonth),
                };
            } else if (customerInfo.customerType === "BIZ_CORP") {
                requestData.corpBiz = {
                    depositType: customerInfo.depositType,
                    phone: customerInfo.phone,
                    address: customerInfo.address,
                    corpName: customerInfo.corpName,
                    corpAddress: customerInfo.corpAddress,
                    bizLicenseNumber: customerInfo.bizLicenseNumber,
                    contractRangeMonth: Number(customerInfo.contractRangeMonth),
                };
            }

            // 모든 이미지 업로드 성공 후 requestData 전송
            const response = await createData("/api/share/contract/v1/request", requestData);

            showModal("계약이 요청되었습니다.", <p></p>, [
                {
                    text: "확인",
                    handler: () => {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    },
                },
            ]);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("데이터 업로드 중 오류 발생:", error);
        } finally {
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const checkDriverLicenseExpLt1Yn = (driverLicenseNumber) => {
        const yearDigits = driverLicenseNumber.substring(2, 4);
        const currentYear = new Date().getFullYear();
        const licenseYear = parseInt(yearDigits, 10) + 2000;
        const isCurrentOrLastYear = licenseYear >= currentYear - 1;

        return isCurrentOrLastYear;
    };

    // 운전자 면허검증
    const fetchDriverLicense = async (driverType, requestData) => {
        handleModalClose();
        setState((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const response = await createData("/api/share/driver_license/v1/validation", requestData);
            handleToast(response.data.message, 1000);

            if (driverType === "MAIN") {
                setVerifyDriverLincese(true);
            } else if (driverType === "SUB") {
                setVerifyDriverLinceseSub(true);
            }
        } catch (error) {
            handleToast("면허번호가 일치하지 않습니다. 다시 시도해주세요.", Infinity);
            console.error("데이터 업로드 중 오류 발생:", error);

            if (driverType === "MAIN") {
                setVerifyDriverLincese(false);
            } else if (driverType === "SUB") {
                setVerifyDriverLinceseSub(false);
            }
        } finally {
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const checkCarContractAgeTypes = (idNumber1, idNumber2) => {
        // 필요한 정보 추출
        const contractAgeTypes = carData?.rentInfo.contractAgeTypes;

        // 주민등록번호 기반으로 생년 계산
        const century = idNumber2.startsWith("1") || idNumber2.startsWith("2") ? 1900 : 2000;
        const year = century + parseInt(idNumber1.substring(0, 2), 10);
        const month = parseInt(idNumber1.substring(2, 4), 10) - 1; // 월은 0부터 시작하는 JavaScript의 Date 객체 특성을 반영
        const day = parseInt(idNumber1.substring(4, 6), 10);
        const birthDate = new Date(year, month, day);

        // 현재 날짜
        const today = new Date();
        const currentYear = today.getFullYear();

        // 만 나이 계산
        let age = currentYear - birthDate.getFullYear();
        // 오늘 날짜가 생일 이전이라면, 나이에서 1을 뺌
        if (today < new Date(birthDate.setFullYear(currentYear))) {
            age--;
        }

        // 계약 가능 연령 검사
        let minContractAge = 26; // 기본 계약 가능 연령
        if (contractAgeTypes.includes("AGE_21")) {
            minContractAge = 21;
        } else if (contractAgeTypes.includes("AGE_23")) {
            minContractAge = 23;
        }

        // 나이 검사
        if (age < minContractAge) {
            handleToast(`계약 가능한 최소 연령은 만 ${minContractAge}세입니다.`);
            return false;
        }
        return true;
    };

    const handleCheckDriverLincese = async (driverType) => {
        let requestData;

        if (driverType === "MAIN") {
            if (checkCarContractAgeTypes(driverInfo.idNumber1, driverInfo.idNumber2) === false) {
                return;
            }

            if (driverInfo.driverLicenseNumber === "" || driverInfo.name === "" || !driverInfo.idNumber1 || !driverInfo.idNumber2) {
                handleToast("면허 정보를 입력해주세요.");
                return;
            }

            if (!["1", "2", "3", "4"].includes(driverInfo?.idNumber2.charAt(0))) {
                handleToast("유효하지 않은 주민등록번호입니다.");
                return;
            }

            requestData = {
                licenseNumber: driverInfo.driverLicenseNumber,
                name: driverInfo.name,
                driverLicenseType: driverInfo.driverLicenseType,
                expLt1Yn: driverInfo.expLt1Yn,
            };

            const checkExpLt1Yn = checkDriverLicenseExpLt1Yn(driverInfo.driverLicenseNumber);

            // 운전면허 발급일이 작년 이내인 경우 && 운전면허 취득 1년 미만 체크박스를 체크하지 않은 경우
            if (checkExpLt1Yn === true && driverInfo.expLt1Yn === "N") {
                showModal("", <p>고객님의 운전면허 취득일이 1년 미만인지 최종적으로 확인하셨나요?</p>, [
                    {
                        text: "네, 확인했습니다",
                        handler: () => {
                            fetchDriverLicense(driverType, requestData);
                        },
                    },
                    {
                        text: "아니오",
                        handler: () => {
                            handleModalClose();
                        },
                    },
                ]);
            } else {
                fetchDriverLicense(driverType, requestData);
            }
        } else if (driverType === "SUB") {
            if (checkCarContractAgeTypes(additionalDrivers.idNumber1, additionalDrivers.idNumber2) === false) {
                return;
            }

            if (
                additionalDrivers.driverLicenseNumber === "" ||
                additionalDrivers.name === "" ||
                !additionalDrivers.idNumber1 ||
                !additionalDrivers.idNumber2
            ) {
                handleToast("면허 정보를 입력해주세요.");
                return;
            }

            if (!["1", "2", "3", "4"].includes(additionalDrivers?.idNumber2.charAt(0))) {
                handleToast("유효하지 않은 주민등록번호입니다.");
                return;
            }

            requestData = {
                licenseNumber: additionalDrivers.driverLicenseNumber,
                name: additionalDrivers.name,
                driverLicenseType: additionalDrivers.driverLicenseType,
                expLt1Yn: additionalDrivers.expLt1Yn,
            };

            const checkExpLt1Yn = checkDriverLicenseExpLt1Yn(additionalDrivers.driverLicenseNumber);

            // 운전면허 발급일이 작년 이내인 경우 && 운전면허 취득 1년 미만 체크박스를 체크하지 않은 경우
            if (checkExpLt1Yn === true && additionalDrivers.expLt1Yn === "N") {
                showModal("", <p>고객님의 운전면허 취득일이 1년 미만인지 최종적으로 확인하셨나요?</p>, [
                    {
                        text: "네, 확인했습니다",
                        handler: () => {
                            fetchDriverLicense(driverType, requestData);
                        },
                    },
                    {
                        text: "아니오",
                        handler: () => {
                            handleModalClose();
                        },
                    },
                ]);
            } else {
                fetchDriverLicense(driverType, requestData);
            }
        }

        return;
    };

    // 모달
    const handleModalClose = () => {
        setState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    const showModal = (title, content, buttons) => {
        setState((prevState) => ({ ...prevState, modalContent: { title, content, buttons }, modalOpen: true }));
    };

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            <div className="modal modal-type-2">
                <div className="modal-title">
                    <span className="modal-tit-main">
                        {currentStep === 1 && "안내사항"}
                        {currentStep === 2 && "운전면허 진위여부"}
                        {currentStep === 3 && "고객정보입력"}
                        {currentStep === 4 && "추가 필요서류"}
                    </span>
                    <button type="button" onClick={() => setModalContractStatus(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="modal-content">
                    <div className="step-container">
                        {currentStep === 1 && <ContractStep1 />}
                        {currentStep === 2 && (
                            <ContractStep2
                                driverInfo={driverInfo}
                                setDriverInfo={setDriverInfo}
                                handleCheckDriverLincese={handleCheckDriverLincese}
                                verifyDriverLincese={verifyDriverLincese}
                            />
                        )}
                        {currentStep === 3 && (
                            <ContractStep3
                                carData={carData}
                                driverInfo={driverInfo}
                                customerInfo={customerInfo}
                                setCustomerInfo={setCustomerInfo}
                                additionalDrivers={additionalDrivers}
                                setAdditionalDrivers={setAdditionalDrivers}
                                handleCheckDriverLincese={handleCheckDriverLincese}
                                verifyDriverLinceseSub={verifyDriverLinceseSub}
                            />
                        )}
                        {currentStep === 4 && <ContractStep4 setSubmittedFiles={setSubmittedFiles} />}
                    </div>

                    <div className="step-pagination">
                        <span className={currentStep === 1 ? "active" : ""}></span>
                        <span className={currentStep === 2 ? "active" : ""}></span>
                        <span className={currentStep === 3 ? "active" : ""}></span>
                        <span className={currentStep === 4 ? "active" : ""}></span>
                    </div>
                </div>

                <div className="modal-bottom no-rounded">
                    {currentStep !== 1 && (
                        <button type="button" className="btn-modal" onClick={previousStep}>
                            이전
                        </button>
                    )}
                    <button type="button" className="btn-modal" onClick={() => nextStep()}>
                        {currentStep === 4 ? "완료" : "다음"}
                    </button>
                </div>
            </div>

            {state.modalOpen && (
                <Modal
                    title={state.modalContent.title}
                    content={state.modalContent.content}
                    buttons={state.modalContent.buttons}
                    onClose={handleModalClose}
                    layer={true}
                />
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
