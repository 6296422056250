// /* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchData } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import UserIcon from "resources/icon/user.svg";
import { ManagementContract, ProfileSupplier } from "../modal";

export const Summary = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // 데이터 로딩 상태
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const userData = useSelector((state) => state.login.userData);
    const [summaryData, setSummaryData] = useState([]);

    const fetchMySummary = async () => {
        setLoading(true);

        try {
            if (vehicleStatus === undefined || rentalStatus === undefined) return;

            const requestData = {
                year: vehicleStatus.year,
                month: vehicleStatus.month,
            };

            const response = await fetchData("/api/share/rent_corp/summary/v1/my_page", requestData);

            setSummaryData(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("API 호출 중 에러 발생:", error);
            setLoading(false);
        }
    };

    const [vehicleStatus, setVehicleStatus] = useState();
    const [rentalStatus, setRentalStatus] = useState();

    useEffect(() => {
        updateCurrentDate();
    }, []);

    const updateCurrentDate = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        setVehicleStatus({ year: currentYear, month: currentMonth });
        setRentalStatus({ year: currentYear, month: currentMonth });
    };

    const handleUpdateDates = (type) => {
        const updatedVehicleStatus = { ...vehicleStatus };
        const updatedRentalStatus = { ...rentalStatus };

        updateDate(updatedVehicleStatus, type);
        updateDate(updatedRentalStatus, type);

        setVehicleStatus(updatedVehicleStatus);
        setRentalStatus(updatedRentalStatus);
    };

    const updateDate = (status, type) => {
        if (type === "prev") {
            status.month -= 1;
        } else if (type === "next") {
            status.month += 1;
        }

        if (status.month < 1) {
            status.year -= 1;
            status.month = 12;
        } else if (status.month > 12) {
            status.year += 1;
            status.month = 1;
        }
    };

    useEffect(() => {
        fetchMySummary();
    }, [vehicleStatus, rentalStatus]);

    const [managementContractModalStatus, setManagementContractModalStatus] = useState(false);
    const [profileModal, setProfileModal] = useState(false);

    return (
        <>
            <div className="agency-head">
                <div className="myinfo">
                    <div className="member">
                        <div className="agency-icon">
                            <img src={UserIcon} alt="" />
                            <span className="agency-name">{userData?.name}</span>
                        </div>
                        <div className="agency-option">
                            <button type="button" className="btn-default" onClick={() => setProfileModal(true)}>
                                개인정보 수정
                            </button>
                            <button type="button" className="btn-default" onClick={() => navigate("/logout")}>
                                로그아웃
                            </button>
                        </div>
                    </div>
                    <div className="links">
                        <button type="button" className="btn-upload" onClick={() => navigate("/product/upload")}>
                            차량업로드
                        </button>
                        <button type="button" className="btn-account" onClick={() => navigate("/password/reset")}>
                            비밀번호 변경 <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>

                <div className="btn-viewbox">
                    <button type="button" className="btn-color-01" onClick={() => navigate("/settlement")}>
                        정산서 보기
                    </button>
                    <button type="button" className="btn-color-03" onClick={() => setManagementContractModalStatus(true)}>
                        계약서 관리
                    </button>
                </div>

                <div className="situation">
                    <div className="situation-head">
                        <button type="button" className="btn-prev">
                            <FontAwesomeIcon icon={faAngleLeft} onClick={() => handleUpdateDates("prev")} />
                        </button>
                        <span className="date">
                            {vehicleStatus?.year}년 {vehicleStatus?.month}월 계약 진행 현황
                        </span>
                        <button type="button" className="btn-next">
                            <FontAwesomeIcon icon={faAngleRight} onClick={() => handleUpdateDates("next")} />
                        </button>
                    </div>
                    <div className="situation-content">
                        <div className="item-list">
                            <div className="item">
                                <div className="item-head">
                                    <span>총 접수 계약</span>
                                </div>
                                <div className="item-body">
                                    <span className="color-blue">{summaryData?.contractTotalCount}대</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>진행 중 계약</span>
                                </div>
                                <div className="item-body">
                                    <span className="color-blue">{summaryData?.contractOnProcessCount}대</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="situation">
                    <div className="situation-head">
                        <button type="button" className="btn-prev">
                            <FontAwesomeIcon icon={faAngleLeft} onClick={() => handleUpdateDates("prev")} />
                        </button>
                        <span className="date">
                            {rentalStatus?.year}년 {rentalStatus?.month}월 계약 완료 현황
                        </span>
                        <button type="button" className="btn-next">
                            <FontAwesomeIcon icon={faAngleRight} onClick={() => handleUpdateDates("next")} />
                        </button>
                    </div>
                    <div className="situation-content">
                        <div className="item-list">
                            <div className="item">
                                <div className="item-head">
                                    <span>장기렌트</span>
                                </div>
                                <div className="item-body">
                                    <span className="color-blue">{summaryData?.longRentCount}대</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>구독</span>
                                </div>
                                <div className="item-body">
                                    <span className="color-blue">{summaryData?.firstReleaseCount}대</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-head">
                                    <span>선출고</span>
                                </div>
                                <div className="item-body">
                                    <span className="color-blue">{summaryData?.subscriptionCount}대</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 계약서 관리 모달 */}
            {managementContractModalStatus && <ManagementContract setModal={setManagementContractModalStatus} />}

            {/* 개인정보 수정 모달 */}
            {profileModal && <ProfileSupplier setModal={setProfileModal} />}
        </>
    );
};
