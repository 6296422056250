/* eslint-disable */
import React, { useState, useEffect } from "react";

export const DriverCoverage = ({ initData, onDriverCoverageChange }) => {
    const [formData, setFormData] = useState({
        personalDriverRange: "계약자와 배우자 및 계약자와 배우자의 직계가족", // 개인계약
        personalBizDriverRange: "계약자와 계약자 사업장의 임직원", // 개인 사업자 계약
        corpDriverRange: "계약자와 법인의 임직원", // 법인 사업자 계약
        subscriptionDriverRange: "계약자 기명1인 (계약자 본인만 가능)", // 구독 계약
    });

    const { personalDriverRange, personalBizDriverRange, corpDriverRange, subscriptionDriverRange } = formData;

    const handleChange = (e) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option ? e.option.value : "";
        const name = target.name ? target.name : e.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // 스테이트 리프팅
    useEffect(() => {
        onDriverCoverageChange(formData);
    }, [formData, onDriverCoverageChange]);

    useEffect(() => {
        if (initData !== undefined) {
            setFormData({
                personalDriverRange: initData?.rentInfo?.personalDriverRange,
                personalBizDriverRange: initData?.rentInfo?.personalBizDriverRange,
                corpDriverRange: initData?.rentInfo?.corpDriverRange,
                subscriptionDriverRange: initData?.rentInfo?.subscriptionDriverRange,
            });
        }
    }, [initData]);

    return (
        <section id="section-06" className="section width-100">
            <div className="section-tit">
                <h2>운전자 범위</h2>
            </div>

            <div className="section-content">
                <div className="form view-col">
                    <div className="input-form">
                        <label className="form-title">개인계약</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder=""
                                    name="personalDriverRange"
                                    value={personalDriverRange || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">개인 사업자 계약</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder=""
                                    name="personalBizDriverRange"
                                    value={personalBizDriverRange || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">법인 사업자 계약</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder=""
                                    name="corpDriverRange"
                                    value={corpDriverRange || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-form">
                        <label className="form-title">구독 계약</label>
                        <div className="form-field">
                            <div className="input-element">
                                <input
                                    type="text"
                                    placeholder=""
                                    name="subscriptionDriverRange"
                                    value={subscriptionDriverRange || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
