import React, { useEffect, useState } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { comma, uncomma, handleNumericInput } from "util";

export const Insurance = ({ setFormStatus, handleToast }) => {
    const [formData, setFormData] = useState({
        dutyDaein: 0,
        indemDaein: 0,
        dutyDaemul: 0,
        indemDaemul: 0,
        dutyJacha: 0,
        indemJachaMin: 0,
        indemJachaMax: 0,
        dutyNoInsuranceInjury: 0,
        dutyJason: 0,
        indemJason: 0,
        emergencyCallCount: 0,
    });

    const {
        dutyDaein,
        indemDaein,
        dutyDaemul,
        indemDaemul,
        dutyJacha,
        indemJachaMin,
        indemJachaMax,
        dutyNoInsuranceInjury,
        dutyJason,
        indemJason,
        emergencyCallCount,
    } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                dutyDaein: Number(uncomma(formData.dutyDaein)),
                indemDaein: Number(uncomma(formData.indemDaein)),
                dutyDaemul: Number(uncomma(formData.dutyDaemul)),
                indemDaemul: Number(uncomma(formData.indemDaemul)),
                dutyJacha: Number(uncomma(formData.dutyJacha)),
                indemJachaMin: Number(uncomma(formData.indemJachaMin)),
                indemJachaMax: Number(uncomma(formData.indemJachaMax)),
                dutyNoInsuranceInjury: Number(uncomma(formData.dutyNoInsuranceInjury)),
                dutyJason: Number(uncomma(formData.dutyJason)),
                indemJason: Number(uncomma(formData.indemJason)),
                emergencyCallCount: Number(uncomma(formData.emergencyCallCount)),
            };

            const response = await updateData(`/api/share/rent_corp/contract/v1/insurance_info`, payload);
            setFormStatus(0);
            handleToast(response.data.message);
        } catch (error) {
            handleToast(error.response.data.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const fetchContractData = async () => {
        try {
            const response = await fetchData(`/api/share/rent_corp/v1/me`);

            const contractData = response.data.data;

            setFormData({
                dutyDaein: contractData?.dutyDaein === 0 ? "" : comma(contractData?.dutyDaein) || "",
                indemDaein: contractData?.indemDaein === 0 ? "" : comma(contractData?.indemDaein) || "",
                dutyDaemul: contractData?.dutyDaemul === 0 ? "" : comma(contractData?.dutyDaemul) || "",
                indemDaemul: contractData?.indemDaemul === 0 ? "" : comma(contractData?.indemDaemul) || "",
                dutyJacha: contractData?.dutyJacha === 0 ? "" : comma(contractData?.dutyJacha) || "",
                indemJachaMin: contractData?.indemJachaMin === 0 ? "" : comma(contractData?.indemJachaMin) || "",
                indemJachaMax: contractData?.indemJachaMax === 0 ? "" : comma(contractData?.indemJachaMax) || "",
                dutyNoInsuranceInjury: contractData?.dutyNoInsuranceInjury === 0 ? "" : comma(contractData?.dutyNoInsuranceInjury) || "",
                dutyJason: contractData?.dutyJason === 0 ? "" : comma(contractData?.dutyJason) || "",
                indemJason: contractData?.indemJason === 0 ? "" : comma(contractData?.indemJason) || "",
                emergencyCallCount: contractData?.emergencyCallCount === 0 ? "" : comma(contractData?.emergencyCallCount) || "",
            });
        } catch (error) {
            handleToast(error?.response?.data?.message);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    useEffect(() => {
        fetchContractData();
    }, []);

    const handlePercentageNumericInput = (e) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9]/g, "");

        const parsedValue = parseInt(inputValue, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
            const numberWithCommas = parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            e.target.value = numberWithCommas;
        } else {
            e.preventDefault();
            e.target.value = "";
            handleToast("0에서 100 사이의 정수만 입력 가능합니다.");
        }
    };

    return (
        <>
            <div className="consignment_item-list">
                <div className="item">
                    <div className="item-head">
                        <span>대인</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="dutyDaein"
                                value={dutyDaein || ""}
                                onChange={handleChange}
                                placeholder="한도 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="indemDaein"
                                value={indemDaein || ""}
                                onChange={handleChange}
                                placeholder="면책금 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>대물</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="dutyDaemul"
                                value={dutyDaemul || ""}
                                onChange={handleChange}
                                placeholder="한도 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="indemDaemul"
                                value={indemDaemul || ""}
                                onChange={handleChange}
                                placeholder="면책금 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>자차</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="%">
                            <input
                                type="text"
                                name="dutyJacha"
                                value={dutyJacha || ""}
                                onChange={handleChange}
                                placeholder="한도 입력"
                                onInput={handlePercentageNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="indemJachaMin"
                                value={indemJachaMin || ""}
                                onChange={handleChange}
                                placeholder="최소 면책금 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="indemJachaMax"
                                value={indemJachaMax || ""}
                                onChange={handleChange}
                                placeholder="최대 면책금 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>무보험 상해가입 가입시</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="dutyNoInsuranceInjury"
                                value={dutyNoInsuranceInjury || ""}
                                onChange={handleChange}
                                placeholder="한도 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>자손</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="dutyJason"
                                value={dutyJason || ""}
                                onChange={handleChange}
                                placeholder="한도 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                        <div className="input-element tar" data-esset="원">
                            <input
                                type="text"
                                name="indemJason"
                                value={indemJason || ""}
                                onChange={handleChange}
                                placeholder="면책금 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="item-head">
                        <span>긴급출동 연 횟수</span>
                    </div>
                    <div className="item-content">
                        <div className="input-element tar" data-esset="회">
                            <input
                                type="text"
                                name="emergencyCallCount"
                                value={emergencyCallCount || ""}
                                onChange={handleChange}
                                placeholder="긴급출동 연 횟수 입력"
                                onInput={handleNumericInput}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-bottom2">
                <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                    이전
                </button>
                <button type="button" className="btn-modal" onClick={() => handleSubmit()}>
                    완료
                </button>
            </div>
        </>
    );
};
