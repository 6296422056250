/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchData, createData, createFormData, updateData, deleteData } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { getAgeCategory, comma, convertResidentRegistrationNumber, formatDriverLicenseNumber } from "util";
import { Toast } from "components";

export const ReceiptHistory = ({ setModal, showModal, handleModalClose, contractId, fetchTable }) => {
    const [formStatus, setFormStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]); // 상품 목록 상태
    const [productStatus, setProductStatus] = useState(null);
    const [formData, setFormData] = useState({
        denyReason: "",
    });

    const { denyReason } = formData;

    const handleChange = (e, name) => {
        const target = e.target ? e.target : e;
        const value = target.value ? target.value : e.option?.value;
        const inputName = target.name ? target.name : name;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: value,
        }));
    };

    const fetchContractData = async () => {
        setIsLoading(true);

        try {
            const response = await fetchData(`/api/share/rent_corp/contract/v1/${contractId}`);
            console.log(response.data.data);
            setData(response.data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("필터링된 상품 목록을 불러오는 중 에러 발생:", error);
        }
    };

    const confirmContract = async () => {
        setIsLoading(true);

        try {
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/send_customer`);
            handleToast(response.data.message);
            handleModalClose();
            fetchTable();
            setIsLoading(false);
        } catch (error) {
            handleToast(error.response.data.message);
            handleModalClose();
            setIsLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const cancelContract = async () => {
        setIsLoading(true);

        try {
            const requestData = {
                rejectReason: denyReason,
            };
            const response = await updateData(`/api/share/rent_corp/contract/v1/${contractId}/deny`, requestData);
            handleToast(response.data.message);
            handleModalClose();
            fetchTable();
            setIsLoading(false);
        } catch (error) {
            handleToast(error.response.data.message);
            handleModalClose();
            setIsLoading(false);
            console.error("API 호출 중 에러 발생:", error);
        }
    };

    const onSubmit = () => {
        confirmContract();
    };

    const onCancel = () => {
        cancelContract();
    };

    useEffect(() => {
        fetchContractData();
    }, []);

    // 토스트 팝업
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const handleToast = (txt, duration) => {
        setToastStatus(duration ? duration : 1000);
        setToastMsg(txt);
    };

    useEffect(() => {
        if (toastStatus && toastStatus !== Infinity) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, toastStatus);
        }
    }, [toastStatus]);

    return (
        <>
            {isLoading && (
                <div className="loading">
                    <svg className="loading-circle">
                        <circle cx="50%" cy="50%" r="25"></circle>
                    </svg>
                </div>
            )}

            <div className="modal-layer"></div>

            {!isLoading && (
                <div className="modal modal-type-2">
                    <div className="modal-title">
                        <span className="modal-tit-main">
                            {formStatus === 0 && "접수내역"}
                            {formStatus === 1 && "계약 접수 불가"}
                        </span>
                        <button type="button" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                    <div className="modal-content">
                        {/* 접수내역 폼 */}
                        {formStatus === 0 && (
                            <div className="contract-detail">
                                <div className="item-wrapper">
                                    <div className="item-head">
                                        <span>임차인 정보</span>
                                    </div>
                                    <div className="item-list grid-row">
                                        <div className="item width-100">
                                            <span className="item-tit">성명</span>
                                            <span className="item-desc">{data?.mainDriver.name}</span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">면허번호</span>
                                            <span className="item-desc">
                                                {formatDriverLicenseNumber(data?.mainDriver.driverLicenseNumber)}{" "}
                                                {data?.mainDriver.expLt1Yn === "Y" && "(면허취득 1년 미만)"}
                                            </span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">운전연령</span>
                                            <span className="item-desc">{getAgeCategory(data?.mainDriver.idNumber)}</span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">주민번호</span>
                                            <span className="item-desc">
                                                {convertResidentRegistrationNumber(data?.mainDriver.idNumber)}
                                            </span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">전화번호</span>
                                            <span className="item-desc">{data?.customerPhone}</span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">주소</span>
                                            <span className="item-desc">
                                                {data?.customerAddress} {data?.customerAddressDetail}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {data?.drivers.length > 1 && (
                                    <div className="item-wrapper">
                                        <div className="item-head">
                                            <span>추가 운전자 정보</span>
                                        </div>
                                        {data?.drivers?.map((driver, idx) => {
                                            if (driver.mainDriverYn === "Y") return null;

                                            return (
                                                <div className="item-list grid-row" key={idx}>
                                                    <div className="item width-100">
                                                        <span className="item-tit">성명</span>
                                                        <span className="item-desc">{driver.name}</span>
                                                    </div>
                                                    <div className="item width-100">
                                                        <span className="item-tit">면허번호</span>

                                                        <span className="item-desc">
                                                            {formatDriverLicenseNumber(driver.driverLicenseNumber)}
                                                            {driver.expLt1Yn === "Y" && "(면허취득 1년 미만)"}
                                                        </span>
                                                    </div>
                                                    <div className="item width-100">
                                                        <span className="item-tit">주민번호</span>
                                                        <span className="item-desc">
                                                            {convertResidentRegistrationNumber(driver.idNumber)}
                                                        </span>
                                                    </div>
                                                    <div className="item width-100">
                                                        <span className="item-tit">운전연령</span>
                                                        <span className="item-desc">{getAgeCategory(driver.idNumber)}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <div className="item-wrapper">
                                    <div className="item-head">
                                        <span>렌트 정보</span>
                                    </div>
                                    <div className="item-list grid-row">
                                        <div className="item width-100">
                                            <span className="item-tit">개월수</span>
                                            <span className="item-desc">{data?.contractRangeMonth}개월</span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">보증금/렌탈료</span>
                                            <span className="item-desc">
                                                {comma(data?.depositPrice)} / {comma(data?.rentPrice)}
                                            </span>
                                        </div>
                                        <div className="item width-100">
                                            <span className="item-tit">보증금 분납</span>
                                            <span className="item-desc">
                                                {data?.depositType === "FULL" && "일시납"}
                                                {data?.depositType === "DIVIDE_2" && "2회분납"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-wrapper border-none">
                                    <div className="item-head">
                                        <span>첨부된 파일</span>
                                    </div>
                                    <div className="file-list">
                                        {data?.contractUploads?.map((file, idx) => {
                                            return (
                                                <div className="file" key={idx}>
                                                    <span className="file-tit">{file.originFileName}</span>
                                                    <button
                                                        type="button"
                                                        className="btn-download_file"
                                                        onClick={() => {
                                                            const url = file.uploadUrl;
                                                            window.open(url);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </button>
                                                    {/* <button
                                                        type="button"
                                                        className="btn-download_file"
                                                        onClick={async () => {
                                                            try {
                                                                const response = await fetchData(file.uploadUrl);
                                                                const blob = await response.blob();
                                                                const url = window.URL.createObjectURL(blob);
                                                                const link = document.createElement("a");
                                                                link.href = url;
                                                                link.download = file.originFileName;
                                                                document.body.appendChild(link);
                                                                link.click();
                                                                document.body.removeChild(link);
                                                                window.URL.revokeObjectURL(url);
                                                            } catch (error) {
                                                                console.error("Download failed:", error);
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </button> */}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* 계약 접수 불가 폼 */}
                        {formStatus === 1 && (
                            <div className="modal-form">
                                <div className="content-tit">
                                    <span>계약 접수 불가 사유를 입력해주세요.</span>
                                </div>
                                <div className="content-form">
                                    <div className="textarea-element">
                                        <textarea
                                            name="denyReason"
                                            value={denyReason || ""}
                                            onChange={handleChange}
                                            placeholder="계약 접수 불가 사유를 입력해주세요."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {formStatus === 0 && (
                        <div className="modal-bottom no-rounded">
                            <button type="button" className="btn-modal" onClick={() => setFormStatus(1)} style={{ color: "#e24f40" }}>
                                계약 접수 불가
                            </button>
                            <button
                                type="button"
                                className="btn-modal"
                                onClick={() => {
                                    showModal("", <p>계약서를 발송하시겠습니까?</p>, [
                                        { text: "취소", handler: handleModalClose },
                                        { text: "확인", handler: onSubmit },
                                    ]);
                                }}
                            >
                                계약 승인
                            </button>
                        </div>
                    )}
                    {formStatus === 1 && (
                        <div className="modal-bottom no-rounded">
                            <button type="button" className="btn-modal" onClick={() => setFormStatus(0)}>
                                이전
                            </button>
                            <button type="button" className="btn-modal" style={{ color: "#e24f40" }} onClick={() => onCancel()}>
                                계약 접수 불가
                            </button>
                        </div>
                    )}
                </div>
            )}

            <Toast visible={toastStatus} onClose={setToastStatus} msg={toastMsg} />
        </>
    );
};
